import React, { useCallback, useEffect, useState } from "react";

import { Heading, Button } from "@atd/components";
import { Main } from "@atd/layouts";
import { useParams } from "react-router-dom";
import { fetchTeams, resetReviews } from "@atd/features";
import { isAdmin, getFilterOptions } from "@atd/utils";
import { useFiltersQuery } from "@atd/features/request";
import { useSelector, useDispatch } from "react-redux";
import { flattenDeep } from "lodash";
import DesignerReviews from "../TeamReport/DesignerReviews/DesignerReviews";
import PerformanceReport from "./PerformanceReport";
import LocationBasedReport from "./LocationBasedReport";
import TotalTicketReport from "./TotalTicketReport";
import RevisionTicketReport from "./RevisionTicketReport";
import DailyReport from "../TeamReport/DailyReport";
import useInfiniteScroll from "@closeio/use-infinite-scroll";
import { useLocalStorage } from "@atd/hooks";
const Report = ({
  path,
  params,
  queryParam,
  dateFilterOptions,
  designerFilterOptions,
  onChangeFilter,
  setIsResetData,
  loaderRef,
  page,
  onResetData,
}) => {
  return (
    <>
      {
        {
          review: (
            <DesignerReviews
              path={path}
              designerFilterOptions={designerFilterOptions}
              dateFilterOptions={dateFilterOptions}
              setIsResetData={setIsResetData}
              loaderRef={loaderRef}
              page={page}
              onResetData={onResetData}
            />
          ),
          performance: (
            <PerformanceReport
              params={params}
              dateFilterOptions={dateFilterOptions}
              onChangeFilter={onChangeFilter}
            />
          ),
          location: (
            <LocationBasedReport
              params={params}
              dateFilterOptions={dateFilterOptions}
              onChangeFilter={onChangeFilter}
            />
          ),
          total: (
            <TotalTicketReport
              params={params}
              designerFilterOptions={designerFilterOptions}
              dateFilterOptions={dateFilterOptions}
              onChangeFilter={onChangeFilter}
            />
          ),
          revision: (
            <RevisionTicketReport
              params={params}
              designerFilterOptions={designerFilterOptions}
              dateFilterOptions={dateFilterOptions}
              onChangeFilter={onChangeFilter}
            />
          ),
          dailyreport: (
            <DailyReport
              queryParam={queryParam}
              path={path}
              dateFilterOptions={dateFilterOptions}
              designerFilterOptions={designerFilterOptions}
            />
          ),
        }[path]
      }
    </>
  );
};

const AnalyticalReport = () => {
  const params = {
    country: "all",
    designer: "all",
    date_string: "this_year",
  };
  const { path, id } = useParams();
  const [teamFilters, setTeamFilters] = useLocalStorage("team-filters", params);
  const [queryParams, setQueryParams] = useState({
    ...params,
    ...teamFilters[path],
  });
  const [isResetData, setIsResetData] = useState(false);

  const { teams, hasMore } = useSelector((state) => state.team);
  const [page, loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore,
    reset: isResetData,
  });
  const dispatch = useDispatch();

  const users = flattenDeep([...teams.map((team) => team.users)]);
  const { data: filters, isSuccess } = useFiltersQuery(
    isAdmin() ? "new" : "queue",
  );
  const { date: dateFilter } = filters || {};
  const dateFilterOptions = isSuccess ? getFilterOptions(dateFilter) : [];
  let designerFilterOptions = getFilterOptions(users);
  if (path !== "dailyreport") {
    designerFilterOptions.unshift({ label: "All Designers", value: "all" });
  }

  const onResetData = useCallback(() => {
    if (path === "review") {
      setIsResetData(true);
      dispatch(resetReviews());
    }
  }, [dispatch, path]);

  const onChangeFilter = (field, value) => {
    onResetData();
    setQueryParams((prevParams) => ({
      ...prevParams,
      [field]: value,
    }));
    setTeamFilters((prevParams) => ({
      ...prevParams,
      ...{ [path]: { ...queryParams, [field]: value } },
    }));
  };
  useEffect(() => {
    dispatch(
      fetchTeams({
        type: "accepted",
        dropdown: "yes",
        delete: "yes",
      }),
    );
  }, [dispatch]);

  useEffect(
    () => () => {
      if (path === "review") {
        onResetData();
      }
    },
    [onResetData, path, id],
  );

  useEffect(
    () => () => {
      if (path === "review") {
        dispatch(resetReviews());
      }
    },
    [dispatch, path],
  );

  return (
    <Main ref={scrollerRef} onResetData={onResetData}>
      <Heading
        headText="Team"
        rightContent={
          <Button
            variant="default"
            label="Back to Reports"
            icon="left-arrow"
            onClick={() =>
              (window.location.href = id
                ? "/reports/analytics/dailyreport"
                : "/reports/team")
            }
          />
        }
      />
      <Report
        path={path}
        queryParam={id}
        params={queryParams}
        dateFilterOptions={dateFilterOptions}
        designerFilterOptions={designerFilterOptions}
        onChangeFilter={onChangeFilter}
        setIsResetData={setIsResetData}
        onResetData={onResetData}
        loaderRef={loaderRef}
        page={page}
      />
    </Main>
  );
};

export default AnalyticalReport;
