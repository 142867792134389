import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCreatedDate } from "@atd/utils";
import { ContentState, Box, EmptyState } from "@atd/components";
import { fetchTeams, fetchClients, getClientsCount } from "@atd/features";
import { useLocalStorage } from "@atd/hooks";
import ClientsTable from "./ClientsTable";

const tabList = [
  { id: "all", value: "All" },
  { id: "active", value: "Active Subscription" },
  { id: "inactive", value: "Inactive Subscription" },
  { id: "idle", value: "Inactive" },
  { id: "active_users", value: "Active" },
  { id: "trial_users", value: "Trial" },
];

function ClientsList({
  page,
  role,
  loaderRef,
  hasPermission,
  setIsResetData,
  onResetData,
}) {
  const params = {
    status: "all",
    page: 1,
  };
  const [customersFilter, setCustomersFilter] = useLocalStorage(
    "customers-filters",
    params,
  );
  useEffect(() => {
    setCustomersFilter(customersFilter);
    return () => {};
  }, [customersFilter, setCustomersFilter]);
  const customerParams = Object.keys(customersFilter).reduce((acc, val) => {
    const value =
      val === "Created date"
        ? getCreatedDate(val, customersFilter)
        : customersFilter[val];
    return { ...acc, ...value };
  }, {});
  const [queryParams, setQueryParams] = useState({
    ...params,
    ...customerParams,
  });

  const { counts, fetching, hasError, error, hasMore, clients } = useSelector(
    (state) => state.client,
  );
  const { teams } = useSelector((state) => state.team);
  const dispatch = useDispatch();

  useEffect(() => {
    const realPage = page + 1;

    const promise = dispatch(
      fetchClients({
        ...queryParams,
        limit: 20,
        page: realPage,
      }),
    );

    promise.then(() => {
      setIsResetData(false);
      dispatch(getClientsCount(queryParams));
    });
    return () => {
      promise.abort();
    };
  }, [page, queryParams, setIsResetData, dispatch]);

  useEffect(() => {
    dispatch(fetchTeams({ type: "accepted", dropdown: "yes", delete: "yes" }));
  }, [dispatch]);

  const onClickTabItem = (index) => {
    setIsResetData(true);
    onResetData();
    setQueryParams({
      ...queryParams,
      status: tabList[index].id,
    });
    // setCustomersFilter({
    //   ...queryParams,
    //   ...{ status: tabList[index].id },
    // });
  };

  const hasErrors = hasError && error?.message !== "Aborted";

  return (
    <>
      <ClientsTable
        error={error}
        data={clients}
        users={teams}
        role={role}
        counts={counts}
        fetching={fetching}
        tabList={tabList}
        hasError={hasError}
        hasPermission={hasPermission}
        onClickTabItem={onClickTabItem}
      />
      {(hasMore || fetching) && !hasErrors && (
        <Box ref={loaderRef}>
          <ContentState text="Loading..." />
        </Box>
      )}
      {clients?.length === 0 && !fetching && (
        <EmptyState
          icon="client"
          title="No results found"
          description={
            <span>There aren&rsquo;t any results for that query.</span>
          }
        />
      )}
      {hasError && error.message !== "Aborted" && (
        <EmptyState
          icon="client"
          title="No results found"
          description={
            <span>There aren&rsquo;t any results for that query.</span>
          }
        />
      )}
    </>
  );
}

export default ClientsList;
