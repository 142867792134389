import * as Yup from "yup";

export const AccountSchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string(),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "Password is too short - should be 8 chars minimum."),
  company_name: Yup.string().required("Required"),
  recaptcha: Yup.string().required(),
  phone: Yup.string().nullable().required("Required"),
});

export const AcceptSchema = Yup.object().shape({
  full_name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "Password is too short - should be 8 chars minimum."),
});

export const StripeSchema = Yup.object().shape({
  full_name: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  line1: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  postal_code: Yup.string().required("Required"),
  // stripe_token: Yup.string().required("Required"),
  recaptcha: Yup.string().required(),
});
