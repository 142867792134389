import { forwardRef } from "react";
import styled, { css } from "styled-components";

import { More as MoreIcon } from "@atd/components/Icon/icons";

const More = styled.div`
  color: #4f566a;
  font-size: 28px !important;
  cursor: pointer;
  line-height: 14px;
  vertical-align: super;
  min-height: 24px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.24s, box-shadow 0.24s;
  text-align: center;
  width: 32px;

  &:hover {
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
      rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
      rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 3px 9px 0px,
      rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    background-color: rgb(255, 255, 255);
  }

  ${(props) =>
    props?.isVisible &&
    css`
      box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px,
        rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(60 66 87 / 8%) 0px 3px 9px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;
      background-color: rgb(255, 255, 255);
    `}
`;

const MoreButton = forwardRef((props, ref) => (
  <More ref={ref} {...props}>
    <MoreIcon />
  </More>
));

export default MoreButton;
