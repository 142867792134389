import * as React from "react";

function SvgMore(props) {
  return (
    <svg
      aria-hidden="true"
      className="More_svg__SVGInline-svg More_svg__SVGInline--cleaned-svg More_svg__SVG-svg More_svg__Icon-svg More_svg__Icon--more-svg More_svg__Button-icon-svg More_svg__Icon-color-svg More_svg__Icon-color--gray600-svg"
      height={12}
      width={12}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgMore;
