import React, { useMemo, useState } from "react";
import { Box, GroupBy, DataTable } from "@atd/components";
import styled from "styled-components";
import Border from "../../Border";
import { useGetDesignerReportQuery } from "@atd/features/team";
import { useLocalStorage } from "@atd/hooks";
const status = [
  { label: "Assigned", value: "assigned" },
  { label: "Delivered", value: "delivered" },
  { label: "Closed", value: "closed" },
  { label: "Ratings", value: "ratings" },
];
const getColorByValue = (value, key) => {
  let bgColor = "";
  if (key === "ratings") {
    if (value >= 0 && value <= 1) {
      bgColor = "light_blue";
    } else if (value > 1 && value <= 2) {
      bgColor = "sky_blue";
    } else if (value > 2 && value <= 3) {
      bgColor = "aqua_blue";
    } else if (value > 3 && value <= 4) {
      bgColor = "navy_blue";
    } else if (value > 4 && value <= 5) {
      bgColor = "blue";
    }
  } else {
    if (value >= 0 && value <= 20) {
      bgColor = "light_blue";
    } else if (value > 20 && value <= 40) {
      bgColor = "sky_blue";
    } else if (value > 40 && value <= 60) {
      bgColor = "aqua_blue";
    } else if (value > 60 && value <= 80) {
      bgColor = "navy_blue";
    } else if (value > 80 && value <= 100) {
      bgColor = "blue";
    }
  }

  return bgColor;
};
const DesignerReportDataTable = styled(DataTable)`
  .report_text {
    color: #697386;

    border-color: #e3e8ee !important;
  }
  td:nth-child(-n + 2) {
    background: #ffff !important;
    color: #697386 !important;
    border-color: #e3e8ee !important;
  }
  td:nth-child(1) .report_text {
    margin-right: 0px;
  }
  td:nth-child(1) {
    text-align: start;
  }
  td.light_blue {
    background-color: #daeaff !important;
    border-color: #daeaff !important;
    color: #00000 !important;
    padding: 0px 8px;
  }
  td.sky_blue {
    background-color: #a6cdff !important;
    border-color: #a6cdff !important;
    color: #fff !important;
    padding: 0px 8px;
  }
  td.aqua_blue {
    background-color: #7fabf8 !important;
    border-color: #7fabf8 !important;
    color: #fff !important;
    padding: 0px 8px;
  }
  td.navy_blue {
    background-color: #708cef !important;
    border-color: #708cef !important;
    color: #fff !important;
    padding: 0px 8px;
  }
  td.blue {
    background-color: #5769d6 !important;
    border-color: #5769d6 !important;
    color: #fff !important;
    padding: 0px 8px;
  }
  td {
    border: 1px solid #e3e8ee;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    padding: 4px 8px;
  }
  tbody tr {
    border-bottom: 1px solid #e3e8ee;
    border-top: 1px solid #e3e8ee;
  }
  th {
    font-size: 12px;
    color: #697386;
    text-align: left;
    text-transform: capitalize;
    font-weight: 400;
  }
`;
const getFilterOptions = (options) => {
  if (options) {
    return options.map((option) => ({
      label: option,
      value: option,
    }));
  }
  return [];
};
function DesignerReport() {
  const currentYear = new Date().getFullYear();
  const params = {
    year: currentYear,
    select: "ratings",
  };
  const [designerReportFilters, setDesignerReportFilters] = useLocalStorage(
    "designer-report-filters",
    params,
  );
  const [queryParams, setQueryParams] = useState(designerReportFilters);

  const { data, isLoading } = useGetDesignerReportQuery(queryParams);
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step,
    );
  const yearFilterOptions = getFilterOptions(range(currentYear, 2019, -1));
  const onChangeFilter = (field, value) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      [field]: value,
    }));
    setDesignerReportFilters((prevParams) => ({
      ...prevParams,
      [field]: value,
    }));
  };
  const columns = useMemo(
    () => [
      {
        Header: "Designer",
        accessor: "name",
        Cell: ({ value }) => <span className="report_text">{value}</span>,
      },
      {
        Header: "Overall %",
        accessor: "performance",
        Cell: ({ value }) => <span className="report_text">{value}</span>,
      },
      {
        Header: "Month 1",
        accessor: "month_1",
        Cell: ({ value }) => <span>{value}</span>,
        className: (props) => getColorByValue(props, queryParams?.select),
      },
      {
        Header: "Month 2",
        accessor: "month_2",
        Cell: ({ value }) => <span>{value}</span>,
        className: (props) => getColorByValue(props, queryParams?.select),
      },
      {
        Header: "Month 3",
        accessor: "month_3",
        Cell: ({ value }) => <span>{value}</span>,
        className: (props) => getColorByValue(props, queryParams?.select),
      },
      {
        Header: "Month 4",
        accessor: "month_4",
        Cell: ({ value }) => <span>{value}</span>,
        className: (props) => getColorByValue(props, queryParams?.select),
      },
      {
        Header: "Month 5",
        accessor: "month_5",
        Cell: ({ value }) => <span>{value}</span>,
        className: (props) => getColorByValue(props, queryParams?.select),
      },
      {
        Header: "Month 6",
        accessor: "month_6",
        Cell: ({ value }) => <span>{value}</span>,
        className: (props) => getColorByValue(props, queryParams?.select),
      },
      {
        Header: "Month 7",
        accessor: "month_7",
        Cell: ({ value }) => <span>{value}</span>,
        className: (props) => getColorByValue(props, queryParams?.select),
      },
      {
        Header: "Month 8",
        accessor: "month_8",
        Cell: ({ value }) => <span>{value}</span>,
        className: (props) => getColorByValue(props, queryParams?.select),
      },
      {
        Header: "Month 9",
        accessor: "month_9",
        Cell: ({ value }) => <span>{value}</span>,
        className: (props) => getColorByValue(props, queryParams?.select),
      },
      {
        Header: "Month 10",
        accessor: "month_10",
        Cell: ({ value }) => <span>{value}</span>,
        className: (props) => getColorByValue(props, queryParams?.select),
      },
      {
        Header: "Month 11",
        accessor: "month_11",
        Cell: ({ value }) => <span>{value}</span>,
        className: (props) => getColorByValue(props, queryParams?.select),
      },
      {
        Header: "Month 12",
        accessor: "month_12",
        Cell: ({ value }) => <span>{value}</span>,
        className: (props) => getColorByValue(props, queryParams?.select),
      },
    ],
    [queryParams?.select],
  );
  return (
    <Box flex={{ direction: "column" }}>
      <Box
        flex={{
          direction: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
        className="ccss27"
      >
        <Box
          flex={{
            direction: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="Padding-top--8 Padding-bottom--16 pointer2"
        >
          <GroupBy
            field="year"
            placeholder="Year"
            value={queryParams?.year}
            options={yearFilterOptions}
            onChange={onChangeFilter}
          />
          <GroupBy
            field="select"
            placeholder="Select"
            value={queryParams?.select}
            options={status}
            onChange={onChangeFilter}
          />
        </Box>
      </Box>
      <Border
        border={{ position: "bottom" }}
        margin={{ mt: "0px", mb: "0px" }}
      />
      <DesignerReportDataTable
        tabs={[""]}
        withTabs={false}
        isLoading={isLoading}
        columns={columns}
        data={data}
      />
      <Border margin={{ mt: "40px", mb: "0px" }} />
    </Box>
  );
}

export default DesignerReport;
