import React, { useState } from "react";
import { DayPickerRangeController } from "react-dates";
import { START_DATE, END_DATE } from "react-dates/constants";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import "./DatePicker.css";

function DatePicker({
  startDate,
  endDate,
  autoFocus,
  autoFocusEndDate,
  onDatesChange,
  ...props
}) {
  let focusedInput = null;
  if (autoFocus) {
    focusedInput = START_DATE;
  } else if (autoFocusEndDate) {
    focusedInput = END_DATE;
  }

  const [focusedInputState, setFocusedInputState] = useState(focusedInput);

  const onFocusChange = (value) => {
    setFocusedInputState(!value ? START_DATE : value);
  };

  return (
    <div className="DayPicker-container">
      <DayPickerRangeController
        startDate={startDate}
        endDate={endDate}
        onDatesChange={onDatesChange}
        focusedInput={focusedInputState}
        onFocusChange={onFocusChange}
        hideKeyboardShortcutsPanel
        // initialVisibleMonth={() => moment().add(2, "M")}
        {...props}
      />
    </div>
  );
}

export default DatePicker;
