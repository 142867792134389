import styled from "styled-components";
const Border = styled.div`
  margin-bottom: ${({ margin }) => margin.mb};
  margin-top: ${({ margin }) => margin.mt};
  padding: ${(padding) => padding};
  border: 0;
  ${({ border }) =>
    border?.position ? `border-${border.position}: 1px solid #e3e8ee;` : null};
`;
export default Border;
