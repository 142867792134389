import axios from "axios";
import { getCredentialCookies } from "../utils/tokenUtilities";

// Create instance of axios
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// axiosClient.interceptors.response.use(
//   (response) => {
//     if (
//       response.data &&
//       response.data.status &&
//       response.data.status === "error"
//     ) {
//       return Promise.reject(
//         new Error(response.data.message || "Request failed")
//       );
//     }
//     return response;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

axiosClient.interceptors.request.use((config) => {
  // Get token from cookies
  const { token } = getCredentialCookies();

  config.headers.Authorization = `Bearer ${token}`;
  config.params = config.params || {};

  // if (getCompanyId()) {
  //   config.params["company_id"] = getCompanyId();
  // }

  return config;
});

export default axiosClient;
