import styled from "styled-components";

export const Loader = styled.div`
  height: 100%;
  width: 230px;
  padding: 1.6rem;
  padding-bottom: ${(props) => (props.variant === "plans" ? "0rem" : "auto")};
  border: ${(props) => (props.variant === "plans" ? "1px solid #cfd4da" : "")};
  border-radius: 10px;
  div {
    margin-bottom: 1rem;
    width: 100%;
  }
  .para__load {
    height: 20px;
  }
  .heading__load {
    height: 40px;
  }
`;

export const Load = styled.div`
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  height: 16px;
  width: ${(props) => (props.variant === "amount" ? "100px" : "")};
  border-radius: 1rem;
  @keyframes skeleton-loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;
