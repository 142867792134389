import classnames from "classnames";

import "./Text.css";

function Text(props) {
  const {
    as: Component = "span",
    color = "gray",
    display = "inline",
    lineHeight = 20,
    numericSpacing = "proportional",
    size = 14,
    typeface = "base",
    weight,
    wordBreak,
    visuallyHidden,
    wrap = "wrap",
    children,
    className,
    ...rest
  } = props;

  const classes = classnames(className, {
    [`Text-color--${color}`]: color,
    [`Text-fontSize--${size}`]: size,
    [`Text-fontWeight--${weight}`]: weight,
    [`Text-lineHeight--${lineHeight}`]: lineHeight,
    [`Text-numericSpacing--${numericSpacing}`]: numericSpacing,
    [`Text-typeface--${typeface}`]: typeface,
    [`Text-display--${display}`]: display,
    [`Text-wrap--${wrap}`]: wrap,
    [`Text-wrap--${wordBreak}`]: wordBreak,
    "Text--visuallyHidden": visuallyHidden,
  });

  return (
    <Component className={classes} {...rest}>
      {children}
    </Component>
  );
}

export default Text;
