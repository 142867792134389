import { ErrorMessage } from "formik";
import classnames from "classnames";

function FormFieldInputError({ name, className, ...rest }) {
  return (
    <ErrorMessage name={name}>
      {(msg) => (
        <div
          className={classnames("FormField__input--error", className)}
          {...rest}
        >
          {msg}
        </div>
      )}
    </ErrorMessage>
  );
}

export default FormFieldInputError;
