import React from "react";
import classNames from "classnames";

import styles from "./Typography.module.css";

function Typography({ children, ...props }) {
  const {
    as: Component = "h1",
    size = "medium",
    uiText,
    color,
    id,
    heading,
    bold,
    italic,
  } = props;

  const classes = classNames({
    [styles[size]]: size,
    [styles.uiText]: uiText,
    [styles.base]: uiText,
    [styles.displayText]: !uiText,
    [styles.defaultColor]: color === "default",
    [styles.lightColor]: color === "light",
    [styles.errorColor]: color === "error",
    [styles.linkColor]: color === "link",
    [styles.mediumColor]: color === "medium",
    [styles.invertedColor]: color === "inverted",
    [styles.heading]: heading,
    [styles.bold]: bold,
    [styles.italic]: italic,
    // [styles.heading]: ["h1", "h2", "h3", "h4", "h5", "h6"].includes(Component),
  });

  return (
    <Component className={classes} id={id}>
      {children}
    </Component>
  );
}

export default Typography;
