import * as React from "react";

function SvgRightArrow(props) {
  return (
    <svg
      aria-hidden="true"
      className="RightArrow_svg__SVGInline-svg RightArrow_svg__SVGInline--cleaned-svg RightArrow_svg__SVG-svg RightArrow_svg__Icon-svg RightArrow_svg__Icon--arrowRight-svg RightArrow_svg__Icon-color-svg RightArrow_svg__Icon-color--gray600-svg"
      height={12}
      width={12}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.583 7 7.992 2.409A1 1 0 1 1 9.407.993l6.3 6.3a1 1 0 0 1 0 1.414l-6.3 6.3a1 1 0 0 1-1.415-1.416L12.583 9H1a1 1 0 1 1 0-2z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgRightArrow;
