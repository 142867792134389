import { Table } from "@atd/components";
import { isAdmin } from "@atd/utils";

import TeamsListItem from "./TeamsListItem";

function TeamsList({ roles, items, showActions, setIsOpenEditModal }) {
  return (
    <Table className="table table-bordered mb-0 wt-100 mgt1" id="teams">
      <Table.Head>
        <Table.Row>
          <Table.Cell
            component="th"
            scope="col"
            className="border-0 tb-title pl5"
          >
            NAME
          </Table.Cell>
          <Table.Cell
            component="th"
            scope="col"
            className="border-0 tb-title pl5"
          >
            EMAIL
          </Table.Cell>
          <Table.Cell
            component="th"
            scope="col"
            className="border-0 tb-title pl5"
          >
            ROLE
          </Table.Cell>
          {isAdmin() && (
            <Table.Cell
              component="th"
              scope="col"
              className="border-0 tb-title pl5"
            >
              LAST ACTIVITY
            </Table.Cell>
          )}
          <Table.Cell
            component="th"
            scope="col"
            className="border-0 tb-title pl5"
          >
            STATUS
          </Table.Cell>
          {/* <Table.Cell
            component="th"
            scope="col"
            className="border-0 tb-title pl5"
          >
            VERIFICATION
          </Table.Cell> */}
          {showActions && (
            <Table.Cell
              component="th"
              scope="col"
              className="border-0"
              style={{ width: "4%" }}
            />
          )}
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {items.length > 0 &&
          items.map((team) => {
            const role = roles.find((r) => r.id === team.role);
            return (
              <TeamsListItem
                key={team.id}
                roles={roles}
                team={{ ...team, roleName: role?.name }}
                showActions={showActions}
                setIsOpenEditModal={setIsOpenEditModal}
              />
            );
          })}
        {items.length === 0 && (
          <Table.Empty colSpan={5}>No Items Found</Table.Empty>
        )}
      </Table.Body>
    </Table>
  );
}

export default TeamsList;
