import { useCallback, useState } from "react";
import useInfiniteScroll from "@closeio/use-infinite-scroll";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Main } from "@atd/layouts";
import { AppHeader } from "@atd/components";

const withDataTablePage =
  (mapProps, entity) =>
  (WrappedComponent) =>
  ({ ...ownProps }) => {
    const defaultProps = { withMainLayout: true };

    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const props = mapProps({ ...ownProps, location, navigate, params });

    const allProps = Object.assign(defaultProps, props);

    const { action, title, withMainLayout, hasTabs, headerMargin } =
      allProps || {};

    const [isResetData, setIsResetData] = useState(false);

    const state = useSelector((state) => state[entity]);
    const { hasError, hasMore } = state || {};

    const [page, loaderRef, scrollerRef] = useInfiniteScroll({
      hasMore,
      reset: isResetData,
    });

    const setResetData = useCallback((value) => {
      setIsResetData(value);
    }, []);

    const renderComponent = (
      <>
        <AppHeader
          title={title}
          spread="appContent"
          hasTabs={hasTabs}
          marginBottom={headerMargin}
          // tabs={
          //   <Tabs activeTab={activeTab} setActiveTab={onClickTabItem}>
          //     {tabList.map((item, index) => (
          //       <Tab
          //         key={item.id}
          //         tabTitle={item.value}
          //         tabSlug={item.id}
          //         className="tab-item capitalize"
          //         count={
          //           counts?.[item.id] && isAdmin() ? counts[item.id] : null
          //         }
          //       >
          //         {counts?.[item.id] && isAdmin() ? (
          //           <Badge style={{ marginLeft: 5 }}>{counts[item.id]}</Badge>
          //         ) : null}
          //       </Tab>
          //     ))}
          //   </Tabs>
          // }
        >
          {action}
        </AppHeader>
        <WrappedComponent
          {...props}
          {...ownProps}
          {...{ [entity]: state }}
          page={page}
          hasMore={hasMore}
          loaderRef={loaderRef}
          hasError={hasError}
          setResetData={setResetData}
        />
      </>
    );

    if (!withMainLayout) {
      return renderComponent;
    }

    return <Main ref={scrollerRef}>{renderComponent}</Main>;
  };

export default withDataTablePage;
