import { useState, useEffect } from "react";

import { Button, Select } from "@atd/components";

function Status({ value, options, onChange }) {
  const [fieldValue, setFieldValue] = useState([]);

  useEffect(() => {
    if (!value) {
      setFieldValue([]);
    }
  }, [value]);

  return (
    <div
      className="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8 pointer1"
      id="filter-status"
    >
      <div className="Box-root Flex-flex">
        <Select
          value={fieldValue ?? []}
          multiple
          className="Box-root Flex-flex wt-auto"
          options={options}
          onChange={(newValue) => {
            setFieldValue(newValue);
          }}
          optionWrapperProps={{
            style: { width: 145 },
          }}
          render={({ formattedValue }) => {
            const newValue = formattedValue ? formattedValue?.split(",") : [];
            return (
              <button className="db-InlineFilterButton btn" type="button">
                {newValue?.length > 1 && `${newValue?.length} Status`}
                {newValue?.length === 1 && newValue}
                {newValue?.length === 0 && "Status"}
              </button>
            );
          }}
          renderFooter={({ instance, value: statusValue }) => {
            const { onClose } = instance || {};
            return (
              <Select.Footer>
                <div className="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8 pointer1">
                  <Button
                    variant="primary"
                    label="Apply"
                    onClick={(e) => {
                      e.stopPropagation();
                      onChange("filter_status", statusValue.join(","));
                      onClose();
                    }}
                  />
                </div>
                <div className="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8 pointer1">
                  <Button
                    variant="link"
                    label="Clear"
                    disabled={statusValue.length === 0}
                    onClick={(e) => {
                      e.stopPropagation();
                      setFieldValue([]);
                      onChange("filter_status", null);
                      onClose();
                    }}
                  />
                </div>
              </Select.Footer>
            );
          }}
        />
      </div>
    </div>
  );
}

export default Status;
