import styled from "styled-components";

import Box from "../Box/Box";

const Pressable = styled(Box)`
  position: relative;
  min-height: 24px;
  max-width: 100%;

  & > div {
    border-radius: 4px;
  }
`;

export default Pressable;
