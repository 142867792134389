import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import Badge from "../Badge/Badge";

import styles from "./Tabs.module.css";

const TabsContext = React.createContext({
  activeTab: "",
  setActiveTab: () => {},
});

function Tabs({
  children,
  type,
  border,
  fullWidth,
  center,
  withMargin,
  activeTab,
  setActiveTab,
  renderOnEnd,
  renderEndClassName,
}) {
  const tabs = React.Children.toArray(children);

  const [_activeTab, _setActiveTab] = useState(
    tabs[0].props.tabSlug || tabs[0].props?.tabTitle,
  );

  const classes = classnames(styles.tabs, {
    [styles.border]: border,
    [styles.withMargin]: withMargin,
    [styles.fullWidth]: fullWidth,
    [styles.center]: center,
  });

  return (
    <TabsContext.Provider
      value={{
        activeTab: activeTab || _activeTab,
        setActiveTab,
      }}
    >
      <ul data-testid="tab-list" className={classes}>
        {tabs.map((tab, index) => (
          <li className={styles.tab} key={index}>
            {type === "link" ? (
              <NavLink
                className={styles.tabButton}
                activeClassName={styles.active}
                to={tab.props.link}
              >
                {tab.props.icon && (
                  <div className={styles.icon}>
                    <span>{tab.props.icon}</span>
                  </div>
                )}
                {tab.props.tabTitle}
                {tab.props.renderCount && tab.props.renderCount({ styles })}
                {tab.props.count && !tab.props.renderCount ? (
                  <span className={styles.badgeWrap}>
                    <Badge small className={styles.badge}>
                      {tab.props.count}
                    </Badge>
                  </span>
                ) : null}
              </NavLink>
            ) : (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                href="#"
                className={`${styles.tabButton}${
                  (tab.props.tabSlug || tab.props?.tabTitle) ===
                  (activeTab || _activeTab)
                    ? ` ${styles.active}`
                    : ""
                }${tab.props.disabled ? ` ${styles.disabled}` : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  if (!tab.props.disabled) {
                    _setActiveTab(tab.props.tabSlug || tab.props?.tabTitle);
                    if (setActiveTab) {
                      setActiveTab(tab.props.tabSlug || tab.props?.tabTitle);
                    }
                  }
                }}
                style={tab.props.style}
                disabled={tab.props.disabled}
              >
                {tab.props.icon && (
                  <div className={styles.icon}>
                    <span>{tab.props.icon}</span>
                  </div>
                )}
                {tab.props.tabTitle}
                {tab.props.count ? (
                  <span className={styles.badgeWrap}>
                    <Badge small className={styles.badge}>
                      {tab.props.count}
                    </Badge>
                  </span>
                ) : null}
              </a>
            )}
          </li>
        ))}
        {renderOnEnd ? (
          <li
            className={`${styles.tab} ${styles.action} ${renderEndClassName}`}
          >
            {renderOnEnd()}
          </li>
        ) : (
          ""
        )}
      </ul>
      {tabs.map((tab) =>
        (tab.props.tabSlug || tab.props?.tabTitle) === (activeTab || _activeTab)
          ? tab
          : "",
      )}
    </TabsContext.Provider>
  );
}

export default Tabs;
