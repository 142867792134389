import React from "react";

import styles from "./Color.module.css";

function Color({ color, style, className, ...props }) {
  return (
    <span
      {...props}
      style={{ backgroundColor: color, ...style }}
      className={`${className} ${styles.color}`}
    />
  );
}

export default Color;
