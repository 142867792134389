import { getCredentialCookies } from "@atd/utils";

import { accountApi } from "../user/accountApi";

const { token } = getCredentialCookies();

const notificationApi = accountApi.injectEndpoints({
  tagTypes: ["Notification"],
  endpoints: (build) => ({
    getAllNotification: build.query({
      query: () => ({
        url: "notification",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response) => response.results,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Notification", id })),
              { type: "Notification", id: "LIST" },
            ]
          : [{ type: "Notification", id: "LIST" }],
    }),
    getNotification: build.query({
      query: (id) => ({
        url: `notification/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response) => response.results,
      providesTags: (result, error, id) => [{ type: "Notification", id }],
    }),
    getClientNotification: build.query({
      query: () => ({
        url: "get_notification",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response) => response,
      providesTags: (result, error, id) => [{ type: "Notification", id }],
    }),
    addNotification: build.mutation({
      query: (body) => ({
        url: "notification",
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body,
      }),
      invalidatesTags: [{ type: "Notification", id: "LIST" }],
    }),
    updateNotification: build.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return {
          url: `notification/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "Notification", id },
      ],
    }),
    deleteNotification: build.mutation({
      query: (id) => ({
        url: `notification/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      invalidatesTags: ["Notification"],
    }),
    markAsAllRead: build.mutation({
      query: (data) => {
        const { isMarked } = data;
        return {
          url: "notification",
          method: isMarked === 1 ? "UNLINK" : "LINK",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "Notification", id },
      ],
    }),
    updateNotificationStatus: build.mutation({
      query: (data) => {
        const { id, isActive } = data;
        return {
          url: `notification/${id}`,
          method: isActive === 1 ? "UNLINK" : "LINK",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "Notification", id },
      ],
    }),
  }),
  overrideExisting: false,
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useGetNotificationQuery,
  useGetAllNotificationQuery,
  useGetClientNotificationQuery,
  useAddNotificationMutation,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation,
  useMarkAsAllReadMutation,
  useUpdateNotificationStatusMutation,
} = notificationApi;
