import { Tooltip } from "@atd/components";
import Icon from "@atd/components/Icon/Icon";

function HelpButton() {
  return (
    <Tooltip content="Help">
      <span className="TopNavIcon">
        <Icon
          icon="help"
          onClick={() =>
            window.open("https://help.alltimedesign.com/en/", "_blank")
          }
        />
      </span>
    </Tooltip>
  );
}

export default HelpButton;
