import uuid from "react-uuid";
// import { useEffect } from "react";
// import { useDispatch } from "react-redux";

import { EmptyState } from "@atd/components";

import { Review } from "./Review";
// import { fetchDesignerReviews } from "@atd/features";

export const Reviews = ({ data, isFetching }) => {
  if (!isFetching && data?.length === 0) {
    return (
      <EmptyState
        title="No results found"
        description={
          <span>There aren&rsquo;t any results for that query.</span>
        }
      />
    );
  }

  return (
    <>
      {data?.map((review) => (
        <Review key={uuid()} review={review} />
      ))}
    </>
  );
};
