import React from "react";
import {
  Box,
  DateFilter,
  Button,
  GroupBy,
  SearchFilter,
} from "@atd/components";

import { isAdmin, getFilterOptions } from "@atd/utils";
import { useFiltersQuery } from "@atd/features/request";

const activityGroups = [
  { label: "All", value: "all" },
  { label: "Activity", value: "activity" },
  { label: "Company", value: "company" },
  { label: "Country", value: "country" },
  { label: "Designer", value: "designer" },
  { label: "Date", value: "date" },
  { label: "Plan", value: "plan_&_billings" },
];

const getActivityGroupOptions = (options) => {
  if (options) {
    return options.map((option) => ({
      label:
        option?.name?.charAt(0) === option?.name?.charAt(0).toUpperCase()
          ? option?.name
          : option?.name?.charAt(0)?.toUpperCase() + option?.name?.slice(1),
      value: option?.id,
    }));
  }
  return [];
};

function ActivityFilters({
  params,
  filterOptions,
  onChangeFilter,
  clear,
  users,
}) {
  const { data: filters, isSuccess } = useFiltersQuery(
    isAdmin() ? "new" : "queue",
  );

  const { date: dateFilter } = filters || {};
  const dateFilterOptions = isSuccess ? getFilterOptions(dateFilter) : [];
  const activityGroupFilterOptions = activityGroups;
  const activityFilterOptions = !!params.group_by
    ? getActivityGroupOptions(filterOptions[params.group_by])
    : [];
  let designerFilterOptions =
    params.group_by === "designer"
      ? getActivityGroupOptions(filterOptions["designer"])
      : [];
  designerFilterOptions.unshift({ label: "All Designers", value: "all" });
  const showFilter =
    params?.group_by !== "designer" && !!activityFilterOptions?.length;
  return (
    <Box
      flex={{
        direction: "row",
        alignItems: "center",
        wrap: "nowrap",
        justifyContent: "space-between",
      }}
    >
      <Box
        flex={{
          direction: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="db-InlineFilterForm-bottom pointer2"
      >
        <DateFilter
          value={params?.date_string}
          startDate={params?.start_date}
          endDate={params?.end_date}
          options={dateFilterOptions}
          onChange={onChangeFilter}
        />
        <GroupBy
          field="group_by"
          placeholder="Group by"
          value={params?.group_by}
          options={activityGroupFilterOptions}
          onChange={onChangeFilter}
        />
        {showFilter && (
          <>
            <SearchFilter
              field="select"
              placeholder="Select"
              value={params?.select}
              options={activityFilterOptions}
              onChange={onChangeFilter}
            />
          </>
        )}
        {params?.group_by === "designer" && (
          <SearchFilter
            field="select"
            placeholder="Select"
            value={params?.select}
            options={designerFilterOptions}
            onChange={onChangeFilter}
          />
        )}
      </Box>
      <Box
        flex={{
          direction: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button variant="default" label="Clear all" onClick={clear} />
      </Box>
    </Box>
  );
}

export default ActivityFilters;
