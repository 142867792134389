import React, { useState, useCallback, useEffect } from "react";
import { Box, Table } from "@atd/components";
import {
  useGetCountriesFiltersQuery,
  useGetLocationBasedTicketReportQuery,
} from "@atd/features/team/teamApi";
import { useDispatch } from "react-redux";
import TicketReportHeader from "../TeamReport/TicketReport/TicketReportHeader";
import TicketReportChart from "../TeamReport/TicketReport/TicketReportChart";
import CollapsibleReport from "./CollapsibleReport";
import ReportHeader from "./ReportHeader";
import { getFilterOptions } from "@atd/utils";
import Border from "../../Border";

function LocationBasedReport({ params, dateFilterOptions, onChangeFilter }) {
  const locationBasedTicket = useGetLocationBasedTicketReportQuery(params);
  const table = locationBasedTicket?.data?.table;
  const collapsible = table?.map((val, i) => false);
  const [collapse, setCollapse] = useState(collapsible || [false]);
  const { data } = useGetCountriesFiltersQuery();
  const dispatch = useDispatch();

  let countriesfiltersOptions = getFilterOptions(data, "name", "name");
  countriesfiltersOptions.unshift({ label: "All Countries", value: "all" });

  useEffect(() => {
    return () => {};
  }, [dispatch]);

  const toggle = useCallback(
    (index) => {
      let collapseFilter = collapse;
      collapseFilter[index] = !collapseFilter[index];
      setCollapse([]);
      setCollapse((arr) => [...arr, ...collapseFilter]);
    },
    [collapse],
  );
  const headers = {
    ...{
      social_media: 0,
      print_design: 0,
      marketing: 0,
      branding: 0,
      animation: 0,
      others: 0,
    },
    ...locationBasedTicket?.data?.headers,
  };

  return (
    <Box>
      <TicketReportHeader
        title="Location Based Tickets Reports"
        params={params}
        placeholder="All Countries"
        groupByField="country"
        onChangeFilter={onChangeFilter}
        groupByFilterOptions={countriesfiltersOptions}
        dateFilterOptions={dateFilterOptions}
      />
      <TicketReportChart
        data={locationBasedTicket?.data?.chart}
        isLoading={locationBasedTicket?.isLoading}
        width="100%"
        height={300}
      />
      <Table className="wt-100">
        <Table.Head>
          {headers && (
            <Box className="Margin-left--24">
              <ReportHeader
                headers={headers}
                withTable
                isKey
                isHeader
                background="white"
              />
            </Box>
          )}
          <Border
            border={{ position: "bottom" }}
            margin={{ mt: "10px", mb: "0px" }}
          />
        </Table.Head>
        <Table.Body>
          {table &&
            table?.map((value, i) => (
              <CollapsibleReport
                key={i}
                headers={headers}
                data={value}
                toggle={() => toggle(i)}
                collapse={collapse[i]}
                firstKey="name"
                lastKey="total_tickets"
              />
            ))}
        </Table.Body>
      </Table>
    </Box>
  );
}

export default LocationBasedReport;
