import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "@atd/components";
import { fetchTemplates, deleteTemplate } from "@atd/features";
import TemplateService from "@atd/features/template/templateApi";

import DataTable from "../Communications/DataTable";

function TemplateNameCell({ value }) {
  return (
    <>
      <div className="title1 bl-cont">{value.name}</div>
      <div className="cont1">{value.subject}</div>
    </>
  );
}

function EmailTemplates() {
  const navigate = useNavigate();

  const { templates, loading, error } = useSelector((state) => state.template);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTemplates());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "item",
        Cell: TemplateNameCell,
      },
    ],
    [],
  );

  const data = useMemo(() => {
    const announcements = templates
      ? templates.map((item) => ({
          id: item.id,
          item,
        }))
      : [];
    return announcements;
  }, [templates]);

  const actions = [
    {
      name: "Duplicate",
      value: "duplicate",
    },
  ];

  const onDuplicate = useCallback(
    async (id) => {
      const { status, results } = await TemplateService.copy(id);
      if (status === "success") {
        navigate(`/settings/email-templates/${results.id}`);
      }
    },
    [navigate],
  );

  return (
    <DataTable
      title="Email Templates"
      isLoading={loading}
      error={error}
      action={
        <Button
          label="New Template"
          variant="primary"
          icon="plus"
          onClick={() => navigate("/settings/email-templates/new")}
        />
      }
      columns={columns}
      data={data}
      onEdit={(id) => navigate(`/settings/email-templates/${id}`)}
      onDelete={(id) => dispatch(deleteTemplate(id))}
      onDuplicate={onDuplicate}
      actions={actions}
    />
  );
}

export default EmailTemplates;
