import styled from "styled-components";

import { Box, Alert, EmptyState } from "@atd/components";
import { device } from "@atd/utils";

import Coupon from "./Coupon";
import SummaryItem from "./SummaryItem";
import CheckoutButton from "./CheckoutButton";
import Agree from "./Agree";
import Total from "./Total";

const SummaryStyle = styled.table`
  .title1,
  .title2 {
    @media ${device.laptopHD} {
      font-size: 12px;
      letter-spacing: -0.5px;
    }
  }
`;

function Summary({ summaries, showMessage, onRemove, onSubmit }) {
  const total = summaries.reduce((acc, summary) => {
    const amount = summary.has_breakup
      ? summary.breakup_amount
      : summary.total_offer_price;
    return acc + amount * summary.designers;
  }, 0);

  return (
    <Box
      as="form"
      flex={{ direction: "column" }}
      style={{ flex: "1 1 0%" }}
      onSubmit={onSubmit}
    >
      <Box
        padding={{ right: 0, left: 20, vertical: 20 }}
        style={{ flex: "1 1 0%" }}
      >
        <Box
          flex={{ direction: "column", justifyContent: "flex-start" }}
          style={{ height: "100%" }}
        >
          <Box
            flex={{ direction: "row", justifyContent: "space-between" }}
            divider={{ color: "light", side: "bottom", width: 1 }}
          >
            <div className="title3 mgt4 mgb2">Summary</div>
          </Box>
          {showMessage && summaries.length > 0 && (
            <Alert status="error">
              We are sorry to say that the payment process for the same billing
              period may not be merged with the cross billing period
              subscriptions. Thus, we request you to complete the purchase
              process for the subscriptions that fall under the same billing
              period before proceeding with the cross billing period
              subscriptions.
            </Alert>
          )}
          <SummaryStyle className="table mb-0 mgt2">
            <tbody>
              {summaries &&
                summaries.map((plan) => (
                  <SummaryItem
                    key={plan.id}
                    plan={plan}
                    onRemove={() => onRemove(plan.id)}
                  />
                ))}
              {summaries.length > 0 && <Total amount={total} />}
            </tbody>
          </SummaryStyle>
          {summaries.length === 0 && (
            <EmptyState
              title="No summary items"
              description={
                <span>
                  There aren&rsquo;t any plans added here on summaries section.
                </span>
              }
              flex={{ alignItems: "flex-start", justifyContent: "center" }}
            />
          )}
          {summaries.length > 0 && (
            <>
              <Coupon />
              <Agree />
              <CheckoutButton />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Summary;
