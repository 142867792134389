import { ContentState } from "@atd/components";

function withLoading(Component) {
  return function withLoadingComponent({ isLoading, ...props }) {
    if (!isLoading) return <Component {...props} />;
    return <ContentState text="Loading..." />;
  };
}

export default withLoading;
