import React from "react";

function Tab({ children, onTabChange }) {
  onTabChange && onTabChange();
  return (
    <> {Array.isArray(children) ? children.map((item) => item) : children} </>
  );
}

export default Tab;
