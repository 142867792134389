import { Field } from "formik";

import { FormFieldInputError } from "@atd/components";

function RequestName({
  values,
  errors,
  touched,
  designTypeOptions,
  setActiveRequestType,
  onSetFieldValue,
  onChange,
}) {
  const { dimentions, request_type, request_name } = values;
  const handleChangeInput = async () => {
    const activeRequest = designTypeOptions
      ? designTypeOptions?.find((option) =>
          option.options.some((el) =>
            request_name.replace(/\s/g, "_").toLowerCase().includes(el.id),
          ),
        )
      : {};

    if (request_type && !activeRequest) {
      setActiveRequestType({});
      const fileDimensions = dimentions.map((d) => ({
        ...d,
        category_id: "",
        category_item_id: "",
      }));
      await onSetFieldValue(null, {
        request_type: "",
        dimentions: fileDimensions,
      });
    }
  };

  return (
    <>
      <div className="title2">
        Request Name
        <span style={{ color: "red" }}>*</span>
      </div>
      <div className="cont4">
        Give your design a unique and descriptive name.
      </div>
      <div className="FormField mgt1">
        <Field
          name="request_name"
          className={`iput ${
            errors.request_name &&
            touched.request_name &&
            "FormField__input--hasError"
          }`}
          placeholder="Example, Flyer, Brochure etc.,"
          onChange={onChange}
          onKeyUp={handleChangeInput}
        />
        <FormFieldInputError name="request_name" />
      </div>
    </>
  );
}

export default RequestName;
