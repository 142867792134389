import React from "react";
import { Heading } from "@atd/components";
import { Main } from "@atd/layouts";
import Border from "../Border";
const Sales = () => {
  return (
    <Main>
      <Heading headText="Sales" />
      <Border
        border={{ position: "bottom" }}
        margin={{ mg: "10px", mt: "10px" }}
      />
    </Main>
  );
};

export default Sales;
