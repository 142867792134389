import Box from "../Box/Box";

import PageContentLeft from "./PageContentLeft";
import PageContentRight from "./PageContentRight";
import PageSidebarAction from "./PageSidebarAction";

function PageContent({ children }) {
  return (
    <Box flex={{ direction: "row" }} style={{ height: "100%" }}>
      {children}
    </Box>
  );
}

PageContent.Main = PageContentLeft;
PageContent.Sidebar = PageContentRight;
PageContent.Action = PageSidebarAction;

export default PageContent;
