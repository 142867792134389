import React from "react";
import { Box, Text } from "@atd/components";

function ActivityGroupHeader({ headText, headKey }) {
  const key = headKey === "all" || headKey === undefined;
  return (
    <Box
      flex={{
        direction: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
      className=" Margin-bottom--10 Margin-top--20 "
    >
      <Text
        color={key ? "gray900" : "blue500"}
        weight={key ? "bold" : "medium"}
        size={key ? 20 : 16}
        lineHeight="32"
        numericSpacing="proportional"
        wrap
        typeface="base"
        display="inline"
      >
        {key ? headText : headText.toUpperCase()}
      </Text>
    </Box>
  );
}

export default ActivityGroupHeader;
