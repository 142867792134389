import React from "react";

import Tooltip from "../Tooltip/Tooltip";

function ExcerptText({ text, length }) {
  if (text?.length > length) {
    return (
      <Tooltip content={text}>
        <span>
          {text?.substr(0, length)}
          ...
        </span>
      </Tooltip>
    );
  }

  return <span>{text}</span>;
}

export default ExcerptText;
