import { useState, useCallback, useEffect, createContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useInfiniteScroll from "@closeio/use-infinite-scroll";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import RequestService from "@atd/features/request/requestApi";
import { isAdmin } from "@atd/utils";
import { Announcement } from "@atd/components";
import { Main } from "@atd/layouts";
import { useGetClientAnnouncementQuery } from "@atd/features/dashboard";
import { resetRequest } from "@atd/features";
import { withSelector } from "@atd/hoc";

import RequestsList from "./RequestsList";

// import { tabList } from "./RequestTabOptions";

import "./Requests.css";

const RequestContent = createContext({
  onResetData: () => {},
});

function Requests({ user }) {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [isResetData, setIsResetData] = useState(false);

  const { data: announcement } = useGetClientAnnouncementQuery();

  const { hasMore, isSearch } = useSelector((state) => state.requests);
  const dispatch = useDispatch();

  const onResetData = useCallback(() => {
    setIsResetData(true);
    dispatch(resetRequest());
  }, [dispatch]);

  const [page, loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore,
    reset: isResetData,
  });

  const { query } = params || {};
  const { role } = user || {};

  useEffect(
    () => () => {
      if (query) {
        onResetData();
      }
    },
    [onResetData, query],
  );

  useEffect(
    () => () => {
      dispatch(resetRequest());
    },
    [dispatch],
  );

  const onAddNewDesignRequest = async () => {
    setLoading(true);
    try {
      const { code, results } = await RequestService.addRequest();
      if (code === 200) {
        navigate(`/requests/${results.id}/new`);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const headingTitle = isAdmin() ? "All Requests" : "Requests";
  const headingText = isSearch ? "Search Results" : headingTitle;

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <RequestContent.Provider value={{ onResetData }}>
      <Main ref={scrollerRef} onResetData={onResetData}>
        {/* {!isAdmin() &&
          Array.isArray(announcement) &&
          announcement.map((ann) => (
            <Announcement
              id={ann.id}
              title={ann?.title}
              message={ann?.message}
            />
          ))} */}
        <RequestsList
          page={page}
          role={role}
          headerText={headingText}
          loading={loading}
          onAddNewDesignRequest={onAddNewDesignRequest}
          isSearch={isSearch}
          loaderRef={loaderRef}
          scrollerRef={scrollerRef}
          onResetData={onResetData}
          setIsResetData={setIsResetData}
          renderAboveTitle={() => {
            return (
              <>
                {!isAdmin() &&
                  Array.isArray(announcement) &&
                  announcement.map((ann) => (
                    <Announcement
                      id={ann.id}
                      title={ann?.title}
                      message={ann?.message}
                    />
                  ))}
              </>
            );
          }}
        />
      </Main>
    </RequestContent.Provider>
  );
}

export default withSelector((state) => state.user)(Requests);
