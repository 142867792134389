import React from "react";

import { Attachments } from "@atd/components";

function AttachReference({ open, uploading, onRemove, files, inputProps }) {
  return (
    <>
      <div className="Box-root Flex-flex Flex-alignItems--center Flex-direction--row mgt4 wt200">
        <div className="Box-root Margin-right--8">
          <span
            title="Gross volume"
            className="Text-color--default Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--block customcss7"
          >
            <span>Attach Reference Files</span>
          </span>
        </div>
        <div className="Box-root Box-hideIfEmpty mwt58">
          <div
            aria-label="0.0%"
            title="0%"
            className="Badge Badge--variant--status Box-root Box-background--gray100 Flex-inlineFlex Flex-alignItems--center Flex-direction--row"
          >
            <span className="Badge-text Text-color--gray600 Text-fontSize--12 Text-fontWeight--medium Text-lineHeight--16 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--inline">
              Optional
            </span>
          </div>
        </div>
      </div>
      <div className="FormField mgt1">
        <Attachments
          isUploading={uploading}
          files={files}
          onClick={open}
          inputProps={inputProps}
          onRemove={onRemove}
        />
      </div>
    </>
  );
}

export default AttachReference;
