import AccordionItem from "./AccordionItem";

function Accordion({ children, ...rest }) {
  return (
    <ul className="accordion-menu" {...rest}>
      {children}
    </ul>
  );
}

Accordion.Item = AccordionItem;

export default Accordion;
