/* eslint-disable import/no-anonymous-default-export */
import Activity from "./Activity/Activity";
import Reply from "./Reply/Reply";
import Note from "./Note/Note";

export default {
  note: Note,
  activity: Activity,
  reply: Reply,
  delivery: Reply,
};
