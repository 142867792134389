import { useState, useCallback } from "react";
import { SketchPicker } from "react-color";
import styled from "styled-components";

import { Box, Button, AtdPopover } from "@atd/components";
import BrandInfoAdder from "./BrandCreate/BrandInfoAdder";

const ChromePickerStyle = styled.div`
  & .chrome-picker,
  & .sketch-picker {
    padding: 0 !important;
    font-family: inherit !important;
    box-shadow: none !important;
    width: 240px !important;

    .flexbox-fix {
      padding: 5px 10px !important;
      margin: 0 !important;
    }

    .flexbox-fix:last-child {
      padding-top: 10px !important;
    }
  }
`;

function ColorPickerFooter({ onSave }) {
  return (
    <div>
      <Box flex={{ justifyContent: "flex-end" }} padding={{ all: 8 }}>
        <Button
          isFullWidth
          variant="default"
          label="Add color"
          onClick={onSave}
        />
      </Box>
    </div>
  );
}

function ColorPicker({ showColorPicker, onShowColorPicker, onChange }) {
  const [brandColor, setBrandColor] = useState("");

  const onChangeColor = useCallback((value) => {
    setBrandColor(value);
  }, []);

  return (
    <AtdPopover
      interactive
      placement="bottom"
      offset={[-10, 0]}
      visible={showColorPicker}
      onShow={onShowColorPicker}
      arrowStyle={{
        position: "relative",
        top: "-1px",
      }}
      element={
        <ChromePickerStyle>
          <SketchPicker color={brandColor} onChangeComplete={onChangeColor} />
          <ColorPickerFooter
            onSave={() => {
              onShowColorPicker(false);
              onChange(brandColor);
            }}
          />
        </ChromePickerStyle>
      }
    >
      <div>
        <BrandInfoAdder title="Add a color" />
      </div>
    </AtdPopover>
  );
}

export default ColorPicker;
