import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import BrandApi from "./brandApi";

const initialState = {
  brands: [],
  brand: null,
  loading: false,
  error: null,
  creating: false,
};

export const fetchBrands = createAsyncThunk(
  "brand/fetchBrands",
  async (params) => {
    try {
      const response = await BrandApi.getAllBrands(params);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const fetchBrand = createAsyncThunk(
  "brand/fetchBrand",
  async (brandId) => BrandApi.getBrand(brandId),
);

export const createBrand = createAsyncThunk(
  "brand/createBrand",
  async (payload) => BrandApi.addBrand(payload),
);

export const updateBrand = createAsyncThunk(
  "brand/updateBrand",
  async (payload) => BrandApi.editBrand(payload),
);

export const deleteBrand = createAsyncThunk("brand/deleteBrand", async (id) =>
  BrandApi.deleteBrand(id),
);

export const archiveBrand = createAsyncThunk(
  "brand/archiveBrand",
  async (args) => BrandApi.archive(args.id, args.isArchived),
);

const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchBrands.pending]: (state) => {
      state.brand = null;
      state.loading = true;
      state.error = null;
    },
    [fetchBrands.fulfilled]: (state, action) => {
      state.loading = false;
      state.brands = action.payload;
    },
    [fetchBrands.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [fetchBrand.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchBrand.fulfilled]: (state, action) => {
      state.loading = false;
      state.brand = action.payload;
    },
    [fetchBrand.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [createBrand.pending]: (state) => {
      state.creating = true;
      state.error = null;
      state.brand = null;
    },
    [createBrand.fulfilled]: (state, action) => {
      state.creating = false;
      state.brands = [...state.brands, action.payload];
      state.brand = action.payload;
    },
    [createBrand.rejected]: (state, action) => {
      state.creating = false;
      state.error = action.error.message;
      state.brand = null;
    },
    [deleteBrand.pending]: (state) => {
      state.error = null;
    },
    [deleteBrand.fulfilled]: (state, action) => {
      state.brands = state.brands.filter(
        (brand) => brand.id !== action.payload,
      );
    },
    [deleteBrand.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [updateBrand.pending]: (state) => {
      state.error = null;
    },
    [updateBrand.fulfilled]: (state, action) => {
      state.brands = state.brands.map((brand) => ({
        ...brand,
        brand_name:
          brand.id === action.payload.id
            ? action.payload.brand_name
            : brand.brand_name,
      }));
      state.brand = {
        ...state.brand,
        ...action.payload.data,
      };
    },
    [updateBrand.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [archiveBrand.pending]: (state) => {
      state.error = null;
    },
    [archiveBrand.fulfilled]: (state, action) => {
      state.brands = state.brands.filter(
        (brand) => brand.id !== action.payload.id,
      );
      // state.brands = state.brands.map((brand) => ({
      //   ...brand,
      //   ...(brand.id === action.payload.id
      //     ? { is_archived: action.payload.is_archived }
      //     : brand),
      // }));
    },
    [archiveBrand.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export default brandSlice.reducer;
