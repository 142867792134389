import React from "react";

import Box from "../Box/Box";

function SelectFooter({ children, ...rest }) {
  return (
    <Box
      {...rest}
      padding={{ all: 12 }}
      className="db-InlineDateRangeFilter-footer"
    >
      <Box className="ButtonGroup pointer2">
        <Box
          flex={{
            alignItems: "center",
            direction: "row",
            justifyContent: "flexStart",
            wrap: "nowrap",
          }}
          className="mglmgt-8"
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default SelectFooter;
