import React from "react";

import Box from "../Box/Box";
import Button from "../Button/Button";

function DatePickerFooter({ disabled, onApply, onClear }) {
  return (
    <Box
      padding={{ all: 12 }}
      background="offset"
      className="db-InlineDateRangeFilter-footer"
    >
      <Box className="ButtonGroup">
        <Box
          flex={{
            alignItems: "center",
            direction: "row",
            justifyContent: "flexStart",
            wrap: "nowrap",
          }}
          className="mglmgt-8"
        >
          <Box hideIfEmpty margin={{ top: 8, left: 8 }}>
            <Button
              disabled={disabled}
              label="Apply"
              variant="primary"
              onClick={onApply}
            />
          </Box>
          <Box hideIfEmpty margin={{ top: 8, left: 8 }}>
            <Button label="Clear" variant="link" onClick={onClear} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DatePickerFooter;
