import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { deleteBrand, updateBrand, archiveBrand } from "@atd/features";
import {
  Modal,
  Table,
  Dropdown,
  MoreButton,
  Button,
  Tooltip,
} from "@atd/components";
import { withSelector } from "@atd/hoc";

function BrandsListItem({ brand, user }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const { role } = user || {};

  const onChangeAction = (id) => {
    if (id === "rename") {
      setTimeout(() => setIsOpenEditModal(true), 100);
    } else if (id === "edit") {
      navigate(`/brands/${brand.id}/edit`);
    } else if (id === "archive") {
      dispatch(
        archiveBrand({
          id: brand.id,
          isArchived: brand.is_archived,
        }),
      );
    } else if (id === "delete") {
      setTimeout(() => {
        if (window.confirm("Are you sure you want to delete this brand?")) {
          dispatch(deleteBrand(brand.id));
        }
      }, 100);
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await dispatch(
        updateBrand({ id: brand.id, data: { brand_name: values.brand_name } }),
      ).unwrap();
      setIsOpenEditModal(false);
    } catch (error) {
      toast.error(error.message);
      setIsOpenEditModal(true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        title="Edit Brand"
        isOpen={isOpenEditModal}
        onClose={setIsOpenEditModal}
        width={377}
        hasFooter={false}
      >
        <Formik initialValues={{ brand_name: "" }} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <div className="pd16">
              <Form>
                <Field
                  name="brand_name"
                  className="iput wt-100"
                  placeholder={brand.brand_name}
                  autoFocus
                />
                <div className="mgl1 mgb5">
                  <div
                    className="Box-root Flex-flex mgt2"
                    style={{ float: "right" }}
                  >
                    <div className="Box-root Box-hideIfEmpty Margin-right--8">
                      <Button
                        variant="default"
                        type="button"
                        onClick={() => setIsOpenEditModal(false)}
                      >
                        <div className="Box-root" />
                        Cancel
                      </Button>
                    </div>
                    <div className="Box-root Box-hideIfEmpty">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Change
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </Modal>
      <Table.Row>
        <Table.Cell
          component="td"
          className="bnd"
          onClick={() => navigate(brand.id)}
        >
          <Tooltip content={brand.brand_name}>
            <div className="title1 ellipsis wt-15" style={{ width: "230px" }}>
              {brand.brand_name}
            </div>
          </Tooltip>
          <div className="cont1">
            {brand?.request_count > 0
              ? `Used in ${brand?.request_count} projects`
              : "Not use"}
          </div>
        </Table.Cell>
        <Table.Cell component="td" onClick={() => navigate(brand.id)}>
          <div className="cont1 mt-3">
            {moment(brand.modified).format("DD MMM")}
          </div>
        </Table.Cell>
        <Table.Cell component="td" className="dropdown">
          {role !== 4 && (
            <Dropdown
              popover={{ placement: "bottom-end" }}
              as={MoreButton}
              onChange={onChangeAction}
            >
              <Dropdown.Options>
                <Dropdown.Option value="edit">Edit</Dropdown.Option>
                <Dropdown.Option value="archive">
                  {brand.is_archived ? "Unarchive" : "Archive"}
                </Dropdown.Option>
                <Dropdown.Option value="delete">
                  <span className="red-txt">Delete</span>
                </Dropdown.Option>
              </Dropdown.Options>
            </Dropdown>
          )}
        </Table.Cell>
      </Table.Row>
    </>
  );
}

export default withSelector((state) => state.user)(BrandsListItem);
