import React from "react";
import styled from "styled-components";

import Popover from "../Popover/AtdPopover";

import TableHead from "./TableHead";
import TableBody from "./TableBody";
import TableRow from "./TableRow";
import TableCell from "./TableCell";
import TableEmpty from "./TableEmpty";

const TableStyle = styled.table`
  &.atd-Table--border {
    border: 1px solid #e0e1e4;

    & th,
    & td {
      padding-left: 10px !important;
    }

    & thead {
      background-color: var(--light-color);
      border-bottom: 1px solid #e0e1e4;
    }

    & tr:hover {
      background: transparent;
    }
  }
`;

export function TableMoreIcon({ children, onMount }) {
  return (
    <Popover
      offset={[0, 0]}
      placement="bottom-end"
      element={children}
      onMount={onMount}
    >
      <div className="dots btn">
        <svg
          aria-hidden="true"
          className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--more-svg Button-icon-svg Icon-color-svg Icon-color--gray600-svg"
          height={12}
          width={12}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
            fillRule="evenodd"
          />
        </svg>
      </div>
    </Popover>
  );
}

function Table({
  hasBorder = false,
  withMargin,
  children,
  className,
  ...rest
}) {
  return (
    <TableStyle
      {...rest}
      className={`${className}${hasBorder ? " atd-Table--border" : ""}`}
    >
      {children}
    </TableStyle>
  );
}

Table.Head = TableHead;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Cell = TableCell;
Table.More = TableMoreIcon;
Table.Empty = TableEmpty;

export default Table;
