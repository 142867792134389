import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Button,
  // ScrollToFieldError,
  Editor,
  // FormFieldInputError,
  // SearchFilter,
  DateTimeFilter,
  // Select,
  SearchFilter,
} from "@atd/components";
import { Main } from "@atd/layouts";
import Border from "@atd/pages/Reports/Border";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import {
  getClientMeetingFilters,
  getSingleClientMeeting,
  postClientMeeting,
  putClientMeeting,
  resetMeeting,
} from "@atd/features";
import { useSelector, useDispatch } from "react-redux";
import { getFilterOptions } from "@atd/utils";
import { MeetingSchema } from "@atd/schema";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
const initialValues = {
  client_name: "",
  client_email: "",
  date: "",
  hosted_by: "",
  purpose: "",
  meeting_status: "",
  description: "",
};

const MeetingActions = ({ cancel, save, disabled, values }) => {
  return (
    <Box flex hideIfEmpty margin={{ top: 8, left: 8 }} className="pointer1">
      <Button type="button" variant="link" onClick={() => cancel()}>
        Cancel
      </Button>
      <Button
        disabled={disabled}
        onClick={() => save(values)}
        type="submit"
        variant="primary"
        label="Save"
      />
    </Box>
  );
};
const AddMeeting = () => {
  const { id, meetingId } = useParams();
  const { hosts, meeting } = useSelector((state) => state.client);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: meeting || initialValues,
    validationSchema: MeetingSchema,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
    },
  });
  const { errors, touched, values, setFieldValue, resetForm, isSubmitting } =
    formik || {};
  const [params, setParams] = useState(meeting || initialValues);
  const admin_hosts = getFilterOptions(hosts.admins);
  const client_hosts = getFilterOptions(hosts.clients);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onChangeFilter = (field, value) => {
    const queryParam = { ...params, [field]: value };
    setFieldValue(field, value);
    setParams(queryParam);
  };
  useEffect(() => {
    if (meetingId) {
      dispatch(getSingleClientMeeting({ id, meetingId }));
    }

    dispatch(getClientMeetingFilters(id));
    return () => {};
  }, [dispatch, id, meetingId]);

  const cancelAddMeeting = () => {
    navigate(`/clients/${id}`);
    if (meetingId) {
      dispatch(resetMeeting());
    }
    setParams(initialValues);
  };

  const saveMeeting = async (values) => {
    if (Object.values(values).filter(Boolean)?.length) {
      const dateTime = !!values?.date ? values?.date?.split("|") : [];
      const date = !!(dateTime?.length === 1)
        ? values.date
        : `${dateTime[0]?.trim()}T${moment(dateTime[1], ["h:mm A"]).format(
            "HH:mm:ss",
          )}`;
      const payload = {
        ...values,
        date,
        client_name: [values?.client_name],
        hosted_by: [values?.hosted_by],
      };
      const { data } = meetingId
        ? await dispatch(putClientMeeting({ id, meetingId, payload })).unwrap()
        : await dispatch(postClientMeeting({ id, payload })).unwrap();

      if (data.status === "success") {
        toast.success(data.message);
        navigate(`/clients/${id}`);
        resetForm();
        dispatch(resetMeeting());
      }
    }
  };

  return (
    <Main>
      {/* <Formik
        initialValues={initialValues}
        validationSchema={MeetingSchema}
        onSubmit={(values, { setSubmitting }) => {
          saveMeeting(values);
          alert(JSON.stringify(values));
          // setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          errors,
          touched,
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleReset,
        }) => ( */}
      {/* <Form onSubmit={handleSubmit} onReset={handleReset}> */}
      <Heading
        style={{ padding: "0px" }}
        headText="Add Meetings"
        rightContent={
          <MeetingActions
            values={values}
            disabled={isSubmitting}
            save={saveMeeting}
            cancel={cancelAddMeeting}
          />
          // <Box
          //   flex
          //   hideIfEmpty
          //   margin={{ top: 8, left: 8 }}
          //   className="pointer1"
          // >
          //   <Button
          //     type="button"
          //     variant="link"
          //     onClick={() => cancelAddMeeting()}
          //   >
          //     Cancel
          //   </Button>
          //   <Button
          //     disabled={isSubmitting}
          //     onClick={() => saveMeeting(values)}
          //     type="submit"
          //     variant="primary"
          //     label="Save"
          //   />
          // </Box>
        }
      />
      <Border
        border={{ position: "bottom" }}
        margin={{ mt: "20px", mb: "10px" }}
      />
      {/* <ScrollToFieldError /> */}
      <>
        <div className="title2">
          Client Name
          <span style={{ color: "red" }}>*</span>
        </div>
        <div className="FormField mgt1">
          <SearchFilter
            field="client_name"
            size="normal"
            inputStyle={{ minWidth: 344 }}
            margin={{ top: 8, left: 0 }}
            placeholder="Eg: Enter Client Name"
            value={values?.client_name}
            options={client_hosts}
            onChange={onChangeFilter}
            className={`${
              errors.client_name &&
              touched.client_name &&
              "FormField__input--hasError"
            }`}
            // {...formik.getFieldProps("client_name")}
            // onChange={onChangeFilter}
          />
          {/* <Select
            interactive
            isOptionGroup
            value={params?.client_name ?? ""}
            // value={category_item_id}
            placeholder="Select your design type"
            // disabled={disabledItems}
            options={client_hosts}
            onChange={(newValue) => {
              setFieldValue("client_name", newValue);
            }}
            containerOptions={{
              style: { width: 344 },
            }}
            inputStyle={{ minWidth: 344 }}
            inputProps={{
              className: `iput ${
                errors?.client_name &&
                touched?.client_name &&
                "FormField__input--hasError"
              }`,
            }}
            renderOption={(label) => <>{<span>{label}</span>}</>}
          /> */}
          {/* <SearchFilter
            limit={20}
            size="large"
            className={`iput ${
              errors.client_name &&
              touched.client_name &&
              "FormField__input--hasError"
            }`}
            // inputStyle={inputStyle}
            value={values?.client_name}
            hasSearchField
            placeholder="Eg: Enter Client Name"
            options={client_hosts}
            onChange={(companyId) => {
              onChangeFilter("client_name", companyId);
            }}
          /> */}
          {/* <Field
            name="client_name"
            className={`iput pointer1 ${
              errors.client_name &&
              touched.client_name &&
              "FormField__input--hasError"
            }`}
            as="select"
            // options={client_hosts}
            // {...formik.getFieldProps("client_name")}
            value={values?.client_name}
            placeholder="Eg: Enter Client Name"
            onChange={(e) => {
              onChangeFilter("client_name", e.target.value);
            }}
          >
            {client_hosts.map((val) => (
              <option>{val?.label}</option>
            ))}
          </Field> */}
          {/* <FormFieldInputError name="client_name" /> */}
          {errors?.client_name && touched?.client_name ? (
            <div className="FormField__input--error">{errors.client_name}</div>
          ) : null}
        </div>
        <div className="title2 mgt2">
          Email ID
          <span style={{ color: "red" }}>*</span>
        </div>
        <div className="FormField mgt1">
          <input
            name="client_email"
            className={`iput ${
              errors.client_email &&
              touched.client_email &&
              "FormField__input--hasError"
            }`}
            value={values?.client_email}
            placeholder="Eg: Enter Email ID"
            // onChange={handleChange}
            // onChange={(e) => {
            //   onChangeFilter("client_email", e.target.value);
            // }}
            {...formik.getFieldProps("client_email")}
            onChange={(e) => {
              onChangeFilter("client_email", e.target.value);
            }}
          />
          {errors.client_email && touched.client_email ? (
            <div className="FormField__input--error">{errors.client_email}</div>
          ) : null}
        </div>
        <div className="title2 mgt2">
          Date
          <span style={{ color: "red" }}>*</span>
        </div>
        <div className="FormField mgt1">
          <DateTimeFilter
            className={`iput ${
              errors.date && touched.date && "FormField__input--hasError"
            }`}
            date={values?.date}
            onChange={onChangeFilter}
            // {...formik.getFieldProps("date")}
            // onChange={onChangeFilter}
          />
          {errors.date && touched.date ? (
            <div className="FormField__input--error">{errors.date}</div>
          ) : null}
          {/* <FormFieldInputError name="date" /> */}
        </div>
        <div className="title2 mgt2">
          Hosted By
          <span style={{ color: "red" }}>*</span>
        </div>
        <div className="FormField mgt1" id="rtype">
          {/* <Select
            limit={20}
            size="large"
            className={`iput ${
              errors.hosted_by &&
              touched.hosted_by &&
              "FormField__input--hasError"
            }`}
            // inputStyle={inputStyle}
            value={values?.hosted_by}
            hasSearchField
            placeholder="Eg: Enter Status"
            options={admin_hosts}
            onChange={(value) => {
              onChangeFilter("hosted_by", value);
            }}
          /> */}
          <SearchFilter
            size="large"
            field="hosted_by"
            placeholder="Hosted By"
            inputStyle={{ minWidth: 344 }}
            margin={{ top: 8, left: 0 }}
            value={values?.hosted_by}
            options={admin_hosts}
            onChange={onChangeFilter}
            className={`${
              errors.hosted_by &&
              touched.hosted_by &&
              "FormField__input--hasError"
            }`}
            // onChange={onChangeFilter}
            // {...formik.getFieldProps("hosted_by")}
          />
          {errors.hosted_by && touched.hosted_by ? (
            <div className="FormField__input--error">{errors.hosted_by}</div>
          ) : null}
          {/* <FormFieldInputError name="hosted_by" /> */}
        </div>
        <div className="title2 mgt2">
          Purpose
          <span style={{ color: "red" }}>*</span>
        </div>
        <div className="FormField mgt1" id="rtype">
          {/* <Select
            limit={20}
            size="large"
            className={`iput ${
              errors.purpose && touched.purpose && "FormField__input--hasError"
            }`}
            // inputStyle={inputStyle}
            value={values?.purpose}
            hasSearchField
            placeholder="Purpose"
            options={[
              { label: "Customer Feedback", value: "Customer Feedback" },
              {
                label: "Customer Onboarding",
                value: "Customer Onboarding",
              },
              {
                label: "Subscription Cancellation",
                value: "Subscription Cancellation",
              },
              {
                label: "Escalation Call",
                value: "Escalation Call",
              },
            ]}
            onChange={(value) => {
              onChangeFilter("purpose", value);
            }}
          /> */}
          <SearchFilter
            size="large"
            inputStyle={{ minWidth: 344 }}
            margin={{ top: 8, left: 0 }}
            field="purpose"
            placeholder="Purpose"
            value={values?.purpose}
            options={[
              { label: "Customer Feedback", value: "Customer Feedback" },
              {
                label: "Customer Onboarding",
                value: "Customer Onboarding",
              },
              {
                label: "Subscription Cancellation",
                value: "Subscription Cancellation",
              },
              {
                label: "Escalation Call",
                value: "Escalation Call",
              },
            ]}
            className={`${
              errors.purpose && touched.purpose && "FormField__input--hasError"
            }`}
            onChange={onChangeFilter}
            // {...formik.getFieldProps("purpose")}
            // onChange={onChangeFilter}
          />
          {errors.purpose && touched.purpose ? (
            <div className="FormField__input--error">{errors.purpose}</div>
          ) : null}
          {/* <FormFieldInputError name="purpose" /> */}
        </div>
        <div className="title2 mgt2">
          Status
          <span style={{ color: "red" }}>*</span>
        </div>
        <div className="FormField mgt1" id="rtype">
          {/* <Select
            limit={20}
            size="large"
            className={`iput ${
              errors.meeting_status &&
              touched.meeting_status &&
              "FormField__input--hasError"
            }`}
            // inputStyle={inputStyle}
            value={values?.meeting_status}
            hasSearchField
            placeholder="Eg: Enter Status"
            options={[
              { label: "Meeting Scheduled", value: "Meeting Scheduled" },
              {
                label: "Meeting Rescheduled",
                value: "Meeting Rescheduled",
              },
              { label: "Meeting Cancelled", value: "Meeting Cancelled" },
              { label: "Meeting Completed", value: "Meeting Completed" },
            ]}
            onChange={(value) => {
              onChangeFilter("meeting_status", value);
            }}
          /> */}
          <SearchFilter
            size="large"
            inputStyle={{ minWidth: 344 }}
            margin={{ top: 8, left: 0 }}
            field="meeting_status"
            placeholder="Status"
            value={values?.meeting_status}
            options={[
              { label: "Meeting Scheduled", value: "Meeting Scheduled" },
              {
                label: "Meeting Rescheduled",
                value: "Meeting Rescheduled",
              },
              { label: "Meeting Cancelled", value: "Meeting Cancelled" },
              { label: "Meeting Completed", value: "Meeting Completed" },
            ]}
            className={`${
              errors.meeting_status &&
              touched.meeting_status &&
              "FormField__input--hasError"
            }`}
            onChange={onChangeFilter}
            // {...formik.getFieldProps("meeting_status")}
            // onChange={onChangeFilter}
          />
          {errors.meeting_status && touched.meeting_status ? (
            <div className="FormField__input--error">
              {errors.meeting_status}
            </div>
          ) : null}
          {/* <FormFieldInputError name="meeting_status" /> */}
        </div>
      </>
      <div className="title2 mgt2">Description</div>
      <div className="FormField mgt1 mgb5">
        <Editor
          className={`${
            errors.description &&
            touched.description &&
            "FormField__input--hasError"
          }`}
          model={values?.description}
          config={{
            placeholderText: "Describe what you are announcement for",
          }}
          // onModelChange={handleChange}
          // onModelChange={(newValue) => {
          //   onChangeFilter("description", newValue);
          // }}
          onModelChange={(newValue) => {
            onChangeFilter("description", newValue);
          }}
          {...formik.getFieldProps("description")}
        />
        {errors.description && touched.description ? (
          <div className="FormField__input--error">{errors.description}</div>
        ) : null}
        {/* <FormFieldInputError name="description" /> */}
      </div>
      <Border
        border={{ position: "bottom" }}
        margin={{ mt: "20px", mb: "20px" }}
      />
      <Box
        flex={{
          direction: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {/* <Box flex hideIfEmpty margin={{ top: 8, left: 8 }} className="pointer1">
          <Button
            type="button"
            variant="link"
            onClick={() => cancelAddMeeting()}
          >
            Cancel
          </Button>
          <Button
            // disabled={isSubmitting}
            onClick={() => saveMeeting(values)}
            type="submit"
            variant="primary"
            label="Save"
          />
        </Box> */}
        <MeetingActions
          values={values}
          disabled={isSubmitting}
          save={saveMeeting}
          cancel={cancelAddMeeting}
        />
      </Box>
      {/* </Form> */}
      {/* )}
      </Formik> */}
    </Main>
  );
};

export default AddMeeting;
