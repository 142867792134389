import { map } from "lodash";
import { useSelector } from "react-redux";

import { roles as roleData } from "@atd/utils";

function HasPermission({
  roles = [],
  condition = "equal",
  renderAuthFailed,
  isLoading,
  children,
}) {
  const { loading, user } = useSelector((state) => state.user);
  const { role } = user || {};

  const roleItems = roleData.filter((roleItem) =>
    roles.some((r) => roleItem.name === r),
  );
  const roleIds = map(roleItems, "id");

  if (loading) {
    return isLoading ?? null;
  }

  switch (condition) {
    case "equal":
      if (!roleIds.includes(role)) {
        return renderAuthFailed ?? null;
      }
      break;
    case "not-equal":
      if (roleIds.includes(role)) {
        return renderAuthFailed ?? null;
      }
      break;
    default:
      return children;
  }

  return children;
}

export default HasPermission;
