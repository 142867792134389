/* eslint-disable jsx-a11y/control-has-associated-label */
import { Button, Heading } from "@atd/components";
import { Main } from "@atd/layouts";

function Clients() {
  return (
    <Main>
      <Heading
        headText="Services"
        rightContent={
          <div className="Box-root pointer2">
            <div
              id="tm"
              className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap mglmgt-8"
            >
              <div className="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8 pointer1">
                <Button variant="default" className="wt-but">
                  Manage discount coupons
                </Button>
              </div>
              <div className="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8 pointer1">
                <Button variant="default" className="wt-but">
                  Preview service catalog
                </Button>
              </div>
              <div className="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8 pointer1">
                <button type="button" className="bl-but">
                  <div className="Box-root Padding-right--8">
                    <div
                      aria-hidden="true"
                      className="SVGInline SVGInline--cleaned SVG Icon Icon--add Button-icon Icon-color Icon-color--white Box-root Flex-flex tsform1"
                    >
                      <svg
                        aria-hidden="true"
                        className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--add-svg Button-icon-svg Icon-color-svg Icon-color--white-svg"
                        height={12}
                        width={12}
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 7h6a1 1 0 0 1 0 2H9v6a1 1 0 0 1-2 0V9H1a1 1 0 1 1 0-2h6V1a1 1 0 1 1 2 0z"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  Create Service
                </button>
              </div>
              {/* partial */}
            </div>
          </div>
        }
      />
      {/* Heading */}

      <table className="table mb-0 wt-100 sm-ht fulltable" id="services">
        <thead className="stik">
          <tr>
            <th scope="col" className="border-0 wt-4" />
            <th scope="col" className="border-0 tb-title pl5">
              NAME
            </th>
            <th scope="col" className="border-0 tb-title pl5 wt-10">
              UPDATED
            </th>
            <th scope="col" className="border-0 wt-4" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="service-details" data-href="service-details.html">
              <img
                src="https://spiceblue.co/atd_ui/ticket/assets/images/req-img10.png"
                alt="ATD"
              />
            </td>
            <td className="service-details" data-href="service-details.html">
              <div className="title1">Design in a Day Monthly Plan</div>
              <div className="cont1">US$49.99 / year</div>
            </td>
            <td>
              <div className="cont1 mt-3">31 Mar</div>
            </td>
            <td className="dropdown">
              <div className="dots btn">
                <svg
                  aria-hidden="true"
                  className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--more-svg Button-icon-svg Icon-color-svg Icon-color--gray600-svg"
                  height={12}
                  width={12}
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
            </td>
          </tr>
          <tr>
            <td className="service-details" data-href="service-details.html">
              <img
                src="https://spiceblue.co/atd_ui/ticket/assets/images/req-img10.png"
                alt="ATD"
              />
            </td>
            <td className="service-details" data-href="service-details.html">
              <div className="title1">Pro Half Yearly Plan</div>
              <div className="cont1">US$9.99 / year</div>
            </td>
            <td>
              <div className="cont1 mt-3">23 Mar</div>
            </td>
            <td className="dropdown">
              <div className="dots btn">
                <svg
                  aria-hidden="true"
                  className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--more-svg Button-icon-svg Icon-color-svg Icon-color--gray600-svg"
                  height={12}
                  width={12}
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
            </td>
          </tr>
          <tr>
            <td className="service-details" data-href="service-details.html">
              <img
                src="https://spiceblue.co/atd_ui/ticket/assets/images/req-img10.png"
                alt="ATD"
              />
            </td>
            <td className="service-details" data-href="service-details.html">
              <div className="title1">Standard Monthly Plan</div>
              <div className="cont1">US$24.99 / year</div>
            </td>
            <td>
              <div className="cont1 mt-3">23 Mar</div>
            </td>
            <td className="dropdown">
              <div className="dots btn">
                <svg
                  aria-hidden="true"
                  className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--more-svg Button-icon-svg Icon-color-svg Icon-color--gray600-svg"
                  height={12}
                  width={12}
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
            </td>
          </tr>
          <tr>
            <td className="service-details" data-href="service-details.html">
              <img
                src="https://spiceblue.co/atd_ui/ticket/assets/images/req-img10.png"
                alt="ATD"
              />
            </td>
            <td className="service-details" data-href="service-details.html">
              <div className="title1">Standard Yearly Plan</div>
              <div className="cont1">US$199.00 / month</div>
            </td>
            <td>
              <div className="cont1 mt-3">23 Mar</div>
            </td>
            <td className="dropdown">
              <div className="dots btn">
                <svg
                  aria-hidden="true"
                  className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--more-svg Button-icon-svg Icon-color-svg Icon-color--gray600-svg"
                  height={12}
                  width={12}
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Main>
  );
}

export default Clients;
