/* eslint-disable import/prefer-default-export */
import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";

import { accountApi } from "../features/user/accountApi";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(accountApi.middleware),
});
