/* eslint-disable no-nested-ternary */
import React from "react";
import styled from "styled-components/macro";

import Box from "../Box/Box";

const StyledBadge = styled(Box)`
  background: ${(props) =>
    props.type === "success"
      ? "#cbf4c9"
      : props.type === "info"
      ? "#fbefec"
      : props.type === "error"
      ? "#df1b41"
      : props.type === "warning"
      ? "#FEF6DD"
      : props.type === "error-inverse"
      ? "#FBDBCF"
      : props.type === "error-light" || props.type === "success-light"
      ? "transparent"
      : "#e3e8ee"};
  color: ${(props) =>
    props.type === "success"
      ? "#0e6245"
      : props.type === "success-light"
      ? "#56b180"
      : props.type === "info"
      ? "#EA552B"
      : props.type === "error"
      ? "#FBDBCF"
      : props.type === "warning"
      ? "#4f566b"
      : props.type === "error-inverse" || props.type === "error-light"
      ? "#df1b41"
      : "#4f566b"};
  padding: 1px 6px 2px 6px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  line-height: 16px;
  font-weight: 500;
  font-size: 12px;
  margin-top: 3px;
`;

function Badge({ type, children, ...rest }) {
  return (
    <StyledBadge as="span" type={type} {...rest}>
      {children}
    </StyledBadge>
  );
}

export default Badge;
