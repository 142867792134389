import React, { forwardRef } from "react";
import styled from "styled-components";

import MenuItem from "./MenuItem";

export { MenuItem };

const List = styled.ul`
  padding: 8px 0;
  list-style: none;
  margin: 0;
`;

const Menu = forwardRef(({ children, ...rest }, ref) => (
  <List ref={ref} {...rest}>
    {children}
  </List>
));

Menu.Item = MenuItem;

export default Menu;
