import { Editor, FormFieldInputError } from "@atd/components";

import { Text } from "./BrandDetails.style";

function Description({ isEdit, disabled, text, setFieldValue }) {
  return (
    <>
      <div className="title2 mgt2">Brand description</div>
      {isEdit ? (
        <div className="cont2 mgt1 brdr-bt1">
          <div style={{ maxWidth: 600 }}>
            <Editor
              model={text}
              disabled={disabled}
              placeholder="We love listening to your brand stories. Tell us all about it!"
              onModelChange={(value) => {
                setFieldValue("description", value);
              }}
            />
          </div>
          <FormFieldInputError name="description" />
        </div>
      ) : (
        <Text
          className="cont2 mgt1"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
    </>
  );
}

export default Description;
