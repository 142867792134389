import { createContext } from "react";

const PlanContext = createContext({
  offerType: "",
  offerValue: 0,
  promo: "",
  prices: [],
  designers: 1,
  isProcessing: false,
  onChangeDesigners: () => {},
  onApplyPromo: () => {},
  onProceedCheckout: () => {},
});

export default PlanContext;
