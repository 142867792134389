import * as Yup from "yup";

import "./checkIfExists";

const TeamSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Required")
    .checkIfExists("Email address already exists", "email", "/teams"),
  role: Yup.number().required("Required"),
});

export default TeamSchema;
