import * as Yup from "yup";

import "./checkIfExists";

export const BrandSchemaAdd = Yup.object().shape({
  brand_name: Yup.string()
    .required("Required")
    .checkIfExists("Brand name already exists", "brand_name", "/brands"),
  target_audience: Yup.string().required("Required"),
});

export const BrandSchemaUpdate = Yup.object().shape({
  brand_name: Yup.string().required("Required"),
  target_audience: Yup.string().required("Required"),
});
