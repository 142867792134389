import { Icon } from "@atd/components";
import { getFormattedTime } from "@atd/utils";

import { useRequestTime } from "./Timer/Timer";

function TotalTime({ time }) {
  const [hmins, secs] = useRequestTime(time, 60);
  const [hrs, mins] = useRequestTime(hmins, 60);

  return (
    <>
      <div className="center">
        <div className="title2 mgt2">
          <Icon
            icon="time"
            as="span"
            flex={false}
            style={{ verticalAlign: "sub", marginRight: "5px" }}
          />
          Total Time: {getFormattedTime({ hrs, mins, secs })} hrs
        </div>
      </div>
      <div className="Box-divider--light-bottom-1">&nbsp;</div>
    </>
  );
}

export default TotalTime;
