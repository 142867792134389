import { forwardRef } from "react";
import classnames from "classnames";
import { camelCase } from "lodash";

import "./Box.css";

const mergeClasses = (classes, data, prefix = "") => {
  if (data && Object.keys(data).length > 0) {
    Object.keys(data).forEach((key) => {
      classes.push(`${prefix}-${key}--${data[key]}`);
    });
  }
};

const Box = forwardRef((props, ref) => {
  const {
    as: Component = "div",
    flex,
    divider,
    padding,
    margin,
    children,
    background,
    hideIfEmpty,
    className,
    ...rest
  } = props;

  const classnamesArray = ["Box-root", className];

  mergeClasses(classnamesArray, padding, "Padding");
  mergeClasses(classnamesArray, margin, "Margin");

  if (flex && Object.keys(flex).length > 0) {
    Object.keys(flex).forEach((key) => {
      if (key === "display") {
        classnamesArray.push(`Flex-${camelCase(flex[key])}`);
      } else {
        classnamesArray.push("Flex-flex");
      }
      classnamesArray.push(`Flex-${key}--${camelCase(flex[key])}`);
    });
  } else if (flex) {
    classnamesArray.push("Flex-flex");
  }

  const classes = classnames(classnamesArray, {
    "Box-hideIfEmpty": hideIfEmpty,
    [`Box-background--${background}`]: background,
    [`Box-divider--${divider?.color}-${divider?.side}-${divider?.width}`]:
      divider && Object.keys(divider).length > 0,
  });

  return (
    <Component ref={ref} className={classes} {...rest}>
      {children}
    </Component>
  );
});

export default Box;
