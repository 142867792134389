import Box from "../Box/Box";

import CollapsibleHeader from "./CollapsibleHeader";
import CollapsibleItem from "./CollapsibleItem";

import CollapsibleContext from "./CollapsibleContext";

import "./Collapsible.css";

function Collapsible({
  children,
  className,
  overrideTitleClassName,
  headerElement,
  ...rest
}) {
  return (
    <CollapsibleContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ overrideTitleClassName, headerElement }}
    >
      <Box
        as="ul"
        className={`accordion-menu${className ? ` ${className}` : ""}`}
        {...rest}
      >
        {children}
      </Box>
    </CollapsibleContext.Provider>
  );
}

Collapsible.Header = CollapsibleHeader;
Collapsible.Item = CollapsibleItem;

export default Collapsible;
