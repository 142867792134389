import React from "react";
import classNames from "classnames";

import { LeftArrow } from "@atd/components/Icon/icons";

import MenuItem from "./MenuItem";
import List from "./List";
import MenuList from "./MenuList";
import MenuExternalIcon from "./MenuExternalIcon";

import styles from "./Menu.module.css";

const MenuCollapseText = ({ children }) => {
  return <span className={styles.collapseText}>{children || "Collapse"}</span>;
};

const MenuCollapseIcon = ({ icon = <LeftArrow /> }) => {
  return <span className={styles.collapseIcon}>{icon}</span>;
};

const Menu = (props) => {
  const { children, className } = props;

  return <nav className={classNames(styles.menu, className)}>{children}</nav>;
};

Menu.MenuItem = MenuItem;
Menu.List = List;
Menu.MenuList = MenuList;
Menu.CollapseText = MenuCollapseText;
Menu.CollapseIcon = MenuCollapseIcon;
Menu.ExternalIcon = MenuExternalIcon;

export default Menu;
