import { Formik, Form, Field } from "formik";
import { Button, Modal, FormFieldInputError } from "@atd/components";
import { usePaymentInputs } from "react-payment-inputs";
import { useAddCardMutation } from "@atd/features/user";

function AddCardModal({ isVisible, onToggleVisibility }) {
  const [addCard] = useAddCardMutation();
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();

  const onSubmit = async (values) => {
    const [expMonth, expYear] = values?.expiryDate.split("/");
    const expiryYear = `20${expYear.trim()}`;
    await addCard({
      number: values?.cardNumber,
      cvv: values?.cvc,
      expiry_year: +expiryYear,
      expiry_month: +expMonth.trim(),
    }).unwrap();
    onToggleVisibility();
  };

  return (
    <Modal
      title="Add a payment method"
      isOpen={isVisible}
      onClose={onToggleVisibility}
    >
      <Formik
        initialValues={{
          cardNumber: "",
          expiryDate: "",
          cvc: "",
        }}
        onSubmit={onSubmit}
        validate={() => {
          let errors = {};
          if (meta.erroredInputs.cardNumber) {
            errors.cardNumber = meta.erroredInputs.cardNumber;
          }
          if (meta.erroredInputs.expiryDate) {
            errors.expiryDate = meta.erroredInputs.expiryDate;
          }
          if (meta.erroredInputs.cvc) {
            errors.cvc = meta.erroredInputs.cvc;
          }
          return errors;
        }}
      >
        {({ isSubmitting, errors, touched }) => {
          return (
            <Form>
              <div className="pd16">
                <div className="grid grid-column-1 grid-align-top">
                  <div className="FormField mgt1">
                    <Field name="cardNumber">
                      {({ field }) => (
                        <input
                          {...getCardNumberProps({
                            onBlur: field.onBlur,
                            onChange: field.onChange,
                            className: `iput Margin-right--8 wt-100 ${
                              errors.cardNumber &&
                              touched.cardNumber &&
                              "FormField__input--hasError"
                            }`,
                          })}
                        />
                      )}
                    </Field>
                    <FormFieldInputError name="cardNumber" />
                  </div>
                </div>
                <div className="grid grid-column-2 grid-align-top mgt1">
                  <div className="FormField mgt1">
                    <Field name="expiryDate">
                      {({ field }) => (
                        <input
                          {...getExpiryDateProps({
                            onBlur: field.onBlur,
                            onChange: field.onChange,
                            className: `iput Margin-right--8 wt-100 ${
                              errors.expiryDate &&
                              touched.expiryDate &&
                              "FormField__input--hasError"
                            }`,
                          })}
                        />
                      )}
                    </Field>
                    <FormFieldInputError name="expiryDate" />
                  </div>
                  <div className="FormField mgt1">
                    <Field name="cvc">
                      {({ field }) => (
                        <input
                          {...getCVCProps({
                            onBlur: field.onBlur,
                            onChange: field.onChange,
                            className: `iput Margin-right--8 wt-100 ${
                              errors.cvc &&
                              touched.cvc &&
                              "FormField__input--hasError"
                            }`,
                          })}
                        />
                      )}
                    </Field>
                    <FormFieldInputError name="cvc" />
                  </div>
                </div>
              </div>
              <Modal.Footer>
                <div className="Box-root Box-hideIfEmpty Margin-right--8">
                  <Button
                    variant="default"
                    type="button"
                    disabled={isSubmitting}
                    onClick={onToggleVisibility}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="Box-root Box-hideIfEmpty">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Add
                  </Button>
                </div>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default AddCardModal;
