import * as React from "react";

function SvgClock(props) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 12.333c2.933 0 5.333-2.4 5.333-5.333 0-2.934-2.4-5.334-5.333-5.334-2.933 0-5.333 2.4-5.333 5.334 0 2.933 2.4 5.333 5.333 5.333Zm0-12c3.667 0 6.667 3 6.667 6.667 0 3.666-3 6.666-6.667 6.666S.333 10.666.333 7c0-3.667 3-6.667 6.667-6.667Zm3.333 6.333v1h-4v-4h1v3h3Z"
        fill="#4F566B"
      />
    </svg>
  );
}

export default SvgClock;
