import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { getCurrentTimeZone, getErrorMessage, axios } from "@atd/utils";

// --> GET REQUEST & THREAD DATA
export const getRequestThreads = createAsyncThunk(
  "requestThread/getRequestThreads",
  async (args) => {
    try {
      const { data } = await axios.get(`/thread/${args.id}`, {
        params: { time_zone: getCurrentTimeZone() },
      });

      if (data.status === "error") {
        const errorMessage = getErrorMessage(data);
        toast.error(errorMessage);
        throw new Error(data.message);
      }

      return {
        threadData: data.results,
      };
    } catch (err) {
      throw new Error(err);
    }
  },
);

export const requestThreadSlice = createSlice({
  name: "requestThread",
  initialState: {
    loading: false,
    hasErrors: false,
    errorMsg: "",
    threadData: [],
  },
  reducers: {
    deleteNote: (state, action) => {
      state.threadData = state.threadData.filter(
        (activity) => activity.id !== action.payload,
      );
    },
  },
  extraReducers: {
    [getRequestThreads.pending]: (state, { meta }) => {
      state.loading = !meta.arg?.isUpdate;
      state.hasErrors = false;
      state.errorMsg = "";
    },
    [getRequestThreads.fulfilled]: (state, action) => {
      state.loading = false;
      state.hasErrors = false;
      state.errorMsg = "";
      state.threadData = action.payload.threadData;
    },
    [getRequestThreads.rejected]: (state, action) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = action.error.message;
      state.threadData = [];
    },
  },
});

export const { deleteNote } = requestThreadSlice.actions;

export default requestThreadSlice.reducer;
