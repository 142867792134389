import React from "react";
import styled from "styled-components";

import Box from "../Box/Box";
import Text from "../Text/Text";
import Icon from "../Icon/Icon";

const ListItem = styled.li`
  padding-right: 20px;
  padding-left: 20px;
  width: auto;
  background-color: ${(props) =>
    props.isActive ? "var(--light-color)" : "transparent"};

  &:hover {
    background-color: var(--light-color);
  }
`;

function MenuItem({
  children,
  color = "default",
  icon,
  active,
  itemProps,
  text,
  ...rest
}) {
  return (
    <ListItem
      isActive={active}
      className="UnstyledLink ButtonLink Menu-item Flex-flex"
      {...rest}
    >
      <Box
        flex={{
          alignItems: "baseline",
          direction: "row",
          justifyContent: "flexStart",
        }}
      >
        <Box flex={{ alignItems: "center", display: "inline-flex" }}>
          {icon && (
            <span className="Margin-right--8">
              <Icon icon={icon} color={active ? "blue500" : color} />
            </span>
          )}
          <Box {...itemProps}>
            <Text
              color={active ? "blue500" : color}
              typeface="base"
              wrap="wrap"
              {...text}
            >
              <span>{children}</span>
            </Text>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
}

export default MenuItem;
