import * as React from "react";

function SvgWarning(props) {
  return (
    <svg
      aria-hidden="true"
      className="Warning_svg__SVGInline-svg Warning_svg__SVGInline--cleaned-svg Warning_svg__SVG-svg Warning_svg__Icon-svg Warning_svg__Icon--warning-svg Warning_svg__Notice-icon-svg Warning_svg__Icon-color-svg Warning_svg__Icon-color--red-svg"
      height={12}
      width={12}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m10.115 1.308 5.635 11.269A2.365 2.365 0 0 1 13.634 16H2.365A2.365 2.365 0 0 1 .25 12.577L5.884 1.308a2.365 2.365 0 0 1 4.231 0zM8 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM8 9c.552 0 1-.32 1-.714V4.714C9 4.32 8.552 4 8 4s-1 .32-1 .714v3.572C7 8.68 7.448 9 8 9z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgWarning;
