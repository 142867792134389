import { Link } from "react-router-dom";
import styles from "./Signup.module.css";

import ATD_LOGO from "../../assets/images/atd-logo.svg";

function Signup() {
  // Signup Form Submission Handler
  const signupFormSubmitHandler = (e) => {
    e.preventDefault();
    // const formData = new FormData(e.currentTarget);
  };

  return (
    <div className="h-screen bg-blue-50 flex items-center justify-center">
      <div className={`${styles["signup-wrapper"]} bg-white shadow-2xl flex`}>
        <div className="login-left flex-1 p-10">
          <div className="logo">
            <a href="https://alltimedesign.com/">
              <img
                className="w-16 mb-12"
                src={ATD_LOGO}
                alt="All Time Design Logo"
              />
            </a>
          </div>
          <div className="text-center">
            <h1 className="text-2xl font-bold mb-3">Setup your Account</h1>
            <p>
              <span className="text-secondary text-sm">
                Unlimited designs, Dedicated designers, World-class customer
                experience and much more at just a click away!
              </span>
            </p>
          </div>
          <form className="my-8" onSubmit={signupFormSubmitHandler}>
            <div className="mb-4">
              <input
                className="w-full py-1 px-2 border border-gray-300 rounded outline-none focus:ring-2 focus:ring-primary transition-all"
                type="text"
                name="company_name"
                placeholder="Company Name"
                required
              />
            </div>
            <div className="mb-4">
              <input
                className="w-full py-1 px-2 border border-gray-300 rounded outline-none focus:ring-2 focus:ring-primary transition-all"
                type="text"
                name="full_name"
                placeholder="Full Name"
                required
              />
            </div>
            <div className="mb-4">
              <input
                className="w-full py-1 px-2 border border-gray-300 rounded outline-none focus:ring-2 focus:ring-primary transition-all"
                type="email"
                name="email"
                placeholder="Email Address"
                required
              />
            </div>
            <div className="mb-4">
              <input
                className="w-full py-1 px-2 border border-gray-300 rounded outline-none focus:ring-2 focus:ring-primary transition-all"
                type="password"
                name="password"
                placeholder="Password (Minimum 8 characters)"
                minLength={8}
                required
              />
            </div>
            <div className="mb-4">
              <input
                className="checked:bg-primary"
                type="checkbox"
                name="accept_tnc"
                id="accept_tnc"
              />
              <label
                className="ml-2 text-secondary-dark text-sm select-none"
                htmlFor="accept_tnc"
              >
                I accept the{" "}
                <a
                  href="https://alltimedesign.com/terms"
                  className="text-primary hover:underline"
                >
                  terms and conditions
                </a>{" "}
                section here.
              </label>
            </div>
            <button
              className="bg-primary hover:bg-primary-light text-white rounded py-1 w-full focus:bg-primary-light focus:ring-2 focus:ring-primary focus:ring-opacity-50 transition-all"
              type="submit"
            >
              Countinue
            </button>
          </form>
          <p className="text-center">
            <Link
              to="/login"
              className="text-secondary text-sm hover:text-primary hover:underline transition-all"
            >
              Already a user!
            </Link>
          </p>
        </div>
        <div className="login-right flex-1 p-10 bg-gradient-to-tr from-primary-dark to-primary-light flex flex-col justify-end">
          <div>
            <h2 className="text-white text-3xl font-semibold mb-3">
              Here To Help
            </h2>
            <p className="text-gray-100 font-light">
              No matter where you are in your journey, we&rsquo;re here to help.
              Do you have any queries? Reach out our Customer Success team
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
