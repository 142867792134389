import { Box, Text } from "@atd/components";
import Icon from "@atd/components/Icon/Icon";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useHover } from "@atd/hooks";
const MoreAnalytics = ({ path, name }) => {
  const [hoverRef, isHovered] = useHover();

  const route = `analytics/${path.split("_")[0]}`;
  return (
    <Box
      ref={hoverRef}
      flex={{
        direction: "column",
        justifyContent: "flex-start",
      }}
      className="Margin-horizontal--20"
    >
      <Link to={route}>
        <Box
          flex={{
            direction: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          style={{ visibility: isHovered ? "visible" : "hidden" }}
          padding={{ left: 20 }}
          margin={{ vertical: 10 }}
        >
          <Text
            weight="500"
            size="12"
            lineHeight="16"
            color={isHovered ? "dark" : "#ea552b"}
          >
            {name} &nbsp;
          </Text>
          <Icon icon="right-arrow" color="#ea552b" />
        </Box>
      </Link>
    </Box>
  );
};

const MoreReport = styled(MoreAnalytics)`
  a {
    visibility: hidden;
  }
  a:hover {
    visibility: visible;
  }
`;
export default MoreReport;
