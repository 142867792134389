function PanelHeader({ title }) {
  return (
    <div className="ContentHeader Box-root Box-divider--light-bottom-1 Box-background--offset Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-direction--column">
      <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
        <div
          className="Box-root Flex-flex Flex-direction--column Flex-justifyContent--center"
          style={{ flexBasis: 0, flexGrow: 1 }}
        >
          <span className="ContentHeader-title Text-color--dark Text-fontSize--16 Text-lineHeight--24 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
            <span className="Text-color--dark Text-fontSize--20 Text-fontWeight--bold Text-lineHeight--28 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
              <span>{title}</span>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default PanelHeader;
