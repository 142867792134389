import { useEffect, useState } from "react";
import uuid from "react-uuid";
import { Formik, Form, FieldArray } from "formik";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

import { Box, ContentState, EmptyState, Feedback } from "@atd/components";
import {
  getRequest,
  getRequestThreads,
  markAsComplete,
  updateCurrentRequest,
} from "@atd/features";
import { useVersionQuery } from "@atd/features/request";

import RequestActions from "./RequestActions";
import activities from "./Acitivities/Activities";

const Threads = styled(Box)`
  position: relative;
  margin-top: 20px;
`;

const ThreadsContent = styled(Box)`
  .activity-reply + div:not(.activity-reply) {
    margin-top: 16px;
  }

  & > div {
    position: relative;
    margin-bottom: 0;
  }

  & > div:last-child::before {
    content: "";
    margin: 0 0 0 25px;
    height: 100%;
    width: 1px;
    display: block;
    position: absolute;
    background: #ffffff;
  }
`;

const ActivityList = styled.div`
  & > div {
    position: relative;
  }
`;

const getThreadType = (threads, type) =>
  threads.some((thread) => thread.isNew && thread.type === type);

function ThreadList({ id, status, request, isSubscriptionPaused }) {
  const [searchParams] = useSearchParams();

  const isShowFeedback = searchParams.get("feedback") === "show";

  const [showFeedback, setShowFeedback] = useState(isShowFeedback);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [feedbackSubmitting, setFeedbackSubmitting] = useState(false);

  const { loading, threadData } = useSelector((state) => state.requestThread);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { data: versions } = useVersionQuery(id);

  const {
    comment,
    comments,
    ticket_type,
    in_progress,
    rating,
    rating_1,
    rating_2,
    rating_3,
  } = request || {};

  useEffect(() => {
    if (id) {
      dispatch(getRequest(id));
      dispatch(
        getRequestThreads({
          id,
          params: { tab: "details" },
          isUpdate: false,
        }),
      );
    }
  }, [dispatch, id]);

  const onAddThread = (type, arrayHelpers) => {
    arrayHelpers.push({
      id: uuid(),
      body: "",
      attachments: [],
      version: versions[0] || "",
      type,
      isNew: true,
    });
  };

  const onSubmitFeedback = async (values) => {
    setFeedbackSubmitting(true);
    try {
      if (user?.role === 1) {
        toast.info("Completing ticket...");
      }
      const response = await dispatch(
        markAsComplete({ id, data: values }),
      ).unwrap();
      if (response.status === "success") {
        if (user?.role === 1) {
          toast.success(response.message);
        }
        dispatch(updateCurrentRequest({ status: 7 }));
        setFeedbackSubmitted(true);
        return;
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setFeedbackSubmitting(false);
    }
  };

  if (loading) {
    return <ContentState text="Loading" />;
  }

  if (!loading && threadData.length === 0) {
    return (
      <EmptyState
        icon="requests"
        title="No results found"
        description={
          <span>There aren&rsquo;t any results for that query.</span>
        }
      />
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {/* <div className="title3">&nbsp;</div> */}
      <Box flex={{ direction: "row" }}>
        <Box padding={{ right: 20 }} className="wt-100">
          <Formik
            enableReinitialize
            initialValues={{ threads: threadData || [] }}
          >
            {({ values, setFieldValue }) => (
              <Threads as={Form}>
                <FieldArray
                  name="threads"
                  render={(arrayHelpers) => (
                    <>
                      <ThreadsContent>
                        {values.threads && values.threads.length > 0
                          ? values.threads.map((thread, index) => {
                              const threadType =
                                "type" in thread ? thread.type : "reply";

                              const Activity = activities[threadType];
                              if (!Activity) {
                                return null;
                              }

                              const onCancelThread = () => {
                                if (thread?.isNew) {
                                  arrayHelpers.remove(index);
                                } else {
                                  setFieldValue(
                                    `threads.${index}.isEditing`,
                                    false,
                                  );
                                }
                              };

                              return (
                                <ActivityList
                                  key={thread.id}
                                  className={`activity-${thread.type}`}
                                >
                                  <Activity
                                    index={index}
                                    activity={thread}
                                    versions={versions}
                                    onCacel={onCancelThread}
                                    onRemove={() => arrayHelpers.remove(index)}
                                    request={request}
                                    {...thread}
                                  />
                                </ActivityList>
                              );
                            })
                          : "No Threads Found"}
                      </ThreadsContent>
                      <RequestActions
                        // eslint-disable-next-line jsx-a11y/aria-role
                        role={user?.role}
                        status={status}
                        isSubscriptionPaused={isSubscriptionPaused}
                        inProgress={in_progress}
                        showFeedback={showFeedback}
                        setShowFeedback={setShowFeedback}
                        disabledReplyButton={
                          status === 7 || getThreadType(values.threads, "reply")
                        }
                        disabledNoteButton={getThreadType(
                          values.threads,
                          "note",
                        )}
                        disabledDeliveryButton={getThreadType(
                          values.threads,
                          "delivery",
                        )}
                        onAddNote={() => onAddThread("note", arrayHelpers)}
                        onAddReply={() => onAddThread("reply", arrayHelpers)}
                        onAddDelivery={() =>
                          onAddThread("delivery", arrayHelpers)
                        }
                        onSubmitFeedback={onSubmitFeedback}
                      />
                    </>
                  )}
                />
              </Threads>
            )}
          </Formik>
          {ticket_type !== "old" && rating && (
            <Feedback.Review comment={comments} rating={rating} />
          )}
          {ticket_type === "old" && rating_1 && rating_2 && rating_3 && (
            <Feedback.ReviewLegacy
              comment={comment}
              rating1={rating_1}
              rating2={rating_2}
              rating3={rating_3}
            />
          )}
          {showFeedback && user?.role !== 1 && (
            <Feedback
              isSuccess={feedbackSubmitted}
              disabled={feedbackSubmitting}
              onSubmit={onSubmitFeedback}
            />
          )}
        </Box>
      </Box>
    </DndProvider>
  );
}

export default ThreadList;
