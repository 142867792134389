import { Field } from "formik";

import { FormFieldInputError } from "@atd/components";

function BrandName({ disabled }) {
  return (
    <>
      <div className="title2 mgt1">Brand Name</div>
      <div className="cont2 mgt1">
        <Field
          name="brand_name"
          disabled={disabled}
          className="iput"
          placeholder="Example: Tesla"
        />
        <FormFieldInputError name="brand_name" />
      </div>
    </>
  );
}

export default BrandName;
