import stdPlanLogo from "./assets/img1.png";
import proPlanLogo from "./assets/img2.png";
import PremiumPlanLogo from "./assets/img3.png";
import ultPlanLogo from "./assets/img4.png";
import UltPlanLogo2 from "./assets/img5.png";
import extrasImg1 from "./assets/img6.png";
import extrasImg2 from "./assets/img7.png";

export const services = {
  service: [
    {
      id: 0,
      heading: "Custom Illustrations",
      desc: "Make your brand truly unique with custom illustrations that tell your story.",
      logo: stdPlanLogo,
      plan: "STANDARD PLAN",
      plan_detail: "Standard",
    },
    {
      id: 1,
      heading: "Presentation design",
      desc: "Be unforgettable. Let our presentation designers ignite your slides and make it captivating and memorable.",
      logo: proPlanLogo,
      plan: "PRO PLAN",
      plan_detail: "Pro",
    },
    {
      id: 2,
      heading: "Motion graphics ",
      desc: "Your ads /marketing materials deserve a major upgrade. Have our animators create jaw-dropping animations designed to break records",
      logo: PremiumPlanLogo,
      plan: "PREMIUM PLAN",
      plan_detail: "Premium",
    },
    {
      id: 3,
      heading: "UI design",
      desc: "Let our expert designers create stunning, responsive websites that enhance user experience and drive conversions.",
      logo: ultPlanLogo,
      plan: "ULTIMATE PLAN",
      plan_detail: "Ultimate",
    },
    {
      id: 4,
      heading: "Front End Development ",
      desc: "Let our app designers craft intuitive, seamless, visually appealing apps for engagement or entertainment.",
      logo: UltPlanLogo2,
      plan: "ULTIMATE PLAN",
      plan_detail: "Ultimate",
    },
  ],
  adds: [
    {
      heading: "Dedicated Designer",
      desc: "I need a Dedicated Designer to help quality check and ensure my designs are consistent and on-brand.",
      logo: extrasImg1,
      plan_detail: "Standard",
    },
    {
      heading: "Project manager",
      desc: "I need a Project Manager to help quality check and ensure my designs are consistent and on-brand.",
      logo: extrasImg2,
      plan_detail: "Pro",
    },
  ],
};

export const plansData = {
  "Standard Monthly": "s_11",
  "Standard Quarterly": "s_22",
  "Standard Yearly": "s_44",
  "Pro Monthly": "p_11",
  "Pro Quarterly": "p_22",
  "Pro Yearly": "p_44",
  "Premium Monthly": "pr_11",
  "Premium Quarterly": "pr_22",
  "Premium Yearly": "pr_44",
  "Ultimate Monthly": "ul_11",
  "Ultimate Quarterly": "ul_22",
  "Ultimate Yearly": "ul_44",
};

export const plans_list = {
  "Standard Monthly": "s_1",
  "Standard Quarterly": "s_2",
  "Standard Yearly": "s_4",
  "Pro Monthly": "p_1",
  "Pro Quarterly": "p_2",
  "Pro Yearly": "p_4",
  "Premium Monthly": "pr_1",
  "Premium Quarterly": "pr_2",
  "Premium Yearly": "pr_4",
  "Ultimate Monthly": "ul_1",
  "Ultimate Quarterly": "ul_2",
  "Ultimate Yearly": "ul_4",
};
