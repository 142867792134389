import * as React from "react";

function SvgRequests(props) {
  return (
    <svg
      className="Requests_svg__SVGInline-svg Requests_svg__SVGInline--cleaned-svg Requests_svg__SVG-svg Requests_svg__db-SideNav-icon-svg Requests_svg__db-SideNav-icon--accountapp-svg Requests_svg__wh-16"
      width={16}
      height={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M5 8a1 1 0 0 1 .117 1.993L5 10H1a1 1 0 0 1-.117-1.993L1 8h4zm4-4a1 1 0 0 1 .117 1.993L9 6H1a1 1 0 0 1-.117-1.993L1 4h8zm0-4a1 1 0 0 1 .117 1.993L9 2H1A1 1 0 0 1 .883.007L1 0h8z"
          fill="#4F566B"
          fillRule="nonzero"
        />
        <path
          d="m15.496 8.675-2.17-2.171.054-.054a1.535 1.535 0 0 1 2.17 2.17l-.054.055zm-.884.883-5.79 5.79L6 16l.651-2.822 5.79-5.79 2.171 2.17z"
          fill="#A3ACB9"
        />
      </g>
    </svg>
  );
}

export default SvgRequests;
