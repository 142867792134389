import * as React from "react";

function SvgBell(props) {
  return (
    <svg
      aria-hidden="true"
      className="Bell_svg__SVGInline-svg Bell_svg__SVGInline--cleaned-svg Bell_svg__SVG-svg Bell_svg__Icon-svg Bell_svg__Icon--notifications-svg Bell_svg__Icon-color-svg Bell_svg__Icon-color--gray-svg"
      height={16}
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.55 14h4.9a2.5 2.5 0 0 1-4.9 0zM13 9.066l1.216 1.232A1 1 0 0 1 13.504 12H2.496a1 1 0 0 1-.712-1.702L3 9.066V5a5 5 0 1 1 10 0z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgBell;
