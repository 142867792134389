import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { pick } from "lodash";

import { updateUser } from "@atd/features";
import { ProfileSchema } from "@atd/schema";

import ChangeProfile from "./ChangeProfile";
import ChangePassword from "./ChangePassword";

const ALLOWED_FIELDS = [
  "first",
  "last",
  "company",
  "email",
  "phone",
  "password",
  "new_password",
];

function ProfileForm() {
  const { user, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const initialValues = {
    first: "",
    last: "",
    company: "",
    email: "",
    phone: "",
    password: null,
    new_password: null,
    changepassword: "",
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const updatePayload = pick(
      {
        ...user,
        ...values,
        new_password: values.new_password || null,
        password: values.password || null,
      },
      ALLOWED_FIELDS,
    );
    await dispatch(updateUser(updatePayload));
    setSubmitting(false);
  };

  const formValues = pick(
    {
      ...user,
      password: null,
      new_password: null,
    },
    ALLOWED_FIELDS,
  );

  return (
    <Formik
      initialValues={formValues || initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={ProfileSchema}
    >
      {() => (
        <Form>
          <ChangeProfile isLoading={loading} user={user} />
          <ChangePassword isLoading={loading} user={user} />
        </Form>
      )}
    </Formik>
  );
}

export default ProfileForm;
