import { ExternalLink } from "@atd/components";

function Agree() {
  return (
    <div className="cont2 mgt2 pd-10">
      <div id="chk">
        <div className="Checkbox">
          <input id="ch1" type="checkbox" className="Checkbox-input" required />
        </div>
      </div>
      <div
        style={{
          "margin-left": "25px",
          "margin-top": "-3px",
        }}
      >
        I agree to your{" "}
        <ExternalLink href="https://alltimedesign.com/terms/" target="_blank">
          Terms and Conditions
        </ExternalLink>{" "}
        and{" "}
        <ExternalLink href="https://alltimedesign.com/privacy/" target="_blank">
          Privacy policy
        </ExternalLink>
        . I understand that my subscription starts today.
      </div>
    </div>
  );
}

export default Agree;
