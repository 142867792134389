import { useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Table, Modal, Select, Badge, Menu, Button } from "@atd/components";
import { updateTeam, deleteTeam } from "@atd/features";
import { getCredentialCookies } from "@atd/utils/tokenUtilities";
import { isAdmin } from "@atd/utils";

function TeamsListItem({ team, roles, showActions }) {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [instance, setInstance] = useState(null);

  const { user_id } = getCredentialCookies();

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const onChangeAction = (id, e) => {
    e.stopPropagation();
    if (instance) {
      instance?.onClose();
    }
    if (id === 1) {
      setIsOpenEditModal(true);
    } else if (id === 2) {
      dispatch(
        updateTeam({ id: team.id, is_active: team.is_active === 1 ? 0 : 1 }),
      );
    } else if (id === 3) {
      // eslint-disable-next-line no-alert
      if (window.confirm("Are you sure you want to delete this user?")) {
        dispatch(deleteTeam(team.id));
      }
    }
  };

  const onSubmit = async (values, { setSubmitting }) => {
    await dispatch(updateTeam({ id: team.id, ...values }));
    setSubmitting(false);
    setIsOpenEditModal(false);
  };

  const roleOptions =
    roles.length > 0
      ? roles.map((role) => ({ label: role.name, value: role.id }))
      : [];

  const isNotDeactiveUserStatus =
    team.status_name === "Active" ? "success" : "default";

  return (
    <>
      <Modal
        title="Edit User Role"
        isOpen={isOpenEditModal}
        onClose={setIsOpenEditModal}
        width={377}
      >
        <Formik initialValues={{ role_id: team?.role }} onSubmit={onSubmit}>
          {({ isSubmitting, values, setFieldValue }) => (
            <div className="pd16">
              <Form>
                <div className="FormField mgt1" id="rtype">
                  <Select
                    placement="bottom-start"
                    value={values.role_id}
                    options={roleOptions}
                    onChange={(value) => {
                      setFieldValue("role_id", value);
                    }}
                    placeholder="Role"
                    containerOptions={{ style: { width: "100%" } }}
                  />
                </div>
                <Modal.Footer
                  className="Box-root Flex-flex mgt2"
                  style={{ float: "right" }}
                >
                  <div className="Box-root Box-hideIfEmpty Margin-right--8">
                    <Button
                      variant="default"
                      type="button"
                      onClick={() => setIsOpenEditModal(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="Box-root Box-hideIfEmpty">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Change
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            </div>
          )}
        </Formik>
      </Modal>
      <Table.Row>
        <Table.Cell component="td" className="title2 wt-15">
          {/* <Tooltip content={team.name}>
            <div className="title1 ellipsis ">{team.name}</div>
          </Tooltip>{" "} */}
          {team.name}{" "}
          {user_id === team.id && (
            <Badge margin={{ left: 8 }} type="info">
              You
            </Badge>
          )}
        </Table.Cell>
        <Table.Cell component="td">{team.email}</Table.Cell>
        <Table.Cell component="td">{team?.role_name}</Table.Cell>
        {isAdmin() && (
          <Table.Cell component="td">{team.last_active_string}</Table.Cell>
        )}
        <Table.Cell component="td">
          <Badge
            type={
              team.status_name === "De Active"
                ? "error-inverse"
                : isNotDeactiveUserStatus
            }
          >
            {team.status_name}
          </Badge>
        </Table.Cell>
        {showActions && (
          <Table.Cell component="td" className="dropdown">
            {user && ![3, 4].includes(user?.role) && (
              <Table.More onMount={(ins) => setInstance(ins)}>
                {team.status_name !== "Pending" && (
                  <Menu>
                    <Menu.Item
                      text={{ weight: "medium" }}
                      onClick={(e) => onChangeAction(1, e)}
                    >
                      Edit
                    </Menu.Item>
                    {isAdmin() && (
                      <Menu.Item
                        text={{ weight: "medium" }}
                        onClick={(e) => onChangeAction(2, e)}
                      >
                        {team.is_active ? "Deactive" : "Active"}
                      </Menu.Item>
                    )}
                  </Menu>
                )}
              </Table.More>
            )}
          </Table.Cell>
        )}
      </Table.Row>
    </>
  );
}

export default TeamsListItem;
