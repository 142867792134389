import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { Button } from "@atd/components";
import { forgotPassword } from "@atd/features";
import Auth from "./Auth";

const ForgotSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

function Forgot() {
  const dispatch = useDispatch();

  const onSubmit = async (
    values,
    { setSubmiting, setSubmitting, resetForm },
  ) => {
    try {
      await dispatch(forgotPassword(values));
      resetForm({});
      setSubmiting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <Auth
      title="Reset your password"
      description={
        <p>
          Type your email address to send instructions to reset your password to
          your inbox.
        </p>
      }
    >
      <Formik
        initialValues={{ email: "" }}
        validationSchema={ForgotSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Field name="email" placeholder="Email Address" />
            {errors.email && touched.email ? (
              <div
                className="FormField__input--error"
                style={{ marginBottom: 20, marginTop: 0, textAlign: "left" }}
              >
                {errors.email}
              </div>
            ) : null}
            <Button disabled={isSubmitting} type="submit">
              Reset Password
            </Button>
            <p>
              <Link to="/login">Back to Sign in</Link>
            </p>
          </Form>
        )}
      </Formik>
    </Auth>
  );
}

export default Forgot;
