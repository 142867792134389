import axios from "@atd/app/axiosClient";
import { toast } from "react-toastify";

import { setCredentialCookies } from "@atd/utils/tokenUtilities";
import { getErrorMessage } from "@atd/utils";

class AuthService {
  async login(payload) {
    try {
      const { data } = await axios.post("/login", payload);
      if (data.status === "error") {
        const errorMessage = getErrorMessage(data);
        toast.error(errorMessage, { toastId: "toast-auth-message" });
        return data;
      }

      toast.success(data.message, { toastId: "toast-auth-message" });

      if (data.code === 200) {
        setCredentialCookies(data.results);
      }
      return data;
    } catch (err) {
      return err;
    }
  }

  async logout() {
    try {
      const { data } = await axios.post("/logout");
      if (data.status === "error") {
        const errorMessage = getErrorMessage(data);
        toast.error(errorMessage);
        return data;
      }
      return data;
    } catch (err) {
      return err;
    }
  }

  async forgot(payload) {
    try {
      const { data } = await axios.post("/forgot", payload);
      if (data.status === "success") {
        toast.success(data.message);
      } else {
        if (data.results && data.results.length > 0) {
          data.results.forEach((item) => {
            toast.error(`${data.message} : ${item.msg}`);
          });
        } else {
          toast.error(data.message);
        }

        throw new Error(data.message);
      }
    } catch (err) {
      throw new Error(err);
    }
  }

  async reset(payload) {
    try {
      const { data } = await axios.post("/reset", payload);
      if (data.status === "error") {
        toast.error(data.message);
        throw new Error(data.message);
      }

      toast.success(data.message);
      return {
        user: data.results,
      };
    } catch (err) {
      throw new Error(err);
    }
  }

  async accept(payload) {
    try {
      const { data } = await axios.post("/accept", payload);

      if (data.status === "error") {
        const errorMessage = getErrorMessage(data);
        toast.error(errorMessage);
        throw new Error(errorMessage);
      }

      toast.success(data.message);

      return data;
    } catch (err) {
      throw new Error(err);
    }
  }
}

export default new AuthService();
