import Box from "../Box/Box";
import Button from "../Button/Button";
import { HasPermission } from "../HasPermission";

function PageSidebarAction({ onClick, imageUrl, actionButtonText }) {
  return (
    <>
      <img className="w-full" src={imageUrl} alt="Create" />
      <Box hideIfEmpty margin={{ top: 16, left: 8 }}>
        <HasPermission roles={["View Only"]} condition="not-equal">
          <Button
            variant="primary"
            label={actionButtonText}
            onClick={onClick}
            iconColor="white"
            icon="plus"
          />
        </HasPermission>
      </Box>
    </>
  );
}

export default PageSidebarAction;
