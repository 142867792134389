import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import uuid from "react-uuid";

import { Box, ContentState, EmptyState } from "@atd/components";
import { axios, formatBytes } from "@atd/utils";

import FileCardIcon from "@atd/components/FileCard/FileCardIcon";
import DownloadFile from "./DownloadFile";

import FolderIcon from "../../../assets/images/folder.png";

function ProductionFileItem({ detail, isFile, onClick }) {
  return (
    <tr onClick={onClick}>
      {!isFile && (
        <td>
          <img src={FolderIcon} alt="ATD" style={{ maxWidth: "none" }} />
        </td>
      )}
      <td style={{ textTransform: "uppercase" }}>
        {isFile && detail?.version}
        {/* {!isSubDir ? (
        ) : (
          <img
            src="https://spiceblue.co/atd_ui/atdapp/admin/assets/images/line.png"
            alt="ATD"
            className="ln"
          />
        )} */}
      </td>
      {isFile && <td />}
      <td>{isFile && <FileCardIcon icon={detail.format} />}</td>
      <td>
        {!isFile ? detail?.version_name : ""}
        {isFile && (
          <div
            className="title1 mt-2"
            style={{ maxWidth: 400 }}
            title={detail?.full_name}
          >
            {detail?.name}
          </div>
        )}
      </td>
      <td>{formatBytes(detail?.size)}</td>
      <td>
        <div className="cont1 mt-3">
          {moment(detail?.modified).format("D MMMM")}
        </div>
      </td>
      <td className="dropdown">
        {isFile && <DownloadFile link={detail?.link} />}
      </td>
    </tr>
  );
}

function ProductionFiles({ id }) {
  const dispatch = useDispatch();

  const [allDetails, setAllDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fileLists, setFileLists] = useState([]);

  useEffect(() => {
    if (!id) return;

    (async () => {
      try {
        const { data } = await axios.get(`/requests/${id}`, {
          params: {
            tab: "production_files",
          },
        });
        if (data?.results) {
          setAllDetails(data.results);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dispatch, id]);

  // const fileItems = allDetails ? Object.keys(allDetails) : [];

  const onClickItem = (value) => {
    const fileSelected = allDetails.find((item) => item.version_name === value);
    setFileLists(fileSelected.files);
  };

  return (
    <Box flex={{ direction: "row" }}>
      <Box padding={{ right: 20 }} className="wt-100">
        {isLoading && <ContentState />}
        {!isLoading && allDetails?.length === 0 && (
          <EmptyState
            title="No results found"
            description={
              <span>There aren&rsquo;t any results for that query.</span>
            }
          />
        )}
        {!isLoading && allDetails?.length > 0 && (
          <table className="table mb-0" id="pro-file-list">
            <thead>
              <tr>
                <th scope="col" className="border-0 tb-title pl5 wt-10">
                  {fileLists.length > 0 ? "VERSION TYPE" : ""}
                </th>
                <th aria-hidden="true" scope="col" className="border-0 wt-10" />
                <th
                  aria-hidden="true"
                  scope="col"
                  className="border-0 wt-4"
                  style={{ minWidth: 45 }}
                />
                <th scope="col" className="border-0 tb-title pl5">
                  NAME
                </th>
                <th scope="col" className="border-0 tb-title pl5 wt-20">
                  SIZE
                </th>
                <th scope="col" className="border-0 tb-title pl5">
                  LAST UPDATED
                </th>
                <th aria-hidden="true" scope="col" className="border-0 wt-4" />
              </tr>
            </thead>
            <tbody>
              {fileLists.length > 0 &&
                fileLists.map((file, index) => (
                  <ProductionFileItem
                    key={uuid()}
                    index={index}
                    detail={file}
                    isFile
                    // isSubDir={true}
                  />
                ))}
              {fileLists.length === 0 &&
                allDetails.map((detail, index) => (
                  <ProductionFileItem
                    key={uuid()}
                    index={index}
                    detail={detail}
                    isFile={false}
                    onClick={() => onClickItem(detail.version_name)}
                  />
                ))}
            </tbody>
          </table>
        )}
      </Box>
    </Box>
  );
}

export default ProductionFiles;
