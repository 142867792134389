import * as React from "react";

function SvgTrash(props) {
  return (
    <svg
      aria-hidden="true"
      className="Trash_svg__SVGInline-svg Trash_svg__SVGInline--cleaned-svg Trash_svg__SVG-svg Trash_svg__Icon-svg Trash_svg__Icon--trash-svg Trash_svg__Button-icon-svg Trash_svg__Icon-color-svg Trash_svg__Icon-color--inherit-svg"
      height={12}
      width={12}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1.002 4V3a1 1 0 0 1 1-1H6a2 2 0 1 1 4 0h4.005a1 1 0 0 1 1 1v1zM2.5 6h11l-.452 9.05a1 1 0 0 1-1 .95H3.952a1 1 0 0 1-.999-.95z" />
    </svg>
  );
}

export default SvgTrash;
