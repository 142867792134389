import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { getUserGeoLocationDetails } from "@atd/utils";

import PaymentForm from "./PaymentForm";

const CardElementContainer = styled.div`
  width: 100%;
  padding: 0.5rem 0.75rem;
  outline: none;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  background: white;
`;

// const Title = styled.h1`
//   color: #3c4257;
//   margin-bottom: 1.5rem;
// `;

export const Captcha = styled.div`
  & canvas {
    vertical-align: super;
    border-radius: 4px;
    width: 100%;
    margin-top: -5px;
  }
`;

function StripeForm(props) {
  const cardElementContainer = useRef(null);
  const stripe = useRef(null);
  const cardElement = useRef(null);

  const [country, setCountry] = useState("");

  useEffect(() => {
    getUserGeoLocationDetails()
      .then((data) => {
        const { results } = data || {};
        setCountry(results);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    stripe.current = window.Stripe(
      country === "Singapore"
        ? process.env.REACT_APP_LIVE_STRIPE_KEY_SINGAPORE
        : process.env.REACT_APP_LIVE_STRIPE_KEY_DEFAULT,
    );
    const elements = stripe.current.elements();

    cardElement.current = elements.create("card", {
      hidePostalCode: true,
      style: {
        base: {
          iconColor: "#666EE8",
          color: "#31325F",
          lineHeight: "1.42857",
          fontWeight: 300,
          fontFamily: "Helvetica Neue",
          fontSize: "15px",
          "::placeholder": {
            color: "#cfd4da",
          },
        },
      },
    });

    cardElement.current.mount(cardElementContainer.current);
  }, [country]);

  return (
    <Wrapper>
      {/* <Title>Pay with Card</Title>{" "} */}
      <div className="form-field">
        <label htmlFor="__card_element" className="form-label-2 sm mgt2">
          Credit card number
        </label>

        <CardElementContainer
          ref={cardElementContainer}
          className="input-field input-styling w-input card-element-container"
          // class=""
        />
      </div>

      <PaymentForm stripe={stripe} cardElement={cardElement} {...props} />
    </Wrapper>
  );
}

export default StripeForm;

const Wrapper = styled.div`
  /* width: 768px; */
`;
