import React from "react";
import { Formik, Form } from "formik";

function DashboardForm({ children, ...rest }) {
  return (
    <Formik {...rest}>
      {(formikProps) => (
        <Form className="ccss13" {...rest.form}>
          {children(formikProps)}
        </Form>
      )}
    </Formik>
  );
}

export default DashboardForm;
