import { useState } from "react";

import { Button, Collapsible } from "@atd/components";

import ConfirmPlanSelectionModal from "./ConfirmPlanSelectionModal";

function CurrentPlanTable({ plans }) {
  const [plan, setPlan] = useState(null);
  const [showUpdatePlanModal, setShowUpdatePlanModal] = useState(false);

  return (
    <>
      {showUpdatePlanModal && (
        <ConfirmPlanSelectionModal
          plan={plan}
          isOpen={showUpdatePlanModal}
          onClose={() => setShowUpdatePlanModal(false)}
        />
      )}
      <table className="table mb-0 wt-100">
        <tbody>
          {plans &&
            plans.length > 0 &&
            plans.map((planItem) => (
              <tr key={planItem.id}>
                <td className="bl-cont">
                  {planItem.quantity} x {planItem.name}
                </td>
                <td>
                  <div className="title1 mt-2">
                    {planItem.currency}
                    {planItem.amount}/{planItem.period}
                  </div>
                </td>
                <td>
                  <div className="cont1 mt-3">
                    <div
                      className="Box-root Flex-flex"
                      style={{ float: "right" }}
                    >
                      <div
                        className="Box-root Box-hideIfEmpty"
                        style={{ pointerEvents: "auto" }}
                      >
                        {planItem?.show_upgrade &&
                        !planItem?.id?.startsWith("u_") ? (
                          <Button
                            variant="primary"
                            onClick={() => {
                              setShowUpdatePlanModal(true);
                              setPlan(planItem);
                              // toast.success(
                              //   "Please contact support team at support@alltimedesign.com",
                              // );
                            }}
                          >
                            Upgrade
                          </Button>
                        ) : (
                          <>
                            {/* {plan.currency}
                            {plan.amount * plan.quantity}/{plan.period} */}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}

function CurrentPlan({ plans, subscription }) {
  return (
    <Collapsible overrideTitleClassName="title3">
      <Collapsible.Item
        isOpen
        collapsible={false}
        title="Current Plan"
        contentClassName="p-0"
      >
        <CurrentPlanTable plans={plans} subscription={subscription} />
      </Collapsible.Item>
    </Collapsible>
  );
}

export default CurrentPlan;
