import React from "react";

function FieldsetRow({ id, label, description, children, className, ...rest }) {
  return (
    <div className={`atd-Fieldset-row ${className}`} {...rest}>
      <label htmlFor={id} className="atd-Fieldset-label">
        {label}
      </label>
      <div className="atd-Fieldset-fields">
        {children}
        <p className="bs-Fieldset-explanation Margin-top--8 Margin-left--8">
          {description}
        </p>
      </div>
    </div>
  );
}

export default FieldsetRow;
