import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { isAdmin } from "@atd/utils";

import Header from "../Header";
import SettingsMenu from "./Menu";

import "./Settings.css";

function Settings({ children }) {
  const { user: currentUser } = useSelector((state) => state.user);
  const { role } = currentUser || {};

  const menus = [
    {
      title: isAdmin() ? "Organization" : "Account",
      menu: [
        { link: "account", name: "Profile" },
        {
          link: "teams",
          name: "Teams",
          userType: "admin",
          restricted: isAdmin() && [2, 6, 7].includes(role),
        },
      ],
    },
    {
      title: "Billing",
      menu: [
        { link: "plans", name: "Plans" },
        { link: "payment", name: "Payment" },
      ],
      userType: "client",
      restricted: [3, 4].includes(role),
    },
    {
      title: "Communication",
      menu: [
        {
          link: "email-templates",
          name: "Templates",
        },
        {
          link: "announcements",
          name: "Announcements",
          restricted: [2].includes(role),
        },
        {
          link: "notifications",
          name: "Notifications",
          restricted: [2].includes(role),
        },
      ],
      restricted: [6, 7].includes(role),
      userType: "admin",
    },
  ];

  const settingsMenu = menus.filter((menu) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!menu.hasOwnProperty("userType")) {
      return menu;
    }
    if (isAdmin()) {
      return menu.userType === "admin";
    }
    return menu.userType === "client";
  });

  return (
    <div className="content-area">
      <Header />
      <div className="app-content js-appContent">
        <div className="page page-settings settings-page">
          <div className="settings-nav">
            <SettingsMenu isAdmin={isAdmin} items={settingsMenu} />
          </div>
          {/* Main Content area */}
          <div className="settings-content" tabIndex={-1}>
            <div>{children || <Outlet />}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
