/* eslint-disable import/prefer-default-export */
import { isAdmin } from "@atd/utils";

export const tabList = isAdmin()
  ? [
      { id: "new", value: "New" },
      { id: "in_progress", value: "In Progress" },
      { id: "revision", value: "Revision" },
      { id: "pending", value: "Pending" },
      { id: "delivered", value: "Delivered" },
      { id: "closed", value: "Closed" },
      { id: "all", value: "All" },
      { id: "archived", value: "Archived" },
    ]
  : [
      { id: "queue", value: "Queue" },
      { id: "delivered", value: "Delivered" },
      { id: "closed", value: "Closed" },
      { id: "draft", value: "Your Drafts" },
    ];
