import { useEffect, forwardRef } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import {
  useGetClientNotificationQuery,
  useMarkAsAllReadMutation,
} from "../../features/dashboard";

import Badge from "../Badge/Badge";
// import Button from "../Button/Button";
import Box from "../Box/Box";
import Icon from "../Icon/Icon";
import AtdPopover from "../Popover/AtdPopover";
import ContentState from "../ContentState/ContentState";
import TextComponent from "../Text/Text";
import Tooltip from "../Tooltip/Tooltip";

import {
  Content,
  Header,
  Title,
  MarkAll,
  Date,
  LearnMore,
  Text,
  NotificationBubble,
  NotificationBubbleWrapper,
} from "./Notification.style";

const NotificationItems = forwardRef((_, ref) => {
  const {
    data: notifications,
    isLoading,
    refetch: fetchNotifications,
  } = useGetClientNotificationQuery();
  const [markAsAllRead, { isSuccess }] = useMarkAsAllReadMutation();

  const { results } = notifications || {};
  const { data, not_viewed } = results || {};

  useEffect(() => {
    if (isSuccess) {
      toast.success("All notifications marked as read");
    }
  }, [isSuccess]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  return (
    <Box className="notify-menu" style={{ width: "435px" }} ref={ref}>
      {isLoading && (
        <ContentState
          padding={{ all: 16 }}
          iconSize="small"
          text="Loading..."
        />
      )}
      {!isLoading && (data?.length === 0 || !data) && (
        <Box padding={{ all: 16 }} flex={{ justifyContent: "center" }}>
          <TextComponent>No notification items</TextComponent>
        </Box>
      )}
      {!isLoading && data?.length > 0 && (
        <>
          <Header>
            <div className="arrow" />
            <Title>
              Notifications{" "}
              {not_viewed > 0 && <Badge type="info">{not_viewed} New</Badge>}
            </Title>
            <MarkAll
              variant="link"
              disabled={not_viewed === 0}
              onClick={() => markAsAllRead({ isMarked: !not_viewed })}
            >
              Mark all as read
            </MarkAll>
          </Header>
          {data?.length > 0 &&
            data?.map((notification) => (
              <Content
                href={notification.url}
                target="_blank"
                isViewed={notification.viewed}
              >
                {notification.viewed === 0 && (
                  <NotificationBubble className="bubble" />
                )}
                <Date>{moment(notification.modified).format("MMM DD")}</Date>
                <Text>
                  <div
                    dangerouslySetInnerHTML={{ __html: notification.message }}
                  />
                </Text>
                <LearnMore>
                  Learn more <Icon icon="right-arrow" color="blue" />
                </LearnMore>
              </Content>
            ))}
        </>
      )}
    </Box>
  );
});

function Notification() {
  const { data: notifications } = useGetClientNotificationQuery();

  const { results } = notifications || {};
  const { not_viewed } = results || {};

  return (
    <AtdPopover
      interactive
      placement="bottom-end"
      offset={[28, -5]}
      arrowStyle={{
        position: "relative",
        right: "35px",
      }}
      element={<NotificationItems />}
    >
      <span className="TopNavIcon">
        <Tooltip content="Notification">
          <NotificationBubbleWrapper>
            {not_viewed > 0 && (
              <NotificationBubble>{not_viewed}</NotificationBubble>
            )}
            <Icon icon="bell" />
          </NotificationBubbleWrapper>
        </Tooltip>
      </span>
    </AtdPopover>
  );
}

export default Notification;
