import Box from "../Box/Box";

function PageContentRight({ children }) {
  return (
    <Box flex={{ direction: "column " }} style={{ flex: "1 1 0%" }}>
      <Box
        padding={{ right: 0, left: 20, vertical: 20 }}
        style={{ flex: "1 1 0%" }}
      >
        <Box
          flex={{ direction: "column", justifyContent: "flex-start" }}
          style={{ height: "100%" }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default PageContentRight;
