import React from "react";
import { Field } from "formik";

import { Box, Editor } from "@atd/components";
import { Select } from "@atd/components/InputField";

function CreateEmailTemplateForm({ errors, handleBlur }) {
  return (
    <>
      <div className="title2">Template Name</div>
      <div className="FormField mgt1">
        <Field
          name="name"
          className={`iput ${errors.name && "FormField__input--hasError"}`}
          placeholder="eg: Follow-up #1"
          onBlur={handleBlur}
        />
        {errors?.name && (
          <div className="FormField__input--error">{errors.name}</div>
        )}
      </div>
      <div className="title2 mgt4">Subject</div>
      <Box flex={{ alignItems: "center" }} className="FormField mgt1">
        <Field
          name="subject"
          className={`iput ccss17 ${
            errors.subject && "FormField__input--hasError"
          }`}
          placeholder="eg: {{organization.name}} Follow-up"
        />
        <Field name="tag">
          {({ field: { value }, form: { values, setFieldValue } }) => (
            <Select
              value={value}
              defaultText="Insert"
              options={["company_url", "description"]}
              className={errors?.tag ? "FormField__input--hasError" : ""}
              onChange={(e) => {
                setFieldValue("tag", e.target.value);
                setFieldValue(
                  "subject",
                  `${values?.subject}{{${e.target.value}}}`,
                );
              }}
            />
          )}
        </Field>
      </Box>
      <div className="title2 mgt4">Body</div>
      <div className="FormField mgt1">
        <Field name="body">
          {({ field: { value }, form: { setFieldValue } }) => (
            <Editor
              model={value}
              className={errors?.body ? "FormField__input--hasError" : ""}
              onModelChange={(newValue) => setFieldValue("body", newValue)}
              hasCustomBottomToolbar={false}
            />
          )}
        </Field>
        {errors?.body && (
          <div className="FormField__input--error">{errors.name}</div>
        )}
      </div>
    </>
  );
}

export default React.memo(CreateEmailTemplateForm);
