import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field } from "formik";
import { pick } from "lodash";
import { toast } from "react-toastify";

import { Editor, FormFieldInputError } from "@atd/components";
import {
  useGetNotificationQuery,
  useUpdateNotificationMutation,
  useAddNotificationMutation,
} from "@atd/features/dashboard";
import { NotificationSchema } from "@atd/schema";

import SettingsForm from "../Communications/SettingsForm";

const initialValues = {
  title: "",
  message: "",
  audience_type: "client",
  url: "https://app.alltimedesign.com/teams",
  name: "learn more",
};

function CreateNotification() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: notification } = useGetNotificationQuery(id);
  const [updateNotification] = useUpdateNotificationMutation();
  const [addNotification] = useAddNotificationMutation();

  const [formValues, setFormValues] = useState(null);

  const isEditing = useMemo(() => id !== "new", [id]);

  useEffect(() => {
    if (isEditing) {
      setFormValues(pick(notification, Object.keys(initialValues)));
    }
  }, [isEditing, notification]);

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const { status, message } = isEditing
        ? await updateNotification({
            id,
            ...values,
          }).unwrap()
        : await addNotification({
            ...values,
          }).unwrap();

      if (status === "error") {
        toast.error(message);
      }
      if (status === "success") {
        toast.success(message);
      }
      if (status === "success") {
        navigate("/settings/notifications");
      }
    } catch (error) {
      toast.error(error.message);
    }
    setSubmitting(false);
  };

  return (
    <SettingsForm
      title={isEditing ? "Edit Notification" : "New Notification"}
      initialValues={formValues || initialValues}
      onSubmit={onSubmit}
      onCancel={() => navigate("/settings/notifications")}
      validationSchema={NotificationSchema}
      enableReinitialize
    >
      {({ errors, touched }) => (
        <div className="Box-root" style={{ maxWidth: 630 }}>
          <div className="title2">Notification Title</div>
          <div className="FormField mgt1">
            <Field
              name="title"
              className={`iput ${
                errors.title && touched.title && "FormField__input--hasError"
              }`}
              placeholder="eg: Black Friday Holiday"
            />
            <FormFieldInputError name="title" />
          </div>
          <div className="title2 mgt4">Message</div>
          <div className="FormField mgt1">
            <Field name="message">
              {({ field: { value }, form: { setFieldValue } }) => (
                <Editor
                  model={value}
                  onModelChange={(newValue) =>
                    setFieldValue("message", newValue)
                  }
                  hasCustomBottomToolbar={false}
                />
              )}
            </Field>
          </div>
        </div>
      )}
    </SettingsForm>
  );
}

export default CreateNotification;
