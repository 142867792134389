import { forwardRef } from "react";
import { Outlet } from "react-router-dom";

import Header from "../Header";

const Main = forwardRef(({ onResetData, children }, ref) => (
  <div className="content-area">
    <Header onResetData={onResetData} />
    <div className="app-content" tabIndex="-1" ref={ref}>
      <div className="PageWrapper">{children || <Outlet />}</div>
    </div>
  </div>
));

export default Main;
