import * as React from "react";

function SvgBrand(props) {
  return (
    <svg
      className="Brand_svg__SVGInline-svg Brand_svg__SVGInline--cleaned-svg Brand_svg__SVG-svg Brand_svg__db-SideNav-icon-svg Brand_svg__db-SideNav-icon--payments-svg Brand_svg__wh-16"
      width={16}
      height={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M11.1 10.002A2.1 2.1 0 1 0 6.9 10H1c-.552 0-1-.413-1-.923V2.923C0 2.413.448 2 1 2h12.002c.553 0 1 .413 1 .923v7.08zM7.006 4A2.003 2.003 0 1 0 7 8.006 2.003 2.003 0 0 0 7.006 4z"
          fill="#A3ACB9"
          className="Brand_svg__db-SideNav-iconFill--secondary"
        />
        <path
          d="M15 14H3c-.552 0-1-.413-1-.923V6.923C2 6.413 2.448 6 3 6h12c.552 0 1 .413 1 .923v6.154c0 .51-.448.923-1 .923zM9.006 8A2.003 2.003 0 1 0 9 12.006 2.003 2.003 0 0 0 9.006 8z"
          fill="#4F566B"
          className="Brand_svg__db-SideNav-iconFill--primary"
        />
      </g>
    </svg>
  );
}

export default SvgBrand;
