import styled from "styled-components";

import Box from "../Box/Box";
import Icon from "../Icon/Icon";
import Text from "../Text/Text";

const AlertStyle = styled(Box)`
  background-color: ${({ status }) =>
    status === "error" ? "#fff5fa" : "#fafafa"};
  padding: 12px 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0 0 0 1px
    ${({ status }) => (status === "error" ? "#ebeef1" : "#E3E8EE")};
  border-radius: 4px;

  & .Alert__title {
    display: flex;
    align-items: center;
  }

  & .Alert__text {
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
    color: #404452;
  }
`;

const AlertContent = styled.div`
  display: flex;

  svg {
    margin-right: 10px;
    margin-top: 5px;
  }
`;

function Alert({
  status,
  title,
  description,
  action,
  icon,
  children,
  withIcon = false,
}) {
  return (
    <AlertStyle
      status={status}
      flex={{ alignItems: "center", justifyContent: "space-between" }}
    >
      <AlertContent>
        {withIcon && (
          <Icon icon={icon} color={status === "error" ? "red" : ""} />
        )}
        <div>
          {title && (
            <Text
              as="h3"
              color="dark"
              size={14}
              weight="medium"
              className="Alert__title"
            >
              <span>{title}</span>
            </Text>
          )}
          <div className="Alert__text">{description || children}</div>
        </div>
      </AlertContent>
      <div>{action}</div>
    </AlertStyle>
  );
}

export default Alert;
