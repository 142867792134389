import React from "react";
import { ArrowDown, ArrowRight } from "@atd/components/Icon/icons";
import { Box, Collapsible, Table, Text } from "@atd/components";
import ReportHeader from "./ReportHeader";
import styled from "styled-components";
import ExcerptText from "@atd/components/ExcerptText/ExcerptText";
import { TableWrapper } from "./ReportHeader";
const CollapsibleItem = styled(Collapsible.Item)`
  .content {
    border: 0;
    border-top: 1px solid #e9f1f7;
    border-bottom: 1px solid #e9f1f7;
  }
  span {
    display: flex;
    width: 100% !important;
  }
`;
// const AnalyticalReportDataTable = styled(DataTable)`
//   table td {
//     border: 0;
//   }
//   td {
//     border: 0;
//     text-align: center;
//   }
//   td:first-child {
//     text-align: start;
//     max-width: 0;
//   }
//   tr:last-child {
//     padding: 0 0 11.2px 0 !important;
//   }
//   &.react-tabs {
//     width: 100% !important;
//   }
// `;
const CollapsibleStyle = styled(Collapsible)`
  .total-table > * {
    width: calc(100% / ${({ items }) => items});

    & > span,
    & > span > span {
      justify-content: center;
    }
  }

  }
  .total-table--head > *:first-child + div {
    min-width: calc((100% / ${({ items }) => items}) + 3%);
    & > span {
      justify-content: flex-start;
    }
  }

  .total-table--head > *:first-child {
    width: 24px;
  }
`;

const tableData = (dataTable, headers, { firstKey, lastKey }) => {
  const table = dataTable
    ? dataTable.map((item) => {
        const items = { ...headers, ...item };
        const keys = Object.keys(items);
        let mapKeys = keys.filter((val) => val !== firstKey && val !== lastKey);
        mapKeys.unshift(firstKey);
        mapKeys[mapKeys.length] = lastKey;
        const mapItem = mapKeys.reduce(
          (acc, val) => ({
            ...acc,
            [val]: items[val],
          }),
          {},
        );
        return mapItem;
      })
    : [];
  return table;
};

// const getTableColumns = (
//   dataTable,
//   headers,
//   { firstKey, lastKey, highlight = true, showDefault },
// ) => {
//   const columns = dataTable
//     ? dataTable?.map((item, i) => {
//         const items = { ...headers, ...item };
//         const keys = Object.keys(items);
//         let mapKeys = keys.filter((val) => val !== firstKey && val !== lastKey);
//         mapKeys.unshift(firstKey);
//         mapKeys[mapKeys.length] = lastKey;
//         const mapItem = mapKeys.map((val, index) => {
//           return {
//             Header: "",
//             accessor: val,
//             className: `${
//               items[val]?.length > 10 ? "wt-4" : "wt-4"
//             } Text-wrap--noWrap `,
//             Cell: ({ value }) => (
//               <span
//                 className={`title2 cli ${
//                   val === firstKey && highlight ? "blu-txt" : ""
//                 } ${!index ? "wt-4" : "wt-4 TextInput-element--align--center"}`}
//               >
//                 <ExcerptText
//                   text={
//                     showDefault
//                       ? value
//                           ?.split(" ")
//                           .map(
//                             (word) =>
//                               (word[0] || "")?.toUpperCase() + word.substr(1),
//                           )
//                           .join(" ")
//                       : value || 0
//                   }
//                   length={10}
//                 />
//                 {/* {value || 0} */}
//               </span>
//             ),
//           };
//         });
//         return mapItem;
//       })
//     : [];
//   return columns[0];
// };

const CollapsibleReport = ({
  toggle,
  collapse,
  headers,
  data,
  showDefault = false,
  firstKey,
  lastKey,
}) => {
  const { entities, ...rest } = data;
  const items = { ...headers, ...rest };
  const keys = Object.keys(items);
  let mapKeys = keys.filter(
    (val) =>
      val !== "header_name" && val !== "total_tickets" && val !== "total",
  );
  const isTotalTicket = items.hasOwnProperty("total");
  mapKeys.unshift("header_name");
  mapKeys[mapKeys.length] = isTotalTicket ? "total" : "total_tickets";
  let reportHeaders = mapKeys.reduce(
    (acc, val) => ({
      ...acc,
      [val]: items[val],
    }),
    {},
  );

  return (
    <Table.Row>
      <CollapsibleStyle
        items={Object.keys(reportHeaders).length}
        onClick={toggle}
        overrideTitleClassName="wt-100 total-table total-table--head"
        className="Margin-all--0 wt-100 Flex-flex Flex-direction--row Flex-flex  Flex-flex Flex-alignItems--center"
      >
        <CollapsibleItem
          collapsible={true}
          isOpen={collapse}
          className=" wt-100 list_style_none"
          headerClassName="analytics-header"
          contentClassName="analytics-content"
          title={
            <>
              <Box
                flex={{
                  direction: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                // style={{ height: "40px" }}
              >
                {collapse ? <ArrowDown /> : <ArrowRight />}
              </Box>
              {Object.keys(reportHeaders).map((key, i) => (
                <Box
                  component="td"
                  // className="wt-100"
                  className={`${
                    rest[key]?.length > 10 ? "wt-10" : "wt-15"
                  } Text-wrap--noWrap`}
                  style={{
                    display: "flex",
                    padding: "14px 10px 10px 7px",
                    textAlign: "center",
                    justifyContent: "space-between",

                    whiteSpace: "nowrap",
                  }}
                >
                  <Text
                    as="span"
                    color="gray900"
                    weight="medium"
                    className="report-table"
                    style={{
                      display: "flex",
                      padding: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {showDefault ? rest[key]?.toUpperCase() : rest[key] || 0}
                  </Text>
                </Box>
              ))}
              {/* </Box> */}
            </>
          }
        >
          {entities.map((value, index) => (
            <Box
              padding={{ left: 24 }}
              className={`${
                entities.length - 1 === index ? "Padding-bottom--12" : ""
              } wt-100`}
            >
              <ReportHeader
                headers={value}
                hideProperty={showDefault}
                headerKeys={headers}
                firstKey={firstKey}
              />
              {value[value?.entity] && (
                <>
                  {/* <AnalyticalReportDataTable
                    className="Margin-top--0"
                    tabs={[""]}
                    withTableHead={false}
                    withTabs={false}
                    isLoading={false}
                    columns={getTableColumns(value[value?.entity], headers, {
                      firstKey: firstKey,
                      highlight: true,
                      lastKey: lastKey,
                    })}
                    data={tableData(value[value?.entity], headers, {
                      firstKey: firstKey,
                      lastKey: lastKey,
                    })}
                  /> */}

                  {/* <Box
                      flex
                      background="white"
                      style={{ width: 24, alignSelf: "stretch" }}
                    /> */}
                  {tableData(value[value?.entity], headers, {
                    firstKey: firstKey,
                    lastKey: lastKey,
                  }).map((val, index) => (
                    <TableWrapper
                      items={keys.length + 1}
                      className="total-table wt-100"
                      flex
                    >
                      <>
                        {Object.values(val).map((report, i) => (
                          <>
                            <Box
                              className={`${
                                report?.length > 10 ? "wt-10" : "wt-15"
                              } Text-wrap--noWrap`}
                              // className="wt-100"
                              style={{
                                display: "flex",
                                padding: 0,
                                whiteSpace: "nowrap",
                              }}
                            >
                              <Text
                                as="span"
                                color={`${!i ? "blue" : "gray900"}`}
                                weight="regular"
                                className="report-table"
                                wrap="nowrap"
                              >
                                <ExcerptText
                                  className="report-text"
                                  text={
                                    showDefault
                                      ? report
                                          ?.split(" ")
                                          .map(
                                            (word) =>
                                              (word[0] || "")?.toUpperCase() +
                                              word.substr(1),
                                          )
                                          .join(" ")
                                      : report || 0
                                  }
                                  length={
                                    index
                                      ? 10
                                      : report?.length <= 4
                                      ? report?.length
                                      : 22
                                  }
                                />
                              </Text>
                            </Box>
                          </>
                        ))}
                      </>
                    </TableWrapper>
                  ))}
                </>
              )}
            </Box>
          ))}
        </CollapsibleItem>
      </CollapsibleStyle>
    </Table.Row>
  );
};

export default CollapsibleReport;
