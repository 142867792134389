import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosLib from "axios";

import {
  getErrorMessage,
  axios,
  getRoleById,
  getUniqueObjects,
} from "@atd/utils";
import moment from "moment";

const initialState = {
  teams: [],
  reviews: [],
  fetching: false,
  hasMore: false,
  review_count: null,
  team: null,
  loading: true,
  error: null,
};

export const fetchTeams = createAsyncThunk(
  "team/fetchTeams",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/teams", {
        params,
      });

      if (data.status === "error") {
        throw data;
      }

      return data.results;
    } catch (error) {
      if (error.status === "error") {
        throw error;
      }
      return rejectWithValue(error);
    }
  },
);

export const fetchTeam = createAsyncThunk("team/fetchTeam", async (teamId) => {
  try {
    const { data } = await axios.get(`/teams/${teamId}`);

    if (data.status === "error") {
      throw new Error(data.message);
    }

    return data.results;
  } catch (error) {
    throw Error(error);
  }
});

export const createTeam = createAsyncThunk(
  "team/createTeam",
  async (payload) => {
    try {
      const { data } = await axios.post("/teams", payload);

      if (data.status === "error") {
        const errorMessage = getErrorMessage(data);
        toast.error(errorMessage);

        throw new Error(data.message);
      }

      if (data.status === "success") {
        toast.success(data?.message);
      }

      return data.results;
    } catch (error) {
      throw Error(error);
    }
  },
);

export const updateTeam = createAsyncThunk(
  "team/updateTeam",
  async (payload) => {
    const currentPayload = { ...payload };
    delete currentPayload.id;
    try {
      const { data } = await axios.put(`/teams/${payload.id}`, currentPayload);

      if (data.status === "error") {
        const errorMessage = getErrorMessage(data);
        toast.error(errorMessage);
        throw new Error(errorMessage);
      }

      if (data.status === "success") {
        toast.success(data.message);
      }

      return { id: payload.id, data: currentPayload };
    } catch (error) {
      throw Error(error);
    }
  },
);

export const deleteTeam = createAsyncThunk("teams/deleteTeam", async (id) => {
  try {
    const { data } = await axios.delete(`/teams/${id}`);

    if (data.status === "error") {
      throw new Error(data.message);
    }

    if (data.status === "success") {
      toast.success(data?.message);
    }

    return id;
  } catch (error) {
    throw Error(error);
  }
});

export const fetchDesignerReviews = createAsyncThunk(
  "team/fetchDesignerReviews",
  async (
    {
      date_string = "custom",
      start_date = date_string === "custom"
        ? `${moment().format("YYYY")}-01-01`
        : undefined,
      end_date = date_string === "custom"
        ? moment().format("YYYY-MM-DD")
        : undefined,
      country,
      designer,
      page = 0,
      limit,
    },
    { signal, rejectWithValue },
  ) => {
    const source = axiosLib.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const { data } = await axios.get("/designers_reviews", {
        params: {
          date_string,
          start_date,
          end_date,
          country,
          designer,
          page,
          limit,
        },
        cancelToken: source.token,
      });

      if (data.status === "error") {
        throw data;
      }

      return data.results;
    } catch (error) {
      if (error.status === "error") {
        toast.error(error.message);
        throw new Error(error.message);
      }
      return rejectWithValue(error);
    }
  },
);

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    updateAllTeams: (state, action) => {
      state.teams = action.payload;
    },
    resetReviews: (state) => {
      state.reviews = [];
      state.loading = true;
      state.hasMore = false;
    },
  },
  extraReducers: {
    [fetchTeams.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchTeams.fulfilled]: (state, action) => {
      state.loading = false;
      state.teams = action.payload;
    },
    [fetchTeams.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [fetchTeam.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchTeam.fulfilled]: (state, action) => {
      state.loading = false;
      state.team = action.payload;
    },
    [fetchTeam.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [createTeam.fulfilled]: (state, action) => {
      state.teams.push(action.payload);
    },
    [deleteTeam.pending]: (state) => {
      state.error = null;
    },
    [deleteTeam.fulfilled]: (state, action) => {
      state.teams = state.teams.filter((team) => team.id !== action.payload);
    },
    [deleteTeam.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [updateTeam.fulfilled]: (state, action) => {
      const { data } = action.payload || {};
      const { name } = getRoleById(data.role_id) || {};

      state.teams = state.teams.map((team) => {
        const updateData =
          // eslint-disable-next-line no-nested-ternary
          team.id === action.payload.id
            ? "role_id" in data
              ? { role: data.role_id, role_name: name }
              : data
            : {};

        return {
          ...team,
          ...updateData,
        };
      });
    },
    [fetchDesignerReviews.pending]: (state, action) => {
      // state.loading = true;
      state.fetching = true;
      // state.error = null;
    },
    [fetchDesignerReviews.fulfilled]: (state, { payload }) => {
      const path = "/reports/analytics/review";
      const location = window.location.pathname === path;
      state.loading = false;
      state.fetching = false;
      state.hasMore = payload.has_more && location;
      state.review_count = payload.total_count;
      state.reviews = getUniqueObjects([
        ...state.reviews,
        ...(payload.data || []),
      ]);
    },
    [fetchDesignerReviews.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export const { resetReviews } = teamSlice.actions;

export default teamSlice.reducer;
