import React, { useEffect, useMemo } from "react";
import { useGetTeamPerformanceReportQuery } from "@atd/features/team/teamApi";
import { fetchTeams } from "@atd/features";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { flattenDeep } from "lodash";
import { getFilterOptions } from "@atd/utils";
import { Box, DataTable } from "@atd/components";
import TicketReportHeader from "../TeamReport/TicketReport/TicketReportHeader";
import TicketReportChart from "../TeamReport/TicketReport/TicketReportChart";
import styled from "styled-components";
const PerformanceDataTable = styled(DataTable)`
  thead {
    border-bottom: 1px solid #dee2e6;
  }
  th {
    line-height: 20px;
    font-weight: 500;
    font-size: 14px;
    color: #3c4257;
    text-align: center;
  }
  th:first-child {
    text-align: start;
  }
  td:first-child,
  span.cli {
    text-align: start;
    font-weight: 400;
  }
  td {
    text-align: center;
  }
`;
function PerformanceReport({ params, onChangeFilter, dateFilterOptions }) {
  const { path } = useParams();
  const { teams } = useSelector((state) => state.team);
  const dispatch = useDispatch();
  const users = flattenDeep([...teams.map((team) => team.users)]);
  const performanceBasedTicket = useGetTeamPerformanceReportQuery(params);
  let designerFilterOptions = getFilterOptions(users);
  designerFilterOptions.unshift({ label: "All Designers", value: "all" });
  const tableData = useMemo(() => {
    const table = performanceBasedTicket?.data?.table
      ? performanceBasedTicket?.data?.table.map((item) => ({
          name: item?.name,
          "5_star": item["5_star"],
          "4_star": item["4_star"],
          "3_star": item["3_star"],
          "2_star": item["2_star"],
          "1_star": item["1_star"],
          delivered: item["delivered"],
          closed: item["closed"],
          avg_rating: item["avg_rating"],
        }))
      : [];
    return table;
  }, [performanceBasedTicket?.data?.table]);
  useEffect(() => {
    dispatch(
      path === "performance"
        ? fetchTeams({
            type: "accepted",
            dropdown: "yes",
            delete: "yes",
          })
        : null,
    );
    return () => {};
  }, [dispatch, path]);
  const PerformanceTicketColumns = useMemo(
    () => [
      {
        Header: "Designers",
        accessor: "name",
        Cell: ({ value }) => <span className="title2 cli">{value || 0}</span>,
      },
      {
        Header: "5 Stars",
        accessor: "5_star",
        Cell: ({ value }) => (
          <span className="report-ticket">{value || 0}</span>
        ),
      },
      {
        Header: "4 Stars",
        accessor: "4_star",
        Cell: ({ value }) => (
          <span className="report-ticket">{value || 0}</span>
        ),
      },
      {
        Header: "3 Stars",
        accessor: "3_star",
        Cell: ({ value }) => (
          <span className="report-ticket">{value || 0}</span>
        ),
      },
      {
        Header: "2 Stars",
        accessor: "2_star",
        Cell: ({ value }) => (
          <span className="report-ticket">{value || 0}</span>
        ),
      },
      {
        Header: "1 Stars",
        accessor: "1_star",
        Cell: ({ value }) => (
          <span className="report-ticket">{value || 0}</span>
        ),
      },
      {
        Header: "CLOSED",
        accessor: "closed",
        Cell: ({ value }) => (
          <span className="report-ticket">{value || 0}</span>
        ),
      },
      {
        Header: "DELIVERED",
        accessor: "delivered",
        Cell: ({ value }) => (
          <span className="report-ticket">{value || 0}</span>
        ),
      },
      {
        Header: "AVERAGE",
        accessor: "avg_rating",
        Cell: ({ value }) => (
          <span className="report-ticket">{value || 0}</span>
        ),
      },
    ],
    [],
  );
  return (
    <Box>
      <TicketReportHeader
        title="Performance"
        params={params}
        groupByField="designer"
        placeholder="All Designers"
        onChangeFilter={onChangeFilter}
        groupByFilterOptions={designerFilterOptions}
        dateFilterOptions={dateFilterOptions}
      />
      <TicketReportChart
        data={performanceBasedTicket?.data?.chart}
        isLoading={performanceBasedTicket?.isLoading}
        width="100%"
        height={300}
      />
      {/* <Border
        border={{ position: "bottom" }}
        margin={{ mt: "10px", mb: "0px" }}
      /> */}
      <Box className="Margin-top--20">
        <PerformanceDataTable
          tabs={[""]}
          withTabs={false}
          isLoading={performanceBasedTicket?.isLoading}
          columns={PerformanceTicketColumns}
          data={tableData}
        />
      </Box>
    </Box>
  );
}

export default PerformanceReport;
