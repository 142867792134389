import { Button, Modal } from "@atd/components";

function TimerModal({ isOpen, onClose, onComplete, onPause }) {
  return (
    <Modal
      title="Timer"
      backgroundColor="var(--light-color)"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Body>
        <p>Do you want to take a break or completed this request?</p>
      </Modal.Body>
      <Modal.Footer
        className="Box-root Flex-flex mgt2"
        backgroundColor="var(--light-color)"
      >
        <div className="Box-root Box-hideIfEmpty Margin-right--8">
          <Button variant="default" type="button" onClick={onPause}>
            Pause
          </Button>
        </div>
        <div className="Box-root Box-hideIfEmpty">
          <Button variant="primary" type="submit" onClick={onComplete}>
            Complete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default TimerModal;
