import React from "react";
import { Box } from "@atd/components";
import Activity from "./Activity";
import { getAssignToUserName } from "@atd/utils";

function ActivityGroupList({ activity, users }) {
  const assignedUsers = getAssignToUserName(users, activity?.assigned_to);
  return (
    <Box flex={{ direction: "column", justifyContent: "flex-start" }}>
      <Activity assignedUsers={assignedUsers} activity={activity} />
    </Box>
  );
}

export default ActivityGroupList;
