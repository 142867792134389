import { forwardRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Dropdown, Tooltip } from "@atd/components";
import { getCredentialCookies } from "@atd/utils";
// import { useGetClientAnnouncementQuery } from "@atd/features/dashboard";
import { logout } from "@atd/features";

// const Profile = forwardRef((props, ref) => (
//   <div
//     ref={ref}
//     className="PressableCore PressableCore--radius--all PressableCore--width PressableCore--width--auto Box-root Flex-inlineFlex bsd1"
//   >
//     <div className="PressableCore-base Box-root dropdown wt-auto">
//       <Button withPadding={false} variant="link" {...props}>
//         <div
//           aria-controls="menu4"
//           aria-haspopup="menu"
//           id="menu4-button"
//           aria-hidden="true"
//           className="SVGInline SVGInline--cleaned SVG Icon Icon--person Icon-color Icon-color--gray Box-root Flex-flex"
//         >
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             focusable="false"
//             width="1rem"
//             height="1rem"
//             preserveAspectRatio="xMidYMid meet"
//             viewBox="0 0 24 24"
//             style={{ transform: "rotate(360deg)" }}
//           >
//             <path
//               fill="currentColor"
//               d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
//             />
//           </svg>
//         </div>
//       </Button>
//     </div>
//   </div>
// ));
const Profile = forwardRef((props, ref) => (
  <span className="IconSVG" ref={ref}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      width="1rem"
      height="1rem"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
      style={{ transform: "rotate(360deg)" }}
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
      />
    </svg>
  </span>
));

function ProfileButton() {
  const navigate = useNavigate();
  // const { data: announcement } = useGetClientAnnouncementQuery();

  const { user_id } = getCredentialCookies();

  const dispatch = useDispatch();

  const userMenuHandler = async (id) => {
    if (id === "profile") {
      navigate("/settings/account");
    } else {
      await dispatch(logout(user_id));
      localStorage.clear();
      // if (Array.isArray(announcement)) {
      //   announcement?.forEach((ann) => {
      //     localStorage.removeItem(ann.id);
      //   });
      //   localStorage.clear();
      // }
    }
  };

  return (
    <Tooltip content="Profile">
      <span className="TopNavIcon">
        <Dropdown
          as={Profile}
          popover={{ placement: "bottom-end", offset: [5, 6] }}
          onChange={userMenuHandler}
        >
          <Dropdown.Options>
            <Dropdown.Option value="profile">Profile</Dropdown.Option>
            <Dropdown.Option value="logout">Logout</Dropdown.Option>
          </Dropdown.Options>
        </Dropdown>
      </span>
    </Tooltip>
  );
}

export default ProfileButton;
