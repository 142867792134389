import React from "react";

import Box from "../Box/Box";
import Text from "../Text/Text";
import PressableCore from "../Pressable/PressableCore";
import PressableCoreBase from "../Pressable/PressableCoreBase";

function FullscreenHeader({ title, controls, onClose }) {
  return (
    <Box className="ccss14">
      <Box
        flex={{ justifyContent: "space-between" }}
        padding={{ right: 20, left: 8, vertical: 20 }}
        divider={{ color: "light", side: "bottom", width: 1 }}
        className="FullscreenHeader"
        style={{ flex: "0 0 auto" }}
      >
        <Box flex={{ alignItems: "center" }}>
          <PressableCore
            height="medium"
            width="auto"
            cursor="pointer"
            radius="all"
            className="bsd1"
          >
            <PressableCoreBase>
              <button
                className="UnstyledLink PressableContext Padding-horizontal--12 Padding-vertical--4 PressableContext--cursor--pointer PressableContext--display--inlineFlex PressableContext--fontLineHeight--20 PressableContext--fontSize--14 PressableContext--fontWeight--medium PressableContext--height PressableContext--height--medium PressableContext--radius--all PressableContext--width PressableContext--width--auto"
                type="button"
                onClick={onClose}
              >
                <div className="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row posi1">
                  <div className="TextAligner Box-root flx4" />
                  <div className="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row Flex-justifyContent--flexStart flx5">
                    <div
                      aria-hidden="true"
                      className="SVGInline SVGInline--cleaned SVG Icon Icon--cancel Icon-color Icon-color--gray600 Box-root Flex-flex tsform1"
                    >
                      <svg
                        aria-hidden="true"
                        className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--cancel-svg Icon-color-svg Icon-color--gray600-svg"
                        height={12}
                        width={12}
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 6.585l4.593-4.592a1 1 0 0 1 1.415 1.416L9.417 8l4.591 4.591a1 1 0 0 1-1.415 1.416L8 9.415l-4.592 4.592a1 1 0 0 1-1.416-1.416L6.584 8l-4.59-4.591a1 1 0 1 1 1.415-1.416z"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </button>
            </PressableCoreBase>
          </PressableCore>
          <Box
            background="gray200"
            margin={{ right: 20, left: 8 }}
            className="FullscreenHeader-separator ccss15"
          >
            &nbsp;
          </Box>
          <Text
            color="default"
            size={14}
            medium="medium"
            wrap="wrap"
            display="inline"
          >
            <span>{title}</span>
          </Text>
        </Box>
        <Box flex>{controls}</Box>
      </Box>
    </Box>
  );
}

export default FullscreenHeader;
