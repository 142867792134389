import * as Yup from "yup";

const RequestSchema = Yup.object().shape({
  request_name: Yup.string()
    .required("Request name is required")
    .max(50, "Request name must be in 1 - 50 character range"),
  request_type: Yup.string().required("Design type is required"),
  // file_dimentions: Yup.string().matches(
  //   /([0-9]+\.?[0-9]+((W|w){1})\s?x\s?[0-9]+\.?[0-9]+((h|H){1}$))/,
  //   "Must match the string pattern eg. 10W x 10H, 20.5W x 10.5H",
  // ),
  // // file_dimentions: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       category_id: Yup.string().required(
  //         "File Size or Dimensions is required"
  //       ),
  //       category_item_id: Yup.string().required(
  //         "File Size or Dimensions is required"
  //       ),
  //       units: Yup.string().required("Dimentions units is required"),
  //       height: Yup.string().required("Dimentions height must be a number"),
  //       length: Yup.string().required("Dimentions length must be a number"),
  //     })
  //   )
  //   .min(1, "File Size or Dimensions is required"),
  file_type: Yup.array().min(1, "Please select file format"),
  project_details: Yup.string().required("Design Brief is required"),
});

export default RequestSchema;
