/* eslint-disable react/no-danger */
import { useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "@atd/components";
import {
  useGetAllNotificationQuery,
  useDeleteNotificationMutation,
  useUpdateNotificationStatusMutation,
} from "@atd/features/dashboard";

import DataTable from "../Communications/DataTable";

function NotificationNameCell({ value }) {
  return (
    <>
      <div className="title1 bl-cont">{value.title}</div>
      <div
        className="cont1"
        dangerouslySetInnerHTML={{ __html: value.message }}
      />
    </>
  );
}

function Notifications() {
  const navigate = useNavigate();

  const {
    data: allNotification,
    isLoading,
    error,
  } = useGetAllNotificationQuery();
  const [
    updateNotificationStatus,
    { data: updatedNotificationData, isSuccess },
  ] = useUpdateNotificationStatusMutation();
  const [deleteNotification] = useDeleteNotificationMutation();

  const { message } = updatedNotificationData || {};

  useEffect(() => {
    if (isSuccess) {
      toast.success(message);
    }
  }, [isSuccess, message]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "item",
        Cell: NotificationNameCell,
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    [],
  );

  const data = useMemo(() => {
    const notifications = allNotification
      ? allNotification.map((item) => ({
          item,
          id: item.id,
          status: item.is_active ? "Actived" : "Deactivated",
        }))
      : [];
    return notifications;
  }, [allNotification]);

  return (
    <DataTable
      title="All Notifications"
      isLoading={isLoading}
      error={error}
      action={
        <Button
          label="Create Notification"
          variant="primary"
          icon="plus"
          onClick={() => navigate("/settings/notifications/new")}
        />
      }
      data={data}
      actions={({ value }) => {
        const {
          item: { id, is_active },
        } = value;
        return [
          {
            name: is_active ? "Make a Deactive" : "Make a Active",
            value: "activate",
            onClick: () =>
              updateNotificationStatus({ id, isActive: is_active }),
          },
        ];
      }}
      columns={columns}
      onEdit={(id) => navigate(`/settings/notifications/${id}`)}
      onDelete={(id) => deleteNotification(id)}
    />
  );
}

export default Notifications;
