import React, { forwardRef } from "react";
import classnames from "classnames";

import "./CheckBox.css";

function CheckBox(props, ref) {
  const { className, inputClassName, containerClassName, ...rest } = props;

  const classes = classnames("Checkbox", className);
  const inputClasses = classnames("Checkbox-input", inputClassName);

  return (
    <div className={containerClassName} id="chk">
      <div className={classes}>
        <input ref={ref} type="checkbox" className={inputClasses} {...rest} />
      </div>
    </div>
  );
}

export default forwardRef(CheckBox);
