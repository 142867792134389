import styled, { css } from "styled-components";

import Box from "../Box/Box";

const PressableContext = styled(Box)`
  position: relative;
  z-index: 1;
  flex: 0 1 auto;
  margin: 0;
  text-decoration: none;
  vertical-align: middle;
  word-break: normal;
  background: transparent;
  border: 0;
  outline: 0;
  transition: color 0.24s;
  appearance: none;
  user-select: auto;

  ${(props) =>
    props.vertical === 0 &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `}
  ${(props) =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${(props) =>
    props.radius === "all"
      ? css`
          border-radius: 4px;
        `
      : props.radius &&
        css`
          border-radius: ${props.radius}px;
        `}
  ${(props) =>
    props.height &&
    css`
      min-height: inherit;
    `}
  ${(props) =>
    props.height === "small" &&
    css`
      min-height: 24px;
    `}
  ${(props) =>
    props.width &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.display &&
    css`
      display: ${props.display};
    `}
  ${(props) =>
    props.fontLineHeight &&
    css`
      line-height: ${props.fontLineHeight}px;
    `}
  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize}px;
    `}
  ${(props) =>
    props.fontWeight &&
    css`
      font-weight: ${props.fontWeight};
    `}
`;

export default PressableContext;
