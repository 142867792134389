import * as React from "react";
const SvgArrowDownRight = (props) => (
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="currentColor"
      d="m12.667 10-4 4-.947-.947 2.393-2.386H2.667v-8H4v6.666h6.113L7.72 6.947 8.667 6z"
    />
  </svg>
);
export default SvgArrowDownRight;
