import React from "react";
import ActivityGroupHeader from "./ActivityGroupHeader";
import ActivityGroupList from "./ActivityGroupList";
import { Box } from "@atd/components";
const ActivityGroupContent = ({ activities, params, users }) => {
  return (
    <>
      {activities?.map((activity, index) => (
        <Box flex={{ direction: "column" }} key={index}>
          {activity.header === "column" ? (
            <>
              <ActivityGroupHeader
                headKey={params.group_by}
                headText={activity.value}
              />
            </>
          ) : (
            <>
              <ActivityGroupList users={users} activity={activity} />
            </>
          )}
        </Box>
      ))}
    </>
  );
};

export default ActivityGroupContent;
