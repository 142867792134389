import { Box, Button } from "@atd/components";

function Actions({ disabled, onCancel }) {
  return (
    <div className="Box-root Padding-top--16 Padding-bottom--8 Flex-flex Flex-alignItems--center Flex-justifyContent--spaceBetween">
      <span className="Text-color--dark Text-fontSize--28 Text-fontWeight--bold Text-lineHeight--32 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
        <span className="atitle">&nbsp;</span>
      </span>
      <div className="Box-root pointer2">
        <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap mglmgt-8">
          <Box
            flex
            hideIfEmpty
            margin={{ top: 8, left: 8 }}
            className="pointer1"
          >
            <Button
              type="button"
              disabled={disabled}
              variant="link"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={disabled}
              type="submit"
              variant="primary"
              label="Save"
            />
          </Box>
        </div>
      </div>
    </div>
  );
}

export default Actions;
