import React from "react";
import { NavLink } from "react-router-dom";

import { ArrowDownRight } from "@atd/components/Icon/icons";

import styles from "./Menu.module.css";

const List = ({ link, itemText }) => (
  <li className={styles.menuItem}>
    <span>
      <NavLink
        to={link}
        className={({ isActive }) => {
          const linkClasses = [styles.itemLink];
          if (isActive) linkClasses.push(styles.isActive);
          return linkClasses.join(" "); // returns "registerButton" or "registerButton active"
        }}
      >
        <span className={styles.itemLinkContent}>
          <span className={styles.itemIcon}>
            <ArrowDownRight />
          </span>
          <span className={styles.itemText}>{itemText}</span>
        </span>
      </NavLink>
    </span>
  </li>
);

export default List;
