import styled from "styled-components";
import { uniqueId } from "lodash";

import CheckBox from "../CheckBox/CheckBox";
import Box from "../Box/Box";
import ExternalLink from "../ExternalLink/ExternalLink";

const Option = styled.li`
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ isDisabled }) => (isDisabled ? "#A3ACB9;" : "#1a1f36;")};
  text-align: left;
  padding: 5px 20px;
  background-color: ${({ isActive }) =>
    isActive ? "white" : "var(--light-color)"};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  padding-left: ${({ isMultiple }) => isMultiple && "40px"};

  &:hover {
    background: var(--light-color);
  }

  & label {
    cursor: ${({ isDisabled }) => isDisabled && "not-allowed !important"};
  }
`;

function SelectOptionGroup({
  value,
  limit,
  option,
  disabled,
  isMultiple,
  renderOption,
  onChange,
}) {
  const fieldId = uniqueId("atd-chk-");

  const optionItems =
    limit > 0 ? option.options.slice(0, limit) : option.options;

  return (
    <>
      <Box
        as="li"
        divider={{ side: "bottom", color: "light", width: 1 }}
        margin={{ top: 8 }}
        padding={{
          bottom: 8,
          left: 8,
        }}
      >
        <div tabIndex={-1} className="ccss23">
          <Box>
            <ExternalLink className="UnstyledLink ButtonLink Menu-item Flex-flex">
              <div className="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--rowReversed ccss20">
                <div className="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--rowReversed Flex-justifyContent--flexStart ccss22">
                  <span className="ButtonLink-label Text-color--blue Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--block ccss24">
                    <span className="Text-color--blue Text-align--left Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--block">
                      <span>{option.value}</span>
                    </span>
                  </span>
                </div>
              </div>
            </ExternalLink>
          </Box>
        </div>
      </Box>
      {optionItems.map((optionItem, index) => (
        <Option
          key={optionItem.id}
          isMultiple={isMultiple}
          isDisabled={
            Array.isArray(disabled) ? disabled?.includes(optionItem.id) : false
          }
          isActive={optionItem.id === value && !isMultiple}
          onClick={(e) => {
            if (!isMultiple && !disabled.includes(optionItem.id)) {
              onChange(e, optionItem);
            }
          }}
        >
          {isMultiple && (
            <CheckBox
              id={`${fieldId}-${index}`}
              value={optionItem.id}
              containerClassName="fil-chk"
              disabled={
                Array.isArray(disabled)
                  ? disabled?.includes(optionItem.id)
                  : false
              }
              checked={
                !isMultiple
                  ? optionItem.value === value
                  : value.includes(optionItem.id)
              }
              onChange={onChange}
            />
          )}
          <label htmlFor={`${fieldId}-${index}`} style={{ cursor: "pointer" }}>
            {renderOption ? renderOption(optionItem) : optionItem.name}
          </label>
        </Option>
      ))}
    </>
  );
}

export default SelectOptionGroup;
