import { Box, Text } from "@atd/components";
import uuid from "react-uuid";
import { ReviewStar } from "@atd/components/Feedback/FeedbackReviewLegacy";

export const ReviewItem = ({ company, rating }) => {
  return (
    <Box
      flex={{
        direction: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Text color="default" weight="medium" lineHeight="20">
        {company}&nbsp;
      </Text>
      {Array(5)
        .fill()
        .map((_, index) => (
          <ReviewStar
            index={index + 1}
            rating={rating}
            key={uuid()}
            style={{ fontSize: 12, padding: 0 }}
          />
        ))}
    </Box>
  );
};
