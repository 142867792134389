import { useMemo } from "react";

import { EmptyState, DataTable, ExcerptText } from "@atd/components";

import styled from "styled-components";

const DataTableStyle = styled(DataTable)`
  th {
    box-shadow: rgb(227 232 238) 0px -1px 0px 0px inset !important;
    text-align: center;
    position: sticky;
    white-space: nowrap;
  }
  th:nth-child(2),
  th:nth-child(3) {
    text-align: start;
  }
  td,
  th {
    padding: 6px 10px 6px 7px;
  }
  td:nth-child(2) {
    color: var(--primary-color);
    text-align: start;
  }
  td:first-child,
  th:first-child,
  td:nth-child(3) {
    text-align: start;
  }
  td {
    text-align: center;
    white-space: nowrap;
  }
`;

function ClientsTable({
  data,
  queryParams,
  fetching,
  error,
  counts,
  tabList,
  hasError,
  hasPermission,
  onClickTabItem,
}) {
  const columnKeys = useMemo(() => {
    const columns = {
      feedback: [
        "client_name",
        "company",
        "plan",
        "date_sign_up",
        "total_tickets",
        "closed_tickets",
        "ratings",
      ],
      meetings: ["client_name", "company", "plan", "date", "time", "status"],
      inactive: [
        "client_name",
        "company",
        "plan",
        "signup",
        "total_tickets",
        "last_tickets_raised",
        "idle_date",
        "inactive_days",
        "average_feedback",
      ],
    };
    return columns[queryParams.tab];
  }, [queryParams.tab]);
  let columns = useMemo(() => {
    const keys = columnKeys;
    return keys.map((val) => {
      return {
        Header: val
          .split("_")
          .map((word) => (word[0] || "")?.toUpperCase() + word.substr(1))
          .join(" "),
        accessor: val,
        Cell: ({ value }) => (
          <ExcerptText
            text={value}
            length={value?.length >= 15 ? 10 : value?.length}
          />
        ),
      };
    });
  }, [columnKeys]);

  const clientsData = useMemo(() => {
    if (!data) return [];
    return data;
  }, [data]);

  if (hasError && error.message !== "Aborted") {
    return (
      <EmptyState
        icon="client"
        title="Something went wrong!"
        description={<span>{error.message}</span>}
      />
    );
  }

  if (!fetching && data?.length === 0) {
    return (
      <EmptyState
        icon="client"
        title="No results found"
        description={
          <span>There aren&rsquo;t any results for that query.</span>
        }
      />
    );
  }

  return (
    <DataTableStyle
      tabs={tabList}
      tabCounts={counts}
      withTabs={hasPermission}
      withTabCounts={true}
      isLoading={false}
      columns={columns}
      data={clientsData}
      // onClick={(id) => navigate(id)}
      onClickTabItem={onClickTabItem}
    />
  );
}

export default ClientsTable;
