import React from "react";

import Box from "../Box/Box";
import Spinner from "../Spinner/Spinner";
import Text from "../Text/Text";

function ContentState({ text, iconSize = "large", ...rest }) {
  return (
    <Box
      className="ContentState"
      padding={{ horizontal: 20, vertical: 48 }}
      flex={{
        alignItems: "center",
        direction: "column",
        justifyContent: "center",
        wrap: "wrap",
      }}
      {...rest}
    >
      <Box
        padding={{
          bottom: text && iconSize === "large" ? 12 : 0,
          right: text && iconSize === "small" ? 12 : 0,
        }}
      >
        <Spinner size={iconSize} />
      </Box>
      {text && (
        <Text
          className="ContentState-title"
          wrap
          numericSpacing="proportional"
          display="block"
        >
          {text}
        </Text>
      )}
    </Box>
  );
}

export default ContentState;
