import { Icon } from "@atd/components";

function SummaryItem({ plan, onRemove }) {
  const currentAmount = plan.has_breakup
    ? plan.breakup_amount
    : plan.total_offer_price;

  const totalSum = currentAmount * plan.designers;

  return (
    <tr key={plan.id}>
      <td className="title2">
        {plan.designers} x {plan.name} - {plan.period}
      </td>
      <td></td>
      <td>
        <div className="title2 right">${totalSum.toFixed(2)}</div>
      </td>
      <td style={{ width: "2%" }}>
        <Icon icon="cancel" color="gray600" onClick={() => onRemove(plan.id)} />
      </td>
    </tr>
  );
}

export default SummaryItem;
