import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, AppHeader } from "@atd/components";
import { Main } from "@atd/layouts";
import TicketReport from "./TeamReport/TicketReport/TicketReport";
import DesignerReviews from "./TeamReport/DesignerReviews/DesignerReviews";
// import TimeActivityReport from "./TeamReport/TimeActivityReport";
import DailyReport from "./TeamReport/DailyReport";
import DesignerReport from "./TeamReport/DesignerReport";
import { isAdmin, getFilterOptions } from "@atd/utils";
import { useFiltersQuery } from "@atd/features/request";
import {
  useGetLocationBasedTicketReportQuery,
  useGetTeamPerformanceReportQuery,
  useGetTeamTotalTicketReportQuery,
  useGetTeamRevisionTicketReportQuery,
} from "@atd/features/team";
import { useSelector, useDispatch } from "react-redux";
import { flattenDeep } from "lodash";
import { fetchTeams } from "@atd/features";
import { useLocalStorage } from "@atd/hooks";
const Team = () => {
  const params = {
    // report_Id: "",
    location: { date_string: "this_year" },
    performance: { date_string: "this_year" },
    total: { date_string: "this_year" },
    revision: { date_string: "this_year" },
  };
  const [teamFilters, setTeamFilters] = useLocalStorage("team-filters", params);
  const { data: filters, isSuccess } = useFiltersQuery(
    isAdmin() ? "new" : "queue",
  );
  const { date: dateFilter } = filters || {};
  const dateFilterOptions = isSuccess ? getFilterOptions(dateFilter) : [];
  const [ticketReportId, setTicketReportId] = useState(teamFilters[""]);
  const [queryParams, setQueryParams] = useState(teamFilters);
  const filterParams = teamFilters;
  const {
    location: { country, designer, ...location_queryParamFilter },
    performance: {
      country: performance_country,
      designer: performance_designer,
      ...performance_queryParamFilter
    },
    total: {
      country: total_country,
      designer: total_designer,
      ...total_queryParamFilter
    },
    revision: {
      country: revision_country,
      designer: revision_designer,
      ...revision_queryParamFilter
    },
  } = queryParams || {};
  console.log(
    country,
    designer,
    performance_country,
    performance_designer,
    total_country,
    total_designer,
    revision_country,
    revision_designer,
  );
  const queryParamFilter = {
    location: location_queryParamFilter,
    performance: performance_queryParamFilter,
    total: total_queryParamFilter,
    revision: revision_queryParamFilter,
  };
  const locationBasedTicket = useGetLocationBasedTicketReportQuery(
    queryParamFilter["location"],
  );
  const performanceBasedTicket = useGetTeamPerformanceReportQuery(
    queryParamFilter["performance"],
  );
  const totalTicketReport = useGetTeamTotalTicketReportQuery(
    queryParamFilter["total"],
  );
  const revisionTicketReport = useGetTeamRevisionTicketReportQuery(
    queryParamFilter["revision"],
  );
  const { teams } = useSelector((state) => state.team);
  const dispatch = useDispatch();
  const users = flattenDeep([...teams.map((team) => team.users)]);
  let designerFilterOptions = getFilterOptions(users);
  designerFilterOptions.unshift({ label: "All Designers", value: "all" });
  const onChangeFilter = useCallback(
    ({ id }, field, value) => {
      let queryParam = queryParams;
      let params = queryParams[id];
      params = { ...params, [field]: value };
      queryParam[id] = params;
      setQueryParams((prevParam) => ({
        ...prevParam,
        ...queryParam,
      }));
      setTicketReportId(id);
      const filter = {};
      filter[id] = { ...filterParams[id], ...{ [field]: value } };
      setTeamFilters((prevParam) => ({
        ...prevParam,
        // report_Id: ticketReportId,
        ...{
          [id]: {
            ...queryParams[id],
            ...{
              [field]: value,
              ...{ country: queryParams[id]["country"] },
              ...{ designer: queryParams[id]["designer"] },
            },
          },
        },
      }));
    },
    [queryParams, filterParams, setTeamFilters],
  );
  useEffect(() => {
    return () => {};
  }, [ticketReportId]);
  useEffect(() => {
    dispatch(
      fetchTeams({
        type: "accepted",
        dropdown: "yes",
        delete: "yes",
      }),
    );
    return () => {};
  }, [dispatch]);
  const locationBasedTicketColumns = useMemo(
    () => [
      {
        Header: "LOCATIONS",
        accessor: "location",
        Cell: ({ value }) => <span className="title2 cli">{value}</span>,
      },
      {
        Header: "Tickets",
        accessor: "count",
        Cell: ({ value }) => <span className="report-ticket">{value}</span>,
      },
    ],
    [],
  );
  const PerformanceTicketColumns = useMemo(
    () => [
      {
        Header: "DESIGNERS",
        accessor: "location",
        Cell: ({ value }) => <span className="title2 cli">{value}</span>,
      },
      {
        Header: "OVERALL",
        accessor: "count",
      },
    ],
    [],
  );
  const TotalTicketColumns = useMemo(
    () => [
      {
        Header: "CATEGORIES",
        accessor: "location",
        Cell: ({ value }) => <span className="title2 cli">{value}</span>,
      },
      {
        Header: "TOTAL",
        accessor: "count",
      },
    ],
    [],
  );
  const revisionTicketColumns = useMemo(
    () => [
      {
        Header: "CATEGORIES",
        accessor: "location",
        Cell: ({ value }) => <span className="title2 cli">{value}</span>,
      },
      {
        Header: "TOTAL",
        accessor: "count",
      },
    ],
    [],
  );
  return (
    <Main>
      <AppHeader title="Team" spread="appContent" />
      <DesignerReport />
      <Box flex={{ direction: "column", justifyContent: "center" }}>
        <Box flex={{ direction: "row", alignItems: "center" }}>
          <TicketReport
            title="location_based_tickets"
            params={queryParams}
            onChangeFilter={onChangeFilter}
            columns={locationBasedTicketColumns}
            borderPosition="borderRight"
            dateFilterOptions={dateFilterOptions}
            report={locationBasedTicket}
          />
          <TicketReport
            title="performance"
            params={queryParams}
            onChangeFilter={onChangeFilter}
            columns={PerformanceTicketColumns}
            dateFilterOptions={dateFilterOptions}
            report={performanceBasedTicket}
          />
        </Box>
        {/* <Border
          border={{ position: "bottom" }}
          margin={{ mb: "40px", mt: "0px" }}
        /> */}
        <Box
          flex={{ direction: "row", alignItems: "center" }}
          className="Margin-top--40"
        >
          <TicketReport
            title="total_tickets_reports"
            params={queryParams}
            onChangeFilter={onChangeFilter}
            columns={TotalTicketColumns}
            borderPosition="borderRight"
            dateFilterOptions={dateFilterOptions}
            report={totalTicketReport}
          />
          <TicketReport
            title="revision_tickets_reports"
            params={queryParams}
            onChangeFilter={onChangeFilter}
            columns={revisionTicketColumns}
            dateFilterOptions={dateFilterOptions}
            report={revisionTicketReport}
          />
        </Box>
      </Box>
      <DesignerReviews
        dateFilterOptions={dateFilterOptions}
        designerFilterOptions={designerFilterOptions}
      />
      {/* <TimeActivityReport dateFilterOptions={dateFilterOptions} /> */}
      <DailyReport dateFilterOptions={dateFilterOptions} />
    </Main>
  );
};

export default Team;
