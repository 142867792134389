import { useState, useEffect, memo } from "react";
import { toast } from "react-toastify";

import axiosUnsplash from "@atd/utils/axiosUnsplash";
import SearchInput from "./SearchInput";
import SearchBody from "./SearchBody";
import SearchFooter from "./SearchFooter";

function RoyalImageSearchForm({ royalImagesHandler, onCloseModal }) {
  const [searchImages, setSearchImages] = useState([]);
  const [selectedImg, setSelectedImg] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { data } = await axiosUnsplash.get("/photos");
      setSearchImages(data);
      setIsLoading(false);
    })();
  }, []);

  const fetchSearchResults = async (keyword) => {
    const {
      data: { results },
    } = await axiosUnsplash.get("/search/photos", {
      params: {
        query: keyword,
      },
    });
    setSearchImages(results);
  };

  const searchImageHandler = async (keyword) => {
    setIsLoading(true);
    await fetchSearchResults(keyword);
    setIsLoading(false);
  };

  const onSubmitHandler = () => {
    if (selectedImg) {
      royalImagesHandler(selectedImg);
      onCloseModal();
    } else {
      toast.error("Please select an image!");
    }
  };

  return (
    <div className="pd16">
      <SearchInput searchImageHandler={searchImageHandler} />
      <SearchBody
        isLoading={isLoading}
        searchImages={searchImages}
        selectedImg={selectedImg}
        selectImgHandler={(url) => setSelectedImg(url)}
      />
      <SearchFooter onSubmitHandler={onSubmitHandler} />
    </div>
  );
}

export default memo(RoyalImageSearchForm);
