function FeedbackSuccess() {
  return (
    <section className="copy">
      <div className="container-half">
        <div className="icon">
          <svg width="100%" height="100%" viewBox="0 0 78 78">
            <g
              strokeWidth={5}
              stroke="#3EA4EB"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path
                d="M24.7529297,41.4525637 34.2547154,50.9543494 55,30.2090647"
                strokeDasharray="42.77581787109375"
                strokeDashoffset={0}
              />
            </g>
          </svg>
        </div>
        <span className="h1 common-IntroText">
          <h1 className="initial">
            Thank you for the <br />
            feedback
          </h1>
        </span>
        <span className="p common-BodyText">
          <p className="initial">Good communication with the team.</p>
          <p className="done">
            Thanks once again for your honest feedback. We&rsquo;ll closely
            review the help you received to understand how we can improve.
          </p>
        </span>
      </div>
    </section>
  );
}

export default FeedbackSuccess;
