import { Modal, Table } from "@atd/components";
import { CircleCheck } from "@atd/components/Icon/icons";

function SubscriptionHelpModal({ show, onClose }) {
  return (
    <Modal
      title="What is a Subscription?"
      isOpen={show}
      onClose={onClose}
      hasFooter={false}
      width={700}
    >
      <div className="pd16">
        <p>
          The scope, turnaround time, and the quantity of the designs may vary
          based on the plan(s) you have chosen.
        </p>
        <p className="title2 mgt2">
          Graphic Design Subscription may look like one of the following:
        </p>
        <Table hasBorder className="table wt-100 mgb10 wt-half mgt1">
          <thead>
            <tr className="left">
              <th className="title2 fnt-14">One Subscription</th>
              <th className="title2 fnt-14">Two Subscriptions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  1-3 Unique Social Media Graphics
                </div>
                <div className="mgt1">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  1 One-sided Flyer or 1 Two-sided Flyer
                </div>
                <div className="mgt1">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  1 Google Ad in 8 Different Sizes
                </div>
                <div className="mgt1">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  6 Plain Text Tees or 3 Typography Tees
                </div>
                <div className="mgt1">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  1 Product Background Removal
                </div>
              </td>
              <td>
                <div>
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  2-6 Unique Social Media Graphics
                </div>
                <div className="mgt1">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  4 Podcast Cover Graphics
                </div>
                <div className="mgt1">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  2 Unique Google Ads in 8 different sizes
                </div>
                <div className="mgt1">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  1 Flyer with 4 Variations
                </div>
                <div className="mgt1">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Background Removal with Complex Shapes
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <p className="mgt2">
          There are four levels of subscriptions; Standard, Pro, Premium and
          Ultimate. Our creative team sweats it out to deliver the designs
          within 2 - 3 business days for Standard subscription, 24 hours for Pro
          subscription and Premium subscriptions and even lesser time for
          Ultimate pack.
        </p>
      </div>
    </Modal>
  );
}

export default SubscriptionHelpModal;
