/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { forwardRef } from "react";
import styled from "styled-components";
import { flatMap, map } from "lodash";

import { DoubleArrow } from "../Icon/icons";
import Icon from "../Icon/Icon";

const InputField = styled.input`
  cursor: pointer;
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: none !important;
  border: none !important;
  width: auto;
  outline: none;
  position: absolute;
  top: 1px;
  right: 2px;
  left: 0;
  bottom: 2px;
`;

function SmallInput({ placeholder, value, hasSearchField, onClick, onChange }) {
  return (
    <div
      className="dbtn btn SearchableSelect-element PressableContext Padding-horizontal--8 Padding-vertical--4 PressableContext--cursor--pointer PressableContext--display--inlineFlex PressableContext--fontLineHeight--20 PressableContext--fontSize--14 PressableContext--fontWeight--medium PressableContext--height PressableContext--height--medium PressableContext--radius--all PressableContext--width PressableContext--width--maximized ccss7"
      onClick={onClick}
    >
      <div className="Box-root wt-100">
        <div className="TruncatedText oflow2">
          <span className="Text-color--default Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--inline Padding-right--8">
            {value || placeholder}
          </span>
          <InputField
            type="text"
            readOnly={hasSearchField}
            autoComplete="off"
            placeholder={placeholder}
            onChange={onChange}
            value={value ?? ""}
          />
        </div>
      </div>
      <Icon icon="double-arrow" color="gray600" />
    </div>
  );
}

const SearchableInput = forwardRef(
  (
    {
      inputRef,
      value,
      defaultValue,
      placeholder,
      size = "normal",
      isOptionGroup,
      multiple,
      options,
      instance,
      isSearching,
      filterValue,
      render,
      style,
      onChange,
      withArrow,
      renderItem,
      hasSearchField = false,
      onClick,
      ...props
    },
    ref,
  ) => {
    const allValues = flatMap(options, ({ options: items }) =>
      map(items, (item) => ({
        label: item.name,
        value: item.id,
        icon: item.icon,
      })),
    );

    const allOptions = isOptionGroup ? allValues : options;

    const filteredOptions =
      value &&
      allOptions.filter((option) =>
        multiple
          ? value?.includes(option.value)
          : value?.toString().includes(option.value),
      );

    return (
      <div className="atd-dropdown__inputWrapper" ref={ref}>
        {render ? (
          <span className="btn">
            <div onClick={onClick} role="listbox">
              {render({
                value,
                style,
                onClick,
                items: filteredOptions,
                show: !!instance?.isOpen,
                formattedValue: defaultValue,
              })}
            </div>
          </span>
        ) : (
          <span className="btn">
            {size === "small" ? (
              <SmallInput
                placeholder={placeholder}
                value={isSearching ? filterValue : defaultValue}
                onChange={onChange}
                hasSearchField={hasSearchField}
                onClick={onClick}
              />
            ) : (
              <div>
                {renderItem && (
                  <span className="Select__selectedText">{renderItem()}</span>
                )}
                <input
                  ref={inputRef}
                  className="iput"
                  id="TypeSearchBox"
                  type="text"
                  autoComplete="off"
                  readOnly={hasSearchField}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    minWidth: "100%",
                    ...(size === "medium" ? { height: 28, minHeight: 28 } : {}),
                    ...style,
                  }}
                  placeholder={placeholder}
                  onChange={onChange}
                  value={isSearching ? filterValue : defaultValue}
                  onClick={onClick}
                  {...props}
                />
                {withArrow && (
                  <span className="iconn">
                    <DoubleArrow />
                  </span>
                )}
              </div>
            )}
          </span>
        )}
      </div>
    );
  },
);

export default SearchableInput;
