import { getCredentialCookies } from "@atd/utils";

import { accountApi } from "../user/accountApi";

const { token } = getCredentialCookies();

const announcementApi = accountApi.injectEndpoints({
  tagTypes: ["Announcement"],
  endpoints: (build) => ({
    getAllAnnouncements: build.query({
      query: () => ({
        url: "announcement",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response) => response.results,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Announcement", id })),
              { type: "Announcement", id: "LIST" },
            ]
          : [{ type: "Announcement", id: "LIST" }],
    }),
    getAnnouncement: build.query({
      query: (id) => ({
        url: `announcement/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response) => response.results,
      providesTags: (result, error, id) => [{ type: "Announcement", id }],
    }),
    getClientAnnouncement: build.query({
      query: () => ({
        url: "get_announcement",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response) => response.results,
      providesTags: (result, error, id) => [{ type: "Announcement", id }],
    }),
    updateAnnouncementStatus: build.mutation({
      query: (data) => {
        const { id, isActive } = data;
        return {
          url: `announcement/${id}`,
          method: isActive === 1 ? "UNLINK" : "LINK",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "Announcement", id },
      ],
    }),
    addAnnoucement: build.mutation({
      query: (body) => ({
        url: "announcement",
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body,
      }),
      invalidatesTags: [{ type: "Announcement", id: "LIST" }],
    }),
    updateAnnoucement: build.mutation({
      query: (data) => {
        const { id, ...body } = data;
        return {
          url: `announcement/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "Announcement", id },
      ],
    }),
    deleteAnnouncement: build.mutation({
      query: (id) => ({
        url: `announcement/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      invalidatesTags: ["Announcement"],
    }),
  }),
  overrideExisting: false,
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useGetAnnouncementQuery,
  useGetAllAnnouncementsQuery,
  useGetClientAnnouncementQuery,
  useAddAnnoucementMutation,
  useUpdateAnnoucementMutation,
  useDeleteAnnouncementMutation,
  useUpdateAnnouncementStatusMutation,
} = announcementApi;
