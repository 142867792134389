import { Link } from "react-router-dom";
import logo from "../assets/atd-logo-white.svg";

const HeaderContent = () => {
  return (
    <div className="co-progress-wrapper">
      <nav className="co-nav-wrapper wf-section">
        <div className="nav-checkout-wrapper w-nav">
          <div className="container_ w-container">
            <div className="nav-checkout">
              <a
                href="https://alltimedesign.com/"
                className="nav-logo w-nav-brand"
              >
                <div className="nav-logo w-embed">
                  <img src={logo} alt="All Time design logo" />
                </div>
              </a>
              <div className="co-nav-sign-in">
                Already have an account? &nbsp;
                <Link to="/login" className="checkout-link">
                  Log in
                </Link>
              </div>
              {/* <div className="co-nav-menu">
                <Link to="/login" className="link-button w-inline-block">
                  <div className="co-button-text">Log in</div>
                </Link>
              </div> */}
            </div>
          </div>
          <div className="w-nav-overlay" data-wf-ignore id="w-nav-overlay-0" />
        </div>
      </nav>
    </div>
  );
};

export default HeaderContent;
