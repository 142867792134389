import uuid from "react-uuid";
import { Transition } from "react-transition-group";

import Box from "../Box/Box";
import Text from "../Text/Text";

import FilterType from "./FilterType/FilterType";

const transitionStyles = {
  entering: { maxHeight: "400px" },
  entered: { maxHeight: "400px" },
  exiting: { maxHeight: 0 },
  exited: { maxHeight: 0 },
};

function FilterItem({ name, label, checked, onChange, ...rest }) {
  const inputId = uuid();

  return (
    <>
      <Box
        divider={{ color: "light", side: "bottom", width: 1 }}
        padding={{ all: 8 }}
      >
        <Box flex={{ alignItems: "center", direction: "row" }}>
          <input
            id={label}
            name={name}
            type="checkbox"
            checked={checked}
            className="Checkbox-input"
            onChange={onChange}
            style={{ cursor: "pointer" }}
          />
          <Box padding={{ left: 8 }} style={{ cursor: "pointer" }}>
            <Text color="default" weight="medium">
              <label htmlFor={inputId} style={{ cursor: "inherit" }}>
                {label}
              </label>
            </Text>
          </Box>
        </Box>
      </Box>
      <Transition in={checked} timeout={300}>
        {(state) => {
          return (
            <Box
              background="gray50"
              divider={{ color: "light", side: "bottom", width: 1 }}
              margin={{ top: 0 }}
              padding={{
                all: ["entered"].includes(state) && 12,
                vertical:
                  ["exited", "entering"].includes(state) && state !== "entering"
                    ? 0
                    : state === "entering" && 12,
                horizontal:
                  ["exited", "exiting", "entering"].includes(state) && 12,
              }}
              style={{
                maxHeight: 0,
                overflow: "hidden",
                transition: "max-height 0.3s ease 0s, padding 0.3s ease 0s",
                ...transitionStyles[state],
              }}
            >
              <FilterType
                name={name}
                label={label}
                {...rest}
                checked={checked}
              />
            </Box>
          );
        }}
      </Transition>
    </>
  );
}

export default FilterItem;
