import styled from "styled-components";
import { Tabs } from "react-tabs";

const UITabs = styled(Tabs)`
  margin-top: -52px;
  position: relative;
  z-index: 1;

  .tabs {
    border: 0;
  }
`;

export default UITabs;
