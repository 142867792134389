import React from "react";

function TableEmpty({ colSpan, trClassName, children }) {
  return (
    <tr className={trClassName}>
      <td colSpan={colSpan}>
        <div className="Text-align--center">{children}</div>
      </td>
    </tr>
  );
}

export default TableEmpty;
