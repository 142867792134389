import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";

import RequestApi from "./requestApi";

const initialState = {
  error: null,
  request: null,
  creating: false,
  success: false,
  loading: true,
  fetching: false,
  hasErrors: false,
  errorMsg: "",
  requests: [],
  request_types: [],
  hasMore: false,
  isRequestLoading: true,
  search: null,
  counts: {},
  isSearch: false,
  processing: false,
};

export const searchRequest = createAsyncThunk(
  "request/searchRequest",
  async (query) => RequestApi.search(query),
);

export const getAllRequestAsync = createAsyncThunk(
  "request/getAllRequestAsync",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await RequestApi.getAllRequests(payload, source.token);
    return response;
  },
);

export const getRequest = createAsyncThunk(
  "request/getRequest",
  async (id, { rejectWithValue }) => {
    try {
      const data = await RequestApi.getSingle(id);
      if (data.status === "error") {
        throw data;
      }
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getRequestTypesAsync = createAsyncThunk(
  "request/getRequestTypesAsync",
  async () => RequestApi.getRequestTypes(),
);

export const updateRequest = createAsyncThunk(
  "request/updateRequest",
  async (payload) => RequestApi.updateRequest(payload),
);

export const deleteRequest = createAsyncThunk(
  "request/deleteRequest",
  async (payload) => RequestApi.deleteRequest(payload),
);

export const submitRequest = createAsyncThunk(
  "request/submitRequest",
  async (payload) => RequestApi.submitRequest(payload.id, payload.data),
);

export const markAsComplete = createAsyncThunk(
  "request/markAsComplete",
  async (args) => RequestApi.markAsComplete(args.id, args.data),
);

export const getRequestsCount = createAsyncThunk(
  "request/getRequestsCount",
  async (params) => {
    try {
      const response = await RequestApi.getRequestsCount(params);
      if (response.status === "error") {
        toast.error(response.message);
      }
      return response.results;
    } catch (err) {
      toast.error(err.message);
      return err;
    }
  },
);

export const setRequestTimer = createAsyncThunk(
  "request/setRequestTimer",
  async ({ id, payload }) => {
    try {
      const response = await RequestApi.setRequestTimer(id, payload);

      const { status, message } = response || {};

      if (status === "error") {
        toast.error(message);
      }
      if (status === "success") {
        toast.success(message);
      }
      return response;
    } catch (err) {
      toast.error(err.message);
      return err;
    }
  },
);

export const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    addError: (state, action) => {
      state.error = action.payload;
    },
    resetRequest: (state) => {
      state.requests = [];
      state.loading = true;
      state.hasMore = false;
    },
    resetRequestData: (state) => {
      state.requests = [];
    },
    updateCurrentRequest: (state, action) => {
      state.request = { ...state.request, ...action.payload };
    },
    setSearchQuery: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: {
    [getAllRequestAsync.pending]: (state) => {
      state.fetching = true;
      state.isSearch = false;
      // state.hasMore = false;
      // state.creating = false;
      // state.success = false;
    },
    [getAllRequestAsync.fulfilled]: (state, action) => {
      state.isSearch = false;
      state.fetching = false;
      state.loading = false;
      state.hasErrors = false;
      state.requests = [
        ...state.requests,
        ...(action.payload.results?.data || {}),
      ];
      state.hasMore = action.payload.results?.has_more;
    },
    [getAllRequestAsync.rejected]: (state, action) => {
      // state.fetching = false;
      state.isSearch = false;
      state.loading = false;
      state.hasErrors = true;
      state.error = action.error;
      state.hasMore = false;
    },
    [searchRequest.pending]: (state) => {
      state.fetching = true;
      state.isSearch = true;
    },
    [searchRequest.fulfilled]: (state, action) => {
      state.fetching = false;
      state.loading = false;
      state.isSearch = true;
      state.hasErrors = false;
      state.requests = [
        ...state.requests,
        ...(action.payload.results?.data || {}),
      ];
      state.hasMore = action.payload.results?.has_more;
    },
    [searchRequest.rejected]: (state, action) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = action.error;
      state.requests = [];
      state.isSearch = true;
      state.hasMore = false;
    },
    [getRequest.pending]: (state) => {
      state.isRequestLoading = true;
      state.request = null;
      state.requests = [];
    },
    [getRequest.fulfilled]: (state, action) => {
      state.isRequestLoading = false;
      state.error = null;
      state.request = action.payload.results;
    },
    [getRequest.rejected]: (state, action) => {
      state.isRequestLoading = false;
      state.error = action.error;
    },

    [getRequestTypesAsync.pending]: (state) => {
      state.hasErrors = false;
      state.errorMsg = "";
      state.requests = [];
      state.creating = false;
      state.success = false;
    },
    [getRequestTypesAsync.fulfilled]: (state, action) => {
      state.hasErrors = false;
      state.errorMsg = "";
      state.request_types = action.payload.request_types;
    },
    [getRequestTypesAsync.rejected]: (state, action) => {
      state.hasErrors = true;
      state.errorMsg = action.error.message;
      state.requests = [];
    },
    [updateRequest.pending]: (state) => {
      state.hasErrors = false;
      state.errorMsg = "";
    },
    [updateRequest.fulfilled]: (state, action) => {
      if (["archive", "unarchive"].includes(action.payload.update_type)) {
        state.requests = state.requests.filter(
          (request) => request.id !== action.payload.request_ids?.[0],
        );
      }
      state.hasErrors = false;
      state.errorMsg = "";
    },
    [updateRequest.rejected]: (state, action) => {
      state.hasErrors = true;
      state.errorMsg = action.error.message;
    },
    [submitRequest.pending]: (state) => {
      state.creating = true;
      state.success = false;
      state.hasErrors = false;
      state.errorMsg = "";
    },
    [submitRequest.fulfilled]: (state, action) => {
      state.creating = false;
      state.success = true;
      state.hasErrors = false;
      state.errorMsg = "";
      state.request = action.payload;
    },
    [submitRequest.rejected]: (state, action) => {
      state.success = false;
      state.creating = false;
      state.hasErrors = true;
      state.errorMsg = action.error.message;
    },
    [deleteRequest.pending]: (state) => {
      state.errorMsg = "";
    },
    [deleteRequest.fulfilled]: (state, action) => {
      const updatedRequestsData = state.requests.filter(
        (request) => request.id !== action.payload.id,
      );
      state.requests = updatedRequestsData;
    },
    [deleteRequest.rejected]: (state, action) => {
      state.errorMsg = action.error.message;
    },
    [markAsComplete.fulfilled]: (state, action) => {
      const updatedRequestsData = state.requests.filter(
        (request) => request.id !== action.payload.id,
      );
      state.requests = updatedRequestsData;
    },
    [getRequestsCount.fulfilled]: (state, action) => {
      state.counts = action.payload.reduce((res, count) => {
        const results = { ...res };
        results[count.name] = count.count;
        return results;
      }, {});
      state.request = null;
    },
    [setRequestTimer.pending]: (state) => {
      state.processing = true;
    },
    [setRequestTimer.fulfilled]: (state) => {
      state.processing = false;
    },
    [setRequestTimer.rejected]: (state) => {
      state.processing = false;
    },
  },
});

export const {
  resetRequest,
  resetRequestData,
  updateCurrentRequest,
  setSearchQuery,
} = requestSlice.actions;

export default requestSlice.reducer;
