import * as React from "react";

function SvgCalendar(props) {
  return (
    <svg
      aria-hidden="true"
      className="Calendar_svg__SVGInline-svg Calendar_svg__SVGInline--cleaned-svg Calendar_svg__SVG-svg Calendar_svg__Icon-svg Calendar_svg__Icon--calendar-svg Calendar_svg__db-InlineDateRangeFilter-calendarIcon-svg Calendar_svg__Icon-color-svg Calendar_svg__Icon-color--gray600-svg"
      height={14}
      width={14}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 8h16v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm13-6h1a2 2 0 0 1 2 2v2H0V4a2 2 0 0 1 2-2h1v1a1 1 0 1 0 2 0V2h6v1a1 1 0 0 0 2 0zM4 0a1 1 0 0 1 1 1v1H3V1a1 1 0 0 1 1-1zm8 0a1 1 0 0 1 1 1v1h-2V1a1 1 0 0 1 1-1z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgCalendar;
