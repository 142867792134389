import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import icons from "./icons/icons";

function Icon({ icon, color = "", size = "regular", ...rest }) {
  const AtdIcon = icons[icon];

  if (!AtdIcon) {
    return null;
  }

  return (
    <span
      className={classNames("IconSVG", {
        [`IconSVG--${size}`]: size,
        // [`Icon-color--${color}`]: color,
      })}
      {...rest}
    >
      {typeof icon === "string" && <AtdIcon fill="currentColor" />}
    </span>
  );
  // return (
  //   <Box
  //     flex
  //     aria-hidden="true"
  //     className={`SVGInline SVGInline--cleaned SVG Icon Icon--filter Button-icon Icon-color Icon-color--${color}`}
  //     {...rest}
  //   >
  //     {typeof icon === "string" && <AtdIcon />}
  //   </Box>
  // );
}

Icon.defaultProps = {
  color: "gray600",
};

Icon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

export default Icon;
