import { useState, useRef, useEffect, useCallback } from "react";
import classnames from "classnames";
import { flatMap, map } from "lodash";
import uuid from "react-uuid";

import SelectOption from "./SelectOption";
import SelectFooter from "./SelectFooter";
import Popover from "../Popover/AtdPopover";
import Menu from "../Menu/Menu";

import SearchableInput from "./SearchableInput";
import SelectOptionGroup from "./SelectOptionGroup";

import "./Select.css";

export { SearchableInput };

const getFormattedValue = ({ value, options, multiple, isOptionGroup }) => {
  if (multiple) {
    const filteredOptions = options.filter((option) =>
      value.includes(option.value),
    );
    return filteredOptions.map(({ label }) => label);
  }

  if (isOptionGroup && Array.isArray(options)) {
    const optionGroup = options
      ? options.find((option) => option?.options?.some((el) => el.id === value))
      : {};

    if (optionGroup) {
      const groupOptions = optionGroup?.options?.find((el) => el.id === value);
      if (groupOptions) {
        return { label: groupOptions.name, value: groupOptions.id };
      }
    }
  }

  return options.find((option) => option.value === value);
};

// <Select value="1" placeholder="Enter text here" options={[ { label: 'Display', value: 'dsds' } ]} render={} />

function Select({
  value,
  limit = 0,
  disabled = [],
  placeholder,
  multiple,
  options,
  onChange,
  optionProps,
  optionWrapperProps,
  render,
  renderOption,
  renderFooter,
  className,
  inputStyle,
  inputProps,
  containerOptions,
  hasSearchField = false,
  isOptionGroup = false,
  size = "normal",
  interactive = false,
  withArrow = true,
  isDisabled = false,
  ...rest
}) {
  const tippyBox = useRef();
  const selectRef = useRef(null);
  const inputRef = useRef(null);

  const [instance, setInstance] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const onFilterOptions = useCallback(
    (e) => {
      setFilterValue(e.target.value);
      setIsSearching(true);

      let updatedOptions = options.filter(
        (option) =>
          option?.label?.toLowerCase().search(e.target.value.toLowerCase()) !==
          -1,
      );

      if (isOptionGroup) {
        updatedOptions = options.map((option) => ({
          ...option,
          options: option.options.filter(
            (groupOption) =>
              groupOption?.name
                ?.toLowerCase()
                .search(e.target.value.toLowerCase()) !== -1,
          ),
        }));
      }

      // setIsOpen(true);
      setFilteredOptions(updatedOptions);
    },
    [options, isOptionGroup],
  );

  const handleChange = useCallback(
    (e, option) => {
      e.stopPropagation();

      setIsSearching(false);

      // Check all checkbox options for option group dropdown if check all option checkbox is checked
      if (isOptionGroup && multiple && e.target.value === "all") {
        if (!e.target.checked) {
          onChange([]);
          return;
        }
        const allValues = flatMap(options, ({ options: items }) =>
          map(items, ({ id }) => id),
        );
        onChange(allValues);
        return;
      }

      // Uncheck option on option group dropdown
      if (isOptionGroup && multiple && e.target.value !== "all") {
        if (e.target.checked) {
          onChange([...value, e.target.value]);
          return;
        }
        onChange([...value].filter((val) => val !== e.target.value));
        return;
      }

      // Select option on dropdown and close the dropdown after clicked the item
      if (isOptionGroup && !multiple) {
        if (onChange) {
          onChange(option?.id);
        }
        if (instance) {
          instance.onClose();
        }
        return;
      }

      // Uncheck option from multiple select options dropdown
      if (multiple && e.target.checked) {
        if (Array.isArray(value) && value.includes(option.value)) {
          // uncheck and remove value from selected options
          onChange(value.filter((val) => val !== option.value));
          return;
        }
        // add selected value to the array
        onChange([...value, option.value]);
        return;
      }

      // Remove item from value if uncheck from dropdown
      if (multiple && !e.target.checked) {
        onChange(value.filter((val) => val !== option.value));
        return;
      }

      if (instance?.onClose) {
        instance.onClose();
      }
      onChange(option.value);
    },
    [instance, isOptionGroup, multiple, options, value, onChange],
  );

  useEffect(() => {
    if (!value && inputRef.current?.value) {
      inputRef.current.value = "";
    }
  }, [value]);

  const activeValue = getFormattedValue({
    value,
    options,
    isOptionGroup,
    multiple,
  });

  const defaultValue = !multiple ? activeValue?.label : activeValue.join(", ");

  const activeOptions = !isSearching ? options : filteredOptions;
  const optionItems = limit > 0 ? activeOptions.slice(0, limit) : activeOptions;

  const selectClasses = classnames("Select", className, {
    [`Select--${size}`]: size,
    "Select--disabled": isDisabled,
  });

  return (
    <div className={selectClasses} ref={selectRef} {...containerOptions}>
      <Popover
        offset={[0, 5]}
        placement="bottom-start"
        visible={!isDisabled ? isVisible : false}
        onShow={setIsVisible}
        interactive={interactive || multiple}
        onMount={(popoverInstance) => setInstance(popoverInstance)}
        bottomGap={hasSearchField ? 60 : 30}
        renderTop={() => {
          if (!hasSearchField) {
            return null;
          }
          return (
            <input
              autoComplete="off"
              autoFocus
              className="SearchableList-input"
              onChange={onFilterOptions}
            />
          );
        }}
        scrollContainerOptions={{
          ...(limit > 0 ? { style: { maxHeight: 200 } } : {}),
        }}
        {...rest}
        element={
          <div
            className={classnames("atd-select-menu", {
              "atd-select-menu--isGroup": isOptionGroup,
            })}
          >
            <Menu
              ref={tippyBox}
              style={{
                minWidth: selectRef.current?.offsetWidth || 0,
                padding: isOptionGroup && 0,
              }}
            >
              {optionItems.length === 0 && (
                <Menu.Item itemProps={{ padding: { left: 20 } }}>
                  No Items Found
                </Menu.Item>
              )}
              {isOptionGroup &&
                optionItems.map((option) => {
                  const isSelected = !multiple
                    ? option.value === value
                    : value.includes(option.value);

                  return (
                    <SelectOptionGroup
                      key={uuid()}
                      limit={limit}
                      value={value}
                      option={option}
                      isMultiple={multiple}
                      isSelected={isSelected}
                      disabled={disabled}
                      onChange={handleChange}
                      renderOption={renderOption}
                    />
                  );
                })}
              {!isOptionGroup &&
                optionItems.map((option, index) => (
                  <SelectOption
                    key={uuid()}
                    index={index}
                    multiple={multiple}
                    isSelected={
                      !multiple
                        ? option.value === value
                        : value.includes(option.value)
                    }
                    onChange={(e) => handleChange(e, option)}
                    {...optionProps}
                  >
                    {renderOption ? renderOption(option) : option.label}
                  </SelectOption>
                ))}
            </Menu>
            {renderFooter &&
              renderFooter({
                value,
                instance,
                formattedValue: defaultValue,
              })}
          </div>
        }
      >
        <div className="TypeSearchBox--wrapper">
          <SearchableInput
            inputRef={inputRef}
            size={size}
            value={value}
            isOptionGroup={isOptionGroup}
            defaultValue={defaultValue}
            placeholder={placeholder}
            multiple={multiple}
            options={options}
            instance={instance}
            style={inputStyle}
            hasSearchField={hasSearchField}
            isSearching={isSearching}
            filterValue={filterValue}
            render={render}
            onChange={onFilterOptions}
            withArrow={withArrow}
            onClick={() => setIsVisible((prevIsVisible) => !prevIsVisible)}
            {...inputProps}
            onBlur={() => {
              inputProps?.onBlur?.();
            }}
          />
        </div>
      </Popover>
    </div>
  );
}

Select.SearchableInput = SearchableInput;
Select.Footer = SelectFooter;

export default Select;
