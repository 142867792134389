import React, { useCallback, useEffect, useState } from "react";
import { compose } from "@reduxjs/toolkit";
import { Filters } from "@atd/components";
import { withDataTablePage, withSelector } from "@atd/hoc";
import {
  fetchCustomers,
  resetCustomers,
  resetClientsData,
} from "@atd/features";
import { useDispatch } from "react-redux";
import FeedbackList from "./FeedbackList";
import { useLocalStorage } from "@atd/hooks";
import { getCreatedDate } from "@atd/utils";
const tabList = [
  { id: "feedback", value: "Feedback" },
  { id: "meetings", value: "Meetings" },
  { id: "inactive", value: "Inactive" },
];
function Feedback({
  page,
  loaderRef,
  hasMore,
  hasError,
  fetching,
  setResetData,
  customers,
}) {
  const dispatch = useDispatch();
  const onResetData = useCallback(() => {
    setResetData(true);
    dispatch(resetCustomers());
  }, [dispatch, setResetData]);
  const params = {
    tab: "feedback",
    page: page,
  };
  const [customersFilter, setCustomersFilter] = useLocalStorage(
    "customers-filters",
    {},
  );
  useEffect(() => {
    setCustomersFilter(customersFilter);
    return () => {};
  }, [customersFilter, setCustomersFilter]);
  const customerParams = Object.keys(customersFilter).reduce((acc, val) => {
    const value =
      val === "Created date"
        ? getCreatedDate(val, customersFilter)
        : customersFilter[val];
    return { ...acc, ...value };
  }, {});
  const [queryParams, setQueryParams] = useState({
    ...params,
    ...customerParams,
  });
  useEffect(() => {
    const realPage = page + 1;
    dispatch(fetchCustomers({ ...queryParams, page: realPage }));
  }, [dispatch, page, queryParams]);
  const onClickTabItem = (index) => {
    // setResetData(true);
    onResetData();
    // dispatch(resetCustomers());
    setQueryParams({
      ...queryParams,
      tab: tabList[index].id,
    });
  };
  return (
    <FeedbackList
      page={page}
      hasMore={hasMore}
      hasError={hasError}
      fetching={fetching}
      queryParams={queryParams}
      data={customers}
      tabs={tabList}
      // hasPermission={![2, 6, 7].includes(role)}
      loaderRef={loaderRef}
      onResetData={onResetData}
      onClickTabItem={onClickTabItem}
    />
  );
}

export default compose(
  withSelector((state) => state.client),
  withDataTablePage(({ user }) => {
    const { role } = user || {};
    return {
      title: "Customer",
      action: ![2, 6, 7].includes(role) && (
        <Filters onDone={[fetchCustomers]} clear={resetClientsData} />
      ),
    };
  }, "client"),
)(Feedback);
