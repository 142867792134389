import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchTeams } from "@atd/features";
import { Box } from "@atd/components";
import { useGetActivityFiltersQuery } from "@atd/features/activity";

import ActivityFilters from "./ActivityFilters";
import ActivityGroup from "./ActivityGroup/ActivityGroup";
import Border from "./Border";

function Activities({
  params,
  onChangeFilter,
  clear,
  page,
  loaderRef,
  onResetData,
  setIsResetData,
}) {
  const { data } = useGetActivityFiltersQuery();

  const { teams } = useSelector((state) => state.team);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTeams());
    return () => {};
  }, [dispatch]);
  return (
    <Box>
      <ActivityFilters
        params={params}
        filterOptions={data}
        onChangeFilter={onChangeFilter}
        clear={clear}
        users={teams}
      />
      <Border border={{ position: "bottom" }} margin={{ mb: "0px" }} />
      <ActivityGroup
        page={page}
        loaderRef={loaderRef}
        onResetData={onResetData}
        setIsResetData={setIsResetData}
        params={params}
        users={teams}
      />
    </Box>
  );
}

export default Activities;
