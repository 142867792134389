/* eslint-disable import/no-anonymous-default-export */
import * as icons from "./index";

export default {
  "arrow-top-right": icons.ArrowExport,
  "arrow-return-right": icons.ArrowReturn,
  "arrow-up": icons.ArrowUp,
  "arrow-down": icons.ArrowDown,
  brand: icons.Brand,
  bell: icons.Bell,
  calendar: icons.Calendar,
  cancel: icons.Cancel,
  check: icons.Check,
  "circle-check": icons.CircleCheck,
  client: icons.Client,
  clock: icons.Clock,
  "clock-alt": icons.ClockAlt,
  customers: icons.Customers,
  "double-arrow": icons.DoubleArrow,
  filter: icons.Filter,
  feedback: icons.Feedback,
  help: icons.Help,
  lock: icons.Lock,
  "left-arrow": icons.LeftArrow,
  "right-arrow": icons.RightArrow,
  more: icons.More,
  pencil: icons.Pencil,
  plus: icons.Plus,
  reports: icons.Reports,
  requests: icons.Requests,
  search: icons.Search,
  spinner: icons.Spinner,
  settings: icons.Settings,
  teams: icons.Teams,
  time: icons.Time,
  trash: icons.Trash,
  review: icons.Review,
  warning: icons.Warning,
  payments: icons.Payments,
  playColored: icons.PlayColored,
  pauseColored: icons.PauseColored,
  completeColored: icons.CompleteColored,
};
