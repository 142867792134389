import * as React from "react";

function SvgCheck(props) {
  return (
    <svg
      aria-hidden="true"
      className="Check_svg__SVGInline-svg Check_svg__SVGInline--cleaned-svg Check_svg__SVG-svg Check_svg__Icon-svg Check_svg__Icon--check-svg Check_svg__Icon-color-svg Check_svg__Icon-color--blue500-svg"
      height={12}
      width={12}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.297 13.213.293 8.255c-.39-.394-.39-1.033 0-1.426s1.024-.394 1.414 0l4.294 4.224 8.288-8.258c.39-.393 1.024-.393 1.414 0s.39 1.033 0 1.426L6.7 13.208a.994.994 0 0 1-1.402.005z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgCheck;
