import React from "react";

function ExternalLink({ to, children, className, ...rest }) {
  return (
    <a
      href={to}
      target="_blank"
      rel="noreferrer"
      className={className}
      {...rest}
    >
      {children}
    </a>
  );
}

export default ExternalLink;
