import * as React from "react";

function SvgReview(props) {
  return (
    <svg
      width={48}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.656 12.409a.7.7 0 0 0-1.272 0L20.52 18.62a.7.7 0 0 1-.552.4l-6.796.808a.7.7 0 0 0-.392 1.209l5.024 4.643a.7.7 0 0 1 .212.652l-1.336 6.709a.7.7 0 0 0 1.028.748l5.972-3.34a.7.7 0 0 1 .68 0l5.972 3.34a.7.7 0 0 0 1.028-.748l-1.332-6.708a.7.7 0 0 1 .212-.652l5.02-4.645a.7.7 0 0 0-.392-1.208l-6.792-.808a.7.7 0 0 1-.556-.4l-2.864-6.211ZM48 24a23.94 23.94 0 0 1-8 17.889V62a2 2 0 0 1-2.992 1.736L24 56.306l-13.008 7.431A1.999 1.999 0 0 1 8 62.002V41.889A24 24 0 1 1 48 24ZM24 48a23.89 23.89 0 0 1-12-3.212v13.769l11.008-6.293a2 2 0 0 1 1.984 0L36 58.56V44.792a23.887 23.887 0 0 1-12 3.209Zm0-4a20 20 0 1 0 0-40 20 20 0 0 0 0 40Z"
        fill="#407AE0"
      />
    </svg>
  );
}

export default SvgReview;
