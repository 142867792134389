import React from "react";

import Box from "../Box/Box";

function FullscreenContent({ background, children }) {
  return (
    <Box
      background={background}
      className="FullscreenContent FullscreenContent--scroll flx11"
    >
      <Box flex={{ justifyContent: "center" }} className="ccss16">
        {children}
      </Box>
    </Box>
  );
}

FullscreenContent.defaultProps = {
  background: "white",
};

export default FullscreenContent;
