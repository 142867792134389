import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { fetchUser, removeUser } from "@atd/features";
import { removeCredentialCookies } from "@atd/utils";
import { Notification } from "@atd/components";

import SearchInput from "./SearchInput";
import HelpButton from "./HelpButton";
import ProfileButton from "./ProfileButton";
import FeedbackButton from "./FeedbackButton";
// import CurfewButton from "./CurfewButton";

function Header({ onResetData }) {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (
        window.intercomSettings?.name === "name" ||
        window.intercomSettings?.email === "email"
      ) {
        await dispatch(removeUser());
        removeCredentialCookies();
        navigate("/login");
      }
    })();
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  return (
    <div className="Topbar-wrap">
      <div className="Topbar">
        <span className="TopNav_mobileMenuOpener">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.5em"
            height="1.5em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 20 20"
          >
            <path
              fill="#fff"
              d="M3 15h14v-2H3v2zM3 5v2h14V5H3zm0 6h14V9H3v2z"
            />
          </svg>
        </span>
        <SearchInput onResetData={onResetData} />
        <div className="TopNav-buttons">
          {/* <CurfewButton /> */}
          <FeedbackButton />
          <Notification />
          <HelpButton />
          <ProfileButton />
        </div>
      </div>
    </div>
  );
}

export default Header;
