import { cloneElement } from "react";
import uuid from "react-uuid";

import {
  Table,
  Dropdown,
  ContentState,
  EmptyState,
  MoreButton,
  AppHeader,
  Typography,
} from "@atd/components";

function DataTable({
  title,
  action,
  columns,
  data,
  actions = [],
  isLoading,
  error,
  onEdit,
  onDuplicate,
  onDelete,
  onChangeAction,
}) {
  const handleChangeAction = async (actionType, row) => {
    const { id } = row;
    switch (actionType) {
      case "delete":
        if (window.confirm("Are you sure you want to delete this item?")) {
          onDelete?.(id);
        }
        break;
      case "duplicate":
        onDuplicate?.(id);
        break;
      case "edit":
        onEdit?.(id);
        break;
      default:
        if (onChangeAction) {
          onChangeAction(actionType, row);
        }
        break;
    }
  };

  const rows = data.map((dataRow, index) => ({
    index,
    id: dataRow.id,
    value: dataRow,
    cells: columns.map((col) => ({
      id: dataRow[col.accessor].id,
      column: col,
      row: dataRow,
      render: (type) => {
        if (type === "Cell") {
          const Cell = col?.Cell;

          if (Cell) {
            return cloneElement(<Cell />, { value: dataRow[col.accessor] });
          }

          return <div className="cont1 mt-3">{dataRow[col.accessor]}</div>;
        }

        return null;
      },
    })),
  }));

  return (
    <>
      <AppHeader
        title={<Typography>{title}</Typography>}
        spread="settingsContent"
      >
        {action}
      </AppHeader>
      {error ? (
        <EmptyState
          title="Something went wrong!"
          description={<span>{error}</span>}
        />
      ) : (
        <Table
          className={`table mb-0 Margin-top--32 wt-100 ${
            !isLoading && "table-bordered"
          }`}
        >
          <Table.Head>
            <Table.Row>
              {columns.map((col) => (
                <Table.Cell
                  key={uuid()}
                  component="th"
                  scope="col"
                  className={`border-0 tb-title pl5${
                    col?.className ? ` ${col?.className}` : ""
                  }`}
                >
                  {col.Header}
                </Table.Cell>
              ))}
              <th aria-hidden="true" scope="col" className="border-0 wt-4" />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {isLoading && (
              <Table.Row>
                <Table.Cell
                  component="td"
                  colSpan={columns?.length}
                  style={{ border: 0, textAlign: "center" }}
                >
                  <ContentState />
                </Table.Cell>
              </Table.Row>
            )}
            {!isLoading && rows?.length === 0 && (
              <Table.Row>
                <Table.Cell
                  component="td"
                  colSpan={columns?.length}
                  style={{ border: 0 }}
                >
                  <EmptyState
                    title="No results found"
                    description={
                      <span>
                        There aren&rsquo;t any results for that query.
                      </span>
                    }
                  />
                </Table.Cell>
              </Table.Row>
            )}
            {!isLoading && rows?.length > 0 && (
              <>
                {rows.map((row) => (
                  <Table.Row key={row.id}>
                    {row.cells.map((cell, idx) => (
                      <Table.Cell
                        key={uuid()}
                        component="td"
                        onClick={() => onEdit?.(cell.id)}
                        style={{ width: idx === 0 ? "55%" : undefined }}
                      >
                        {cell.render("Cell")}
                      </Table.Cell>
                    ))}
                    <Table.Cell component="td" className="dropdown">
                      <Dropdown
                        as={MoreButton}
                        onChange={(value) => handleChangeAction(value, row)}
                      >
                        <Dropdown.Options>
                          <Dropdown.Option value="edit">Edit</Dropdown.Option>
                          {typeof actions !== "function" &&
                            Array.isArray(actions) &&
                            actions.map((actionItem) => (
                              <Dropdown.Option
                                value={actionItem.value}
                                {...actionItem}
                              >
                                {actionItem.name}
                              </Dropdown.Option>
                            ))}
                          {typeof actions === "function" &&
                            actions?.({ value: row.value })?.map(
                              (actionItem) => (
                                <Dropdown.Option
                                  value={actionItem.value}
                                  {...actionItem}
                                >
                                  {actionItem.name}
                                </Dropdown.Option>
                              ),
                            )}
                          <Dropdown.Option value="delete">
                            <span className="red-txt">Delete</span>
                          </Dropdown.Option>
                        </Dropdown.Options>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </>
            )}
          </Table.Body>
        </Table>
      )}
    </>
  );
}

export default DataTable;
