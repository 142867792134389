import { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useDispatch } from "react-redux";

import { AcceptSchema } from "@atd/schema";
import { accept } from "@atd/features";

import { checkCredentialCookies } from "@atd/utils";

import Auth from "./Auth";
import { ErrorMessage } from "./ErrorMessage";

function Accept() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const email = searchParams.get("email");
  const uId = searchParams.get("u_id");

  useEffect(() => {
    if (checkCredentialCookies()) {
      navigate("/requests");
    }
  }, [navigate]);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      if (!uId) {
        alert("Invalid uid");
        return;
      }
      const { status } = await dispatch(
        accept({
          ...values,
          email,
          u_id: uId,
        }),
      ).unwrap();

      if (status === "success") {
        navigate("/login");
      }
    } catch (error) {
      setSubmitting(false);
    } finally {
      resetForm({});
      setSubmitting(false);
    }
  };

  const initialValues = {
    email,
    full_name: "",
    password: "",
  };

  return (
    <Auth title="Setup your account">
      <Formik
        initialValues={initialValues}
        validationSchema={AcceptSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field name="full_name" placeholder="Full Name" />
            <ErrorMessage name="full_name" />
            <Field type="email" name="email" placeholder="Email Address" />
            <ErrorMessage name="email" />
            <Field type="password" name="password" placeholder="Password" />
            <ErrorMessage name="password" />
            <button disabled={isSubmitting} type="submit">
              {isSubmitting ? "Processing..." : "Signup"}
            </button>
            <p>
              <Link to="/login">Back to Sign in</Link>
            </p>
          </Form>
        )}
      </Formik>
    </Auth>
  );
}

export default Accept;
