import uuid from "react-uuid";
import styled from "styled-components";

const RadioContainer = styled.div`
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + label {
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Helvetica Neue, Ubuntu, sans-serif;
    line-height: 20px;
    font-weight: 500;
    font-size: 13px;
    color: #1a1f36;
    text-align: left;
    padding: 6px;
    cursor: pointer;
    vertical-align: middle;
  }
  input[type="radio"] + label span {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0px 4px 0 0;
    vertical-align: middle;
    cursor: pointer;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
  input[type="radio"] + label span {
    background-color: White;
    border: 1px solid #5469d4;
    margin-top: -2px;
  }
  input[type="radio"]:checked + label span {
    background-color: White;
    box-shadow: inset 0 0 0 0.2875em #5469d4;
  }
  input[type="radio"] + label span,
  input[type="radio"]:checked + label span {
    -webkit-transition: box-shadow 0.5s;
    -o-transition: box-shadow 0.5s;
    -moz-transition: box-shadow 0.5s;
    transition: box-shadow 0.5s;
  }
`;

function Radio({ id, label, ...props }) {
  const inputId = id || uuid();

  return (
    <RadioContainer>
      <input type="radio" id={inputId} {...props} />
      <label htmlFor={inputId}>
        <span />
        {label}
      </label>
    </RadioContainer>
  );
}

export default Radio;
