import { useState, useContext, useEffect } from "react";
import classnames from "classnames";

import CollapsibleContext from "./CollapsibleContext";
import CollapsibleHeader from "./CollapsibleHeader";

function CollapsibleItem({
  title,
  isOpen = false,
  collapsible = true,
  headerElement: headerEl,
  children,
  className,
  headerClassName,
  contentClassName,
}) {
  const { overrideTitleClassName, headerElement } =
    useContext(CollapsibleContext);

  const [isActive, setIsActive] = useState(isOpen);

  useEffect(() => {
    setIsActive(isOpen);
  }, [isOpen]);

  const classes = classnames(className, {
    open: isActive,
    closed: !isActive,
  });
  const sectionClass = classnames("content", contentClassName);
  return (
    <li className={classes}>
      <div
        aria-hidden="true"
        onClick={() => {
          if (collapsible) {
            setIsActive((prev) => !prev);
          }
        }}
      >
        <CollapsibleHeader
          overrideTitleClassName={overrideTitleClassName}
          title={title}
          headerClassName={headerClassName}
          element={headerEl ?? headerElement}
        />
      </div>
      <section className={sectionClass}>{children}</section>
    </li>
  );
}

export default CollapsibleItem;
