import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";

import { fetchBrands } from "@atd/features";
import { EmptyState, PageContent, ContentState } from "@atd/components";

import BrandCreate from "../BrandCreate";
import BrandsListItem from "./BrandsListItem";

const tabList = [
  { id: "all", value: "All" },
  { id: "archive", value: "Archive" },
];

const BrandTabs = styled(Tabs)`
  margin-top: -53px;
  position: relative;
  z-index: 1;
  height: 100%;

  .tabs {
    border: 0;
  }
`;

function BrandsListItems({ brands }) {
  return (
    <table className="table table-bordered mb-0">
      <thead>
        <tr>
          <th scope="col" className="border-0 tb-title pl5">
            BRAND NAME
          </th>
          <th
            scope="col"
            className="border-0 tb-title pl5"
            style={{ width: "30%" }}
          >
            LAST UPDATED
          </th>
          <th
            aria-hidden="true"
            scope="col"
            className="border-0"
            style={{ width: "4%" }}
          />
        </tr>
      </thead>
      <tbody>
        {brands.length > 0 &&
          brands.map((brand, index) => (
            <BrandsListItem index={index} key={brand.id} brand={brand} />
          ))}
      </tbody>
    </table>
  );
}

function BrandsList() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [queryParams, setQueryParams] = useState({
    status: "all",
  });

  const { brands, loading } = useSelector((state) => state.brand);
  const dispatch = useDispatch();

  useEffect(() => {
    const { status } = queryParams;
    dispatch(
      fetchBrands({
        archived: status === "archive",
      }),
    );
  }, [dispatch, queryParams]);

  const onClickTabItem = (index) => {
    setActiveTabIndex(index);
    setQueryParams({
      ...queryParams,
      status: tabList[index].id,
    });
  };

  return (
    <BrandTabs
      selectedTabClassName="active"
      selectedIndex={activeTabIndex}
      onSelect={onClickTabItem}
    >
      <TabList className="tabs Padding-all--0 Margin-bottom--0 Requests--tabs">
        {tabList.map((item) => (
          <Tab key={item.id} className="tab-item capitalize">
            {item.value}
          </Tab>
        ))}
      </TabList>

      <PageContent>
        <PageContent.Main>
          {loading && <ContentState text="Loading" />}
          {!loading && brands?.length === 0 && (
            <EmptyState
              icon="brand"
              title="No results found"
              description={
                <span>There aren&rsquo;t any results for that query.</span>
              }
            />
          )}
          {!loading && brands?.length > 0 && (
            <>
              {tabList.map((item) => (
                <TabPanel key={item.id}>
                  <BrandsListItems brands={brands} />
                </TabPanel>
              ))}
            </>
          )}
        </PageContent.Main>
        <PageContent.Sidebar>
          <div className="center pd-30">
            <BrandCreate />
          </div>
        </PageContent.Sidebar>
      </PageContent>
    </BrandTabs>
  );
}

export default BrandsList;
