import { useContext } from "react";

import PlanContext from "./PlanContext";

function Total({ amount }) {
  const { offerType, offerValue } = useContext(PlanContext);

  const totalAmount =
    offerType === "FIXED"
      ? amount - +offerValue
      : (amount * (100 - +offerValue)) / 100;

  return (
    <tr className="Box-divider--light-vertical-1">
      <td className="title2" style={{ fontSize: 12 }}>
        Total
      </td>
      <td />
      <td>
        <div className="title2 right" style={{ fontSize: 12 }}>
          $ {offerValue ? totalAmount.toFixed(2) : amount.toFixed(2)}
        </div>
      </td>
      <td style={{ width: "2%" }} />
    </tr>
  );
}

export default Total;
