import * as React from "react";

const SvgArrowReturn = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      fill="#666EE8"
      fillRule="evenodd"
      d="M3.8 9.646a4.009 4.009 0 0 1-3.8-4C0 4.024.966 2.626 2.354 2L4 3.646a2 2 0 1 0 0 4h7.52L8.293 4.353l1.414-1.414 5.707 5.707-5.707 5.707-1.414-1.414 3.227-3.293H3.8z"
    />
  </svg>
);

export default SvgArrowReturn;
