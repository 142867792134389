import uuid from "react-uuid";
import { useDropzone } from "react-dropzone";
import moment from "moment";

import { formatBytes, fileName } from "@atd/utils";
import { useAttachment } from "@atd/hooks";
import FileCardIcon from "@atd/components/FileCard/FileCardIcon";

import {
  Close,
  Card,
  CardWrapper,
  CardTitle,
  SmallText,
  FileSize,
} from "./BrandDetails.style";
import BrandInfoAdder from "./BrandInfoAdder";

function MediaFile({
  id,
  name,
  label,
  value,
  accept,
  isEdit,
  setFieldValue,
  addLabel = "Upload a file",
}) {
  const { onSetAttachments, isUploading } = useAttachment(value, {
    brand_id: id,
    last_update: "yes",
  });

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        return;
      }
      const { results } = await onSetAttachments(acceptedFiles);
      // on drop we add to the existing files
      setFieldValue(name, value.concat(results));
    },
  });

  const onRemoveMedia = (index) => (event) => {
    event.stopPropagation();

    const updatedAttachments = [...value];
    if (index > -1) {
      updatedAttachments.splice(index, 1);
    }
    setFieldValue(name, updatedAttachments);
  };

  if (!isEdit && (value?.length === 0 || !value)) {
    return null;
  }

  return (
    <div>
      <div className="title2 mgt2">{label}</div>
      <CardWrapper {...getRootProps()}>
        {value?.map((file, index) => (
          <Card
            key={uuid()}
            contentAlign="center"
            onClick={() => window.open(file.url)}
          >
            {isEdit && (
              <Close icon="cancel" top={10} onClick={onRemoveMedia(index)} />
            )}
            <CardTitle>
              <FileCardIcon icon={file.format} width={30} />
            </CardTitle>
            <CardTitle>
              <span title={file?.full_name}>
                {fileName(file?.name, file?.format)}
              </span>
            </CardTitle>
            <FileSize>({formatBytes(file?.size)})</FileSize>
            <SmallText>
              LAST UPDATE: {moment(file?.last_update_in_utc).format("MMM DD")}
            </SmallText>
          </Card>
        ))}
        {isEdit && (
          <BrandInfoAdder
            title={addLabel}
            onClick={open}
            inputProps={{ ...getInputProps() }}
            isLoading={isUploading}
          />
        )}
      </CardWrapper>
    </div>
  );
}

export default MediaFile;
