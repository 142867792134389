import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import moment from "moment";

import Box from "../Box/Box";
import Text from "../Text/Text";
// eslint-disable-next-line import/no-named-as-default
import Tooltip from "../Tooltip/Tooltip";

import "./DateInput.css";

const Pressable = styled(Box)`
  position: relative;
  min-height: 24px;
  max-width: 100%;

  & > div {
    border-radius: 4px;
  }
`;

const PressablePart = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  & > div {
    border-radius: 4px;
  }

  ${(props) =>
    props.keylines &&
    css`
      z-index: 2;
    `}
  ${(props) =>
    props.shadows &&
    css`
      z-index: 1;
    `}
  ${(props) =>
    props.selectedKeyline &&
    css`
      z-index: 3;
      box-shadow: 0 0 0 2px var(--primary-color);
    `}
  ${(props) =>
    props.focusShadow &&
    css`
      box-shadow: 0 0 0 0 rgb(0 0 0 / 0%);
      transition: opacity 0.25s, box-shadow 0.25s;
    `}
`;

const PressableChildren = styled.div`
  position: relative;
  z-index: 4;
  width: 100%;
`;

const PressableCore = styled(Box)`
  background-color: rgb(255, 255, 255);

  ${(props) =>
    props.shadows &&
    !props.hasError &&
    css`
      box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    `}
  ${(props) =>
    props.hasError &&
    css`
      box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(223 27 65) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    `}
  ${(props) =>
    props.width === "auto" &&
    css`
      max-width: 100%;
    `}
  ${(props) =>
    props.radius === "all"
      ? css`
          border-radius: 4px;
        `
      : props.radius &&
        css`
          border-radius: ${props.radius}px;
        `}
    ${(props) =>
      props.cursor &&
      css`
        cursor: ${props.cursor};
      `}
    ${(props) =>
      props.height === "small" &&
      css`
        max-width: 100%;
      `}
  }
`;

const PressableCorBase = styled(Box)`
  position: relative;
  z-index: 1;
  display: inherit;
  align-items: inherit;
  width: inherit;
  height: inherit;
  border-radius: inherit;
`;

const PressableContext = styled.div`
  position: relative;
  z-index: 1;
  flex: 0 1 auto;
  margin: 0;
  text-decoration: none;
  vertical-align: middle;
  word-break: normal;
  background: transparent;
  border: 0;
  outline: 0;
  transition: color 0.24s;
  appearance: none;
  user-select: auto;

  ${(props) =>
    props.vertical === 0 &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `}
  ${(props) =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${(props) =>
    props.radius === "all"
      ? css`
          border-radius: 4px;
        `
      : props.radius &&
        css`
          border-radius: ${props.radius}px;
        `}
  ${(props) =>
    props.height &&
    css`
      min-height: inherit;
    `}
  ${(props) =>
    props.height === "small" &&
    css`
      min-height: 24px;
    `}
  ${(props) =>
    props.width &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.display &&
    css`
      display: ${props.display};
    `}
  ${(props) =>
    props.fontLineHeight &&
    css`
      line-height: ${props.fontLineHeight}px;
    `}
  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize}px;
    `}
  ${(props) =>
    props.fontWeight &&
    css`
      font-weight: ${props.fontWeight};
    `}
`;

const PressableCoreOverlay = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  border-radius: inherit;
  opacity: 0;
  transition: opacity 0.24s;
  pointer-events: none;

  ${(props) =>
    props.extendBy1 &&
    css`
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
    `}
`;

function DatePickerPressableField() {
  return (
    <PressableCore
      width="auto"
      radius="all"
      cursor="text"
      height="small"
      flex={{ display: "inline-flex" }}
      className="PressableField InputInlay bsd1"
    >
      <PressableCorBase>
        <PressableContext
          as={Box}
          display="inline-flex"
          fontLineHeight={16}
          fontSize={13}
          fontWeight={400}
          height="small"
          radius="all"
          width="auto"
          cursor="text"
          padding={{ horizontal: 0 }}
          flex={{ alignItems: "center" }}
          className="colr2"
        >
          <Text
            color="default"
            size={13}
            typeface="base"
            wrap="wrap"
            display="block"
          >
            <Text color="gray500" typeface="base">
              /
            </Text>
          </Text>
        </PressableContext>
      </PressableCorBase>
      <PressableCoreOverlay extendBy1 background="white" />
    </PressableCore>
  );
}

function DatePickerPressable(props) {
  const { date, onChange } = props;

  const [selected, setSelected] = useState(false);
  const [currentDate, setCurrentDate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [showMonthTooltip, setShowMonthTooltip] = useState(false);
  const [showDayTooltip, setShowDayTooltip] = useState(false);
  const [showYearTooltip, setShowYearTooltip] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (!date) {
      setCurrentDate({
        day: "",
        month: "",
        year: "",
      });
      return;
    }

    const currentDateFormat = moment(date).format("MM-DD-YYYY");
    const dateString = moment(date).isValid() && currentDateFormat.split("-");
    if (Array.isArray(dateString)) {
      setCurrentDate({
        month: dateString[0],
        day: dateString[1],
        year: dateString[2],
      });
    }
  }, [date]);

  const onFocus = (e) => {
    if (e.target.value) {
      e.target.select();
    }
    setSelected(true);
  };

  const onChangeDate = (e, type) => {
    setCurrentDate((prevDate) => {
      const newValue = { ...prevDate, [type]: e.target.value };
      return newValue;
    });
  };

  const inputProps = {
    display: "inline-flex",
    fontLineHeight: 16,
    fontSize: 13,
    fontWeight: 400,
    height: "small",
    radius: "all",
    width: "auto",
    cursor: "text",
  };

  return (
    <Pressable flex={{ display: "inline-flex" }}>
      {selected && (isValid || !isTouched) && (
        <PressablePart keylines>
          <PressablePart selectedKeyline />
        </PressablePart>
      )}
      <PressablePart shadows>
        <PressablePart focusShadow />
      </PressablePart>
      <PressableChildren>
        <PressableCore
          shadows
          width="auto"
          radius="all"
          cursor="text"
          height="small"
          flex={{ display: "inline-flex" }}
          className="PressableField DateInput-root DateInput-root--size--small bsd2"
          hasError={!isValid && isTouched}
        >
          <PressableCorBase>
            <Box flex padding={{ horizontal: 2 }}>
              <Box flex>
                <Tooltip
                  visible={showMonthTooltip}
                  onClickOutside={() => setShowMonthTooltip(false)}
                  offset={[0, 5]}
                  content="Month (MM)"
                  arrow={false}
                  placement="bottom"
                >
                  <PressableContext
                    as="input"
                    maxLength={2}
                    pattern="[0-9]*"
                    placeholder="MM"
                    type="text"
                    aria-describedby="DateInput-input-302-description"
                    aria-label="Month"
                    className="Input Input--nowrap Padding-vertical--0  DateInput-input DateInput-input--month Padding-vertical--0 colr2"
                    onFocus={(e) => {
                      setShowMonthTooltip(true);
                      onFocus(e);
                    }}
                    onBlur={(e) => {
                      setSelected(false);
                      setShowMonthTooltip(false);
                      if (!e.target.value) {
                        setIsValid(false);
                        return;
                      }
                      setIsValid(true);

                      onChange(
                        moment(
                          `${e.target.value}-${currentDate.day}-${currentDate.year}`,
                        ),
                      );
                    }}
                    value={currentDate.month}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setIsValid(false);
                      } else {
                        setIsValid(true);
                      }
                      onChangeDate(e, "month");
                    }}
                    onKeyDown={() => {
                      setIsTouched(true);
                    }}
                    {...inputProps}
                  />
                </Tooltip>
              </Box>
              <DatePickerPressableField />
              <Box flex>
                <Tooltip
                  visible={showDayTooltip}
                  onClickOutside={() => setShowDayTooltip(false)}
                  offset={[0, 5]}
                  content="Day (DD)"
                  arrow={false}
                  placement="bottom"
                >
                  <PressableContext
                    as="input"
                    maxLength={2}
                    pattern="[0-9]*"
                    placeholder="DD"
                    type="text"
                    aria-describedby="DateInput-input-302-description"
                    aria-label="Day"
                    className="Input Input--nowrap Padding-vertical--0  DateInput-input DateInput-input--day Padding-vertical--0 colr2"
                    onFocus={(e) => {
                      setShowDayTooltip(true);
                      onFocus(e);
                    }}
                    onBlur={(e) => {
                      setSelected(false);
                      setShowDayTooltip(false);
                      if (!e.target.value) {
                        setIsValid(false);
                        return;
                      }
                      setIsValid(true);

                      onChange(
                        moment(
                          `${currentDate.month}-${e.target.value}-${currentDate.year}`,
                        ),
                      );
                    }}
                    value={currentDate.day}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setIsValid(false);
                      } else {
                        setIsValid(true);
                      }
                      onChangeDate(e, "day");
                    }}
                    onKeyDown={() => {
                      setIsTouched(true);
                    }}
                    {...inputProps}
                  />
                </Tooltip>
              </Box>
              <DatePickerPressableField />
              <Box flex>
                <Tooltip
                  visible={showYearTooltip}
                  onClickOutside={() => setShowYearTooltip(false)}
                  offset={[0, 5]}
                  content="Year (YYYY)"
                  arrow={false}
                  placement="bottom"
                >
                  <PressableContext
                    as="input"
                    maxLength={4}
                    pattern="[0-9]*"
                    placeholder="YYYY"
                    type="text"
                    aria-describedby="DateInput-input-304-description"
                    aria-label="Year"
                    className="Input Input--nowrap Padding-vertical--0  DateInput-input DateInput-input--year Padding-vertical--0 colr2"
                    onFocus={(e) => {
                      setShowYearTooltip(true);
                      onFocus(e);
                    }}
                    onBlur={(e) => {
                      setSelected(false);
                      setShowYearTooltip(false);
                      if (!e.target.value) {
                        setIsValid(false);
                        return;
                      }
                      setIsValid(true);

                      onChange(
                        moment(
                          `${currentDate.month}-${currentDate.day}-${e.target.value}`,
                        ),
                      );
                    }}
                    value={currentDate.year}
                    onChange={(e) => {
                      if (!e.target.value) {
                        setIsValid(false);
                      } else {
                        setIsValid(true);
                      }
                      onChangeDate(e, "year");
                    }}
                    onKeyDown={() => {
                      setIsTouched(true);
                    }}
                    {...inputProps}
                  />
                </Tooltip>
              </Box>
            </Box>
          </PressableCorBase>
          <PressableCoreOverlay extendBy1 background="white" />
        </PressableCore>
      </PressableChildren>
    </Pressable>
  );
}

function DatePickerContentHeader({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  return (
    <Box
      background="white"
      divider={{ color: "light", side: "bottom", width: 1 }}
      padding={{ horizontal: 0, vertical: 16 }}
      flex={{ direction: "column" }}
      className="ContentHeader ccss3"
    >
      <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--spaceBetween">
        <div className="ContentHeader-start Box-root Margin-right--16 Flex-flex Flex-alignItems--center">
          <DatePickerPressable date={startDate} onChange={setStartDate} />
        </div>
        <div className="Box-root Flex-flex Flex-direction--column Flex-justifyContent--center flx1">
          <span className="ContentHeader-title Text-color--dark Text-fontSize--20 Text-fontWeight--regular Text-lineHeight--28 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
            <span className="Text-color--dark Text-fontSize--28 Text-fontWeight--bold Text-lineHeight--32 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
              <div
                aria-hidden="true"
                className="SVGInline SVGInline--cleaned SVG Icon Icon--arrowRight db-DateRangeInput-input-arrow Icon-color Icon-color--gray600 Box-root Flex-flex"
              >
                <svg
                  aria-hidden="true"
                  className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--arrowRight-svg db-DateRangeInput-input-arrow-svg Icon-color-svg Icon-color--gray600-svg"
                  height={16}
                  width={16}
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.583 7L7.992 2.409A1 1 0 1 1 9.407.993l6.3 6.3a1 1 0 0 1 0 1.414l-6.3 6.3a1 1 0 0 1-1.415-1.416L12.583 9H1a1 1 0 1 1 0-2z"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
            </span>
          </span>
        </div>
        <div className="ContentHeader-end Box-root Margin-left--16 Flex-flex Flex-alignItems--center">
          <DatePickerPressable date={endDate} onChange={setEndDate} />
        </div>
      </div>
    </Box>
  );
}

export default DatePickerContentHeader;
