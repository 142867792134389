import styled from "styled-components";

const ControlRadio = styled.label`
  display: inline-flex;
  aling-items: center;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 15px;

  & input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  & .control__indicator {
    position: absolute;
    left: 0;
    height: 20px;
    width: 20px;
    background: #e6e6e6;
  }
  & .control__indicator {
    border-radius: 50%;
  }
  &:hover input ~ .control__indicator,
  & input:focus ~ .control__indicator {
    background: #ccc;
  }
  & input:checked ~ .control__indicator {
    background: ${(props) => (props.background ? "#5469d4" : "#0068e5")};

    box-shadow: ${(props) =>
      props.boxshadow ? "inset 0 0 0 0.2875em #5469d4" : "none"};
  }
  &:hover input:not([disabled]):checked ~ .control__indicator,
  & input:checked:focus ~ .control__indicator {
    background: ${(props) => (props.background ? "#5469d4" : "#0068e5")};
  }
  & input:disabled ~ .control__indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }
  & .control__indicator:after {
    content: "";
    position: absolute;
    display: none;
  }
  & input:checked ~ .control__indicator:after {
    display: block;
  }
  .control--checkbox .control__indicator:after {
    left: 8px;
    top: 4px;
    width: 3px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .control--checkbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b;
  }
  & .control__indicator:after {
    left: 7px;
    top: 7px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: #fff;
  }
  & input:disabled ~ .control__indicator:after {
    background: #7b7b7b;
  }
`;

function RadioField({ label, background, boxshadow, ...rest }) {
  return (
    <ControlRadio className="control control--radio" background boxshadow>
      {label} <input type="radio" {...rest} />{" "}
      <div className="control__indicator" />
    </ControlRadio>
  );
}

export default RadioField;
