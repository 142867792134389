/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { usePlaidLink } from "react-plaid-link";
import { toast } from "react-toastify";

import { axios } from "@atd/utils";
import { Button } from "@atd/components";
import { CircleCheck } from "@atd/components/Icon/icons";
import { useRegisterAccountMutation } from "@atd/features/user";
import { getErrorMessage, setCredentialCookies } from "@atd/utils";
import { useSelector } from "react-redux";

const Title = styled.h1`
  color: #3c4257;
  margin-bottom: 0;
`;

const Secure = styled.span`
  color: #428bff !important;
  font-weight: 500 !important;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 1.5rem;
`;

const AddBankAccount = styled.button`
  background: none;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.isActive ? "#39ca86" : "#394149")}};
  border: 0;
  padding: 0;
  margin-top: 20px;

  margin-bottom: ${(props) => (props.isActive ? "10px" : "")}};

  svg  {
    margin-right: 10px;
  }

  svg path {
    stroke: #39ca86
  }
`;

const PayButton = styled(Button)`
  width: 100%;
  background-color: #333 !important;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  padding: 0.65rem 0.75rem;
  cursor: pointer;
  margin-bottom: 1.5rem;

  &:hover {
    background-color: #000 !important;
  }
`;

const PlaidPayment = (props) => {
  const { uId, planId, isPlanAvailable, allPlans, promoData, totalAmount } =
    props;

  const { code } = promoData || {};
  const { planDetails, currentPlan, quantity } = useSelector(
    (store) => store.auth,
  );

  const [registerAccount] = useRegisterAccountMutation();

  const [linkToken, setLinkToken] = useState(null);
  const [isSuccessPayment, setIsSuccessPayment] = useState(false);
  const [billingToken, setBillingToken] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSuccess = useCallback(async (public_token, { account_id }) => {
    // send public_token to server
    setIsSuccessPayment(false);
    const { data } = await axios.post("/token_exchange", {
      public_token,
      account_id,
    });

    const { billing_token } = data || {};

    if (billing_token) {
      setIsSuccessPayment(true);
      setBillingToken(billing_token);
      // toast.success("Payment Successful");
    } else {
      toast.error(data.message);
    }

    window.history.pushState("", "", "/");
  }, []);

  let isOauth = false;
  const config = {
    token: linkToken,
    // receivedRedirectUri: window.location.href,
    onSuccess,
  };

  if (window.location.href.includes("?oauth_state_id=")) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready) {
      open();
    }
  }, [ready, open, isOauth]);

  useEffect(() => {
    const generateToken = async () => {
      const { data } = await axios(`/linktoken/${uId}`, {
        method: "POST",
      });
      if (data.link_token) {
        setLinkToken(data.link_token);
      }
    };

    generateToken();
  }, [uId]);

  const onSubmitPayment = async () => {
    try {
      const payload = {
        temp_user_id: uId,
        full_name: null,
        country: null,
        plan_id: currentPlan || planId || "s_1",
        postal_code: null,
        line1: null,
        city: null,
        stripe_token: billingToken,
        promo_code: code ?? null,
        dpu: 1,
      };

      const response = await registerAccount({
        body: payload,
        params: { payment_method: "ach" },
      }).unwrap();
      const { status, message, results } = response || {};

      if (status === "success") {
        toast.success(message);
        // set this data in your cookies
        setCredentialCookies(results);
        // Redirect user to dashboard
        // resetForm({});
        window.location.replace("/requests");
      } else {
        const errorMessage = getErrorMessage(response);
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Title>ACH</Title>
      {!isSuccessPayment ? (
        <>
          <AddBankAccount onClick={() => open()} disabled={!ready}>
            + {!ready ? "Loading..." : "Add Bank Account"}
          </AddBankAccount>
          <Secure>
            <svg
              width={12}
              height={19}
              viewBox="0 0 16 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.85714 6.85714V5.14286C2.85714 2.3025 5.16071 0 8 0C10.8393 0 13.1429 2.3025 13.1429 5.14286V6.85714H13.7143C14.975 6.85714 16 7.88214 16 9.14286V16C16 17.2607 14.975 18.2857 13.7143 18.2857H2.28571C1.02321 18.2857 0 17.2607 0 16V9.14286C0 7.88214 1.02321 6.85714 2.28571 6.85714H2.85714ZM5.14286 6.85714H10.8571V5.14286C10.8571 3.565 9.57857 2.28571 8 2.28571C6.42143 2.28571 5.14286 3.565 5.14286 5.14286V6.85714Z"
                fill="#428BFF"
              />
            </svg>
            256-bit SSL Secured - Powered by Stripe and Plaid.
          </Secure>
        </>
      ) : (
        <AddBankAccount isActive={true}>
          <CircleCheck /> Success
        </AddBankAccount>
      )}
      {/* <PaymentForm
        {...props}
        stripeToken={billingToken}
        disabled={!isSuccessPayment}
      /> */}
      <PayButton
        disabled={!isSuccessPayment || isSubmitting}
        type="submit"
        onClick={onSubmitPayment}
      >
        Pay {allPlans?.currency}
        {(!promoData || !isPlanAvailable) &&
          planDetails &&
          (
            Number(planDetails?.[currentPlan]?.total_offer_price) *
            Number(quantity)
          )?.toLocaleString()}
        {promoData && isPlanAvailable && totalAmount.toLocaleString()}
      </PayButton>
    </>
  );
};

export default PlaidPayment;
