import React from "react";

import FilterSelect from "../../InputField/SelectField";
import FilterString from "./String";
import FilterDate from "./Date";

function FilterType(props) {
  const { type, checked, label, name, queryParams, onFilter, ...rest } = props;
  switch (type) {
    case "singular":
      return (
        <FilterSelect
          {...rest}
          label={label}
          name={name}
          value={
            queryParams[label]
              ? queryParams[label][name]
              : queryParams[label][name === "plans" ? "plans" : name]
          }
          onChange={(e) => onFilter(e, label)}
          isVisible={checked}
          width="100%"
        />
      );
    case "string":
      return (
        <FilterString
          name={name}
          label={label}
          value={
            queryParams[label]
              ? queryParams[label][name]
              : queryParams[label][name === "plans" ? "plans" : name]
          }
          onChange={(e) => onFilter(e, label)}
          {...rest}
          isVisible={checked}
          width={230}
        />
      );
    case "location":
      return (
        <FilterString
          {...rest}
          name={name}
          value={
            queryParams[label]
              ? queryParams[label][name]
              : queryParams[label][name === "plans" ? "plans" : name]
          }
          onChange={(e) => onFilter(e, label)}
          isVisible={checked}
          label="Country"
          width={230}
        />
      );
    case "date":
      return (
        <FilterDate
          label={label}
          name={name}
          onFilter={onFilter}
          queryParams={queryParams}
          {...rest}
          isVisible={checked}
        />
      );
    default:
      return null;
  }
}

export default FilterType;
