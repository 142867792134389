import * as Yup from "yup";

const ProfileSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  // new_password: Yup.string()
  //   .required("This field is required")
  //   .min(8, "Password is too short - should be 8 chars minimum."),
  changepassword: Yup.string()
    .when("new_password", {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref("new_password")],
        "Both password need to be the same",
      ),
    })
    // .required("This field is required")
    .min(8, "Password is too short - should be 8 chars minimum."),
  // password: Yup.string()
  //   .required("This field is required")
  //   .min(8, "Password is too short - should be 8 chars minimum."),
});

export default ProfileSchema;
