import React from "react";
import styled from "styled-components";

import {
  PressableCore,
  PressableCoreBase,
  PressableContext,
  PressableCoreOverlay,
} from "../Pressable";

const ToggleBoxItemlabel = styled(PressableContext)`
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
`;

function ToggleBoxItem({ label }) {
  return (
    <div className="ToggleBoxItem ToggleBoxItem--checked ToggleBoxItem--isFirst ToggleBoxItem--isLast Box-root Padding-all--0">
      <PressableCore
        flex={{ display: "inline-flex" }}
        height="medium"
        width="maximized"
        cursor="pointer"
        radius="all"
        className="PressableToggle ToggleBoxItem-pressable"
        style={{
          backgroundColor: "rgb(255, 255, 255)",
          boxShadow:
            "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgb(99, 91, 255) 0px 0px 0px 2px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px",
        }}
      >
        <PressableCoreBase>
          <ToggleBoxItemlabel
            as="label"
            display="inline-flex"
            fontLineHeight={20}
            fontSize={14}
            fontWeight="medium"
            height="medium"
            radius="all"
            width="maximized"
            className="ToggleBoxItem-label PressableContext--cursor--pointer"
            htmlFor="ToggleBoxItem-33"
            padding={{
              horizontal: 8,
              vertical: 4,
            }}
          >
            <input
              className="ToggleBoxItem-source"
              id="ToggleBoxItem-33"
              name="ApplicationTypeToggleBox"
              type="radio"
            />
            <div
              className="ToggleBoxItem-area Box-root Padding-all--16 Flex-flex Flex-alignItems--baseline Flex-direction--row"
              style={{ position: "relative" }}
            >
              <div
                className="Box-root Flex-flex Flex-alignItems--baseline Flex-direction--row Flex-justifyContent--flexStart"
                style={{ lineHeight: 0, flex: "1 1 auto" }}
              >
                <div
                  className="PressableCore PressableCore--cursor--pointer PressableCore--radius--circle PressableCore--width PressableControl Box-root Flex-inlineFlex"
                  style={{
                    backgroundColor: "rgb(99, 91, 255)",
                    height: 14,
                    boxShadow:
                      "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(99, 91, 255) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.08) 0px 2px 5px 0px",
                    minWidth: 14,
                    width: 14,
                    transform: "translateY(2.07px)",
                  }}
                >
                  <div className="PressableCore-base Box-root">
                    <div className="ToggleBoxItem-dot ToggleBoxItem-dot--visible Box-root Box-background--white" />
                  </div>
                  <div className="PressableCore-overlay PressableCore-overlay--extendBy1 Box-root Box-background--white" />
                </div>
                <div
                  className="ToggleBoxItem-copy Box-root Padding-left--12"
                  style={{ pointerEvents: "none" }}
                >
                  <div
                    className="Box-root Flex-flex Flex-direction--column Flex-justifyContent--flexStart Flex-wrap--nowrap"
                    style={{ marginLeft: "-4px", marginTop: "-4px" }}
                  >
                    <div
                      className="Box-root Box-hideIfEmpty Margin-top--4 Margin-left--4"
                      style={{ pointerEvents: "auto" }}
                    >
                      <span
                        className="ToggleBoxItem-label Text-color--inherit Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--block"
                        style={{ marginTop: "-1px" }}
                      >
                        <span>{label}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ToggleBoxItemlabel>
          <PressableCoreOverlay extendBy={2} background="white" />
        </PressableCoreBase>
      </PressableCore>
    </div>
  );
}

export default ToggleBoxItem;
