import { Box, Button } from "@atd/components";
import { isAdmin } from "@atd/utils";

function RequestActions({
  role,
  status,
  inProgress,
  disabledReplyButton,
  disabledNoteButton,
  disabledDeliveryButton,
  showFeedback,
  setShowFeedback,
  isSubscriptionPaused,
  onAddNote,
  onAddReply,
  onAddDelivery,
  onSubmitFeedback,
}) {
  return (
    <div className="mgt4 mgb5">
      <Box
        flex={{
          direction: "row",
          justifyContent: "flexStart",
          wrap: "nowrap",
        }}
        className="mglmgt-8"
      >
        {![4, 7].includes(role) && (
          <Box hideIfEmpty margin={{ top: 8, left: 8 }}>
            <Button
              disabled={disabledReplyButton}
              variant="default"
              label="Reply"
              icon="pencil"
              onClick={onAddReply}
            />
          </Box>
        )}
        {isAdmin() && inProgress === 1 && ![6, 7].includes(role) && (
          <Box hideIfEmpty margin={{ top: 8, left: 8 }}>
            <Button
              disabled={disabledDeliveryButton || isSubscriptionPaused}
              variant="default"
              label="Deliver"
              icon="arrow-top-right"
              onClick={onAddDelivery}
            />
          </Box>
        )}
        {isAdmin() && status !== 7 && (
          <Box hideIfEmpty margin={{ top: 8, left: 8 }}>
            <Button
              variant="default"
              disabled={disabledNoteButton}
              label="Add Notes"
              icon="plus"
              onClick={onAddNote}
            />
          </Box>
        )}
        {status !== 7 && !showFeedback && ![6, 7, 4, 2].includes(role) && (
          <Box hideIfEmpty margin={{ top: 8, left: 8 }}>
            <Button
              variant="default"
              label={role === 1 ? "Mark As Complete" : "Complete & Review"}
              onClick={() => {
                if (role !== 1) {
                  setShowFeedback(true);
                } else {
                  onSubmitFeedback({
                    rating: "5",
                    comments: "Admin has closed",
                  });
                }
              }}
            />
          </Box>
        )}
      </Box>
    </div>
  );
}

export default RequestActions;
