import * as React from "react";

function SvgPlus(props) {
  return (
    <svg
      aria-hidden="true"
      className="Plus_svg__SVGInline-svg Plus_svg__SVGInline--cleaned-svg Plus_svg__SVG-svg Plus_svg__Icon-svg Plus_svg__Icon--add-svg Plus_svg__Button-icon-svg Plus_svg__Icon-color-svg Plus_svg__Icon-color--white-svg"
      height={12}
      width={12}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 7h6a1 1 0 0 1 0 2H9v6a1 1 0 0 1-2 0V9H1a1 1 0 1 1 0-2h6V1a1 1 0 1 1 2 0z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgPlus;
