import styled, { css } from "styled-components";

import Box from "../Box/Box";

const PressableCoreOverlay = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  border-radius: inherit;
  opacity: 0;
  transition: opacity 0.24s;
  pointer-events: none;

  ${(props) =>
    props.extendBy &&
    css`
      top: -${props.extendBy}px;
      right: -${props.extendBy}px;
      bottom: -${props.extendBy}px;
      left: -${props.extendBy}px;
    `}

  ${(props) =>
    props.extendBy1 &&
    css`
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
    `}
`;

export default PressableCoreOverlay;
