import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { compose } from "@reduxjs/toolkit";

import { Box, Button } from "@atd/components";
import { resetClientsData } from "@atd/features";
import { withDataTablePage, withSelector } from "@atd/hoc";
import ClientApi from "@atd/features/client/clientApi";

import ClientsList from "./ClientsList";

function Clients({ page, loaderRef, setResetData }) {
  const params = useParams();

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { role } = user || {};
  const { query } = params || {};

  const onResetData = useCallback(() => {
    setResetData(true);
    dispatch(resetClientsData());
  }, [dispatch, setResetData]);

  useEffect(
    () => () => {
      if (query) {
        onResetData();
      }
    },
    [onResetData, query],
  );

  useEffect(
    () => () => {
      dispatch(resetClientsData());
    },
    [dispatch],
  );

  return (
    <ClientsList
      page={page}
      role={role}
      hasPermission={![2, 6, 7].includes(role)}
      loaderRef={loaderRef}
      onResetData={onResetData}
      setIsResetData={setResetData}
    />
  );
}

export default compose(
  withSelector((state) => state.user),
  withDataTablePage(({ user }) => {
    const { role } = user || {};
    return {
      title: "Customer",
      hasTabs: true,
      action: ![2, 6, 7].includes(role) && (
        <Box
          flex={{
            direction: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10",
          }}
        >
          {/* <Filters
            onDone={[fetchClients, getClientsCount]}
            clear={resetClientsData}
          /> */}
          <Button
            label="Export XLSX"
            variant="default"
            icon="arrow-top-right"
            onClick={() => ClientApi.exportClients()}
          />
        </Box>
      ),
    };
  }, "client"),
)(Clients);
