import React, { useMemo } from "react";
import { Box, Text, Table, ExcerptText } from "@atd/components";
import styled from "styled-components";
import { useParams } from "react-router-dom";
export const TableWrapper = styled(Box)`
  /* &.total-table--head > *:first-child + div {
    width: calc((100% / ${({ items }) => items}) + 3%);
  } */

  &:not(.total-table--head) > *:first-child {
    min-width: calc((100% / ${({ items }) => items}) + 3%);
    justify-content: flex-start;

    & > span > span {
      justify-content: flex-start;
    }
  }

  & > * {
    width: calc(100% / ${({ items }) => items});
    justify-content: center;
  }
`;

const ReportHeader = ({
  headers,
  isKey = false,
  withTable = false,
  isHeader = false,
  headerKeys,
  background = "offset",
  hideProperty = false,
  firstKey,
}) => {
  const items = { ...headerKeys, ...headers };
  const { entity, ...rest } = items;
  const { path } = useParams();
  const isDailyReport = useMemo(() => {
    return path === "dailyreport";
  }, [path]);
  const isObject = Object.keys(rest)
    .map((val) => Array.isArray(rest[val]))
    ?.filter(Boolean).length;
  let mapItems = Object.keys(items);
  mapItems = mapItems.filter((val) => val !== "categories" && val !== "total");
  const hasProperty = mapItems.some(
    (val) => val !== "categories" && val !== "total",
  );
  if (hasProperty && !hideProperty) {
    mapItems.unshift(withTable ? "locations" : "categories");
    mapItems[mapItems.length] = withTable ? "total_tickets" : "total";
  }
  const keys = isObject ? [] : [...new Set(mapItems)];
  return (
    <Box
      flex={{
        direction: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
      background={background}
    >
      {isObject ? (
        <Box className="wt-100 x" style={{ display: "flex" }}>
          <Table.Cell component="td" style={{ padding: 0 }}>
            <Text
              as="span"
              size="14"
              weight="medium"
              color="default"
              lineHeight="20"
              className="Padding-top--14 Padding-bottom--10 Padding-right--10 Padding-left--8"
            >
              {entity}
            </Text>
          </Table.Cell>
        </Box>
      ) : (
        <>
          <TableWrapper items={keys.length} className="total-table wt-100" flex>
            {keys.map((val, index) => (
              <>
                <Box
                  className={`${
                    headers[val]?.length > 10 ? "wt-10" : "wt-15"
                  } Text-wrap--noWrap`}
                  // className="wt-100"
                  style={{
                    display: "flex",
                    padding: 0,
                    whiteSpace: "nowrap",
                  }}
                >
                  <Text
                    as="span"
                    color={`${
                      val.includes("categories") && !isHeader
                        ? "blue"
                        : "gray900"
                    }`}
                    weight={`${isHeader ? "medium" : "regular"}`}
                    className="report-table"
                    wrap="nowrap"
                  >
                    {isKey ? (
                      <ExcerptText
                        text={val.split("_").join(" ")?.toUpperCase()}
                        length={
                          keys.length >= 15 ? 5 : keys.length <= 10 ? 10 : 8
                        }
                      />
                    ) : withTable ? (
                      headers[val]
                    ) : (
                      <ExcerptText
                        text={
                          (headers[val] || "")
                            .toString()
                            ?.split("_")
                            .map(
                              (word) =>
                                (word[0] || "")?.toUpperCase() + word.substr(1),
                            )
                            .join(" ") || 0
                        }
                        length={
                          !index
                            ? isDailyReport
                              ? 30
                              : 10
                            : headers[val]?.length
                        }
                      />
                    )}
                  </Text>
                </Box>
              </>
            ))}
          </TableWrapper>
        </>
      )}
    </Box>
  );
};

export default ReportHeader;
