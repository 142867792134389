import styled, { css } from "styled-components";

import Box from "../Box/Box";

const PressableCore = styled(Box)`
  /* background-color: rgb(255, 255, 255); */

  ${(props) =>
    props.shadows &&
    !props.hasError &&
    css`
      box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    `}
  ${(props) =>
    props.hasError &&
    css`
      box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(223 27 65) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
        rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    `}
  ${(props) =>
    props.width === "auto" &&
    css`
      max-width: 100%;
    `}
  ${(props) =>
    props.width === "maximized" &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.radius === "all"
      ? css`
          border-radius: 4px;
        `
      : props.radius &&
        css`
          border-radius: ${props.radius}px;
        `}
    ${(props) =>
      props.cursor &&
      css`
        cursor: ${props.cursor || "pointer"};
      `}
    ${(props) =>
      props.height === "small" &&
      css`
        min-height: 24px;
      `}
    ${(props) =>
      props.height === "medium" &&
      css`
        min-height: 28px;
      `}
    ${(props) =>
      props.height === "large" &&
      css`
        min-height: 36px;
      `}
  }
`;

export default PressableCore;
