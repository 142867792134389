import { Box, Text } from "@atd/components";
import Border from "@atd/pages/Reports/Border";
import { ReviewItem } from "./ReviewItem";
import moment from "moment";
export const Review = ({ review }) => {
  return (
    <Box
      as="section"
      flex={{
        direction: "column",
      }}
      className="Margin-horizontal--20"
    >
      <Box
        flex={{
          direction: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="Margin-vertical--10 Padding-horizontal-20 "
      >
        <ReviewItem rating={review?.rating} company={review?.company_name} />
        <Text color="default" weight="regular" lineHeight="20">
          {moment(review?.modified).format("MMM DD, YYYY")}
        </Text>
      </Box>
      <Text color="default" weight="regular" lineHeight="20">
        {review?.comments}
      </Text>
      <Border
        margin={{ mt: "10px", mb: "10px" }}
        padding="0 0 10px 0"
        border={{ position: "bottom" }}
      />
    </Box>
  );
};
