import Feedback from "./Feedback/Feedback";
import Team from "./Team/Team";
import Sales from "./Sales/Sales";
import { useLocation } from "react-router-dom";
import AnalyticalReport from "./Team/AnalyticalReport/AnalyticalReport";

function Reports() {
  const { pathname } = useLocation();
  const key = pathname.split("/")[2];
  return (
    <>
      {
        {
          feedback: <Feedback />,
          team: <Team />,
          sales: <Sales />,
          analytics: <AnalyticalReport />,
        }[key]
      }
    </>
  );
}

export default Reports;
