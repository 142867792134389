import { useContext } from "react";

import { Button } from "@atd/components";

import PlanContext from "./PlanContext";

function CheckoutButton() {
  const { isProcessing } = useContext(PlanContext);

  return (
    <div className="center">
      <div className="Box-root Flex-flex mgt2">
        <div className="Box-root Box-hideIfEmpty">
          <Button type="submit" variant="primary" disabled={isProcessing}>
            {isProcessing ? "Processing..." : "Proceed to Checkout"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CheckoutButton;
