import { Select } from "@atd/components";

function GroupBy({ value, options, onChange, placeholder, field }) {
  return (
    <div
      className="Box-root Box-hideIfEmpty Margin-top--8 Margin-left--8 pointer1"
      id="filter-status"
    >
      <div className="Box-root Flex-flex">
        <Select
          value={value}
          size="small"
          placeholder={placeholder}
          className="Box-root Flex-flex wt-auto"
          options={options}
          onChange={(value) => {
            onChange(field, value);
          }}
        />
      </div>
    </div>
  );
}

export default GroupBy;
