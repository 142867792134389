/* eslint-disable react/jsx-no-target-blank */
import { useState } from "react";
import { useFormikContext } from "formik";

import {
  Panel,
  FormControl,
  Button,
  Modal,
  Message,
  Box,
} from "@atd/components";
import { pick } from "lodash";
import UserService from "@atd/features/user/userApi";

import { FeidRowStyle, FormControlStyle } from "./ProfileForm.style";

function ChangePassword({ user }) {
  const { values, errors, setFieldValue, setSubmitting, isSubmitting } =
    useFormikContext();

  const [showConfirmPassModal, setShowConfirmPassModal] = useState(false);
  const [errorConfirmation, setErrorConfirmation] = useState(null);

  const onConfirmPassword = async () => {
    setSubmitting(true);
    setErrorConfirmation(null);

    if (errors.password) {
      setSubmitting(false);
      return;
    }

    try {
      const updatePayload = pick({ ...user, ...values }, [
        "first",
        "last",
        "company",
        "email",
        "phone",
        "password",
        "new_password",
      ]);

      const { status, message } = await UserService.updateUser(updatePayload);

      if (status === "error") {
        throw new Error(message || "Something went wrong!");
      }

      if (status === "success") {
        setFieldValue("password", "");
        setFieldValue("new_password", "");
        setFieldValue("changepassword", "");
        setShowConfirmPassModal(false);
      }
    } catch (error) {
      setErrorConfirmation(error?.message?.replace("Error: ", ""));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Panel>
      <Modal
        title="Change Password"
        isOpen={showConfirmPassModal}
        onClose={setShowConfirmPassModal}
      >
        <div id="MerchantInfoForm">
          <div className="atd-Fieldset-wrapper Box-root Margin-bottom--2">
            <fieldset className="atd-Fieldset">
              {!!errorConfirmation && (
                <Box margin={{ vertical: 8, horizontal: 16 }}>
                  <Message status="warning" text={errorConfirmation} />
                </Box>
              )}
              <div className="atd-Fieldset-rows">
                <FormControl
                  control="input"
                  name="password"
                  type="password"
                  label="Current Password"
                  placeholder="*******"
                  description="You will be requested to confirm your password for security reasons."
                />
              </div>
            </fieldset>
          </div>
        </div>
        <Modal.Footer>
          <div className="Box-root Box-hideIfEmpty Margin-right--8">
            <Button
              variant="default"
              type="button"
              onClick={() => setShowConfirmPassModal(false)}
            >
              Cancel
            </Button>
          </div>
          <div className="Box-root Box-hideIfEmpty">
            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting}
              onClick={onConfirmPassword}
            >
              Confirm & Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Panel.Header title="Change Password" />
      <Panel.Body>
        <div id="MerchantInfoForm">
          <div className="atd-Fieldset-wrapper Box-root Margin-bottom--2">
            <fieldset className="atd-Fieldset">
              <FeidRowStyle className="atd-Fieldset-rows">
                <FormControlStyle
                  as={FormControl}
                  control="input"
                  name="new_password"
                  type="password"
                  label="New Password"
                  placeholder="*******"
                />
                <FormControlStyle
                  as={FormControl}
                  control="input"
                  name="changepassword"
                  type="password"
                  label="Confirm New Password"
                  placeholder="*******"
                  description="For security, you will be asked to confirm your password."
                />
              </FeidRowStyle>
            </fieldset>
          </div>
        </div>
      </Panel.Body>
      <Panel.Footer right>
        <div className="Box-root Box-hideIfEmpty Margin-right--8">
          <Button type="button" disabled={isSubmitting} variant="default">
            Cancel
          </Button>
        </div>
        <div className="Box-root Box-hideIfEmpty">
          <Button
            disabled={
              isSubmitting ||
              !values.changepassword ||
              !values.new_password ||
              errors?.changepassword
            }
            variant="primary"
            type="button"
            onClick={() => setShowConfirmPassModal(true)}
          >
            Save Password
          </Button>
        </div>
      </Panel.Footer>
    </Panel>
  );
}

export default ChangePassword;
