import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { getCredentialCookies, getErrorMessage } from "@atd/utils";
import moment from "moment";
import { toast } from "react-toastify";

const { token } = getCredentialCookies();
export const teamReportApi = createApi({
  reducerPath: "teamReportApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: [
    "Performance Report",
    "Location Based Ticket Report",
    "Total Ticket Report",
    "Revision Ticket Report",
  ],
  endpoints: (build) => ({
    getLocationBasedTicketReport: build.query({
      query: ({
        date_string = "custom",
        start_date = date_string === "custom"
          ? `${moment().format("YYYY")}-01-01`
          : undefined,
        end_date = date_string === "custom"
          ? moment().format("YYYY-MM-DD")
          : undefined,
        country,
      }) => ({
        url: "/report/team/location_based_tickets",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date_string,
          start_date,
          end_date,
          country,
        },
      }),
      transformResponse: (response) => {
        if (response.status === "error") {
          const errorMessage = getErrorMessage(response);
          toast.error(errorMessage);
          throw new Error(response.message);
        }
        return response.results;
      },
    }),
    getTeamPerformanceReport: build.query({
      query: ({
        date_string = "custom",
        start_date = date_string === "custom"
          ? `${moment().format("YYYY")}-01-01`
          : undefined,
        end_date = date_string === "custom"
          ? moment().format("YYYY-MM-DD")
          : undefined,
        designer,
      }) => ({
        url: "/report/team/performance",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date_string,
          start_date,
          end_date,
          designer,
        },
      }),
      transformResponse: (response) => {
        if (response.status === "error") {
          const errorMessage = getErrorMessage(response);
          toast.error(errorMessage);
          throw new Error(response.message);
        }
        return response.results;
      },
    }),
    getTeamTotalTicketReport: build.query({
      query: ({
        date_string = "custom",
        start_date = date_string === "custom"
          ? `${moment().format("YYYY")}-01-01`
          : undefined,
        end_date = date_string === "custom"
          ? moment().format("YYYY-MM-DD")
          : undefined,
        designer,
      }) => ({
        url: "/report/team/total_tickets",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date_string,
          start_date,
          end_date,
          designer,
        },
      }),
      transformResponse: (response) => {
        if (response.status === "error") {
          const errorMessage = getErrorMessage(response);
          toast.error(errorMessage);
          throw new Error(response.message);
        }
        return response.results;
      },
    }),
    getTeamRevisionTicketReport: build.query({
      query: ({
        date_string = "custom",
        start_date = date_string === "custom"
          ? `${moment().format("YYYY")}-01-01`
          : undefined,
        end_date = date_string === "custom"
          ? moment().format("YYYY-MM-DD")
          : undefined,
        designer,
      }) => ({
        url: "/report/team/revision_tickets",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date_string,
          start_date,
          end_date,
          designer,
        },
      }),
      transformResponse: (response) => {
        if (response.status === "error") {
          const errorMessage = getErrorMessage(response);
          toast.error(errorMessage);
          throw new Error(response.message);
        }
        return response.results;
      },
    }),
    getDailyReports: build.query({
      query: ({
        date_string = "custom",
        start_date = date_string === "custom"
          ? moment().format("YYYY-MM-DD")
          : undefined,
        end_date = date_string === "custom"
          ? moment().format("YYYY-MM-DD")
          : undefined,
        designer,
      }) => ({
        url: "/daily_reports",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date_string,
          start_date,
          end_date,
          designer:
            window.location?.pathname === "/reports/analytics/dailyreport" ||
            window.location.pathname === "/reports/team"
              ? undefined
              : designer,
        },
      }),
      transformResponse: (response) => {
        if (response.status === "error") {
          const errorMessage = getErrorMessage(response);
          toast.error(errorMessage);
          throw new Error(response.message);
        }
        return response.results;
      },
    }),
    getCountriesFilters: build.query({
      query: () => ({
        url: "/countries_filters",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response) => {
        return response.results;
      },
    }),
    getDesignerReport: build.query({
      query: ({ year, select }) => ({
        url: "/designers_reports",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          year,
          select,
        },
      }),
      transformResponse: (response) => {
        return response.results;
      },
    }),
  }),
});

export const {
  useGetLocationBasedTicketReportQuery,
  useGetTeamPerformanceReportQuery,
  useGetTeamRevisionTicketReportQuery,
  useGetTeamTotalTicketReportQuery,
  useGetCountriesFiltersQuery,
  useGetDesignerReportQuery,
  useGetDailyReportsQuery,
} = teamReportApi;
