import { getCredentialCookies } from "@atd/utils";

import { accountApi } from "../user/accountApi";

const { token } = getCredentialCookies();

const extendedApi = accountApi.injectEndpoints({
  endpoints: (build) => ({
    version: build.query({
      query: (requestId) => ({
        url: `version/${requestId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response) => response.results,
    }),
  }),
  overrideExisting: false,
});

// eslint-disable-next-line import/prefer-default-export
export const { useVersionQuery } = extendedApi;
