import { useEffect, useState, memo } from "react";
import { useDispatch } from "react-redux";

import { Box, Select, AssignUser, Button } from "@atd/components";
import { updateClient } from "@atd/features";

const ClientAssignTo = ({ users, value }) => {
  const { id, assignedTo } = value || {};
  const [assignTo, setAssignTo] = useState(assignedTo);

  const dispatch = useDispatch();

  useEffect(() => {
    if (assignedTo) {
      setAssignTo(assignedTo);
    }
  }, [assignedTo]);

  const onUpdateAssignedTo = async (value) => {
    setAssignTo(value);
    await dispatch(
      updateClient({
        id: id,
        data: {
          update_type: "assign",
          assign_to: value,
        },
      }),
    );
  };

  return (
    <AssignUser
      users={users}
      value={assignTo || ""}
      isDisabled={!value.isActive}
      onChange={onUpdateAssignedTo}
      renderFooter={({ instance, value }) => {
        const { onClose } = instance || {};
        return (
          <Select.Footer style={{ background: "white" }}>
            <Box
              margin={{ top: 8, left: 8 }}
              flex={{ justifyContent: "flex-end" }}
              className="pointer1"
              style={{ flex: 1 }}
            >
              <Button
                variant="default"
                label="Clear"
                disabled={value.length === 0}
                onClick={(e) => {
                  e.stopPropagation();
                  onUpdateAssignedTo("");
                  onClose();
                }}
              />
            </Box>
          </Select.Footer>
        );
      }}
    />
  );
};

export default memo(ClientAssignTo);
