/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import ratings, { ratingIcons } from "./ratings";

function FeedbackRatingControl({ rating, setRating }) {
  const CurrentRatingIcon = ratingIcons[rating]?.icon;
  const CurrentRatingColor = ratingIcons[rating]?.activeColor;

  return (
    <div className="rating-control" animate-in-index={6}>
      {ratings.map(({ id, label }, index) => {
        const RatingIcon = ratingIcons[id].icon;

        if (!RatingIcon) {
          return null;
        }

        return (
          <div
            key={`${label}-${id}`}
            className="rating-option"
            onClick={() => setRating(index + 1)}
          >
            <div
              className="icon"
              style={{
                transform: `scale(${rating === index + 1 ? 0 : 1})`,
                fill: "#C6CCD0",
              }}
            >
              <RatingIcon />
            </div>
            <div
              className="label"
              style={{
                transform: `translateY(${rating === index + 1 ? 9 : 0}px)`,
                color: "rgb(171, 178, 182)",
              }}
            >
              {label}
            </div>
          </div>
        );
      })}
      <div
        className="current-rating"
        style={{ transform: `translateX(${(rating - 1) * 100}%)` }}
      >
        <div className="svg-wrapper" style={{ fill: CurrentRatingColor }}>
          <CurrentRatingIcon />
        </div>
      </div>
    </div>
  );
}

export default FeedbackRatingControl;
