import React from "react";
import { uniqueId } from "lodash";
import { ErrorMessage, Field, useFormikContext } from "formik";
import classnames from "classnames";

import FormField from "./FieldsetRow";
import FieldError from "./FieldsetError";

function Input({
  name,
  label,
  description,
  type = "text",
  inputOptions,
  disabled,
  ...props
}) {
  const { errors } = useFormikContext();

  const inputClassnames = classnames("iput acc-input", {
    "FormField__input--hasError": errors?.[name],
  });

  return (
    <FormField
      id={uniqueId("atd-input-")}
      label={label}
      description={description}
      {...props}
    >
      <Field
        name={name}
        id={uniqueId("atd-input-")}
        className={inputClassnames}
        type={type}
        disabled={disabled}
        {...inputOptions}
      />
      <ErrorMessage name={name} component={FieldError} />
    </FormField>
  );
}

export default Input;
