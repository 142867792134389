import React from "react";
// import ReactDOM from "react-dom";
import Chart from "react-apexcharts";
import "./Charts.css";
import { ContentState } from "@atd/components";
// import { Tooltip } from "@atd/components";
const Charts = ({ id, type, width, height, chartData, isLoading }) => {
  const data_series = chartData?.series;
  const data_categories = chartData?.categories;
  const chartSeries = {
    bar: [
      {
        name: id,
        data: data_series,
      },
    ],
    stacked: data_series,
    line: [
      {
        name: id,
        data: data_series,
      },
    ],
  };
  const chartOptions = {
    bar: {
      chart: {
        id: "bar",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        markers: {
          width: 20,
          height: 12,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: ["#708cef"],
          radius: 0,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
        showForSingleSeries: true,
        // customLegendItems: ["id"],
      },
      colors: ["#708cef"],
      markers: {
        colors: "#708cef",
      },
      responsive: [
        {
          breakpoint: 1300,
          options: {
            chart: {
              width: "100%",
            },
          },
        },
      ],
      xaxis: {
        categories: data_categories,
      },
      plotOptions: {
        bar: {
          // columnWidth: "10%",
          barHeight: "20%",
          // borderRadius: 5,
          horizontal: true,
          // colors: "#5769d6",
        },
      },
      grid: {
        // strokeDashArray: 5,
        xaxis: {
          //stacked
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        // x: {
        //   show: false,
        // },
        marker: {
          show: true,
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const category = w.globals.initialSeries[0]?.name;
          const label = w.globals.labels[dataPointIndex];
          const data = series[seriesIndex][dataPointIndex];
          return (
            '<div class="tooltip bar_tooltip">' +
            '<span class="category">' +
            category +
            "</span>" +
            '<div class="data">' +
            '<div class="series">' +
            "<span class='marker'>" +
            "<span class='label'>" +
            label +
            "</span>" +
            "</span>" +
            "</div>" +
            "<span class='data_label'>" +
            ":  " +
            data +
            "</span>" +
            "</div>" +
            "</div>"
          );
        },
      },
    },
    stacked: {
      colors: ["#5769d6", "#708cef", "#7fabf8", "#a6cdff"],
      chart: {
        id: "stacked",
        type: "bar",
        height: 270,
        stacked: true, //stacked
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        colors: ["#5769d6", "#708cef", "#7fabf8", "#a6cdff"],
      },
      responsive: [
        {
          breakpoint: 1300,
          options: {
            chart: {
              width: "100%",
            },
          },
        },
      ],
      xaxis: {
        categories: data_categories,
        // labels: {
        //   formatter: function (val) {
        //     return val + "K";
        //   },
        // },
      },
      legend: {
        show: true, //stacked,
        markers: {
          width: 20,
          height: 12,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 0,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true, //stacked
          // rangeBarOverlap: false,
          // rangeBarGroupRows: true,
          dataLabels: {
            // position: 'top',
            // maxItems: 2,
            // hideOverflowingLabels: false,
            // orientation: horizontal
          },
          barHeight:
            data_categories?.length *
              (data_categories?.length > 10 ? 0.9 : 2.9) +
            "%",

          // borderRadius: 5,
          // colors: [
          //   function ({ value, seriesIndex, w }) {
          //     console.log(value, seriesIndex, w);
          //     // if (value < 55) {
          //     //   // return "#7E36AF";
          //     // } else {
          //     //   // return "#D9534F";
          //     // }
          //     return "#5769d6";
          //   },
          // ],
          // colors: {
          //   ranges: [
          //     {
          //       from: 0.0,
          //       to: 100.0,
          //       color: "#5769d6",
          //     },
          //   ],

          //   //   backgroundBarColors: [
          //   //     "#2E93fA",
          //   //     "#66DA26",
          //   //     "#546E7A",
          //   //     "#E91E63",
          //   //     "#FF9800",
          //   //     "#523E7A",
          //   //     "#E45E14",
          //   //     "#FF2530",
          //   //   ], //stacked
          //   backgroundBarOpacity: 1,
          //   backgroundBarRadius: 15, // stacked
          // },
        },
      },
      grid: {
        xaxis: {
          //stacked
          lines: {
            show: true,
          },
        },
        yaxis: {
          // reversed: true,
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        // x: {
        //   show: false,
        // },
        theme: "dark",
        marker: {
          show: true,
        },
        onDatasetHover: {
          highlightDataSeries: true,
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const markerColor = w.globals.markers.colors[seriesIndex];
          const category = w.globals.initialSeries[seriesIndex]?.name;
          const label = w.globals.labels[dataPointIndex];
          const data = series[seriesIndex][dataPointIndex];
          return (
            '<div class="tooltip bar_tooltip">' +
            '<span class="category">' +
            category +
            "</span>" +
            '<div class="data">' +
            '<div class="series">' +
            "<span class='marker'  style='background-color: " +
            markerColor +
            "'>" +
            "<span class='label'>" +
            label +
            "</span>" +
            "</span>" +
            "</div>" +
            "<span class='data_label'>" +
            ":  " +
            data +
            "</span>" +
            "</div>" +
            "</div>"
          );
        },
      },
    },
    line: {
      chart: {
        id: "line",
        type: "line",
        toolbar: {
          show: false,
        },
      },
      markers: {
        colors: "#5769d6",
      },
      responsive: [
        {
          breakpoint: 1300,
          options: {
            chart: {
              width: "100%",
            },
          },
        },
      ],
      xaxis: {
        categories: data_categories,
        crosshairs: {
          show: false, //line
        },
        tooltip: {
          enabled: false, //line
        },
      },
      yaxis: {
        // offsetY: 100,
        tickAmount:
          Math.max.apply(null, data_series) > 10
            ? 6
            : Math.max.apply(null, data_series) + 5 * 0.5,
        // axisTicks: {
        //   // offsetY: 100,
        // },
      },
      tooltip: {
        fixed: {
          enabled: data_series?.length === 1,
          position: "topRight",
          offsetX: data_series?.length === 1 ? -160 : 0,
          offsetY: 10,
        },
        marker: {
          show: true,
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const isNumber = Number.isInteger(
            Number(w.globals.categoryLabels[dataPointIndex]),
          );
          const category = w.globals.categoryLabels[dataPointIndex].substring(
            0,
            isNumber ? 4 : 3,
          );
          const data = series[seriesIndex][dataPointIndex];
          return (
            '<div class="tooltip">' +
            '<span class="category">' +
            category +
            "</span>" +
            '<div class="series line_series">' +
            '<span class="dot"></span>' +
            "<span>" +
            ": " +
            data +
            "</span>" +
            "</div>" +
            "</div>"
          );
        },
      },
      stroke: {
        colors: "#5769d6", //line
        lineCap: "butt", //line
        width: 2, //line
      },
      grid: {
        strokeDashArray: 0, //line
      },
    },
  };
  const options = chartOptions[type];
  const series = chartSeries[type];
  if (isLoading && chartData?.length) {
    return <ContentState />;
  } else {
    return (
      <>
        <Chart
          options={options}
          series={series}
          type={type === "stacked" ? "bar" : type}
          width={width}
          height={
            type === "stacked"
              ? data_categories?.length > 10
                ? 1000
                : height
              : height
          }
        />
      </>
    );
  }
};

export default Charts;
