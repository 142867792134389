import React, { forwardRef } from "react";
import classNames from "classnames";

import Icon from "../Icon/Icon";

import "./Button.css";

const ButtonGroup = ({ children }) => {
  return <div className="BtnGroup">{children}</div>;
};

const Button = forwardRef((props, ref) => {
  const {
    disabled,
    label,
    variant,
    className,
    icon,
    iconColor,
    small,
    mini,
    children,
    withDropdownArrow,
    withFlexLayout,
    wide,
    block,
    fullWidth,
    onClick,
    type = "button",
    ...rest
  } = props;

  return (
    <button
      ref={ref}
      type={type}
      className={classNames("Button Btn", className, {
        "Btn--primary": !["default", "transparent", "link"].includes(variant),
        "Btn--secondary": variant === "default",
        "Btn--transparent": variant === "transparent",
        "Btn--link": variant === "link",
        "Btn--danger": variant === "danger",
        "Btn--small": small,
        "Btn--mini": mini,
        "Btn--block": block,
        "Btn--withDropdownArrow": withDropdownArrow,
        "Button--withFlexLayout": withFlexLayout,
        "Button--wide": wide,
        "Button--fullWidth": fullWidth,
        "Btn--iconOnly": !children && !label,
      })}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {icon && (
        <span className="Btn__icon">
          <Icon
            icon={icon}
            color={iconColor || (variant === "primary" ? "white" : "gray")}
          />
        </span>
      )}
      {(children || label) && (
        <span className="Btn__text">{label || children}</span>
      )}
    </button>
  );
});

Button.Group = ButtonGroup;

export default Button;
