import { Box, Icon, Text, Button, Heading, DataTable } from "@atd/components";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  getAllMeetings,
  deleteClientMeeting,
} from "@atd/features/client/clientSlice";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { fetchTeams } from "@atd/features";
// import ClientApi from "@atd/features/client/clientApi";
import ClientAssignTo from "../../ClientAssignTo";
const MeetingIcon = styled(Icon)`
  svg {
    height: 9px;
    fill: #ffff;
  }
`;
const MeetingDataTable = styled(DataTable)`
  th {
    border: 0;
    text-align: center;
  }
  th:first-child {
    text-align: start;
  }
  thead tr {
    border-bottom: 1px solid #dee2e6;
  }
  td {
    border: 0;
    border-bottom: 1px solid #dee2e6;
    text-align: center;
  }
  td:first-child {
    text-align: start;
  }
`;
const Meetings = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { meetings } = useSelector((state) => state.client);

  const { teams } = useSelector((state) => state.team);
  useEffect(() => {
    dispatch(getAllMeetings(id));
    dispatch(
      fetchTeams({
        type: "accepted",
        dropdown: "yes",
        delete: "yes",
      }),
    );
  }, [dispatch, id, navigate]);

  const columns = useMemo(
    () => [
      {
        Header: "DATE",
        accessor: "date",
      },
      {
        Header: "TIME",
        accessor: "time",
      },
      {
        Header: "PURPOSE",
        accessor: "purpose",
      },
      {
        Header: "HOSTED BY",
        accessor: "hosted_by",
        Cell: ({ value }) => <ClientAssignTo value={value} users={teams} />,
      },
      {
        Header: "STATUS",
        accessor: "status",
      },
      {
        Header: "DESCRIPTION",
        accessor: "description",
        className: "meeting_description",
        Cell: ({ value }) => (
          <Text
            className="cont2 mgt1 description"
            dangerouslySetInnerHTML={{ __html: value }}
          />
        ),
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ value }) => (
          <Box
            id={`${value} test`}
            flex={{
              direction: "row",
              gap: "10 0",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="primary"
              onClick={() => navigate(`/clients/meeting/${id}/${value}`)}
            >
              <Icon icon="pencil" color="white" />
            </Button>
            <Button
              variant="primary"
              onClick={() => dispatch(deleteClientMeeting({ id, value }))}
            >
              <Icon icon="trash" color="white" />
            </Button>
          </Box>
        ),
      },
    ],
    [dispatch, id, navigate, teams],
  );

  const data = useMemo(() => {
    const meeting = meetings
      ? meetings.map((item) => ({
          id: item.id,
          date: moment(item?.date).format("MMM DD YYYY"),
          time: moment(item?.date).format("hh:mm A"),
          purpose: item?.purpose,
          hosted_by: {
            id: item?.id,
            assignedTo: item?.hosted_by,
          },
          status: item?.meeting_status,
          description: item?.description,
        }))
      : [];
    return meeting;
  }, [meetings]);
  return (
    <Box>
      <Heading
        rightContent={
          <>
            <Button
              variant="primary"
              iconSize="14"
              label="Add Meetings"
              onClick={() => navigate(`/clients/meeting/${id}`)}
            >
              <MeetingIcon icon="plus" color="white" />
              &nbsp;
              <Text color="white">Add Meetings</Text>
            </Button>
          </>
        }
      />
      <MeetingDataTable withTabs={false} data={data} columns={columns} />
    </Box>
  );
};

export default Meetings;
