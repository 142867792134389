import { useState } from "react";

import { Box, Icon, AtdPopover } from "@atd/components";
import Button from "../Button/Button";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../DatePicker/DatePicker.css";
import Datetime from "react-datetime";
import styled from "styled-components";

const DatePicker = styled(Datetime)`
  tbody tr {
    text-align: center;
  }
  thead tr td {
    display: flex;
    justify-content: center;
  }
  tbody {
    height: 30vh !important;
  }
  tfoot tr {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
    width: 700%;
  }
  .rdtCounters {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10vw !important;
  }
  .rdtCount {
    padding: 10px;
  }
  .rdtBtn:first-child {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 100px;
    margin-left: 16px;
  }
  span.rdtBtn,
  .rdtTimeToggle {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0;
  }
  span.rdtBtn:first-child::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(-45deg);
    left: 4px;
    bottom: 2px;
  }
  span.rdtBtn:not(:first-child):after {
    content: "";
    display: block;
    box-sizing: border-box;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    left: 4px;
    top: 2px;
    margin: -10px 22px;
  }
  .rdtCount {
    width: 54px;
    font-weight: 700;
    font-size: 1.2em;
    margin: 0;
  }
  .rdtTimeToggle {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 0.9));
    width: 18px;
    height: 18px;
    border-radius: 100%;
    border: 2px solid transparent;
    box-shadow: 0 0 0 2px currentColor;
  }
  .rdtTimeToggle::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 7px;
    height: 7px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    top: 7px;
    left: 7px;
  }
  tfoot tr:has(> td.rdtTimeToggle) {
    width: 10vw !important;
  }
`;

function DateTimePicker({ onChange, collapsible, collapse }) {
  const [showCustomDateCalendar, setShowCustomDateCalendar] = useState(false);

  return (
    <div className="" style={{ marginLeft: "-50px" }}>
      {/* <span className="db-InlineDateRangeFilter-separator" /> */}
      <AtdPopover
        interactive
        visible={showCustomDateCalendar}
        onShow={setShowCustomDateCalendar}
        placement="bottom"
        element={
          <Box>
            <DatePicker input={false} open={collapsible} onChange={onChange} />
          </Box>
        }
      >
        <div className="Box-root Flex-flex dropdown wt-auto time_picker">
          <Button
            className="db-InlineFilterButton"
            style={{ backgroundColor: "#ccc", boxShadow: "none" }}
          >
            <Icon icon={"calendar"} color="gray900" />
          </Button>
        </div>
      </AtdPopover>
    </div>
  );
}

export default DateTimePicker;
