import React from "react";
import classnames from "classnames";

import Box from "../Box/Box";
import Text from "../Text/Text";
import Icon from "../Icon/Icon";

import "./EmptyState.css";

function EmptyState({
  title,
  icon,
  description,
  actions,
  innerClassName,
  ...rest
}) {
  return (
    <Box
      flex={{ alignItems: "center", justifyContent: "center" }}
      className="DashboardEmptyState"
      {...rest}
    >
      <Box className={classnames("DashboardEmptyState--inner", innerClassName)}>
        <Box
          hideIfEmpty
          background="white"
          className="Card-root Card--radius--all"
          style={{ display: "inline-block" }}
        >
          <Box background="offset" padding={{ all: 12 }}>
            <Icon icon={icon || "search"} color="gray" />
          </Box>
        </Box>
        <Box padding={{ top: 24 }}>
          <Text lineHeight={28} size={20} weight="bold">
            <span>{title}</span>
          </Text>
        </Box>
        <Box padding={{ top: 4 }}>
          <Text lineHeight={28}>{description}</Text>
        </Box>
        {actions && <Box padding={{ top: 24 }}>{actions}</Box>}
      </Box>
    </Box>
  );
}

export default EmptyState;
