import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field } from "formik";
import { pick } from "lodash";
import { toast } from "react-toastify";
import moment from "moment";

import {
  Box,
  Icon,
  Editor,
  AtdPopover,
  DatePickerWithPresets,
  FormFieldInputError,
} from "@atd/components";
import {
  useGetAnnouncementQuery,
  useUpdateAnnoucementMutation,
  useAddAnnoucementMutation,
} from "@atd/features/dashboard";
import { getCurrentTimeZone } from "@atd/utils";
import { AnnouncementSchema } from "@atd/schema";

import SettingsForm from "../Communications/SettingsForm";

const initialValues = {
  title: "",
  message: "",
  audience_type: "client",
  time_zone: getCurrentTimeZone(),
  from_date_time: "",
  to_date_time: "",
};

function CreateAnnouncement() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: announcement } = useGetAnnouncementQuery(id);
  const [updateAnnoucement] = useUpdateAnnoucementMutation();
  const [addAnnoucement] = useAddAnnoucementMutation();

  const [showCustomDateCalendar, setShowCustomDateCalendar] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const isEditing = useMemo(() => id !== "new", [id]);

  useEffect(() => {
    if (isEditing) {
      setFormValues(pick(announcement, Object.keys(initialValues)));
    }
  }, [isEditing, announcement]);

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const { status, message } = isEditing
        ? await updateAnnoucement({
            id,
            ...values,
          }).unwrap()
        : await addAnnoucement({
            ...values,
          }).unwrap();

      if (status === "error") {
        toast.error(message);
      }
      if (status === "success") {
        toast.success(message);
      }
      if (status === "success") {
        navigate("/settings/announcements");
      }
    } catch (error) {
      toast.error(error.message);
    }
    setSubmitting(false);
  };

  return (
    <SettingsForm
      title={isEditing ? "Edit Announcement" : "New Announcement"}
      initialValues={formValues || initialValues}
      onSubmit={onSubmit}
      onCancel={() => navigate("/settings/announcements")}
      validationSchema={AnnouncementSchema}
      enableReinitialize
    >
      {({ values, errors, touched, setFieldValue }) => (
        <div className="Box-root" style={{ maxWidth: 630 }}>
          <div className="title2">Announcement Title</div>
          <div className="FormField mgt1">
            <Field
              name="title"
              className={`iput ${
                errors.title && touched.title && "FormField__input--hasError"
              }`}
              placeholder="eg: Black Friday Holiday"
            />
            <FormFieldInputError name="title" />
          </div>
          <div className="title2 mgt4">Message</div>
          <div className="FormField mgt1">
            <Field name="message">
              {({ field: { value } }) => (
                <Editor
                  model={value}
                  onModelChange={(newValue) =>
                    setFieldValue("message", newValue)
                  }
                  hasCustomBottomToolbar={false}
                />
              )}
            </Field>
          </div>
          <div className="title2 mgt4">Announcement Periods</div>
          <div
            className="Box-root Flex-flex Flex-alignItems--center Flex-direction--row Flex-justifyContent--spaceBetween Flex-wrap--nowrap ccss27"
            id="filter"
          >
            <div className="db-InlineFilterForm-bottom">
              <div
                className="db-InlineDateRangeFilter-group"
                style={{
                  border:
                    ((errors?.from_date_time && touched?.from_date_time) ||
                      (errors?.to_date_time && touched?.to_date_time)) &&
                    "1px solid #f44336",
                }}
              >
                <div className="Box-root Flex-flex">
                  <button
                    aria-label="Date"
                    className="db-InlineFilterButton btn"
                    type="button"
                  >
                    Choose the from and to date:
                  </button>
                </div>
                <span className="db-InlineDateRangeFilter-separator" />
                <div className="Box-root Flex-flex" id="cale">
                  <AtdPopover
                    interactive
                    visible={showCustomDateCalendar}
                    onShow={setShowCustomDateCalendar}
                    placement="bottom"
                    element={
                      <div className="Box-root">
                        <div className="db-MenuWrapper-contextualLayer Box-root Flex-flex Flex-direction--column Flex-wrap--nowrap">
                          <DatePickerWithPresets
                            onApply={({ startDate, endDate }) => {
                              setShowCustomDateCalendar(false);
                              setFieldValue(
                                "from_date_time",
                                moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
                              );
                              setFieldValue(
                                "to_date_time",
                                moment(endDate).format("YYYY-MM-DDTHH:mm:ss"),
                              );
                            }}
                            onClear={(e) => {
                              e.stopPropagation();
                              setFieldValue("from_date_time", null);
                              setFieldValue("to_date_time", null);
                            }}
                          />
                        </div>
                      </div>
                    }
                  >
                    <div className="Box-root Flex-flex dropdown wt-auto">
                      <button
                        className="db-InlineFilterButton btn"
                        type="button"
                      >
                        <Icon icon="calendar" color="gray600" />
                        {values?.from_date_time && values?.to_date_time && (
                          <Box as="span" padding={{ left: 8 }}>
                            {moment(values?.from_date_time).format("MMM DD")}-
                            {moment(values?.to_date_time).format("MMM DD")}
                          </Box>
                        )}
                      </button>
                    </div>
                  </AtdPopover>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </SettingsForm>
  );
}

export default CreateAnnouncement;
