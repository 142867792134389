import * as React from "react";

function SvgFilter(props) {
  return (
    <svg
      aria-hidden="true"
      className="Filter_svg__SVGInline-svg Filter_svg__SVGInline--cleaned-svg Filter_svg__SVG-svg Filter_svg__Icon-svg Filter_svg__Icon--filter-svg Filter_svg__Button-icon-svg Filter_svg__Icon-color-svg Filter_svg__Icon-color--gray600-svg"
      height={12}
      width={12}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.994.004c.555 0 1.006.448 1.006 1a.997.997 0 0 1-.212.614l-5.782 7.39L9 13.726a1 1 0 0 1-.293.708L7.171 15.97A.1.1 0 0 1 7 15.9V9.008l-5.788-7.39A.996.996 0 0 1 1.389.214a1.01 1.01 0 0 1 .617-.21z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgFilter;
