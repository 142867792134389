import Box from "../Box/Box";

function PageContentLeft({ children }) {
  return (
    <Box
      divider={{ color: "light", side: "right", width: 1 }}
      padding={{ right: 20, left: 0, vertical: 20 }}
      style={{ flex: "3 1 0%" }}
      // style={{ flex: "3 1 0%", height: "100vh" }}
    >
      <Box>
        <Box flex={{ direction: "row" }}>
          <Box padding={{ right: 20 }} style={{ width: "100%" }}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PageContentLeft;
