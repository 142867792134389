function TableCell({
  component: Component = "td",
  children,
  className,
  widthMinimize,
  noWrap,
  ...props
}) {
  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  );
}

export default TableCell;
