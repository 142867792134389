import * as Yup from "yup";

const MeetingSchema = Yup.object().shape({
  client_name: Yup.string().required("Required"),
  client_email: Yup.string().email("Invalid email").required("Required"),
  date: Yup.string().required("Required"),
  hosted_by: Yup.string().required("Required"),
  purpose: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});
export default MeetingSchema;
