import React from "react";

function CollapsibleHeader({
  title,
  element,
  overrideTitleClassName,
  headerClassName,
}) {
  return (
    <header className={headerClassName}>
      <span className={overrideTitleClassName || "title2 capitalize"}>
        {title}
      </span>
      {element}
    </header>
  );
}

export default CollapsibleHeader;
