import LeftPanel from "../Panel/LeftPanel/LeftPanel";
import MiddelPanel from "../Panel/MiddlePanel/MiddelPanel";
import RightPanel from "../Panel/RightPanel/RightPanel";
import FooterAnnotation from "./FooterAnnotation";
import { useCallback, useState, useEffect } from "react";
import { getCredentialCookies, readStatus } from "@atd/utils";
import moment from "moment";

function PreviewAnnotation({
  rolePermissions,
  fileResponse,
  queryParam,
  fetchFileData,
  annotationMessage,
  errorResponse,
  addAnnotation,
  updateAnnotation,
  addNewVersion,
  uploadFiles,
  onChangeStatus,
  onResolve,
  isSubmit,
  onDelete,
  updateQueryParams,
  downloadFile,
  onChangeVersionTypes,
}) {
  const { role, user_type } = getCredentialCookies();
  const [queryParams, setQueryParams] = useState(null);
  const [annotation, setAnnotation] = useState([]);
  const [files, setFiles] = useState([]);
  const [versionFile, setVersionFile] = useState([]);
  const [versionHistory, setVersionHistory] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("active");
  const [isEditValid, setIsEditValid] = useState(null);
  const [attachmentFile, setAttachmentFile] = useState([]);
  const [toggleBrowser, setToggleBrowser] = useState(0);
  const [latestVersion, setLatestVersion] = useState([]);
  const [sideNavAction, setSideNavAction] = useState(false);

  useEffect(() => {
    const getQueryParams = () => {
      if (queryParam) {
        setQueryParams(queryParam);
      }
    };
    getQueryParams();
  }, [queryParam]);

  useEffect(() => {
    const handleFileResponse = async () => {
      if (Object.keys(fileResponse)?.length > 0) {
        const response = fileResponse;
        const versionType = response.attachments.filter(
          (list) => list.reply_id === response.id,
        );
        const request_object = response.attachments.reduce((acc, list) => {
          // Find if a request with the same reply_id already exists
          let existingRequest = acc.find(
            (item) => item.reply_id === list.reply_id,
          );

          if (!existingRequest) {
            // Create a new request if it doesn't exist
            existingRequest = {
              request_id: response?.request_id,
              additional_comments: "",
              request_changes: "",
              approve_work: "",
              version: versionType[0]?.version || "V1",
              annotation: [],
              reply_id: list.reply_id,
            };
            acc.push(existingRequest);
          }

          // Add the annotation to the existing or new request
          existingRequest.annotation.push({
            _attachment_id: list?.attachment_id || "",
            comments: [],
            attachment_full_name: list.full_name,
            attachments: {
              attachment_link: list.link,
              attachment_url: list.url,
              attachment_full_name: list.full_name,
              attachment_format: list.format,
              attachment_size: list.size,
              reply_id: list.reply_id,
            },
          });

          return acc;
        }, []);
        setAnnotation((prevAnnotations) => [
          ...prevAnnotations,
          request_object,
        ]);
        const attachmentDetails = response?.attachments;

        if (attachmentDetails?.length > 0) {
          const processedDetails = await Promise.all(
            attachmentDetails.map(async (ele) => {
              try {
                const supportFile = [
                  "jpg",
                  "jpeg",
                  "png",
                  "gif",
                  "bmp",
                  "cur",
                  "ico",
                  "svg",
                  "jfif",
                  "jpe",
                  "jps",
                  "pdf",
                ].includes(ele?.format);
                let detailsArray = [];
                if (supportFile) {
                  detailsArray = await fetchFileData(ele); // Assume this returns an array of objects
                  console.log("Saravanan347", detailsArray);
                } else {
                  detailsArray = [
                    ...detailsArray,
                    {
                      imageUrl: "",
                      attachment_id: ele.attachment_id,
                      annotation_id: ele.annotation_id,
                      reply_id: ele.reply_id,
                      url_link: ele?.url,
                    },
                  ];
                }
                // Map through the detailsArray and construct the required object structure
                const processedDetailsArray = detailsArray.map((details) => ({
                  url: details?.imageUrl,
                  attachment_id: details?.attachment_id,
                  annotation_id: details.annotation_id ?? "",
                  reply_id: details.reply_id,
                  url_link: details.url_link,
                  version: ele.version,
                  isSelect: false,
                  annotation: true,
                  created_on: response.created,
                  image_type: supportFile ? true : false,
                  format: ele?.format,
                  name: ele.full_name.split(".")[0],
                }));
                return processedDetailsArray;
              } catch (error) {
                console.error(
                  "Error occurred while fetching file data:",
                  error,
                );
                return null; // or handle error as needed
              }
            }),
          );

          // Flatten the array of arrays into a single array
          let flattenedProcessedDetails = processedDetails.flat();
          console.log(
            "Flattened Processed Details",
            flattenedProcessedDetails,
            queryParams,
          );
          let isSelect = false;
          flattenedProcessedDetails = flattenedProcessedDetails.map(
            (item, index) => {
              if (
                item.attachment_id === queryParams.attachment_id &&
                !isSelect
              ) {
                isSelect = true;
                item.isSelect = true;
              }
              return {
                ...item,
                page_Id: index,
              };
            },
          );

          const groupedData = [];
          const pageIdTracker = {};

          flattenedProcessedDetails.map((item) => {
            const reply_id = item.reply_id;

            if (!pageIdTracker[reply_id]) {
              pageIdTracker[reply_id] = 0;
            }

            const newItem = { ...item, page_Id: pageIdTracker[reply_id] };

            groupedData.push(newItem);

            pageIdTracker[reply_id]++;
          });

          const result = groupedData.filter((_) => _.reply_id === response.id);

          setFiles((preImageFiles) => [...preImageFiles, ...result]);

          const groupedByReplyId = groupedData.reduce((acc, item) => {
            if (!acc[item.reply_id]) {
              acc[item.reply_id] = [];
            }
            acc[item.reply_id].push(item);
            return acc;
          }, {});

          const versionFiles = Object.keys(groupedByReplyId).map((replyId) => ({
            version: groupedByReplyId[replyId][0].version,
            isSelect: replyId === response.id ? true : false,
            created_on: groupedByReplyId[replyId][0].created_on,
            files: groupedByReplyId[replyId],
            reply_id: groupedByReplyId[replyId][0].reply_id,
            annotation_id: groupedByReplyId[replyId][0].annotation_id,
          }));

          // Update the state with the grouped version files
          setVersionFile((preImageFiles) => [
            ...preImageFiles,
            ...versionFiles,
          ]);
          const latestVersion = versionFiles[versionFiles.length - 1];
          setLatestVersion(latestVersion);

          const historyList = response.version_history.map((ele) => {
            return {
              ...ele,
              user_name:
                (role === "5" || role === "3") && ele.user_type === "admin"
                  ? ele.from_company
                  : ele.user_name,
            };
          });
          setVersionHistory(historyList.reverse());
          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.log("Attachment not found");
        }
        console.log("fileResponse updated:", fileResponse);
        // Your logic here
      }
    };
    handleFileResponse();
  }, [fetchFileData, fileResponse]);

  useEffect(() => {
    const update = () => {
      if (Object.keys(annotationMessage)?.length > 0) {
        const result = annotationMessage;
        console.log("result", result);

        const filterData = result.annotation.flatMap((ele) => ele);
        const updateList = filterData.map((ele) => ({
          ...ele,
          comments:
            ele.comments?.length > 0
              ? ele.comments
                  .filter((e) => e.is_delete === 0) // Filter out deleted comments
                  .map((list) => {
                    const generateUniqueId = `${Date.now()}-${Math.random()
                      .toString(36)
                      .substr(2, 9)}`;
                    return {
                      ...list,
                      uniqueId: generateUniqueId,
                      isClick: false,
                      isShow: true,
                      attachment_full_name: ele.attachment_full_name,
                      _attachment_id: ele._attachment_id,
                      annotation_id: result.id,
                      admin_as_read: {
                        read: list?.admin_as_read?.read === 1,
                        read_date: list?.admin_as_read?.read_date,
                      },
                      client_as_read: {
                        read: list?.client_as_read?.read === 1,
                        read_date: list?.client_as_read?.read_date,
                      },
                      designer_as_read: {
                        read: list?.designer_as_read?.read === 1,
                        read_date: list?.designer_as_read?.read_date,
                      },
                      resolved: {
                        resolve: list.resolved.resolve === 1,
                        resolve_date: list.resolved.resolve_date,
                      },
                      isDrag:
                        result.approve_work === 1
                          ? false
                          : list.user_type === user_type,
                      list: list?.list?.map((ele) => {
                        const attachmentFile = ele?.attachments.filter(
                          (f) => f._delete === 0,
                        );
                        return {
                          ...ele,
                          isEdit: false,
                          enableEdit: false,
                          isSubmit: true,
                          user_id: ele._id,
                          user_name:
                            (role === "5" || role === "3") &&
                            ele.user_type === "admin"
                              ? ele.from_company
                              : ele.user_name,
                          attachments: attachmentFile.map((_) => ({
                            ..._,
                            image_url: "",
                          })),
                        };
                      }),
                    };
                  })
              : [],
        }));

        setAnnotation((prevAnnotation) => {
          return prevAnnotation.map((request) =>
            request.map((item) => ({
              ...item,
              annotation: item.annotation.map((annotation) => {
                const list = updateList.find(
                  (com) => com._attachment_id === annotation._attachment_id,
                );
                return {
                  ...annotation,
                  comments: list?.comments?.length > 0 ? list?.comments : [],
                };
              }),
            })),
          );
        });
        const commentsList = updateList.flatMap((ele) =>
          ele.comments.filter((_) => _.is_delete === 0),
        );

        console.log("commentsList", commentsList, updateList);
        setUserList(getUniqueUserNamesFromList(commentsList));

        // const check_queryParam = updateList.some(
        //   (ele) => ele._attachment_id === queryParams.attachment_id,
        // );

        // const attachment_id = check_queryParam
        //   ? queryParams.attachment_id
        //   : updateList[0]._attachment_id;
        // const page_Id = check_queryParam ? queryParams.page_Id : 0;

        // const annotationDetails = updateList
        //   .filter((ele) => ele._attachment_id === attachment_id)
        //   .flatMap((ele) =>
        //     ele.comments
        //       .filter((comment) => comment.page_Id === Number(page_Id))
        //       .map((comment) => ({ ...comment })),
        //   );

        const historyList = result?.version_history.map((ele) => {
          return {
            ...ele,
            user_name:
              (role === "5" || role === "3") && ele.user_type === "admin"
                ? ele.from_company
                : ele.user_name,
          };
        });
        setVersionHistory(historyList.reverse());
        console.log("versionFile", versionFile);

        const latestVersions =
          versionFile?.length > 0
            ? versionFile[versionFile.length - 1]
            : latestVersion;

        const enableAnnotation =
          role === "5" || role === "3"
            ? latestVersions?.reply_id === result?.reply_id
            : (role === "1" || role === "2" || role === "6") &&
              latestVersions?.reply_id === result?.reply_id &&
              latestVersions.annotation_id === result.id;
        rolePermissions.finish_review =
          result?.approve_work === 1 ? true : enableAnnotation;
      }
    };

    update();
  }, [annotationMessage, latestVersion]);

  const finishReview = useCallback(
    async (event, selectedVersion) => {
      const details = annotation
        .flatMap((ele) => ele)
        .find((item) => item.reply_id === selectedVersion?.reply_id);
      console.log("finishReview", annotation, selectedVersion, details, event);
      if (!queryParams.annotation_id) {
        const annotation = details.annotation.reduce((acc, curr) => {
          if (Object.keys(curr)?.length > 0) {
            let json_data = {
              _attachment_id: curr._attachment_id,
              attachment_full_name: curr.attachment_full_name,
              comments: curr.comments.map((annotation) => ({
                x: annotation.x,
                y: annotation.y,
                z: annotation.z,
                page_Id: annotation.page_Id,
                _created: annotation._created,
                modified_date: annotation.modified_date,
                list: annotation.list.map((comment) => ({
                  comment: comment.comment,
                  created_on: comment.created_on,
                  modified_date: comment.modified_date,
                  attachments: comment.attachments.map((attachment) => ({
                    attachment_link: attachment.attachment_link,
                    attachment_url: attachment.attachment_url,
                    attachment_name:
                      attachment.attachment_full_name.split(".")[0],
                    attachment_full_name: attachment.attachment_full_name,
                    attachment_format: attachment.attachment_format,
                    attachment_size: attachment.attachment_size,
                    version: attachment.version || "",
                  })),
                })),
              })),
            };
            acc.push(json_data);
          }
          return acc;
        }, []);
        const result = annotation.filter((ele) => ele.comments?.length > 0);
        const payload = {
          request_id: queryParams.request_id,
          reply_id: queryParams.reply_id,
          additional_comments: event.additionalComments,
          request_changes: JSON.parse(event.reviewStatus),
          approve_work:
            rolePermissions?.approve_this_work && event.reviewStatus === "0"
              ? 1
              : 0,
          version: details.version,
          rating:
            rolePermissions?.approve_this_work && event.reviewStatus === "0"
              ? event.rating
              : 0,
          current_date_time: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
          annotation: result,
        };
        console.log("addAnnotation", payload, result);
        await addAnnotation(payload);
      } else if (queryParams.annotation_id) {
        const filterData = details.annotation.reduce((acc, curr) => {
          if (Object.keys(curr)?.length > 0) {
            let json_data = {
              _attachment_id: curr._attachment_id,
              attachment_full_name: curr.attachment_full_name,
              comments: curr.comments.map((annotation) => ({
                x: annotation.x,
                y: annotation.y,
                z: annotation.z,
                pin_id: annotation.pin_id ? annotation.pin_id : "",
                page_Id: annotation.page_Id,
                _created: annotation._created,
                modified_date: annotation.modified_date,
                list: annotation.list.map((comment) => {
                  if (comment.isEdit && comment.comment_id) {
                    const updateMessage = {
                      comment: comment.comment,
                      comment_id: comment.comment_id,
                      created_on: comment.created_on,
                      modified_date: comment.modified_date,
                      attachments: comment.attachments.map((attachment) => ({
                        attachment_link: attachment.attachment_link,
                        attachment_url: attachment.attachment_url,
                        attachment_name:
                          attachment.attachment_full_name.split(".")[0],
                        attachment_full_name: attachment.attachment_full_name,
                        attachment_format: attachment.attachment_format,
                        attachment_size: attachment.attachment_size,
                        version: attachment.version || "",
                      })),
                    };
                    return updateMessage;
                  } else if (comment.isSubmit && !comment.comment_id) {
                    const updateMessage = {
                      comment: comment.comment,
                      created_on: comment.created_on,
                      modified_date: comment.modified_date,
                      attachments: comment.attachments.map((attachment) => ({
                        attachment_link: attachment.attachment_link,
                        attachment_url: attachment.attachment_url,
                        attachment_name:
                          attachment.attachment_full_name.split(".")[0],
                        attachment_full_name: attachment.attachment_full_name,
                        attachment_format: attachment.attachment_format,
                        attachment_size: attachment.attachment_size,
                        version: attachment.version || "",
                      })),
                    };
                    return updateMessage;
                  } else {
                    return {};
                  }
                }),
              })),
            };
            acc.push(json_data);
          }
          return acc;
        }, []);
        const result = filterData.map((attachment) => ({
          _attachment_id: attachment._attachment_id,
          attachment_full_name: attachment.attachment_full_name,
          comments: attachment.comments
            .map((comment) => ({
              ...comment,
              list:
                comment.list?.length > 0
                  ? comment.list.filter((item) => Object.keys(item).length > 0)
                  : comment.list,
            }))
            .filter((comment) => comment.list?.length > 0),
        }));

        const updateList = result.filter((ele) => ele.comments?.length > 0);
        const payload = {
          request_id: queryParams.request_id,
          reply_id: queryParams.reply_id,
          additional_comments: event.additionalComments,
          request_changes:
            event.reviewStatus !== "" ? JSON.parse(event.reviewStatus) : 0,
          approve_work:
            rolePermissions?.approve_this_work && event.reviewStatus === "0"
              ? 1
              : 0,
          version: details.version,
          rating:
            rolePermissions?.approve_this_work && event.reviewStatus === "0"
              ? event.rating
              : 0,
          current_date_time: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
          annotation: updateList,
        };
        console.log("updateAnnotation", payload, result, updateList);
        await updateAnnotation(payload);
      }
    },
    [
      addAnnotation,
      annotation,
      queryParams?.annotation_id,
      queryParams?.reply_id,
      queryParams?.request_id,
      rolePermissions?.approve_this_work,
      updateAnnotation,
    ],
  );

  const getUniqueUserNamesFromList = (data) => {
    const uniqueUsers = data.reduce(
      (accumulator, item) => {
        item.list.forEach((comment) => {
          const { user_name, _id: user_id } = comment;
          console.log("user_name", user_name);
          if (
            getCredentialCookies()?.user_id !== user_id &&
            user_name !== undefined
          ) {
            if (!(user_id in accumulator.uniqueUserIds)) {
              accumulator.uniqueUserIds[user_id] = true;

              const firstLetter = user_name.charAt(0).toUpperCase();
              accumulator.users.push({ user_id, user_name, firstLetter });
            }
          }
        });
        return accumulator;
      },
      { uniqueUserIds: {}, users: [] },
    );

    return uniqueUsers.users;
  };

  const toggleCheckbox = useCallback(
    (e) => {
      const check_Id = e ? 1 : 0;
      if (e) {
        const update = annotation.map((request) =>
          request.map((item) => ({
            ...item,
            annotation: item.annotation.map((annotation) => {
              return {
                ...annotation,
                comments: (annotation.comments || [])?.map((comment) => {
                  return {
                    ...comment,
                    isClick: false,
                  };
                }),
              };
            }),
          })),
        );

        console.log("update", update);
        setAnnotation(update);
      }
      setToggleBrowser(check_Id);
    },
    [annotation],
  );
  const onChangeFile = useCallback(
    async (event) => {
      const update = annotation.map((request) =>
        request.map((item) => ({
          ...item,
          annotation: item.annotation.map((annotation) => {
            return {
              ...annotation,
              comments: (annotation.comments || [])?.map((comment) => ({
                ...comment,
                isClick: false,
              })),
            };
          }),
        })),
      );
      setAnnotation(update);
      setFiles((prevImageFiles) =>
        prevImageFiles.map((item) => ({
          ...item,
          isSelect: item.page_Id === event.page_Id,
        })),
      );

      setVersionFile((prevImageFiles) =>
        prevImageFiles.map((ele) => ({
          ...ele,
          files: ele.files.map((item) => ({
            ...item,
            isSelect: item.page_Id === event.page_Id,
          })),
        })),
      );
      if (event?.annotation) {
        updateQueryParams({
          attachment_id: event.attachment_id,
          page_Id: event.page_Id,
        });
      }
      toggleCheckbox(false);

      console.log("onChangeFile", annotation, event, activeTab);
    },
    [activeTab, annotation, toggleCheckbox, updateQueryParams],
  );

  const onUpdateAnnotation = (data, attachment_id, page_Id, activeTab) => {
    console.log(
      "onUpdateAnnotation",
      data,
      attachment_id,
      page_Id,
      annotation,
      activeTab,
    );
    const update = annotation.map((request) =>
      request.map((item) => ({
        ...item,
        annotation: item.annotation.map((annotation) => {
          if (annotation._attachment_id === attachment_id) {
            if (annotation.comments?.length > 0) {
              const result = annotation.comments.map((comment) => {
                if (comment.page_Id !== page_Id) {
                  return { ...comment, isClick: false };
                } else {
                  const matchingData = data.find(
                    (ele) => ele.uniqueId === comment.uniqueId,
                  );

                  return matchingData
                    ? matchingData
                    : { ...comment, isClick: false };
                }
              });
              return { ...annotation, comments: result };
            } else {
              return {
                ...annotation,
                comments: [
                  ...annotation.comments.map((ele) => {
                    return { ...ele, isClick: false };
                  }),
                  ...data,
                ],
              };
            }
          } else {
            return {
              ...annotation,
              comments: (annotation.comments || [])?.map((comment) => {
                return {
                  ...comment,
                  isClick: false,
                };
              }),
            };
          }
        }),
      })),
    );

    console.log("update", update);
    setAnnotation(update);

    if (activeTab) {
      setActiveTab(activeTab);
    }
  };
  const onReply = (data, attachment_id, page_Id, activeTab) => {
    console.log(
      "onReplyAnnotation",
      data,
      attachment_id,
      page_Id,
      annotation,
      activeTab,
    );
    const update = annotation.map((request) =>
      request.map((item) => ({
        ...item,
        annotation: item.annotation.map((annotation) => {
          if (annotation._attachment_id === attachment_id) {
            if (annotation.comments?.length > 0) {
              const result = annotation.comments.map((comment) => {
                if (comment.page_Id !== page_Id) {
                  return comment;
                } else {
                  const matchingData = data.find(
                    (ele) => ele.uniqueId === comment.uniqueId,
                  );

                  return matchingData ? matchingData : comment;
                }
              });
              return { ...annotation, comments: result };
            } else {
              return {
                ...annotation,
                comments: [...annotation.comments, ...data],
              };
            }
          } else {
            return {
              ...annotation,
              comments: (annotation.comments || [])?.map((comment) => ({
                ...comment,
              })),
            };
          }
        }),
      })),
    );
    setAnnotation(update);
    console.log("update", update);
    setAnnotation(update);

    if (activeTab) {
      setActiveTab(activeTab);
    }

    rolePermissions.request_changes = true;
  };

  const onCreateNewMessage = (data, attachment_id, page_Id, activeTab) => {
    console.log(
      "onCreateNewMessage",
      data,
      attachment_id,
      page_Id,
      annotation,
      activeTab,
    );
    const update = annotation.map((request) =>
      request.map((item) => ({
        ...item,
        annotation: item.annotation.map((annotation) => {
          if (annotation._attachment_id === attachment_id) {
            if (annotation.comments?.length > 0) {
              const result = annotation.comments.filter(
                (comment) => comment.page_Id !== page_Id,
              );
              return {
                ...annotation,
                comments: [...result, ...data],
              };
            } else {
              return {
                ...annotation,
                comments: [...annotation.comments, ...data],
              };
            }
          } else {
            return {
              ...annotation,
              comments: (annotation.comments || [])?.map((comment) => ({
                ...comment,
              })),
            };
          }
        }),
      })),
    );
    if (activeTab) {
      setActiveTab(activeTab);
    }
    setAnnotation(update);

    rolePermissions.request_changes = true;
  };

  const onClickMessgae = async (data, attachment_id, page_Id, activeTab) => {
    toggleCheckbox(false);
    const storedAnnotations = annotation.flat().reduce((acc, ele) => {
      if (Array.isArray(ele.annotation)) {
        ele.annotation.forEach((anno) => {
          if (Array.isArray(anno.comments) && anno.comments.length > 0) {
            acc.push(...anno.comments);
          }
        });
      }
      return acc;
    }, []);
    const isEditEnabled = storedAnnotations.some((ele) =>
      ele.list.some((item) => item.enableEdit),
    );

    if (!isEditEnabled) {
      const object = data.find((ele) => ele.isClick);
      if (!readStatus(object)?.read_status?.read) {
        const dynamicKey = readStatus(object)?.param.read_status;
        const payload = {
          pin_id: object.pin_id,
          _attachment_id: object._attachment_id,
          [dynamicKey]: 1,
          resolved: object.resolved.resolve === true ? 1 : 0,
          annotation_id: object.annotation_id,
          time_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        };
        const response = await onChangeStatus(payload);

        if (response?.status === "success") {
          const findIndex = data.findIndex((ele) => ele.isClick);
          data[findIndex][dynamicKey].read = true;
          setFiles((prevImageFiles) =>
            prevImageFiles.map((item) => ({
              ...item,
              isSelect: item.page_Id === page_Id,
            })),
          );
          setVersionFile((prevImageFiles) =>
            prevImageFiles.map((ele) => ({
              ...ele,
              files: ele.files.map((item) => ({
                ...item,
                isSelect: item.page_Id === page_Id,
              })),
            })),
          );
          onUpdateAnnotation(data, attachment_id, page_Id);
          if (activeTab) {
            setActiveTab(activeTab);
          }
        }
      } else {
        setFiles((prevImageFiles) =>
          prevImageFiles.map((item) => ({
            ...item,
            isSelect: item.page_Id === page_Id,
          })),
        );
        setVersionFile((prevImageFiles) =>
          prevImageFiles.map((ele) => ({
            ...ele,
            files: ele.files.map((item) => ({
              ...item,
              isSelect: item.page_Id === page_Id,
            })),
          })),
        );

        onUpdateAnnotation(data, attachment_id, page_Id);
        if (activeTab) {
          setActiveTab(activeTab);
        }
      }
    } else {
      setIsEditValid(() => !isEditValid);
    }
  };

  const onResolved = async (data, attachment_id, page_Id, uniqueId) => {
    const storedAnnotations = annotation.flat().reduce((acc, ele) => {
      if (Array.isArray(ele.annotation)) {
        ele.annotation.forEach((anno) => {
          if (Array.isArray(anno.comments) && anno.comments.length > 0) {
            acc.push(...anno.comments);
          }
        });
      }
      return acc;
    }, []);
    const isEditEnabled = storedAnnotations.some((ele) =>
      ele.list.some((item) => item.enableEdit),
    );
    if (!isEditEnabled) {
      const resolve = data.find((ele) => ele.uniqueId === uniqueId);
      const payload = {
        pin_id: resolve.pin_id,
        annotation_id: queryParams.annotation_id,
        _attachment_id: attachment_id,
        client_as_read: resolve?.client_as_read.read === true ? 1 : 0,
        designer_as_read: resolve?.designer_as_read.read === true ? 1 : 0,
        admin_as_read: resolve?.admin_as_read.read === true ? 1 : 0,
        resolved: resolve?.resolved?.resolve === true ? 1 : 0,
        time_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
      };

      const response = await onResolve(payload);
      if (response?.status === "success") {
        onUpdateAnnotation(data, attachment_id, page_Id);
      }
    } else {
      setIsEditValid(() => !isEditValid);
    }
  };
  const onUpdateStatus = async (data, attachment_id, page_Id, activeTab) => {
    const storedAnnotations = annotation.flat().reduce((acc, ele) => {
      if (Array.isArray(ele.annotation)) {
        ele.annotation.forEach((anno) => {
          if (Array.isArray(anno.comments) && anno.comments.length > 0) {
            acc.push(...anno.comments);
          }
        });
      }
      return acc;
    }, []);
    const isEditEnabled = storedAnnotations.some((ele) =>
      ele.list.some((item) => item.enableEdit),
    );
    console.log("onUpdateStatus", data, annotation, isEditEnabled);
    if (!isEditEnabled) {
      const object = data.find((ele) => ele.isClick);
      const dynamicKey = readStatus(object)?.param.read_status;
      const payload = {
        pin_id: object.pin_id,
        _attachment_id: attachment_id,
        [dynamicKey]: dynamicKey.read === true ? 1 : 0,
        resolved: object.resolved.resolve === true ? 1 : 0,
        annotation_id: queryParams.annotation_id,
        time_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
      };

      const response = await onChangeStatus(payload);
      if (response?.status === "success") {
        setFiles((prevImageFiles) =>
          prevImageFiles.map((item) => ({
            ...item,
            isSelect: item.page_Id === page_Id,
          })),
        );
        setVersionFile((prevImageFiles) =>
          prevImageFiles.map((ele) => ({
            ...ele,
            files: ele.files.map((item) => ({
              ...item,
              isSelect: item.page_Id === page_Id,
            })),
          })),
        );
        onUpdateAnnotation(data, attachment_id, page_Id);
        if (activeTab) {
          setActiveTab(activeTab);
        }
      }
    } else {
      setIsEditValid(() => !isEditValid);
    }
  };
  const onDeleteThread = async (data, attachment_id, page_Id, uniqueId) => {
    const storedAnnotations = annotation.flat().reduce((acc, ele) => {
      if (Array.isArray(ele.annotation)) {
        ele.annotation.forEach((anno) => {
          if (Array.isArray(anno.comments) && anno.comments.length > 0) {
            acc.push(...anno.comments);
          }
        });
      }
      return acc;
    }, []);
    const isEditEnabled = storedAnnotations.some((ele) =>
      ele.list.some((item) => item.enableEdit),
    );
    if (!isEditEnabled) {
      const object = data.find((ele) => ele.isClick);
      if (object.pin_id !== undefined) {
        const payload = {
          pin_id: object.pin_id,
          annotation_id: queryParams.annotation_id,
          _attachment_id: attachment_id,
          time_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        };

        const response = await onDelete(payload);
        if (response?.status === "success") {
          const updateList = data.filter((ele) => ele.uniqueId !== uniqueId);
          const update = annotation.map((request) =>
            request.map((item) => ({
              ...item,
              annotation: item.annotation.map((annotation) => {
                if (annotation._attachment_id === attachment_id) {
                  if (annotation.comments?.length > 0) {
                    let result = [];
                    if (updateList?.length > 0) {
                      result = annotation.comments
                        .map((comment) => {
                          if (comment.page_Id !== page_Id) {
                            return comment;
                          }

                          const matchingData = updateList.find(
                            (ele) => ele.uniqueId === comment.uniqueId,
                          );

                          return matchingData || [];
                        })
                        .filter((ele) => ele.length !== 0);
                    }
                    return { ...annotation, comments: result };
                  } else {
                    return {
                      ...annotation,
                      comments: [...annotation.comments, ...data],
                    };
                  }
                } else {
                  return {
                    ...annotation,
                    comments: (annotation.comments || [])?.map((comment) => ({
                      ...comment,
                    })),
                  };
                }
              }),
            })),
          );
          const result = update.map((request) =>
            request.map((item) => ({
              ...item,
              annotation: item.annotation.map((annotation) => ({
                ...annotation,
                comments: annotation.comments.filter(
                  (comment) => comment !== undefined,
                ),
              })),
            })),
          );
          setAnnotation(result);

          const allCommentsEmpty = update
            .flat()
            .every((item) =>
              item.annotation.every((_) => _.comments.length === 0),
            );
          const allCommentsNotEmpty = update
            .flat()
            .every((item) =>
              item.annotation.every((annotation) =>
                annotation.comments.every((comment) => comment.pin_id),
              ),
            );

          if (allCommentsEmpty) {
            rolePermissions.request_changes = false;
          } else {
            rolePermissions.request_changes = !allCommentsNotEmpty;
          }
        }
      } else {
        const updateList = data.filter((ele) => ele.uniqueId !== uniqueId);
        const update = annotation.map((request) =>
          request.map((item) => ({
            ...item,
            annotation: item.annotation.map((annotation) => {
              if (annotation._attachment_id === attachment_id) {
                if (annotation.comments?.length > 0) {
                  let result = [];
                  if (updateList?.length > 0) {
                    result = annotation.comments
                      .map((comment) => {
                        if (comment.page_Id !== page_Id) {
                          return comment;
                        }

                        const matchingData = updateList.find(
                          (ele) => ele.uniqueId === comment.uniqueId,
                        );

                        return matchingData || [];
                      })
                      .filter((ele) => ele.length !== 0);
                  }

                  return { ...annotation, comments: result };
                } else {
                  return {
                    ...annotation,
                    comments: [...annotation.comments, ...data],
                  };
                }
              } else {
                return {
                  ...annotation,
                  comments: (annotation.comments || [])?.map((comment) => ({
                    ...comment,
                  })),
                };
              }
            }),
          })),
        );
        const result = update.map((request) =>
          request.map((item) => ({
            ...item,
            annotation: item.annotation.map((annotation) => ({
              ...annotation,
              comments: annotation.comments.filter(
                (comment) => comment !== undefined,
              ),
            })),
          })),
        );
        console.log("onDeleteThread", update);
        setAnnotation(result);
        const allCommentsEmpty = update
          .flat()
          .every((item) =>
            item.annotation.every((_) => _.comments.length === 0),
          );
        const allCommentsNotEmpty = update
          .flat()
          .every((item) =>
            item.annotation.every((annotation) =>
              annotation.comments.every((comment) => comment.pin_id),
            ),
          );

        if (allCommentsEmpty) {
          rolePermissions.request_changes = false;
        } else {
          rolePermissions.request_changes = !allCommentsNotEmpty;
        }
      }
    } else {
      setIsEditValid(() => !isEditValid);
    }
  };

  const forWardAction = useCallback(async () => {
    const storedAnnotations = annotation.flat().reduce((acc, ele) => {
      if (Array.isArray(ele.annotation)) {
        ele.annotation.forEach((anno) => {
          if (Array.isArray(anno.comments) && anno.comments.length > 0) {
            acc.push(...anno.comments);
          }
        });
      }
      return acc;
    }, []);

    const unResolvedList = storedAnnotations.filter(
      (_) => _.resolved.resolve === false,
    );

    const index = unResolvedList.findIndex((ele) => ele.isClick);
    const forWard = unResolvedList.some((_, i) => i === index + 1);

    const empty = unResolvedList[index + 1]?.list.some(
      (ele) => ele?.comment === "",
    );
    const forWardIndex = forWard ? index + 1 : 0;

    if (!empty && typeof forWardIndex === "number") {
      const isEditEnabled = unResolvedList.some((ele) =>
        ele.list.some((item) => item.enableEdit),
      );
      const updatedAnnotations = unResolvedList.map((ele) => {
        return {
          ...ele,
          isClick: false,
          list: ele.list.map((element) => ({
            ...element,
            attachments: element.attachments.map((item) => ({
              ...item,
              image_url: "",
            })),
          })),
        };
      });
      if (!isEditEnabled) {
        const update = updatedAnnotations.find((_, i) => i === forWardIndex);
        if (!readStatus(update)?.read_status?.read) {
          const dynamicKey = readStatus(update)?.param.read_status;
          const payload = {
            pin_id: update.pin_id,
            _attachment_id: update._attachment_id,
            [dynamicKey]: 1,
            resolved: update.resolved.resolve === true ? 1 : 0,
            annotation_id: queryParams.annotation_id,
            time_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
          };
          const response = await onChangeStatus(payload);
          if (response?.status === "success") {
            const updateList = updatedAnnotations
              .map((curr, index) => {
                if (
                  index === forWardIndex &&
                  curr.uniqueId === update.uniqueId
                ) {
                  const readStatusKey = readStatus(curr)?.param?.read_status;
                  return {
                    ...curr,
                    isClick: true,
                    [readStatusKey]: { read: true },
                  };
                } else if (curr.page_Id === update.page_Id) {
                  return { ...curr };
                }
              })
              .filter((item) => item !== undefined);

            const object = files.find((ele) => ele.page_Id === update.page_Id);
            setFiles((prevImageFiles) =>
              prevImageFiles.map((item) => ({
                ...item,
                isSelect: item.page_Id === object.page_Id,
              })),
            );
            setVersionFile((prevImageFiles) =>
              prevImageFiles.map((ele) => ({
                ...ele,
                files: ele.files.map((item) => ({
                  ...item,
                  isSelect: item.page_Id === object.page_Id,
                })),
              })),
            );

            const updateAnnotation = annotation.map((request) =>
              request.map((item) => ({
                ...item,
                annotation: item.annotation.map((annotation) => {
                  if (annotation._attachment_id === object.attachment_id) {
                    if (annotation.comments?.length > 0) {
                      const result = annotation.comments.map((comment) => {
                        if (comment.page_Id !== object.page_Id) {
                          return { ...comment, isClick: false };
                        } else {
                          const matchingData = updateList.find(
                            (ele) => ele.uniqueId === comment.uniqueId,
                          );
                          return matchingData
                            ? matchingData
                            : { ...comment, isClick: false };
                        }
                      });
                      return { ...annotation, comments: result };
                    }
                  } else {
                    return {
                      ...annotation,
                      comments: (annotation.comments || [])?.map((comment) => ({
                        ...comment,
                        isClick: false,
                      })),
                    };
                  }
                }),
              })),
            );
            console.log("forWardAction", updateList, updateAnnotation);
            setAnnotation(updateAnnotation);
          }
        } else {
          const updateList = updatedAnnotations
            .map((curr, index) => {
              if (index === forWardIndex && curr.uniqueId === update.uniqueId) {
                return { ...curr, isClick: true };
              } else if (curr.page_Id === update.page_Id) {
                return { ...curr };
              }
            })
            .filter((item) => item !== undefined);

          const object = files.find((ele) => ele.page_Id === update.page_Id);
          setFiles((prevImageFiles) =>
            prevImageFiles.map((item) => ({
              ...item,
              isSelect: item.page_Id === object.page_Id,
            })),
          );
          setVersionFile((prevImageFiles) =>
            prevImageFiles.map((ele) => ({
              ...ele,
              files: ele.files.map((item) => ({
                ...item,
                isSelect: item.page_Id === object.page_Id,
              })),
            })),
          );

          console.log("forWardAction", updateList);

          const updateAnnotation = annotation.map((request) =>
            request.map((item) => ({
              ...item,
              annotation: item.annotation.map((annotation) => {
                if (annotation._attachment_id === object.attachment_id) {
                  if (annotation.comments?.length > 0) {
                    const result = annotation.comments.map((comment) => {
                      if (comment.page_Id !== object.page_Id) {
                        return { ...comment, isClick: false };
                      } else {
                        const matchingData = updateList.find(
                          (ele) => ele.uniqueId === comment.uniqueId,
                        );
                        return matchingData
                          ? matchingData
                          : { ...comment, isClick: false };
                      }
                    });
                    return { ...annotation, comments: result };
                  }
                } else {
                  return {
                    ...annotation,
                    comments: (annotation.comments || [])?.map((comment) => ({
                      ...comment,
                      isClick: false,
                    })),
                  };
                }
              }),
            })),
          );

          setAnnotation(updateAnnotation);
        }
      } else {
        setIsEditValid(() => !isEditValid);
      }
    }
  }, [
    annotation,
    files,
    isEditValid,
    onChangeStatus,
    queryParams?.annotation_id,
  ]);

  const backWardAction = useCallback(async () => {
    const storedAnnotations = annotation.flat().reduce((acc, ele) => {
      if (Array.isArray(ele.annotation)) {
        ele.annotation.forEach((anno) => {
          if (Array.isArray(anno.comments) && anno.comments.length > 0) {
            acc.push(...anno.comments);
          }
        });
      }
      return acc;
    }, []);

    const unResolvedList = storedAnnotations.filter(
      (_) => _.resolved.resolve === false,
    );

    const index = unResolvedList.findIndex((ele) => ele.isClick);
    const backWard = unResolvedList.some((_, i) => i === index - 1);
    const empty = unResolvedList[index + 1]?.list.some(
      (ele) => ele?.comment === "",
    );
    const backWardIndex =
      backWard && index - 1 >= 0 ? index - 1 : unResolvedList?.length - 1;
    if (!empty && typeof backWardIndex === "number") {
      const isEditEnabled = unResolvedList.some((ele) =>
        ele.list.some((item) => item.enableEdit),
      );
      const updatedAnnotations = unResolvedList.map((ele) => {
        return {
          ...ele,
          isClick: false,
          list: ele.list.map((element) => ({
            ...element,
            attachments: element.attachments.map((item) => ({
              ...item,
              image_url: "",
            })),
          })),
        };
      });
      if (!isEditEnabled) {
        const update = updatedAnnotations.find((_, i) => i === backWardIndex);
        if (!readStatus(update)?.read_status?.read) {
          const dynamicKey = readStatus(update)?.param.read_status;
          const payload = {
            pin_id: update.pin_id,
            _attachment_id: update._attachment_id,
            [dynamicKey]: 1,
            resolved: update.resolved.resolve === true ? 1 : 0,
            annotation_id: queryParams.annotation_id,
            time_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
          };
          const response = await onChangeStatus(payload);
          if (response?.status === "success") {
            const updateList = updatedAnnotations
              .map((curr, index) => {
                if (
                  index === backWardIndex &&
                  curr.uniqueId === update.uniqueId
                ) {
                  const readStatusKey = readStatus(curr)?.param?.read_status;
                  return {
                    ...curr,
                    isClick: true,
                    [readStatusKey]: { read: true },
                  };
                } else if (curr.page_Id === update.page_Id) {
                  return { ...curr };
                }
              })
              .filter((item) => item !== undefined);

            const object = files.find((ele) => ele.page_Id === update.page_Id);
            setFiles((prevImageFiles) =>
              prevImageFiles.map((item) => ({
                ...item,
                isSelect: item.page_Id === object.page_Id,
              })),
            );
            setVersionFile((prevImageFiles) =>
              prevImageFiles.map((ele) => ({
                ...ele,
                files: ele.files.map((item) => ({
                  ...item,
                  isSelect: item.page_Id === object.page_Id,
                })),
              })),
            );

            console.log("backWardAction", updateList);

            const updateAnnotation = annotation.map((request) =>
              request.map((item) => ({
                ...item,
                annotation: item.annotation.map((annotation) => {
                  if (annotation._attachment_id === object.attachment_id) {
                    if (annotation.comments?.length > 0) {
                      const result = annotation.comments.map((comment) => {
                        if (comment.page_Id !== object.page_Id) {
                          return { ...comment, isClick: false };
                        } else {
                          const matchingData = updateList.find(
                            (ele) => ele.uniqueId === comment.uniqueId,
                          );
                          return matchingData
                            ? matchingData
                            : { ...comment, isClick: false };
                        }
                      });
                      return { ...annotation, comments: result };
                    }
                  } else {
                    return {
                      ...annotation,
                      comments: (annotation.comments || [])?.map((comment) => ({
                        ...comment,
                        isClick: false,
                      })),
                    };
                  }
                }),
              })),
            );

            setAnnotation(updateAnnotation);
          }
        } else {
          const updateList = updatedAnnotations
            .map((curr, index) => {
              if (
                index === backWardIndex &&
                curr.uniqueId === update.uniqueId
              ) {
                return { ...curr, isClick: true };
              } else if (curr.page_Id === update.page_Id) {
                return { ...curr };
              }
            })
            .filter((item) => item !== undefined);

          const object = files.find((ele) => ele.page_Id === update.page_Id);
          setFiles((prevImageFiles) =>
            prevImageFiles.map((item) => ({
              ...item,
              isSelect: item.page_Id === object.page_Id,
            })),
          );
          setVersionFile((prevImageFiles) =>
            prevImageFiles.map((ele) => ({
              ...ele,
              files: ele.files.map((item) => ({
                ...item,
                isSelect: item.page_Id === object.page_Id,
              })),
            })),
          );

          console.log("backWardAction", updateList);

          const updateAnnotation = annotation.map((request) =>
            request.map((item) => ({
              ...item,
              annotation: item.annotation.map((annotation) => {
                if (annotation._attachment_id === object.attachment_id) {
                  if (annotation.comments?.length > 0) {
                    const result = annotation.comments.map((comment) => {
                      if (comment.page_Id !== object.page_Id) {
                        return { ...comment, isClick: false };
                      } else {
                        const matchingData = updateList.find(
                          (ele) => ele.uniqueId === comment.uniqueId,
                        );
                        return matchingData
                          ? matchingData
                          : { ...comment, isClick: false };
                      }
                    });
                    return { ...annotation, comments: result };
                  }
                } else {
                  return {
                    ...annotation,
                    comments: (annotation.comments || [])?.map((comment) => ({
                      ...comment,
                      isClick: false,
                    })),
                  };
                }
              }),
            })),
          );

          setAnnotation(updateAnnotation);
        }
      } else {
        setIsEditValid(() => !isEditValid);
      }
    }
  }, [annotation, files, isEditValid]);

  const uploadFile = useCallback(
    async (event) => {
      const files = event.target.files[0];
      if (files) {
        let version = versionFile.findLast((ele) => ele);
        const response = await uploadFiles(event);
        if (response?.status === "success") {
          const ele = version.version.split("")[1];
          const attachmentData = {
            attachment_link: response.results.link,
            attachment_url: response.results.url,
            attachment_full_name: response.results.full_name,
            attachment_format: response.results.format,
            attachment_size: response.results.size,
            attachment_id: "",
            notification: {
              admin_notification: 0,
              customer_notification: 0,
            },
            version: "V".concat(JSON.parse(ele) + 1),
          };
          console.log("uploadResponse", attachmentData);
          setAttachmentFile((preAttachmentFile) => [
            ...preAttachmentFile,
            attachmentData,
          ]);
          return response;
        } else {
          return response;
        }
      }
    },
    [uploadFiles, versionFile],
  );

  const finishUpload = useCallback(async () => {
    if (attachmentFile?.length > 0) {
      const attachmentList = attachmentFile.map((ele) => {
        console.log(" ele.attachment_full_name", ele);
        let data = {
          link: ele.attachment_link,
          url: ele.attachment_url,
          full_name: ele.attachment_full_name,
          name: ele.attachment_full_name.split(".")[0],
          format: ele.attachment_format,
          size: ele.attachment_size,
          notification: ele.notification,
          version: ele.version,
        };
        return { ...data };
      });
      let payload = {
        body: `Hi,
                Thank you for submitting your design request. 
                Attached are the completed files for your review. 
                Please take a moment to go through them and let us know if any revisions are needed.

                Wishing you a great day!

                Best regards,
                All Time Design`,
        attachments: attachmentList,
      };

      const result = await addNewVersion(payload);
      if (result?.status === "success") {
        const response = result.results;
        const request_object = [
          {
            request_id: response?.request_id,
            additional_comments: "",
            request_changes: "",
            approve_work: "",
            version: response.attachments[0].version,
            annotation: [],
            reply_id: response?.id,
          },
        ];
        const annotation = response.attachments.map((list) => {
          const attachmentData = {
            attachment_link: list.link,
            attachment_url: list.url,
            attachment_full_name: list.full_name,
            attachment_format: list.format,
            attachment_size: list.size,
            reply_id: response?.id,
          };
          return {
            _attachment_id:
              typeof list?.attachment_id === "string"
                ? list?.attachment_id
                : "",
            comments: [],
            attachment_full_name: list.full_name,
            attachments: attachmentData,
          };
        });
        const newRequest = request_object.map((ele) => {
          return { ...ele, annotation: annotation };
        });
        setAnnotation((prevAnnotations) => [...prevAnnotations, newRequest]);
        const attachmentDetails = response?.attachments;
        if (attachmentDetails?.length > 0) {
          const processedDetails = await Promise.all(
            attachmentDetails.map(async (ele) => {
              try {
                const supportFile = [
                  "jpg",
                  "jpeg",
                  "png",
                  "gif",
                  "bmp",
                  "cur",
                  "ico",
                  "svg",
                  "jfif",
                  "jpe",
                  "jps",
                  "pdf",
                ].includes(ele?.format);
                let detailsArray = [];
                if (supportFile) {
                  detailsArray = await fetchFileData(ele); // Assume this returns an array of objects
                } else {
                  detailsArray = [
                    ...detailsArray,
                    {
                      imageUrl: "",
                      attachment_id: ele.attachment_id,
                      annotation_id: ele.annotation_id,
                      reply_id: ele.reply_id,
                      url_link: ele?.url,
                    },
                  ];
                }

                // Map through the detailsArray and construct the required object structure
                const processedDetailsArray = detailsArray.map((details) => ({
                  url: details?.imageUrl,
                  attachment_id: details?.attachment_id,
                  annotation_id: details.annotation_id ?? "",
                  reply_id: response?.id,
                  url_link: details.url_link,
                  version: ele.version,
                  isSelect: false,
                  annotation: false,
                  created_on: response.created,
                  image_type: supportFile ? true : false,
                  format: ele?.format,
                  name: ele.full_name.split(".")[0],
                }));
                return processedDetailsArray;
              } catch (error) {
                console.error(
                  "Error occurred while fetching file data:",
                  error,
                );
                return null; // or handle error as needed
              }
            }),
          );

          // Flatten the array of arrays into a single array
          let flattenedProcessedDetails = processedDetails.flat();

          flattenedProcessedDetails = flattenedProcessedDetails.map(
            (item, index) => {
              return {
                ...item,
                annotation: false,
                isSelect: false,
                page_Id: index,
              };
            },
          );
          console.log("imageDetailsArray", flattenedProcessedDetails);
          // setFiles((preImageFiles) => [
          //   ...preImageFiles,
          //   ...flattenedProcessedDetails,
          // ]);
          setVersionFile((preImageFiles) => [
            ...preImageFiles,
            {
              version: flattenedProcessedDetails[0].version,
              isSelect: true,
              created_on: flattenedProcessedDetails[0].created_on,
              files: flattenedProcessedDetails,
              reply_id: flattenedProcessedDetails[0].reply_id,
              annotation_id: flattenedProcessedDetails[0].annotation_id,
            },
          ]);
          setIsLoading(false);
          return result;
        } else {
          return result;
        }
      } else {
        return result;
      }
    }
  }, [addNewVersion, attachmentFile, fetchFileData]);

  const onClosePoup = useCallback(() => {
    setAttachmentFile([]);
  }, []);

  const onChangeVersionType = useCallback(
    async (event) => {
      console.log("onChangeVersionType", event);
      if (event.annotation_id) {
        updateQueryParams({
          annotation_id: event.annotation_id,
          reply_id: event.reply_id,
          attachment_id: event.files[0].attachment_id,
          page_Id: 0,
        });
        await onChangeVersionTypes(event.annotation_id);
      } else {
        updateQueryParams({
          annotation_id: event.annotation_id,
          reply_id: event.reply_id,
          attachment_id: event.files[0].attachment_id,
          page_Id: 0,
        });
        const approve_work = annotation
          .flat()
          .find((ele) => ele.reply_id === event.reply_id);
        const approve_status = approve_work.approve_work === 1 ? true : false;
        rolePermissions.finish_review = approve_status;
        setAnnotation((prevAnnotation) => {
          return prevAnnotation.map((request) =>
            request.map((item) => ({
              ...item,
              annotation: item.annotation.map((annotation) => {
                return {
                  ...annotation,
                  comments: [],
                };
              }),
            })),
          );
        });
      }
      setVersionFile((prevImageFiles) =>
        prevImageFiles.map((item) => ({
          ...item,
          files:
            item.reply_id === event.reply_id
              ? item.files.map((ele, index) => {
                  return { ...ele, isSelect: index === 0 };
                })
              : item.files.map((ele) => {
                  return { ...ele, isSelect: false };
                }),
          isSelect: item.reply_id === event.reply_id ? true : false,
        })),
      );

      const result = versionFile.map((_) => ({
        ..._,
        files: _.files.map((ele) => {
          return { ...ele, isSelect: false };
        }),
      }));

      const list = result.find((ele) => ele.reply_id === event.reply_id);
      list.files[0].isSelect = true;
      setFiles(list?.files);
    },
    [
      annotation,
      onChangeVersionTypes,
      rolePermissions,
      updateQueryParams,
      versionFile,
    ],
  );

  const removeAttachFile = useCallback((index) => {
    setAttachmentFile((prevFiles) => {
      if (prevFiles[index]) {
        return prevFiles.filter((_, i) => i !== index);
      }
      return prevFiles;
    });
  }, []);

  const onSideNav = () => {
    setSideNavAction((prev) => !prev);
  };
  return (
    <>
      <LeftPanel
        file={files}
        isLoading={isLoading}
        rolePermissions={rolePermissions}
        annotation={annotation}
        sideNavAction={sideNavAction}
        onUpdateStatus={onUpdateStatus}
        onUpdateAnnotation={onUpdateAnnotation}
        onClickMessgae={onClickMessgae}
        onResolved={onResolved}
        onDeleteThread={onDeleteThread}
        onSideNav={onSideNav}
      />
      <MiddelPanel
        file={files}
        isLoading={isLoading}
        // referenceFiles={referenceFiles}
        versionFile={versionFile}
        // viewReferenceFiles={viewReferenceFiles}
        annotation={annotation}
        rolePermissions={rolePermissions}
        // deleteAttachementFile={deleteAttachementFile}
        userList={userList}
        forWardAction={forWardAction}
        backWardAction={backWardAction}
        onUpdateAnnotation={onUpdateAnnotation}
        onCreateNewMessage={onCreateNewMessage}
        onResolved={onResolved}
        onUpdateStatus={onUpdateStatus}
        onClickMessgae={onClickMessgae}
        onDeleteThread={onDeleteThread}
        onReply={onReply}
        toggleCheckbox={toggleCheckbox}
        toggleBrowser={toggleBrowser}
        activeTab={activeTab}
        isEditValid={isEditValid}
        sideNavAction={sideNavAction}
      />
      <RightPanel
        file={files}
        isLoading={isLoading}
        onChangeFile={(event) => onChangeFile(event)}
        annotation={annotation}
      />
      <FooterAnnotation
        isSubmit={isSubmit}
        versionFile={versionFile}
        finishReview={finishReview}
        finishUpload={finishUpload}
        uploadFile={uploadFile}
        onChangeVersion={onChangeVersionType}
        onClosePoup={onClosePoup}
        downloadFile={downloadFile}
        rolePermissions={rolePermissions}
        versionHistory={versionHistory}
        isLoading={isLoading}
        removeAttachFile={removeAttachFile}
      />
    </>
  );
}

export default PreviewAnnotation;
