import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import moment from "moment";

import { Box, HasPermission } from "@atd/components";
import { getClientLocalTime, isAdmin } from "@atd/utils";
import { TimeTextSmall } from "./Request.style";

export const UserStatus = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  margin-right: 10px;
  background-color: ${({ status }) =>
    status === "online" ? "#228403" : "#9D9D9D"};
`;

function useLocalTimeClock(clientTimezone = 19800) {
  const [localTime, setLocalTime] = useState(
    moment(getClientLocalTime(clientTimezone)).format("MMM DD, hh:mm A"),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const todayTime = moment(getClientLocalTime(clientTimezone)).format(
        "MMM DD, hh:mm A",
      );
      setLocalTime(todayTime);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [clientTimezone]);

  return localTime;
}

export function StatusThread({ isOnline, clientTimezone, name }) {
  const localTime = useLocalTimeClock(clientTimezone);

  return (
    <>
      {isAdmin() && <UserStatus status={isOnline ? "online" : "offline"} />}
      {name}
      <br />
      {isAdmin() && (
        <TimeTextSmall>
          {isOnline ? "Online" : "Offline"} | Local time {localTime}
        </TimeTextSmall>
      )}
    </>
  );
}

function Status({ name, isOnline, clientTimezone, companyName, companyId }) {
  const localTime = useLocalTimeClock(clientTimezone);

  return (
    <Box
      flex={{ direction: "row", justifyContent: "spaceBetween" }}
      divider={{ color: "light", side: "bottom", width: 1 }}
    >
      <div className="center pd-30">
        <div className="title2 mgt1">
          {name && isAdmin() && (
            <UserStatus status={isOnline ? "online" : "offline"} />
          )}
          {name}
        </div>
        {isAdmin() && (
          <div className="cont2 mgt1">
            {isOnline ? "Online" : "Offline"} | Local time {localTime}
          </div>
        )}
        <div className="cont2 mgt1">
          <HasPermission roles={["Admin"]} condition="not-equal">
            <Link to="/settings/account">{companyName}</Link>
          </HasPermission>
          <HasPermission roles={["Admin"]}>
            <Link to={`/clients/${companyId}`}>{companyName}</Link>
          </HasPermission>
        </div>
      </div>
    </Box>
  );
}

export default Status;
