import styled from "styled-components";

import Button from "../Button/Button";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: inset 0 -1px #ebeef1;
  background: #fff;
  margin: 0;
  padding: 16px 36px 16px 36px;
`;

export const Title = styled.div`
  font-weight: 700;
  color: #404452;
  line-height: 24px;
  font-size: 16px;
`;

export const MarkAll = styled(Button)`
  font-weight: 500;
  color: #a3acba;
  line-height: 16px;
  font-size: 12px;
  padding: 0;
  opacity: 1 !important;
`;

export const Content = styled.a`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Helvetica Neue", "Ubuntu";
  margin: 0;
  padding: 16px 36px 16px 36px;
  background: ${({ isViewed }) =>
    isViewed ? "var(--light-color)" : "#ffffff"};
  display: block;
  position: relative;

  & .bubble {
    left: 14px;
    top: 25px;
  }

  &:hover {
    background: var(--light-color);
  }
`;

export const Date = styled.div`
  font-weight: 500;
  color: #6a7383;
  line-height: 16px;
  font-size: 12px;
  padding-top: 5px;
`;

export const Text = styled.div`
  font-weight: 500 !important;
  color: #404452;
  text-align: left;
  line-height: 20px;
`;

export const LearnMore = styled.div`
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
  display: flex;
  color: var(--primary-color);
  margin-top: 7px;
  align-items: center;
  cursor: pointer;

  & svg {
    fill: var(--primary-color);
    margin-top: -1px;
    margin-left: 5px;
    vertical-align: middle;
  }

  &:hover {
    color: black;
  }

  &:hover svg {
    fill: black;
  }
`;

export const NotificationBubbleWrapper = styled.span`
  position: relative;
  top: 2px;
`;

export const NotificationBubble = styled.span`
  background: #ea552b;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  position: absolute;
  top: -6px;
  right: 0px;
  color: white;
  font-size: 9px;
  line-height: 14px;
  text-align: center;
  font-weight: bold;
  border: 1px solid;
`;
