import styled from "styled-components";
import { TabList, Tabs } from "react-tabs";

import { Box } from "@atd/components";

export const TimeTextSmall = styled.span`
  line-height: 20px;
  font-weight: 400;
  font-size: 12px;
  color: #3c4257;
`;

export const RequestInfoStyle = styled(Box)`
  .sticky .timer-wrapper {
    margin-top: 0 !important;
    height: 58px;

    padding: 10px 20px;
  }

  .sticky .timer {
    font-size: 25px;
    line-height: 35px;
  }
`;

export const TabListStyle = styled(TabList)`
  border: 0;
  margin: 0;

  & > .tab-item {
    flex: 1;
    text-align: center;
    margin: 0;
    font-size: 16px;
    padding: 13px 0px;
  }
`;

export const RequestInfoTabs = styled(Tabs)`
  background: #fff;
  border: 1px solid #e3e8ee;
  border-top: none;

  .react-tabs__tab-panel {
    margin-top: 30px;
    padding: 0 16px 16px 16px;
  }

  .react-tabs__tab-panel:empty {
    display: none;
  }
`;
