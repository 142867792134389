/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const TimerStyle = styled.div`
  background-color: #fff;
  border: 1px solid #e9f1f7;
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  margin-top: 20px !important;
  height: 70px;

  #timer {
    margin: 0;
    line-height: normal;
    margin-right: 10px;
    font-weight: 600;
  }

  .btn {
    margin-left: 5px;
  }

  .time-cont {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Helvetica Neue, Ubuntu, sans-serif;
    line-height: 20px;
    font-weight: 400;
    font-size: 12px;
    color: #3c4257;
  }
`;
