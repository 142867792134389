import * as React from "react";

function SvgSearch(props) {
  return (
    <svg
      aria-hidden="true"
      className="Search_svg__SVGInline-svg Search_svg__SVGInline--cleaned-svg Search_svg__SVG-svg Search_svg__Icon-svg Search_svg__Icon--search-svg Search_svg__Icon-color-svg Search_svg__Icon-color--gray-svg"
      height={12}
      width={12}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6 11.2c.037.028.073.059.107.093l3 3a1 1 0 1 1-1.414 1.414l-3-3a1.009 1.009 0 0 1-.093-.107 7 7 0 1 1 1.4-1.4zM7 12A5 5 0 1 0 7 2a5 5 0 0 0 0 10z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgSearch;
