/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { AtdPopover as Popover, Tooltip } from "@atd/components";

import List from "./List";
import MenuList from "./MenuList";

import styles from "./Menu.module.css";

function MenuItem(props) {
  const {
    link,
    itemText,
    collapse = true,
    children,
    count,
    renderAfterItemText,
    renderButton,
    icon: Icon,
    onClick,
    render,
  } = props;

  const location = useLocation();

  const NavlinkTag = link ? NavLink : "a";

  const linkRender = (
    <span className={styles.item}>
      <NavlinkTag
        to={{ pathname: link }}
        className={({ isActive }) => {
          const linkClasses = [styles.itemLink];
          if (
            isActive ||
            location.pathname.startsWith(`/${link?.split?.("/")?.[1]}`)
          )
            linkClasses.push(styles.isActive);
          return linkClasses.join(" "); // returns "registerButton" or "registerButton active"
        }}
        onClick={onClick}
        target={props.target}
      >
        <span className={styles.itemLinkContent}>
          {render && render()}
          {!render && (
            <span className={styles.itemIcon}>
              <span>
                <Icon active={location.pathname === link} />
              </span>
              {count ? <span className={styles.count}>{count}</span> : ""}
            </span>
          )}
          {itemText && !render && (
            <span className={styles.itemText}>{itemText}</span>
          )}
          {renderAfterItemText ? renderAfterItemText() : null}
          {renderButton && renderButton()}
        </span>
      </NavlinkTag>
    </span>
  );

  return (
    <li className={styles.menuItem}>
      {children ? (
        <Popover
          appendTo={document.body}
          trigger="mouseenter"
          onShow={() => collapse}
          render={() => (
            <div className={styles.itemPopover}>
              <h3 className={styles.itemSubtitle}>{itemText}</h3>
              {children && collapse && (
                <MenuList>
                  {Array.isArray(children)
                    ? children?.map((child, index) => (
                        <List key={index} {...child.props} />
                      ))
                    : children}
                </MenuList>
              )}
            </div>
          )}
          placement="right-start"
          hasManualPlacement={false}
        >
          {linkRender}
        </Popover>
      ) : (
        <Tooltip
          placement="right-start"
          content={itemText}
          onShow={() => collapse}
        >
          {linkRender}
        </Tooltip>
      )}

      {location.pathname.startsWith(`/${link?.split?.("/")?.[1]}`) &&
        children &&
        !collapse && (
          <ul className={styles.menuList}>
            {Array.isArray(children)
              ? children?.map((child, index) => (
                  <List key={index} {...child.props} />
                ))
              : children}
          </ul>
        )}
    </li>
  );
}

export default MenuItem;
