import React, { useRef, useEffect } from "react";

import Select from "../../InputField/SelectField";
import Radio from "../../Radio/Radio";
import Box from "../../Box/Box";
import Text from "../../Text/Text";
import { DateFilter } from "@atd/components";
// import DateTimeFilter from "@atd/components/DateTimeFilter/DateTimeFilter";
const FilterDateType = ({
  inputRef,
  queryParams,
  label,
  onFilter,
  dateFilterOptions,
}) => {
  return (
    <>
      {(() => {
        switch (queryParams[label]?.created || "is in the last") {
          case "is in the last":
            return (
              <Box flex={{ direction: "row" }}>
                <Box margin={{ right: 8 }}>
                  <span>
                    <input
                      ref={inputRef}
                      type="text"
                      value={queryParams[label]?.time_period}
                      name="time_period"
                      className="atd-input"
                      style={{ width: 50 }}
                      onChange={(e) => onFilter(e, label)}
                    />
                  </span>
                </Box>
                <Select
                  id="period"
                  value={queryParams[label]?.period}
                  options={["days", "months"]}
                  width="auto"
                  name="period"
                  onChange={(e) => onFilter(e, label)}
                />
              </Box>
            );
          case "is between":
            return (
              <Box flex={{ direction: "row" }}>
                <Box margin={{ right: 8, top: -8 }} flex={{ direction: "row" }}>
                  <DateFilter
                    value={queryParams[label]?.date_string}
                    startDate={queryParams[label]?.start_date}
                    endDate={queryParams[label]?.end_date}
                    options={dateFilterOptions}
                    onChange={onFilter}
                  />
                </Box>
              </Box>
            );
          default:
            return (
              <Box flex={{ direction: "row" }}>
                <Box margin={{ right: 8, top: -8 }}>
                  <DateFilter
                    showDateRange={false}
                    showTimeFormat={false}
                    value={queryParams[label]?.date_string}
                    // startDate={queryParams[label]?.start_date}
                    // endDate={queryParams[label]?.end_date}
                    options={dateFilterOptions}
                    onChange={(e) => onFilter(e, label)}
                  />
                </Box>
              </Box>
            );
        }
      })()}
    </>
  );
};
function FilterDate({
  isVisible,
  onFilter,
  options,
  queryParams,
  label,
  dateFilterOptions,
  ...rest
}) {
  const inputRef = useRef(null);
  useEffect(() => {
    if (isVisible) {
      inputRef.current?.focus();
    }
  }, [isVisible]);

  return (
    <Text size={12} color="gray">
      <Select
        id="created"
        value={queryParams[label]?.created}
        name="created"
        width="100%"
        options={Object.keys(options).map((val) => val.split("_").join(" "))}
        onChange={(e) => onFilter(e, label)}
      />
      <div className="bs-FilterControl-input-container">
        <FilterDateType
          inputRef={inputRef}
          dateFilterOptions={dateFilterOptions}
          queryParams={queryParams}
          label={label}
          onFilter={onFilter}
        />
      </div>
      <Box style={{ pointerEvents: "none", marginTop: 12 }}>
        <Box
          flex={{
            alignItems: "center",
            direction: "row",
            justifyContent: "flex-start",
            wrap: "nowrap",
          }}
          style={{ marginLeft: "-16px" }}
        >
          <Box
            hideIfEmpty
            margin={{ left: 16 }}
            style={{ pointerEvents: "auto" }}
          >
            <span>Time zone:</span>
          </Box>
          <Box
            hideIfEmpty
            margin={{ left: 16 }}
            style={{ pointerEvents: "auto" }}
          >
            <Radio
              value="GMT+5:30"
              name="timezone"
              label="GMT+5:30"
              onChange={(e) => onFilter(e, label)}
            />
          </Box>
          <Box
            hideIfEmpty
            margin={{ left: 16 }}
            style={{ pointerEvents: "auto" }}
          >
            <Radio
              value="UTC"
              name="timezone"
              label="UTC"
              onChange={(e) => onFilter(e, label)}
            />
          </Box>
        </Box>
      </Box>
    </Text>
  );
}

export default FilterDate;
