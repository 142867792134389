import * as React from "react";

function SvgTerrible(props) {
  return (
    <svg viewBox="0 0 50 50" {...props}>
      <path
        d="M50 25c0 13.807-11.193 25-25 25S0 38.807 0 25 11.193 0 25 0s25 11.193 25 25"
        className="terrible_svg__base"
      />
      <path
        d="M25 31.5c-3.689 0-7.243.954-10.38 2.741a2 2 0 0 0 1.98 3.476A16.911 16.911 0 0 1 25 35.5c2.99 0 5.861.77 8.4 2.217a2 2 0 0 0 1.98-3.476A20.911 20.911 0 0 0 25 31.5Z"
        className="terrible_svg__mouth"
        fill="#FFF"
      />
      <path
        d="M30.649 16.815c.523-.088.615-.117 1.136-.201.521-.084 1.882-.276 2.797-.442.915-.167 1.133-.206 1.914-.298a3.995 3.995 0 0 1 .332 5.954 4 4 0 0 1-6.18-5.013Z"
        className="terrible_svg__right-eye"
        fill="#FFF"
      />
      <path
        d="M18.828 21.828a4.001 4.001 0 0 0 .523-5.013c-.523-.088-.615-.117-1.136-.201-.521-.084-1.882-.276-2.797-.442-.915-.167-1.133-.206-1.914-.298a3.995 3.995 0 0 0-.332 5.954 4 4 0 0 0 5.656 0Z"
        className="terrible_svg__left-eye"
        fill="#FFF"
      />
    </svg>
  );
}

export default SvgTerrible;
