import * as React from "react";

function SvgCircleCheck(props) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m3.733 7 2.8 2.333 3.734-4.666M7 13.533A6.532 6.532 0 0 1 4.5.964 6.533 6.533 0 1 1 7 13.534Z"
        stroke="#3C425C"
      />
    </svg>
  );
}

export default SvgCircleCheck;
