import "./MiddelPanel.css";
import { useEffect, useState, useCallback, useMemo } from "react";
import { Annotation, NewComment, ReplyComment, Tooltip } from "@atd/components";
import { getCredentialCookies, readStatus } from "@atd/utils";
import { FileCardIcon } from "@atd/components";
import { toast } from "react-toastify";
import moment from "moment";
import xss from "xss";

// const Downloadfile = ({ isOpen, position, toggleDropdown, file }) => {
//   const handleItemClick = (ele) => {
//     console.log("download file", ele);
//     window.open(ele?.url_link);
//     toggleDropdown();
//   };

//   return (
//     isOpen && (
//       <div
//         tabIndex="0"
//         className="zoom-dropdown-menu"
//         style={{ top: position.top - 140, left: position.left - 190 }}
//         role="menu"
//         aria-labelledby="dropdown-button"
//         onClick={(e) => e.stopPropagation()}
//       >
//         <div
//           className="zoom-dropdown-item"
//           role="menuitem"
//           tabIndex="0"
//           onClick={() => handleItemClick(file)}
//         >
//           Download file
//         </div>
//       </div>
//     )
//   );
// };

const MiddelPanel = ({
  file,
  referenceFiles,
  viewReferenceFiles,
  isLoading,
  annotation,
  deleteAttachementFile,
  rolePermissions,
  userList,
  forWardAction,
  backWardAction,
  onUpdateStatus,
  onResolved,
  onUpdateAnnotation,
  onCreateNewMessage,
  isEditValid,
  activeTab,
  onClickMessgae,
  onDeleteThread,
  onReply,
  toggleCheckbox,
  toggleBrowser,
  sideNavAction,
}) => {
  console.log(
    "ImagesAnnotation",
    isLoading,
    file,
    toggleBrowser,
    sideNavAction,
  );

  // const containerRef = useRef(null);
  const [annotations, setAnnotations] = useState([]);
  const [files, setFiles] = useState([]);

  const [comment, setComment] = useState("");
  const [currentCommentIndex, setCurrentCommentIndex] = useState(0);
  const [moveTopCorner, setMoveTopCorner] = useState(false);
  const [isAnimate, setIsAnimate] = useState(false);
  const [isValidation, setIsValidation] = useState(false);
  const [validateSumbit, setValidateSumbit] = useState(false);
  const [attachFile, setAttachFile] = useState([]);
  const [rolePermission, setRolePermission] = useState([]);
  const [activeTabs, setActiveTabs] = useState("active");

  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [dropdownPosition, setDropdownPosition] = useState({
  //   top: 0,
  //   left: 0,
  // });

  const actionMenus = useMemo(
    () => [
      {
        id: 0,
        value: "Mark as unread",
        enable: rolePermissions?.change_status,
      },
      {
        id: 1,
        value: "Mark as read",
        enable: rolePermissions?.change_status,
      },
      { id: 2, value: "Copy link", enable: true },
      {
        id: 3,
        value: "Delete thread",
        enable: rolePermissions?.delete_thread,
      },
    ],
    [rolePermissions?.change_status, rolePermissions?.delete_thread],
  );

  useEffect(() => {
    if (annotation) {
      console.log("MiddelPanel", annotation);
      const storedAnnotations = annotation.flat().reduce((acc, ele) => {
        if (Array.isArray(ele.annotation)) {
          ele.annotation.forEach((anno) => {
            if (Array.isArray(anno.comments) && anno.comments.length > 0) {
              acc.push(...anno.comments);
            }
          });
        }
        return acc;
      }, []);

      const object = file.find((ele) => ele.isSelect);
      const updateList = storedAnnotations
        .filter(
          (ele) =>
            ele?.page_Id === object?.page_Id &&
            ((ele.resolved.resolve === false && activeTabs === "active") ||
              (ele.resolved.resolve === true && activeTabs === "resolved")),
        )
        .map((ele) => ({
          ...ele,
          isShow: true,
        }));
      setAnnotations(updateList);
    }
  }, [activeTabs, annotation, file]);

  useEffect(() => {
    if (rolePermissions) {
      setRolePermission(rolePermissions);
    }
  }, [rolePermissions]);

  useEffect(() => {
    if (isEditValid !== null) setIsValidation(true);
  }, [isEditValid]);

  useEffect(() => {
    if (file) {
      setFiles(file);
    }
  }, [file]);

  useEffect(() => {
    if (activeTab) {
      if (activeTab === "active") {
        const check_role = ["1"].includes(getCredentialCookies()?.role);
        rolePermission.resolve = check_role;
        rolePermission.un_resolve = false;
      } else if (activeTab === "resolved") {
        const check_role = ["3", "5"].includes(getCredentialCookies()?.role);
        rolePermission.un_resolve = check_role;
        rolePermission.resolve = false;
      }
      setRolePermission(rolePermission);
      setActiveTabs(activeTab);
    }
  }, [activeTab, rolePermission]);

  const xssConfig = {
    whiteList: {
      span: ["style"],
    },

    css: {
      color: true,
    },
  };

  const handleChange = (event, mainIndex, currentIndex) => {
    const cleanInput = xss(event, xssConfig);
    annotations[mainIndex].list[currentIndex].comment = cleanInput;
    setCurrentCommentIndex(currentIndex);
    setComment(cleanInput);
  };

  const handleReplyChange = (e) => {
    const cleanInput = xss(e, xssConfig);
    setComment(cleanInput);
  };

  const submitMessage = (mainIndex, currentIndex) => {
    setValidateSumbit(false);
    if (annotations[mainIndex].list[currentIndex].isSubmit === false) {
      annotations[mainIndex].list[currentIndex].comment = comment;
      annotations[mainIndex].list[currentIndex].attachments = attachFile;
      annotations[mainIndex].list[currentIndex].isSubmit = true;
      annotations[mainIndex].isDrag = true;
      const annotationses = [...annotations];
      const object = files.find((ele) => ele.isSelect);
      console.log("files", files);
      onCreateNewMessage(annotationses, object.attachment_id, object.page_Id);
      setComment("");
      setAttachFile([]);
    }

    console.log(
      "submitMessage",
      annotations,
      currentIndex,
      annotations[mainIndex].list[currentIndex].isSubmit,
    );
  };

  const handleImageClick = (event, item) => {
    if (rolePermissions?.finish_review) {
      const isValidate = annotations?.some((ele, _) =>
        ele.list.some((item) => item.enableEdit),
      );

      const isValidateLoading = annotations?.some((ele, _) =>
        ele.list.some((item) => item.attachments.some((_) => _.isLoading)),
      );

      const isValidateSumbit = annotations?.some((ele) =>
        ele.list.some(
          (item) =>
            (item.comment || item.comment === "") &&
            attachFile?.length > 0 &&
            !item.isSubmit,
        ),
      );
      const findNoComments = annotations.some((ele) =>
        ele.list.some((_) => !_.comment),
      );
      console.log("offsetX", event, annotations, findNoComments);
      if (isValidate) {
        setIsValidation(true);
      } else if (isValidateLoading) {
        setIsAnimate(true);
      } else if (isValidateSumbit && !validateSumbit) {
        setIsAnimate(true);
        setValidateSumbit(true);
      } else if (!findNoComments) {
        setValidateSumbit(false);
        setComment("");
        setAttachFile([]);
        const { offsetX, offsetY } = event.nativeEvent;

        const element = event.target;
        const width = element.clientWidth;
        const height = element.clientHeight;
        const elementWidth = element.offsetWidth;

        // Calculate the offset from the right
        const offsetFromRight = elementWidth - offsetX;

        // Calculate the offset from the right as a percentage
        const offsetFromRightPercentage =
          (offsetFromRight / elementWidth) * 100;

        const offsetXPercent = (offsetX / width) * 100;
        const offsetYPercent = (offsetY / height) * 100;
        const role_check = [
          { role_Id: "1", read_status: "admin_as_read" },
          { role_Id: "5", read_status: "client_as_read" },
          { role_Id: "7", read_status: "designer_as_read" },
        ];

        const ele = role_check.find(
          (status) => status.role_Id === getCredentialCookies()?.role,
        );

        const newComment = {
          comment: "",
          comment_id: "",
          attachments: [],
          user_name: getCredentialCookies().name,
          user_id: getCredentialCookies().user_id,
          role_id: getCredentialCookies().role,
          modified_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
          created_on: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
          isEdit: false,
          enableEdit: false,
          isSubmit: false,
        };
        const newAnnotation = {
          x: offsetXPercent,
          y: offsetYPercent,
          z: offsetFromRightPercentage,
          isClick: false,
          admin_as_read: {
            read: ele.role_Id === "1" ? true : false,
            read_date: "",
          },
          client_as_read: {
            read: ele.role_Id === "5" ? true : false,
            read_date: "",
          },
          designer_as_read: {
            read: ele.role_Id === "7" ? true : false,
            read_date: "",
          },
          resolved: {
            resolve: false,
            resolve_date: "",
          },
          modified_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
          _created: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
          list: [newComment],
          uniqueId: "",
          page_Id: item.page_Id,
          isDrag: false,
          isShow: true,
        };
        let updatedAnnotationes = [...annotations];
        updatedAnnotationes = updatedAnnotationes.map((ele) => {
          return { ...ele, isClick: false };
        });
        const annotatione_list = updatedAnnotationes.filter((item) =>
          item.list.some(
            (ele) =>
              (ele?.comment !== "" ||
                (ele?.attachments && ele.attachments.length !== 0)) &&
              ele.isSubmit === true,
          ),
        );
        const generateUniqueId = `${Date.now()}-${Math.random()
          .toString(36)
          .substr(2, 9)}`;
        newAnnotation.uniqueId = generateUniqueId;
        const updatedAnnotations = [...annotatione_list, newAnnotation];
        console.log("updatedAnnotations", updatedAnnotations);
        setAnnotations(updatedAnnotations);
        console.log("State update triggered");
      } else if (findNoComments) {
        let updatedAnnotationes = [...annotations];
        const annotatione_list = updatedAnnotationes.filter((item) =>
          item.list.some(
            (ele) =>
              (ele?.comment !== "" ||
                (ele?.attachments && ele.attachments.length !== 0)) &&
              ele.isSubmit === true,
          ),
        );
        setAnnotations(annotatione_list);
      }
    }
  };

  const submitReply = useCallback(
    (index) => {
      setComment("");
      setAttachFile([]);
      const newComment = {
        comment: comment,
        comment_id: "",
        attachments: attachFile,
        user_name: getCredentialCookies().name,
        user_id: getCredentialCookies().user_id,
        role_id: getCredentialCookies().role,
        created_on: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        modified_date: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
        isEdit: false,
        isSubmit: true,
        enableEdit: false,
      };
      const update = (prevAnnotations) => {
        if (prevAnnotations[index]?.list?.length > 0) {
          const updatedAnnotations = [...prevAnnotations];
          const history = updatedAnnotations[index];
          const newMessage = [...history.list, newComment];
          updatedAnnotations[index] = { ...history, list: newMessage };
          return updatedAnnotations;
        }
        return prevAnnotations; // Return previous state if no update is made
      };

      const object = files.find((ele) => ele.isSelect);
      setAnnotations((prevAnnotations) => {
        const newAnnotations = update(prevAnnotations);
        onReply(newAnnotations, object.attachment_id, object.page_Id);
        return newAnnotations;
      });

      setComment("");
    },
    [comment, attachFile, files, onReply],
  );

  const handleAnnotationUpdate = (index, updatedPosition) => {
    if (rolePermissions?.finish_review) {
      const updatedAnnotations = [...annotations];
      const updateAnnotation = {
        x: updatedPosition.x,
        y: updatedPosition.y,
        z: updatedPosition.z,
        isClick: annotations[index].isClick,
        admin_as_read: annotations[index].admin_as_read,
        client_as_read: annotations[index].client_as_read,
        designer_as_read: annotations[index].designer_as_read,
        resolved: annotations[index].resolved,
        list: annotations[index].list,
        uniqueId: annotations[index].uniqueId,
        _created: annotations[index]._created,
        modified_date: annotations[index].modified_date,
        pin_id: annotations[index].pin_id,
        page_Id: annotations[index].page_Id,
        isDrag: annotations[index].isDrag,
        isShow: annotations[index].isShow,
      };
      console.log(
        "updatedAnnotations",
        index,
        currentCommentIndex,
        updatedAnnotations,
      );
      updatedAnnotations[index] = updateAnnotation;
      setAnnotations(updatedAnnotations);
      const object = files.find((ele) => ele.isSelect);
      onUpdateAnnotation(
        updatedAnnotations,
        object.attachment_id,
        object.page_Id,
      );
    }
  };

  const onResolve = async (value) => {
    console.log("onResolve", value);
    annotations.map((ele) => (ele.isClick = false));
    const filterData = annotations.filter(
      (ele) => ele.page_Id === value.page_Id,
    );
    const updateList = filterData.map((item) =>
      item.uniqueId === value.uniqueId
        ? {
            ...item,
            resolved: {
              resolve: !item.resolved.resolve,
              resolve_date: item.resolved.resolve_date,
            },
            isClick: false,
          }
        : { ...item, isClick: false },
    );
    let resolveList = [];
    if (value === "active") {
      resolveList = updateList.map((annotation) =>
        annotation.resolved.resolve === false
          ? { ...annotation, isShow: true }
          : { ...annotation, isShow: false },
      );
    } else if (value === "resolved") {
      resolveList = updateList.map((annotation) =>
        annotation.resolved.resolve === true
          ? { ...annotation, isShow: true }
          : { ...annotation, isShow: false },
      );
    }
    console.log("OnResolved", resolveList);
    const file = files.find((ele) => ele.page_Id === value.page_Id);
    onResolved(updateList, file.attachment_id, file.page_Id, value.uniqueId);
  };

  const removeImage = useCallback(
    (mainIndex, currentIndex, index) => {
      const updatedAnnotations = annotations.slice();
      updatedAnnotations[mainIndex].list[currentIndex].attachments.splice(
        index,
        1,
      );
      setAnnotations(updatedAnnotations);
    },
    [annotations, setAnnotations],
  );

  const onClicked = useCallback(
    async (index) => {
      const value = annotations[index];
      const object = files.find((ele) => ele.isSelect);
      const updatedAnnotations = annotations.map((item) =>
        item.uniqueId === value.uniqueId && item.page_Id === value.page_Id
          ? { ...item, isClick: true }
          : { ...item, isClick: false },
      );
      onClickMessgae(updatedAnnotations, object.attachment_id, object.page_Id);
    },
    [annotations, files, onClickMessgae],
  );

  const onReplyClose = useCallback(
    (index) => {
      setComment("");
      setAttachFile([]);
      setIsValidation(false);
      setMoveTopCorner(false);
      const updatedAnnotations = annotations.map((ele) => {
        return {
          ...ele,
          isClick: false,
          list: ele.list.map((element) => ({
            ...element,
            enableEdit: false,
            attachments: element.attachments.map((item) => ({
              ...item,
              image_url: "",
            })),
          })),
        };
      });

      const annotationses = [...updatedAnnotations];
      setAnnotations(annotationses);
      const object = files.find((ele) => ele.isSelect);
      onUpdateAnnotation(annotationses, object.attachment_id, object.page_Id);
    },
    [annotations, files, onUpdateAnnotation],
  );

  const onUpdateReply = (
    mainIndex,
    currentIndex,
    value,
    message,
    attachmentFile,
  ) => {
    const updatedAnnotations = annotations.map((annotation, mIndex) => {
      if (mIndex === mainIndex) {
        return {
          ...annotation,
          list: annotation.list.map((item, cIndex) => {
            if (cIndex === currentIndex) {
              return {
                ...item,
                isEdit: !value && typeof message === "string",
                enableEdit: value,
                comment: typeof message === "string" ? message : item.comment,
                attachments:
                  attachmentFile?.length > 0
                    ? attachmentFile
                    : item.attachments,
              };
            }
            return { ...item, enableEdit: false };
          }),
        };
      }
      return annotation;
    });
    console.log("edit", updatedAnnotations, message);
    // if (message || attachmentFile?.length > 0) {
    const object = files.find((ele) => ele.isSelect);
    setAnnotations(updatedAnnotations);
    onCreateNewMessage(
      updatedAnnotations,
      object.attachment_id,
      object.page_Id,
    );
    // }
  };

  const OnMoveTopCorner = useCallback(() => {
    setIsAnimate(true);
    setMoveTopCorner(() => !moveTopCorner);
  }, [moveTopCorner]);

  const onAnimationEnds = useCallback(() => {
    console.log("onAnimationEnds");
    setIsAnimate(false);
    setIsValidation(false);
  }, []);

  const actionMenuHandler = useCallback(
    async (item, actionId, index) => {
      const findValue = actionMenus.find((ele) => ele.id === actionId);
      if (!findValue) {
        console.error("No matching action found for actionId:", actionId);
        return;
      }

      if (findValue.id === 0 || findValue.id === 1) {
        const object = files.find((ele) => ele.page_Id === item.page_Id);
        const updatedAnnotations = annotations.map((ele, i) => {
          const dynamicKey = readStatus(ele)?.param.read_status;
          if (ele.uniqueId === item.uniqueId) {
            return {
              ...ele,
              [dynamicKey]: {
                read: findValue.id === 1 ? true : false,
                read_date:
                  readStatus(annotations)?.read_status?.read_date ||
                  moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
              },
            };
          } else {
            return ele;
          }
        });
        onUpdateStatus(
          updatedAnnotations,
          object.attachment_id,
          object.page_Id,
        );
      } else if (findValue.id === 3) {
        const object = files.find((ele) => ele.page_Id === item.page_Id);
        const filterList = annotations.filter(
          (ele) => ele.page_Id === item.page_Id,
        );

        onDeleteThread(
          filterList,
          object.attachment_id,
          object.page_Id,
          item.uniqueId,
        );
      } else {
        const currentUrl = window.location.href;
        navigator.clipboard
          .writeText(currentUrl)
          .then(() => {
            toast.success("Link to selection copied to clipboard");
            console.log("URL copied to clipboard!");
          })
          .catch((err) => {
            toast.message("Failed to copy the Link");
            console.error("Failed to copy the URL: ", err);
          });
      }
    },
    [actionMenus, annotations, files, onDeleteThread, onUpdateStatus],
  );

  // const handleContextMenu = (e) => {
  //   e.preventDefault();
  //   setDropdownPosition({ top: e.clientY, left: e.clientX });
  //   setDropdownOpen(true);
  // };

  // const handleClick = (e) => {
  //   e.preventDefault();
  //   setDropdownOpen(false);
  // };

  console.log("files98998798798467", files);

  return (
    <>
      {isLoading && (
        <>
          <div
            className={`column ${
              sideNavAction ? "collapsed-middlecol" : "middlecol"
            }`}
          >
            <div
              style={{
                padding: "25%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60px"
                height="60px"
                viewBox="0 0 24 24"
              >
                <circle cx="12" cy="2" r="0" fill="#4597f7">
                  <animate
                    attributeName="r"
                    begin="0"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="2"
                  r="0"
                  fill="#4597f7"
                  transform="rotate(45 12 12)"
                >
                  <animate
                    attributeName="r"
                    begin="0.125s"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="2"
                  r="0"
                  fill="#4597f7"
                  transform="rotate(90 12 12)"
                >
                  <animate
                    attributeName="r"
                    begin="0.25s"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="2"
                  r="0"
                  fill="#4597f7"
                  transform="rotate(135 12 12)"
                >
                  <animate
                    attributeName="r"
                    begin="0.375s"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="2"
                  r="0"
                  fill="#4597f7"
                  transform="rotate(180 12 12)"
                >
                  <animate
                    attributeName="r"
                    begin="0.5s"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="2"
                  r="0"
                  fill="#4597f7"
                  transform="rotate(225 12 12)"
                >
                  <animate
                    attributeName="r"
                    begin="0.625s"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="2"
                  r="0"
                  fill="#4597f7"
                  transform="rotate(270 12 12)"
                >
                  <animate
                    attributeName="r"
                    begin="0.75s"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="2"
                  r="0"
                  fill="#4597f7"
                  transform="rotate(315 12 12)"
                >
                  <animate
                    attributeName="r"
                    begin="0.875s"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
              </svg>
            </div>
          </div>
        </>
      )}

      {files.length > 0 &&
        !isLoading &&
        files.map(
          (item, index) =>
            item.isSelect === true && (
              <>
                {item.image_type === true ? (
                  <>
                    <div
                      className={`column ${
                        sideNavAction ? "collapsed-middlecol" : "middlecol"
                      }`}
                      key={index}
                      style={{
                        height: "100%",
                        overflowY: annotations.some((annotation) =>
                          annotation.list.some(
                            (item) =>
                              (!item?.isSubmit &&
                                !annotations.some(
                                  (annotationItem) =>
                                    annotationItem.isClick === true,
                                )) ||
                              annotations.some(
                                (annotationItem) =>
                                  annotationItem.isClick === true,
                              ),
                          ),
                        )
                          ? "hidden"
                          : "auto",

                        overflowX: "hidden",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          height: "auto",
                          minHeight: "100%",
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "column",
                          overflow: "auto",
                        }}
                      >
                        <img
                          style={{ width: "100%", paddingBottom: "80px" }}
                          src={item.url}
                          alt={""}
                          className={` ${
                            rolePermissions?.finish_review && item.annotation
                              ? sideNavAction
                                ? "custom-cursor-close-annotation"
                                : "custom-cursor-open-annotation"
                              : ""
                          } `}
                          onClick={(event) => handleImageClick(event, item)}
                        />

                        {item.annotation &&
                          toggleBrowser === 0 &&
                          annotations.map((annotation, index) => (
                            <>
                              <Annotation
                                key={index}
                                x={annotation.x}
                                y={annotation.y}
                                z={annotation.z}
                                mainIndex={index}
                                annotation={annotation}
                                newComment={annotations?.some(
                                  (annotationItem) =>
                                    annotationItem.isClick === true,
                                )}
                                onClicked={onClicked}
                                onUpdate={(updatedPosition) =>
                                  handleAnnotationUpdate(index, updatedPosition)
                                }
                              >
                                {annotation.list.map(
                                  (item, indexs) =>
                                    item?.isSubmit === false &&
                                    !annotations?.some(
                                      (annotationItem) =>
                                        annotationItem.isClick === true,
                                    ) && (
                                      <NewComment
                                        key={indexs}
                                        mainIndex={index}
                                        currentIndex={indexs}
                                        comment={item.comment}
                                        handleChange={handleChange}
                                        submitMessage={submitMessage}
                                        isAnimate={isAnimate}
                                        onAnimationEnds={onAnimationEnds}
                                        attachFile={attachFile}
                                        setAttachFile={setAttachFile}
                                        referenceFiles={referenceFiles}
                                        userList={userList}
                                      />
                                    ),
                                )}

                                {moveTopCorner === false &&
                                  annotation.isClick === true && (
                                    <ReplyComment
                                      key={index}
                                      mainIndex={index}
                                      annotation={annotation}
                                      comment={comment}
                                      handleReplyChange={handleReplyChange}
                                      submitReply={submitReply}
                                      onReplyClose={onReplyClose}
                                      onUpdateReply={onUpdateReply}
                                      onResolve={onResolve}
                                      removeImage={removeImage}
                                      forWardAction={forWardAction}
                                      backWardAction={backWardAction}
                                      OnMoveTopCorner={OnMoveTopCorner}
                                      onAnimationEnds={onAnimationEnds}
                                      isAnimate={isAnimate}
                                      isValidation={isValidation}
                                      actionMenuHandler={actionMenuHandler}
                                      isCommentEmpty={true}
                                      isCommentNotEmpty={true}
                                      referenceFiles={referenceFiles}
                                      attachFile={attachFile}
                                      setAttachFile={setAttachFile}
                                      deleteAttachementFile={
                                        deleteAttachementFile
                                      }
                                      rolePermission={rolePermission}
                                      userList={userList}
                                    />
                                  )}
                              </Annotation>
                            </>
                          ))}
                      </div>
                    </div>

                    {item.annotation &&
                      annotations.map((annotation, index) => (
                        <>
                          {moveTopCorner === true &&
                            annotation.isClick === true && (
                              <ReplyComment
                                key={index}
                                mainIndex={index}
                                annotation={annotation}
                                comment={comment}
                                handleReplyChange={handleReplyChange}
                                submitReply={submitReply}
                                onReplyClose={onReplyClose}
                                onUpdateReply={onUpdateReply}
                                onResolve={onResolve}
                                removeImage={removeImage}
                                forWardAction={forWardAction}
                                backWardAction={backWardAction}
                                OnMoveTopCorner={OnMoveTopCorner}
                                moveTopCorner={moveTopCorner}
                                onAnimationEnds={onAnimationEnds}
                                isAnimate={isAnimate}
                                isValidation={isValidation}
                                actionMenuHandler={actionMenuHandler}
                                isCommentEmpty={true}
                                isCommentNotEmpty={true}
                                referenceFiles={referenceFiles}
                                attachFile={attachFile}
                                setAttachFile={setAttachFile}
                                deleteAttachementFile={deleteAttachementFile}
                                rolePermission={rolePermission}
                                userList={userList}
                              />
                            )}
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    <div
                      className={`column ${
                        sideNavAction ? "collapsed-middlecol" : "middlecol"
                      }`}
                      key={index}
                    >
                      {/* <Downloadfile
                        isOpen={dropdownOpen}
                        position={dropdownPosition}
                        file={item}
                        toggleDropdown={() => setDropdownOpen(!dropdownOpen)}
                      /> */}
                      {/* <div
                        ref={containerRef}
                        onContextMenu={handleContextMenu}
                        onClick={handleClick}
                      > */}

                      <Tooltip
                        content={
                          "This file a download  option only can't able to create annotation for this file"
                        }
                        placement="bottom"
                      >
                        <div
                          style={{
                            cursor: "not-allowed ",
                            textAlign: "center",
                          }}
                        >
                          <FileCardIcon icon={item.format} />
                        </div>
                      </Tooltip>

                      {/* </div> */}
                    </div>
                  </>
                )}
              </>
            ),
        )}

      {isLoading && (
        <>
          <div className="column rightcol">
            <div className=" nmgt1 rightwrap">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 24 24"
              >
                <circle cx="12" cy="2" r="0" fill="#4597f7">
                  <animate
                    attributeName="r"
                    begin="0"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="2"
                  r="0"
                  fill="#4597f7"
                  transform="rotate(45 12 12)"
                >
                  <animate
                    attributeName="r"
                    begin="0.125s"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="2"
                  r="0"
                  fill="#4597f7"
                  transform="rotate(90 12 12)"
                >
                  <animate
                    attributeName="r"
                    begin="0.25s"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="2"
                  r="0"
                  fill="#4597f7"
                  transform="rotate(135 12 12)"
                >
                  <animate
                    attributeName="r"
                    begin="0.375s"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="2"
                  r="0"
                  fill="#4597f7"
                  transform="rotate(180 12 12)"
                >
                  <animate
                    attributeName="r"
                    begin="0.5s"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="2"
                  r="0"
                  fill="#4597f7"
                  transform="rotate(225 12 12)"
                >
                  <animate
                    attributeName="r"
                    begin="0.625s"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="2"
                  r="0"
                  fill="#4597f7"
                  transform="rotate(270 12 12)"
                >
                  <animate
                    attributeName="r"
                    begin="0.75s"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
                <circle
                  cx="12"
                  cy="2"
                  r="0"
                  fill="#4597f7"
                  transform="rotate(315 12 12)"
                >
                  <animate
                    attributeName="r"
                    begin="0.875s"
                    calcMode="spline"
                    dur="1s"
                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                    repeatCount="indefinite"
                    values="0;2;0;0"
                  />
                </circle>
              </svg>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MiddelPanel;
