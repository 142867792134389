import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const HeaderNav = ({ navBack, page }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [offStatus, setOffStatus] = useState(false);

  useEffect(() => {
    if (searchParams.get("offers") === "off_50") {
      setOffStatus(true);
    }
  }, [searchParams]);

  return (
    <div className="co-top-wrapper">
      <div className="container_ w-container">
        <div className="checkout-title-wrapper">
          <div
            className="co-heading-link block1"
            style={{ display: page === 1 ? "block" : "none" }}
          >
            <h1
              id="__heading_link"
              className="color--gray-900 text-dark mob-align-left"
            >
              <span onClick={navBack}>
                <BackIcon />
              </span>{" "}
              Select your service
            </h1>
          </div>
          <div
            className="co-heading-link block2"
            style={{ display: page === 0 ? "block" : "none" }}
          >
            <h1
              id="__heading_link"
              className="color--gray-900 text-dark mob-align-left"
            >
              {offStatus ? "Create account" : "Create account"}
            </h1>
          </div>
          <div
            className="co-heading-link block3"
            style={{ display: page === 2 ? "block" : "none" }}
          >
            <h1
              id="__heading_link"
              className="color--gray-900 text-dark mob-align-left"
            >
              <span
                onClick={() => {
                  searchParams.delete("page");
                  navigate(-1);
                }}
              >
                <BackIcon />
              </span>{" "}
              Payment
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderNav;

const BackIcon = () => {
  return (
    <svg
      className="arr2"
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="0 0 1024 1024"
    >
      <path
        fill="currentColor"
        d="M685.248 104.704a64 64 0 0 1 0 90.496L368.448 512l316.8 316.8a64 64 0 0 1-90.496 90.496L232.704 557.248a64 64 0 0 1 0-90.496l362.048-362.048a64 64 0 0 1 90.496 0z"
      />
    </svg>
  );
};
