import * as Yup from "yup";

import { axios } from "@atd/utils";

function checkIfExists(message, key, endpoint) {
  return this.test(
    "checkIfExists",
    message,
    (value) =>
      new Promise((resolve) => {
        axios.get(endpoint).then((response) => {
          const isExists = response.data?.results.some(
            (data) => data[key] === value,
          );
          if (isExists) {
            resolve(false);
          }
          resolve(true);
        });
      }),
  );
}

Yup.addMethod(Yup.string, "checkIfExists", checkIfExists);
