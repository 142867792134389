import { Link } from "react-router-dom";
import Badge from "../Badge/Badge";
import { Box } from "@atd/components";
function Heading({
  parentText,
  parentLink,
  headText,
  rightContent,
  withBreadcrumbs = false,
  showBorder = false,
  headCount = 0,
  headerClassName,
  className,
  ...rest
}) {
  return (
    <>
      <div
        className={`Box-root Padding-top--16 Padding-bottom--8 Flex-flex Flex-wrap--wrap Flex-alignItems--center Flex-justifyContent--spaceBetween ${className}`}
        {...rest}
      >
        <span className="Text-color--dark Text-fontSize--28 Text-fontWeight--bold Text-lineHeight--32 Text-numericSpacing--proportional Text-typeface--base Text-wrap--wrap Text-display--inline">
          {withBreadcrumbs ? (
            <>
              <span className="atitle">
                <Link to={parentLink}>{parentText}</Link>
              </span>
              <span
                aria-hidden="true"
                className="SVGInline SVGInline--cleaned SVG Icon Icon--arrowRight Button-icon Icon-color Icon-color--gray600"
                style={{ transform: "translateY(1.1px)", marginLeft: 5 }}
              >
                <svg
                  aria-hidden="true"
                  className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--arrowRight-svg Button-icon-svg Icon-color-svg Icon-color--gray600-svg"
                  height={12}
                  width={12}
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.583 7L7.992 2.409A1 1 0 1 1 9.407.993l6.3 6.3a1 1 0 0 1 0 1.414l-6.3 6.3a1 1 0 0 1-1.415-1.416L12.583 9H1a1 1 0 1 1 0-2z"
                    fillRule="evenodd"
                  />
                </svg>
              </span>
              <span className="cont2 va2">{headText}</span>
            </>
          ) : (
            <Box flex={{ direction: "row", alignItems: "center" }}>
              <span className={headerClassName}>{headText}</span> &nbsp;
              {headCount ? <Badge>{headCount}</Badge> : null}
            </Box>
          )}
        </span>
        {rightContent}
      </div>
      {showBorder && <hr />}
    </>
  );
}

export default Heading;
