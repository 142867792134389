import { useState, useCallback } from "react";
import { toast } from "react-toastify";

import {
  useAddPriceMutation,
  useGetBillingQuery,
  useGetPriceQuery,
  useGetSubscriptionQuery,
} from "@atd/features/user";
import {
  Box,
  AppHeader,
  Typography,
  Alert,
  ContentState,
  Button,
} from "@atd/components";
import { axios, getCredentialCookies } from "@atd/utils";

import CurrentPlan from "./CurrentPlan";
import AddNewPlan from "./AddNewPlan";
import Summary from "./Summary";
import SubscriptionHelpModal from "./SubscriptionHelpModal";

import PlanContext from "./PlanContext";

import "./Plans.css";

function Plans() {
  const { user_id } = getCredentialCookies();

  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [summaries, setSummaries] = useState([]);
  // const [planId, setPlanId] = useState(null);
  const [designers, setDesigners] = useState(1);
  const [numOfMonths, setNumOfMonths] = useState("1");
  const [promoCode, setPromoCode] = useState("");
  const [promoData, setPromoData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [showMessage, setShowMessage] = useState(false);
  const [isResumed, setIsResumed] = useState(false);
  const [isResuming, setIsResuming] = useState(false);

  const onSelectPlan = useCallback((value) => {
    setSelectedPlan(value);
  }, []);
  const onChangePlan = useCallback((value) => setNumOfMonths(value), []);
  const onChangePromo = useCallback(({ target: { value } }) => {
    setPromoCode(value);
  }, []);

  const { data: billing, isLoading } = useGetBillingQuery();
  const { data: prices } = useGetPriceQuery();
  const [addPrice, result] = useAddPriceMutation();
  const { data: subscription } = useGetSubscriptionQuery();

  const { plans } = billing || {};
  const { offer_value, offer_type, code } = promoData || {};

  const selectedPlanSummary = selectedPlan
    ? selectedPlan?.plans.find(
        (plan) => plan.number_of_months.toString() === numOfMonths,
      )
    : null;

  const onAddPromo = useCallback(
    async (callback) => {
      if (summaries.length === 0 || !promoCode) {
        return;
      }
      const planIds =
        summaries.length > 0 ? summaries.map((s) => s.id).join(",") : "";
      try {
        const { data } = await axios.get("/promo", {
          params: {
            code: promoCode ?? "",
            plan: planIds,
            dpu: designers,
          },
        });
        if (data?.status === "error") {
          throw Error(data?.message);
        }
        setPromoData(data?.results);
        callback?.(data?.results);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [designers, summaries, promoCode],
  );

  const onAddSummary = useCallback(() => {
    if (!numOfMonths) {
      alert("No Billing Period Selected! Please choose one.");
      return;
    }
    const currentSummaries = [...summaries];
    const findSummaryIndex = currentSummaries.findIndex(
      (sum) => sum.id === selectedPlanSummary.id,
    );
    const existingPlanItem = summaries[findSummaryIndex];

    if (existingPlanItem) {
      const updatedSummaries = summaries.map((summary) => ({
        ...summary,
        designers:
          selectedPlanSummary.id === summary.id
            ? Number(summary.designers) + Number(designers)
            : Number(summary.designers),
      }));
      setSummaries(updatedSummaries);
      setShowMessage(false);
      localStorage.setItem(
        `summaries_${user_id}`,
        JSON.stringify(updatedSummaries),
      );
    } else {
      const currentPlans = [...summaries];
      const isNotExactPeriod = currentPlans.every(
        (item) => item.period === selectedPlanSummary.period,
      );
      if (!isNotExactPeriod) {
        setShowMessage(true);
        return;
      }
      setShowMessage(false);
      const updatedItems = [
        ...currentPlans,
        {
          ...selectedPlanSummary,
          designers: Number(designers),
        },
      ];
      setSummaries(updatedItems);
      localStorage.setItem(
        `summaries_${user_id}`,
        JSON.stringify(updatedItems),
      );
    }
    onAddPromo();
  }, [
    designers,
    numOfMonths,
    user_id,
    summaries,
    selectedPlanSummary,
    onAddPromo,
  ]);

  // eslint-disable-next-line no-unused-vars
  const onRemoveItemFromSummary = useCallback(
    (id) => {
      onAddPromo();
      const updatedSummaries = summaries.filter((summary) => summary.id !== id);
      setSummaries(updatedSummaries);
      localStorage.setItem(
        `summaries_${user_id}`,
        JSON.stringify(updatedSummaries),
      );
    },
    [user_id, summaries, onAddPromo],
  );

  const onApplyPromo = useCallback(() => {
    onAddPromo(({ description }) => {
      toast.success(description);
    });
  }, [onAddPromo]);

  const onProceedCheckout = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const { data } = await addPrice({
          plans: summaries.map((plan) => ({
            id: plan.id,
            quantity: plan.designers,
            si_id: plan?.si_id,
            sub_id: plan?.sub_id,
          })),
          coupon: code ?? null,
        });
        if (data?.status === "error") {
          throw Error(data?.message);
        }
        localStorage.removeItem(`summaries_${user_id}`);
        toast.success(data?.message);
        setSummaries([]);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [addPrice, summaries, code, user_id],
  );

  const onResumePlan = async () => {
    setIsResumed(false);
    setIsResuming(true);
    const { data } = await axios.get("/resume");
    if (data.status === "success") {
      setIsResumed(true);
      setIsResuming(false);
    }
  };

  return (
    <PlanContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        offerType: offer_type,
        offerValue: offer_value,
        prices: prices || [],
        designers: Number(designers),
        isProcessing: result?.isLoading,
        promo: promoCode,
        selectedPlan: selectedPlanSummary,
        setSummaries,
        numOfMonths,
        onChangePlan,
        onSelectPlan,
        onAddSummary,
        onChangePromo,
        onProceedCheckout,
        onApplyPromo,
        onChangeDesigners: setDesigners,
        onOpenSubscriptionModal: () => setOpenSubscriptionModal(true),
      }}
    >
      <SubscriptionHelpModal
        show={openSubscriptionModal}
        onClose={setOpenSubscriptionModal}
      />
      <AppHeader
        title={<Typography>Plans</Typography>}
        spread="settingsContent"
        marginBottom={false}
      />
      {subscription?.code === 999 && (
        <>
          <Alert
            status="error"
            title="Payment Paused"
            icon="warning"
            withIcon
            description={<span>{subscription?.message}</span>}
            action={
              !isResumed && (
                <Button
                  label="Resume"
                  variant="primary"
                  disabled={isResuming}
                  onClick={onResumePlan}
                />
              )
            }
          />
          <hr />
        </>
      )}
      <Box id="acc" flex={{ direction: "row" }} style={{ marginTop: "-8px" }}>
        <Box
          padding={{ left: 0, vertical: 20, right: 20 }}
          divider={{ color: "light", side: "right", width: 1 }}
          style={{ flex: "2 1 0%", height: "100vh", width: "67%" }}
        >
          {isLoading && (
            <Box>
              <ContentState text="Loading..." />
            </Box>
          )}
          {!isLoading && (
            <Box>
              <Box flex={{ direction: "row" }}>
                <Box padding={{ right: 20 }} style={{ width: "100%" }}>
                  {subscription?.code === 404 && (
                    <>
                      <div className="title3 mgt4 mgb2">Current Plan </div>
                      <Alert
                        description={
                          <span>
                            It looks like you have an inactive subscription.
                            Choose a plan from the list below and kick start
                            your journey to creative bliss.
                          </span>
                        }
                      />
                    </>
                  )}
                  {subscription?.code !== 404 && (
                    <CurrentPlan
                      subscription={subscription}
                      plans={plans || []}
                      isLoading={isLoading}
                    />
                  )}
                  <AddNewPlan
                    prices={prices || []}
                    selectedPlan={selectedPlan}
                    onSelectPlan={onSelectPlan}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Summary
          summaries={summaries}
          showMessage={showMessage}
          onRemove={onRemoveItemFromSummary}
          onSubmit={onProceedCheckout}
        />
      </Box>
    </PlanContext.Provider>
  );
}

export default Plans;
