import classnames from "classnames";
import styled from "styled-components";

const Title = styled.span`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
  color: #1a1f36;
`;

function AccordionTitle({ title }) {
  return (
    <header>
      <Title>{title}</Title>
    </header>
  );
}

function AccordionContent({ children }) {
  return <section className="content">{children}</section>;
}

function AccordionItem({ title, className, children }) {
  return (
    <li className={classnames("open", className)}>
      <AccordionTitle title={title} />
      <AccordionContent>{children}</AccordionContent>
    </li>
  );
}

export default AccordionItem;
