import * as React from "react";

function LeftArrow({ width, height }) {
  return (
    <svg
      aria-hidden="true"
      className="tmrot1 SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--arrowExport-svg Button-icon-svg Icon-color-svg Icon-color--gray600-svg"
      width={width || 12}
      height={height || 12}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 10.006a1 1 0 1 1-2 0v-5.6L2.393 15.009a.992.992 0 1 1-1.403-1.404L11.595 3.002h-5.6a1 1 0 0 1 0-2.001h8.02a1 1 0 0 1 .284.045.99.99 0 0 1 .701.951z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default LeftArrow;
