import React from "react";
import { Box } from "@atd/components";
import TicketReportChart from "./TicketReportChart";
import TicketReportDataTable from "./TicketReportDataTable";
import TicketReportHeader from "./TicketReportHeader";
import MoreReport from "../MoreReport";
import Border from "@atd/pages/Reports/Border";
function TicketReport({
  title,
  borderPosition,
  dateFilterOptions,
  report,
  columns,
  params,
  onChangeFilter,
}) {
  const { data, isLoading } = report;
  const getFilter = (field, value) => {
    onChangeFilter({ id: title.split("_")[0] }, field, value);
  };
  return (
    <Box
      flex={{ direction: "column", wrap: "wrap" }}
      // divider={{ side: "right", width: 1, color: "light" }}
      className="wt-50"
      style={{
        [borderPosition]: "1px solid #e3e8ee",
        height: "auto",
      }}
    >
      <TicketReportHeader
        title={title}
        dateFilterOptions={dateFilterOptions}
        params={params[title.split("_")[0]]}
        onChangeFilter={getFilter}
      />
      <TicketReportChart
        id={title}
        width="100%"
        data={data?.chart || []}
        isLoading={isLoading}
      />
      <TicketReportDataTable
        isLoading={isLoading}
        columns={columns}
        data={data?.table}
      />

      <MoreReport name="More analytics" path={title.split(" ")[0]} />
      <Border
        border={{ position: "bottom" }}
        margin={{ mb: "0px", mt: "0px" }}
      />
    </Box>
  );
}

export default TicketReport;
