import React, { useState, useCallback } from "react";
import { Box, Button, Table } from "@atd/components";
import TicketReportChart from "../TeamReport/TicketReport/TicketReportChart";
import TicketReportHeader from "../TeamReport/TicketReport/TicketReportHeader";
import CollapsibleReport from "./CollapsibleReport";
import ReportHeader from "../AnalyticalReport/ReportHeader";
import Border from "../../Border";
import { useGetTeamTotalTicketReportQuery } from "@atd/features/team/teamApi";
function TotalTicketReport({
  params,
  designerFilterOptions,
  dateFilterOptions,
  onChangeFilter,
}) {
  const totalTicketReport = useGetTeamTotalTicketReportQuery(params);
  const table = totalTicketReport?.data?.table;
  const collapsible = table?.map((val) => false);
  const [collapse, setCollapse] = useState(collapsible || [false]);
  const toggle = useCallback(
    (index) => {
      let collapseFilter = collapse;
      collapseFilter[index] = !collapseFilter[index];

      setCollapse([]);
      setCollapse((arr) => [...arr, ...collapseFilter]);
    },
    [collapse],
  );
  const viewAllEntity = () => {
    const entities = collapsible?.map((val) => true);
    setCollapse(entities);
  };
  const headers = totalTicketReport?.data?.headers;

  return (
    <Box>
      <TicketReportHeader
        title="Total Tickets Reports"
        params={params}
        placeholder="All Designers"
        groupByField="designer"
        onChangeFilter={onChangeFilter}
        groupByFilterOptions={designerFilterOptions}
        dateFilterOptions={dateFilterOptions}
      />
      <TicketReportChart
        data={totalTicketReport?.data?.chart}
        width="100%"
        height={300}
        isLoading={totalTicketReport?.isLoading}
      />
      <Box
        flex={{
          direction: "row",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          variant="default"
          label="View all"
          onClick={() => {
            viewAllEntity();
          }}
        />
      </Box>
      <div style={{ overflowX: "scroll" }}>
        <Table className="wt-100" style={{ minWidth: "1340px" }}>
          <Table.Head>
            <Border
              border={{ position: "bottom" }}
              margin={{ mt: "20px", mb: "0px" }}
            />
            {headers && (
              <Box className="Margin-left--24">
                <ReportHeader
                  headers={headers}
                  isKey
                  isHeader
                  background="white"
                />
              </Box>
            )}
            <Border
              border={{ position: "bottom" }}
              margin={{ mt: "0px", mb: "0px" }}
            />
          </Table.Head>
          <Table.Body>
            {table &&
              table?.map((value, i) => (
                <CollapsibleReport
                  key={i}
                  data={value}
                  headers={headers}
                  toggle={() => toggle(i)}
                  collapse={collapse[i]}
                  firstKey="name"
                  lastKey="total"
                />
              ))}
          </Table.Body>
        </Table>
      </div>
    </Box>
  );
}

export default TotalTicketReport;
