import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import TemplateApi from "./templateApi";

const initialState = {
  templates: [],
  template: null,
  loading: true,
  error: null,
};

export const fetchTemplates = createAsyncThunk(
  "template/fetchTemplates",
  async () => TemplateApi.getAllTemplates(),
);

export const fetchTemplate = createAsyncThunk(
  "template/fetchTemplate",
  async (id) => TemplateApi.getTemplate(id),
);

export const createTemplate = createAsyncThunk(
  "template/createTemplate",
  async (payload) => TemplateApi.addNew(payload),
);

export const updateTemplate = createAsyncThunk(
  "template/updateTemplate",
  async (args) => TemplateApi.update(args.id, args.payload),
);

export const deleteTemplate = createAsyncThunk(
  "template/deleteTemplate",
  async (id) => TemplateApi.deleteTemplate(id),
);

export const archiveTemplate = createAsyncThunk(
  "template/archiveTemplate",
  async (args) => TemplateApi.archive(args.id, args.status),
);

const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTemplates.pending]: (state) => {
      state.template = null;
      state.loading = true;
      state.error = null;
    },
    [fetchTemplates.fulfilled]: (state, action) => {
      state.loading = false;
      state.templates = action.payload;
    },
    [fetchTemplates.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [fetchTemplate.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      state.template = action.payload;
    },
    [fetchTemplate.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [createTemplate.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.template = null;
    },
    [createTemplate.fulfilled]: (state, action) => {
      state.loading = false;
      // state.templates = [...state.templates, action.payload];
      state.template = action.payload;
    },
    [createTemplate.rejected]: (state, action) => {
      state.error = action.error.message;
      state.template = null;
    },
    [deleteTemplate.pending]: (state) => {
      state.error = null;
    },
    [deleteTemplate.fulfilled]: (state, action) => {
      state.templates = state.templates.filter(
        (template) => template.id !== action.payload,
      );
    },
    [deleteTemplate.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [updateTemplate.pending]: (state) => {
      state.error = null;
    },
    [updateTemplate.fulfilled]: (state, action) => {
      state.templates = state.templates.map((template) => ({
        ...template,
        ...(template.id === action.payload.id ? action.payload : template),
      }));
    },
    [updateTemplate.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [archiveTemplate.pending]: (state) => {
      state.error = null;
    },
    [archiveTemplate.fulfilled]: (state, action) => {
      state.templates = state.templates.map((template) => ({
        ...template,
        ...(template.id === action.payload.id
          ? { is_archived: action.payload.is_archived }
          : template),
      }));
    },
    [archiveTemplate.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export default templateSlice.reducer;
