import React, { useState, useEffect, useCallback } from "react";
import useInfiniteScroll from "@closeio/use-infinite-scroll";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { resetActivities } from "@atd/features";
import { Main } from "@atd/layouts";
import { AppHeader } from "@atd/components";

import Activities from "./Activities";

function ActivityLog() {
  const [queryParams, setQueryParams] = useState({});
  const [isResetData, setIsResetData] = useState(false);
  const dispatch = useDispatch();
  const { hasMore } = useSelector((state) => state.activity);
  const [page, loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore,
    reset: isResetData,
  });
  const params = useParams();
  const { query } = params || {};

  const onResetData = useCallback(() => {
    setIsResetData(true);
    dispatch(resetActivities());
  }, [dispatch]);
  useEffect(
    () => () => {
      if (query) {
        onResetData();
      }
      return () => {};
    },
    [onResetData, query],
  );
  useEffect(
    () => () => {
      dispatch(resetActivities());
    },
    [dispatch],
  );
  const onChangeFilter = (field, value) => {
    onResetData();
    if (field === "group_by") {
      setQueryParams((prevParams) => ({
        ...prevParams,
        select: "",
      }));
    }
    setQueryParams((prevParams) => ({
      ...prevParams,
      [field]: value,
    }));
  };
  const clearAllFilters = () => {
    onResetData();
    setQueryParams({});
  };
  return (
    <Main ref={scrollerRef} onResetData={onResetData}>
      <AppHeader
        title="Activity Log"
        spread="appContent"
        marginBottom={false}
      />
      <Activities
        page={page}
        loaderRef={loaderRef}
        onResetData={onResetData}
        setIsResetData={setIsResetData}
        clear={clearAllFilters}
        params={queryParams}
        onChangeFilter={onChangeFilter}
      />
    </Main>
  );
}

export default ActivityLog;
