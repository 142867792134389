import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import RequestService from "../request/requestApi";

export const createNote = createAsyncThunk(
  "notes/createNote",
  async (payload) => RequestService.addNote(payload.id, payload.data),
);

export const noteSlice = createSlice({
  name: "notes",
  initialState: {
    note: null,
    success: false,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [createNote.pending]: (state) => {
      state.creating = true;
      state.success = false;
      state.error = null;
    },
    [createNote.fulfilled]: (state, action) => {
      state.creating = false;
      state.success = true;
      state.error = null;
      state.note = action.payload;
    },
    [createNote.rejected]: (state, action) => {
      state.success = false;
      state.creating = false;
      state.error = action.error.message;
    },
  },
});

export default noteSlice.reducer;
