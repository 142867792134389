import { useEffect, useRef } from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  color: #697386;
  white-space: normal;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  line-height: 16px;
  font-weight: 400;
  font-size: 12px;

  input {
    color: rgb(60, 66, 87);
    text-align: left;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
    cursor: text;
    padding: 8px 12px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
      rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
      rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
      rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    border: 0;
    z-index: 99;
    height: 26px;
    margin-left: 5px;
  }
`;

function FilterString({ label = "is equal to", isVisible, ...props }) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (isVisible) {
      inputRef.current?.focus();
    }
  }, [isVisible]);

  return (
    <InputContainer>
      {label} <input ref={inputRef} type="text" className="iput1" {...props} />
    </InputContainer>
  );
}

export default FilterString;
