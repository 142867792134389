import * as React from "react";

function SvgBad(props) {
  return (
    <svg viewBox="0 0 50 50" {...props}>
      <path
        d="M50 25c0 13.807-11.193 25-25 25S0 38.807 0 25 11.193 0 25 0s25 11.193 25 25"
        className="bad_svg__base"
      />
      <path
        d="M25 32c-3.27 0-6.303.57-9.665 1.754a2 2 0 1 0 1.33 3.773C19.618 36.487 22.207 36 25 36c3.104 0 6.52.592 8.065 1.409a2 2 0 1 0 1.87-3.537C32.707 32.695 28.697 32 25 32Z"
        className="bad_svg__mouth"
        fill="#FFF"
      />
      <path
        d="M30.701 16.815a3.99 3.99 0 0 1 3.544-1.81c.636.78.7.855 1.286 1.589.586.734.613.76 1.068 1.315.455.555.681.806 1.401 1.74a4 4 0 1 1-7.299-2.835Z"
        className="bad_svg__right-eye"
        fill="#FFF"
      />
      <path
        d="M18.828 21.828a4.001 4.001 0 0 0-3.02-6.823c-.636.78-.7.855-1.286 1.589-.586.734-.613.76-1.068 1.315-.455.555-.681.806-1.401 1.74a4 4 0 0 0 6.776 2.179Z"
        className="bad_svg__left-eye"
        fill="#FFF"
      />
    </svg>
  );
}

export default SvgBad;
