import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { getCredentialCookies } from "../../utils/tokenUtilities";

const { token } = getCredentialCookies();

export const activityApi = createApi({
  reducerPath: "activityApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["Activities"],
  endpoints: (build) => ({
    getActivityFilters: build.query({
      query: () => ({
        url: "activities_filters",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response) => {
        return response.results;
      },
    }),
  }),
});

export const { useGetActivityFiltersQuery } = activityApi;
