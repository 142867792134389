import React, { useMemo } from "react";
import { Box, Charts } from "@atd/components";
import styled from "styled-components";

const ReportChartBox = styled(Box)`
  /* @media screen and (max-width: 1000px) and (max-width: 1420px) {
    max-width: 300px !important;
  }
  @media screen and (min-width: 500px) {
    width: 480 !important;
  } */
`;
function TicketReportChart({
  id,
  data,
  width = "500",
  height = "200",
  isLoading,
}) {
  const chartData = useMemo(() => {
    const chart = data?.length
      ? {
          categories: data?.map((item) => item?.entity),
          series: data?.map((item) => item?.count),
        }
      : {};
    return chart;
  }, [data]);
  return (
    <ReportChartBox
      flex={{
        direction: "column",
        justifyContent: "flex-start",
      }}
      className="Margin-horizontal--20"
    >
      <Charts
        id={id}
        type="line"
        width={width}
        height={height}
        chartData={chartData || []}
        isLoading={isLoading}
      />
    </ReportChartBox>
  );
}

export default TicketReportChart;
