import { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getRequest, getRequestThreads, setRequestTimer } from "@atd/features";

import { getFormattedTime } from "@atd/utils";
import TimerModal from "./TimerModal";
import TimeButton from "./TimeButton";
import { TimerStyle } from "./Timer.style";

export function useRequestTime(a, b) {
  return [Math.floor(a / b), a % b];
}

function Timer({ status = "pause", value = 0, ticketStatus }) {
  const timerRef = useRef(null);
  const params = useParams();

  const [time, setTime] = useState(value);
  const [isPlay, setIsPlay] = useState(false);
  const [showTimerModal, setShowTimerModal] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const [hmins, secs] = useRequestTime(time, 60);
  const [hrs, mins] = useRequestTime(hmins, 60);

  const { processing } = useSelector((state) => state.requests);
  const dispatch = useDispatch();

  const { id: requestId } = params || {};

  const tick = () => {
    setTime((currentTime) => currentTime + 1);
  };

  useEffect(() => {
    setTime(value);
  }, [value]);

  useEffect(() => {
    if (status === "complete") {
      setIsCompleted(true);
    } else if (["start", "play"].includes(status)) {
      timerRef.current = setInterval(tick, 1000);
      setIsPlay(true);
      setIsCompleted(false);
    } else if (status === "pause") {
      setIsCompleted(false);
      setIsPlay(false);
    }
    return () => {
      clearInterval(timerRef.current);
    };
  }, [status]);

  const onClickStartTimer = useCallback(async () => {
    setIsCompleted(false);
    const response = await dispatch(
      setRequestTimer({
        id: requestId,
        payload: {
          timer_status: "play",
        },
      }),
    ).unwrap();
    if (response.status === "success") {
      setIsPlay(true);
      timerRef.current = setInterval(tick, 1000);
      await dispatch(getRequest(requestId));
      await dispatch(
        getRequestThreads({
          id: requestId,
          params: { tab: "details" },
          isUpdate: true,
        }),
      );
    }
  }, [dispatch, requestId]);

  const onClickStopTimer = useCallback(
    async (timerStatusValue) => {
      if (timerStatusValue === "pause") {
        setIsPlay(false);
      }
      if (timerStatusValue === "complete") {
        setIsCompleted(false);
      }
      setShowTimerModal(true);
      setShowTimerModal(false);
      clearInterval(timerRef.current);
      await dispatch(
        setRequestTimer({
          id: requestId,
          payload: {
            timer_status: timerStatusValue,
          },
        }),
      );
      await dispatch(getRequest(requestId));
      await dispatch(
        getRequestThreads({
          id: requestId,
          params: { tab: "details" },
          isUpdate: true,
        }),
      );
    },
    [dispatch, requestId],
  );

  return (
    <>
      <TimerModal
        isOpen={showTimerModal}
        onClose={setShowTimerModal}
        onPause={() => onClickStopTimer("pause")}
        onComplete={() => onClickStopTimer("complete")}
      />
      <TimerStyle className="timer-wrapper center wt-100">
        <h1 id="timer" className="timer">
          {getFormattedTime({ hrs, mins, secs })}
        </h1>
        {isCompleted && ![1, 5, 6].includes(ticketStatus) ? (
          <TimeButton isLoading={processing} icon="complete" />
        ) : (
          <>
            {!isPlay && (
              <TimeButton
                icon="play"
                isLoading={processing}
                onClick={onClickStartTimer}
              />
            )}
            {isPlay && (
              <TimeButton
                icon="pause"
                isLoading={processing}
                onClick={() => setShowTimerModal(true)}
              />
            )}
          </>
        )}
      </TimerStyle>
    </>
  );
}

export default Timer;
