import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import Box from "../Box/Box";
import DatePicker from "../DatePicker/DatePicker";

function DatePickerContentBlock({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) {
  return (
    <Box background="offset" className="ContentBlock">
      <Box padding={{ all: 16 }}>
        <DatePicker
          autoFocus
          startDate={startDate}
          endDate={endDate}
          onDatesChange={(value) => {
            setStartDate(value.startDate);
            setEndDate(value.endDate);
          }}
        />
      </Box>
    </Box>
  );
}

export default DatePickerContentBlock;
