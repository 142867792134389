import * as React from "react";

function SvgPauseColored(props) {
  return (
    <svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 .625C9.297.625.625 9.297.625 20S9.297 39.375 20 39.375 39.375 30.703 39.375 20 30.703.625 20 .625Zm7.5 25.625c0 .688-.563 1.25-1.25 1.25h-12.5c-.688 0-1.25-.563-1.25-1.25v-12.5c0-.688.563-1.25 1.25-1.25h12.5c.688 0 1.25.563 1.25 1.25v12.5Z"
        fill="#D88A69"
      />
    </svg>
  );
}

export default SvgPauseColored;
