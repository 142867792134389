import { uniqueId } from "lodash";

import CheckBox from "../CheckBox/CheckBox";
import Menu from "../Menu/Menu";

function SelectOption({
  index,
  multiple,
  value,
  children,
  isSelected,
  onChange,
  ...rest
}) {
  const fieldId = uniqueId("atd-chk-");
  return (
    <Menu.Item
      active={isSelected}
      icon={!multiple && isSelected ? "check" : null}
      text={{ weight: "medium" }}
      onClick={!multiple ? onChange : null}
      itemProps={{
        padding: {
          left: !isSelected || (isSelected && multiple) ? 20 : 0,
        },
      }}
      {...rest}
    >
      {multiple && (
        <CheckBox
          id={fieldId}
          containerClassName="fil-chk"
          checked={isSelected}
          onChange={onChange}
        />
      )}
      {multiple && <label htmlFor={fieldId}>{children}</label>}
      {!multiple && children}
    </Menu.Item>
  );
}

export default SelectOption;
