import * as React from "react";

function SvgOkay(props) {
  return (
    <svg viewBox="0 0 50 50" {...props}>
      <path
        d="M50 25c0 13.807-11.193 25-25 25S0 38.807 0 25 11.193 0 25 0s25 11.193 25 25"
        className="okay_svg__base"
      />
      <path
        d="M25.017 33h-7.539c-1.078.225-1.756 1.294-1.514 2.272.24 1.078 1.31 1.728 1.888 1.728h15.532c1.093-.23 1.852-1.245 1.694-2.338-.157-1.093-1.18-1.662-1.764-1.662H17.478Z"
        className="okay_svg__mouth"
        fill="#FFF"
      />
      <path
        d="M30.649 16.815a3.99 3.99 0 0 1 3.544-1.81 3.985 3.985 0 0 1 2.635 1.167 3.995 3.995 0 0 1 0 5.656 4 4 0 0 1-6.18-5.013Z"
        className="okay_svg__right-eye"
        fill="#FFF"
      />
      <path
        d="M18.828 21.828a4.001 4.001 0 0 0-5.324-5.954 3.995 3.995 0 0 0-.332 5.954 4 4 0 0 0 5.656 0Z"
        className="okay_svg__left-eye"
        fill="#FFF"
      />
    </svg>
  );
}

export default SvgOkay;
