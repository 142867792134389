import React from "react";
import classnames from "classnames";

import Typography from "../Typography/Typography";

import styles from "./AppHeader.module.css";

const AppHeader = (props) => {
  const {
    title,
    subTitle,
    marginBottom = true,
    spread,
    tabs,
    children,
    id = "AppHeader_title",
    fullScreen,
    sticky,
    hasTabs,
    renderAboveTitle,
  } = props;

  const titleClasses = classnames(styles.header, {
    [styles.marginBottom]: marginBottom,
    [styles.spreadForApp]: spread === "appContent",
    [styles.spreadForSettings]: spread === "settingsContent",
    [styles.fullScreen]: fullScreen,
    [styles.sticky]: sticky,
    [styles.hasTabs]: !!tabs || hasTabs,
  });

  return (
    <div className={titleClasses}>
      {renderAboveTitle?.()}
      <div className={styles.inner}>
        <div className={styles.title}>
          <Typography id={id}>{title}</Typography>
          <span className={styles.titleSub} data-testid="app-subtitle">
            {subTitle}
          </span>
        </div>
        {tabs && <div className={styles.tabsWrap}>{tabs}</div>}
        <div className={styles.actions}>{children}</div>
      </div>
    </div>
  );
};

export default AppHeader;
