import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { DataTable } from "@atd/components";
import { CreditCard, File } from "@atd/components/Icon/icons";

import ClientAssignTo from "./ClientAssignTo";
import styled from "styled-components";

const DataTableStyle = styled(DataTable)`
  /* th {
    box-shadow: rgb(227 232 238) 0px -1px 0px 0px inset !important;
  } */

  td,
  th {
    padding: 6px 10px 6px 7px;
  }
`;

function ClientsTable({
  data,
  role,
  users,
  fetching,
  error,
  counts,
  tabList,
  hasError,
  hasPermission,
  onClickTabItem,
}) {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: "COMPANY",
        accessor: "company",
        Cell: ({ value }) => <span className="title2 cli">{value}</span>,
        onClick: (id) => {
          if (role === 1) {
            navigate(id);
          }
        },
      },
      {
        Header: "PLAN NAME",
        accessor: "planName",
        onClick: (id) => {
          if (role === 1) {
            navigate(id);
          }
        },
      },
      {
        Header: "ASSIGNED TO",
        accessor: "assignedTo",
        Cell: ({ value }) => <ClientAssignTo value={value} users={users} />,
      },
      {
        Header: "USERS",
        accessor: "users",
      },
      {
        Header: "BILLING",
        accessor: "billing",
        Cell: ({ value }) => {
          return (
            <>
              {value.showInvoice && <File />}{" "}
              {value.showcardUpdate && <CreditCard />}
            </>
          );
        },
      },
      {
        Header: "CREATED",
        accessor: "createdAt",
      },
      {
        Header: "LAST LOGIN",
        accessor: "lastActive",
      },
    ],
    [users, navigate, role],
  );

  const clientsData = useMemo(() => {
    if (!data) return [];

    return data.map((client) => ({
      id: client?.id,
      company: client?.company,
      planName: client?.plan_type,
      users: client?.users_count,
      assignedTo: {
        id: client?.id,
        isActive: client?.is_active,
        assignedTo: client?.assigned_to,
      },
      billing: {
        showInvoice: client?.show_invoice,
        showcardUpdate: client?.show_card_update,
      },
      createdAt: moment(client?.created).format("llll"),
      lastActive: moment(client?.last_active).format("llll"),
    }));
  }, [data]);

  const filteredColumns = columns.filter((col) => {
    if (role === 2) {
      return ["company", "planName", "assignedTo"].includes(col.accessor);
    }
    return col;
  });

  return (
    <DataTableStyle
      tabs={tabList}
      className="table-bordered"
      withinHeader={true}
      tabCounts={counts}
      withTabs={hasPermission}
      withTabCounts={true}
      isLoading={false}
      columns={filteredColumns}
      data={clientsData}
      // onClick={(id) => navigate(id)}
      onClickTabItem={onClickTabItem}
    />
  );
}

export default ClientsTable;
