/* eslint-disable react/no-danger */
import { useState } from "react";
import styled from "styled-components";

import ConfirmPlanSelectionModal from "../../pages/Settings/Plans/ConfirmPlanSelectionModal";

const AnnouncementContainer = styled.div`
  background-color: #fbefec;
  padding: 1rem 2rem 1.8rem 2rem;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid #e3e8ee;
  border-radius: 4px;

  h1 {
    font-weight: bold;
    display: inline;
    margin: 0;
    line-height: 40px;
  }
`;

const ButtonClose = styled.svg`
  position: absolute;
  right: 15px;
  top: 15px;
  fill: #666;
  cursor: pointer;
`;

function Announcement({ id, title, message }) {
  const [isShowAnnouncement, setIsShowAnnouncement] = useState(true);
  const [showUpdatePlanModal, setShowUpdatePlanModal] = useState(false);
  if (localStorage.getItem(id) === "hidden" || (!title && !message)) {
    return null;
  }

  if (!isShowAnnouncement) {
    return null;
  }
  const announcement = message?.split(/(?=<p.*?<\/p>)/);
  return (
    <AnnouncementContainer>
      <ButtonClose
        role="button"
        className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--cancel-svg Icon-color-svg Icon-color--gray600-svg"
        height={12}
        width={12}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => {
          localStorage.setItem(id, "hidden");
          setIsShowAnnouncement(false);
        }}
      >
        <path
          d="M8 6.585l4.593-4.592a1 1 0 0 1 1.415 1.416L9.417 8l4.591 4.591a1 1 0 0 1-1.415 1.416L8 9.415l-4.592 4.592a1 1 0 0 1-1.416-1.416L6.584 8l-4.59-4.591a1 1 0 1 1 1.415-1.416z"
          fillRule="evenodd"
        />
      </ButtonClose>
      <h1>
        {title}!{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 512 512"
        >
          <path
            fill="#FFD469"
            d="m391.765 115.993l75.102-74.385c3.189-3.158 8.463-2.639 10.975 1.08l17.325 25.649c2.386 3.533 1.177 8.355-2.594 10.344l-92.427 48.736a7.162 7.162 0 0 1-9.275-2.326a7.16 7.16 0 0 1 .894-9.098zm12.42 63.879a5.697 5.697 0 0 0 6.113 4.531l82.782-7.728a5.698 5.698 0 0 0 5.053-6.817l-4.939-24.129c-.716-3.498-4.436-5.484-7.741-4.131l-77.843 31.856a5.7 5.7 0 0 0-3.425 6.418zm-61.809-87.844a5.698 5.698 0 0 0 7.526-1.118l53.071-64a5.7 5.7 0 0 0-1.247-8.394L381.173 4.947c-2.98-1.967-7.015-.741-8.395 2.553L340.26 85.069a5.698 5.698 0 0 0 2.116 6.959z"
          />
          <path
            fill="#00B1FF"
            d="m218.4 479.836l-15.988 9.35c-12.996 7.6-29.692 3.226-37.292-9.77l-42.39-72.487c-7.6-12.996-3.226-29.692 9.77-37.292l15.988-9.35c12.996-7.6 29.692-3.226 37.292 9.77l42.39 72.487c7.601 12.996 3.226 29.692-9.77 37.292z"
          />
          <path
            fill="#0096D1"
            d="m120.402 408.701l154.911-84.34l-105.898-165.808L29.384 262.586c-.903.577-38.751 35.207 2.814 100.288s88.204 45.827 88.204 45.827z"
          />
          <path
            fill="#727272"
            d="M362.532 152.23c46.335 72.548 74.407 151.142 55.022 163.522c-19.385 12.38-78.885-46.14-125.22-118.689S217.928 45.922 237.312 33.541s78.885 46.14 125.22 118.689z"
          />
          <ellipse
            cx="301.36"
            cy="191.299"
            fill="#B2B2B2"
            rx="34.502"
            ry="73.109"
            transform="rotate(-32.567 301.337 191.289)"
          />
          <path
            fill="#D1D1D1"
            d="M416.708 316.293c-16.932 10.814-74.444-48.976-120.779-121.525S219.534 44.896 236.466 34.082c.975-.623 2.069-1.034 3.264-1.261c-7.988-.183-15.814 3.983-20.445 12.839c-20.408 39.028-72.491 125.618-72.491 125.618s-25.845 34.8 19.588 105.935s89.296 62.233 89.296 62.233s99.643-10.179 143.424-12.27c10.365-.495 17.584-6.25 20.583-14.014c-.796 1.31-1.781 2.367-2.977 3.131z"
          />
          <path
            fill="#00B1FF"
            d="M52.892 308.174a14.074 14.074 0 0 1-11.883-6.505c-4.187-6.556-2.267-15.264 4.289-19.451l57.911-36.986c6.555-4.186 15.264-2.267 19.451 4.289s2.267 15.264-4.289 19.451L60.46 305.958a14.018 14.018 0 0 1-7.568 2.216z"
          />
        </svg>
      </h1>
      <div dangerouslySetInnerHTML={{ __html: announcement[0] }} />
      <div
        dangerouslySetInnerHTML={{ __html: announcement[1] }}
        onClick={(e) => {
          e.preventDefault();
          setShowUpdatePlanModal(true);
        }}
      />
      <ConfirmPlanSelectionModal
        isForUltimatePlan
        isOpen={showUpdatePlanModal}
        onClose={() => setShowUpdatePlanModal(false)}
      />
    </AnnouncementContainer>
  );
}

export default Announcement;
