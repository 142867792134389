import React, { useState, useEffect } from "react";

import Button from "../Button/Button";
import AtdPopover from "../Popover/AtdPopover";
import Box from "../Box/Box";
import Text from "../Text/Text";

import FilterHeader from "./FilterHeader";
import FilterItem from "./FilterItem";

import { FilterContainer } from "./Filters.style";

import data from "./data";
import { flattenDeep } from "lodash";
import { fetchTeams } from "@atd/features";
import { useFiltersQuery } from "@atd/features/request";
import { getFilterOptions, isAdmin, getCreatedDate } from "@atd/utils";
import { useSelector, useDispatch } from "react-redux";
import { useLocalStorage } from "@atd/hooks";

function Filters({ filters = data, onDone, clear }) {
  const params = {
    "Created date": {},
    Plans: {},
    Discount: {},
    Designer: {},
    Status: {},
    Location: {},
    Email: {},
  };
  const [customersFilter, setCustomersFilter] = useLocalStorage(
    "customers-filters",
    params,
  );
  useEffect(() => {
    setCustomersFilter(!!customersFilter ? customersFilter : params);
    return () => {};
  }, [customersFilter, params, setCustomersFilter]);
  const [queryParams, setQueryParams] = useState(customersFilter);
  const [isVisible, setIsVisible] = useState(false);
  const { teams } = useSelector((state) => state.team);
  const dispatch = useDispatch();
  const users = flattenDeep([...teams.map((team) => team.users)]);
  const { data: dataFilters, isSuccess } = useFiltersQuery(
    isAdmin() ? "new" : "queue",
  );
  const { date: dateFilter } = dataFilters || {};
  const dateFilterOptions = isSuccess ? getFilterOptions(dateFilter) : [];
  let designerFilterOptions = getFilterOptions(users);
  designerFilterOptions.unshift({ label: "All Designers", value: "all" });
  let customerFilters = filters;
  const designerFilterIndex = customerFilters.findIndex(
    (val) => val.name === "designer",
  );
  customerFilters[designerFilterIndex]["options"] = designerFilterOptions;
  useEffect(() => {
    dispatch(
      fetchTeams({
        type: "accepted",
        dropdown: "yes",
        delete: "yes",
      }),
    );
    return () => {};
  }, [dispatch]);
  const [allFilters, setAllFilters] = useState(customerFilters);

  const onSelectFilterItem = ({ target }) => {
    const { checked, name, id } = target;
    const updatedFilters = allFilters.map((filter) => ({
      ...filter,
      checked: filter.name === name ? checked : filter.checked,
    }));
    setAllFilters(updatedFilters);
    let param = queryParams;
    param[id] = params[id];
    param = { ...queryParams, ...param };
    setQueryParams(param);
  };

  const selectedFilters = allFilters.filter((item) => item.checked);
  const onChangeFilter = ({ target: { value, name } }, label) => {
    let queryParam = queryParams;
    let params = queryParams[label];
    params = { ...params, [name]: value };
    queryParam[label] = params;
    setQueryParams((prevParams) => ({
      ...prevParams,
      ...queryParam,
    }));
    setCustomersFilter((prevParams) => ({
      ...prevParams,
      ...queryParam,
    }));
  };

  const clearFilter = () => {
    setAllFilters(customerFilters);
    setQueryParams(params);
  };
  const submitFilter = () => {
    dispatch(clear());
    let payload = Object.keys(queryParams).reduce((acc, val) => {
      const value =
        val === "Created date"
          ? getCreatedDate(val, queryParams)
          : queryParams[val];
      return { ...acc, ...value };
    }, {});
    payload = { ...payload, limit: 20, page: 1 };
    if (onDone.length) {
      onDone.map((val) => {
        return dispatch(val(payload));
      });
    }
  };
  return (
    <AtdPopover
      interactive
      arrow={false}
      visible={isVisible}
      onShow={setIsVisible}
      placement="bottom-end"
      scrollContainerOptions={{
        style: { minWidth: 270 },
      }}
      element={
        <form>
          <FilterHeader
            onClear={() => clearFilter()}
            onDone={() => submitFilter()}
          />
          <FilterContainer>
            {allFilters.map((filter) => (
              <FilterItem
                {...filter}
                dateFilterOptions={dateFilterOptions}
                queryParams={queryParams}
                onFilter={onChangeFilter}
                onChange={onSelectFilterItem}
              />
            ))}
          </FilterContainer>
        </form>
      }
    >
      <Button variant="default" icon="filter">
        <span>Filter</span>
        {selectedFilters.length > 0 && (
          <Box
            divider={{ color: "light", side: "left", width: 1 }}
            padding={{ left: 8 }}
            margin={{ left: 8 }}
          >
            <Text color="blue" weight="medium" lineHeight={16}>
              <span>{selectedFilters.length}</span>
            </Text>
          </Box>
        )}
      </Button>
    </AtdPopover>
  );
}

export default Filters;
