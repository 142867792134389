import React from "react";

import uuid from "react-uuid";
import Box from "../Box/Box";
import Button from "../Button/Button";
import FileCard from "../FileCard/FileCard";
import Spinner from "../Spinner/Spinner";

function Attachments({
  isUploading,
  label,
  onClick,
  onRemove,
  files,
  inputProps,
}) {
  return (
    <Box
      flex={{
        direction: "row",
        justifyContent: "flex-start",
        wrap: "wrap",
      }}
      className="mgt-8"
    >
      <div className="upload-fbtn-wrapper mgl-7">
        <Box
          hideIfEmpty
          margin={{
            vertical: 8,
            horizontal: 8,
          }}
          className="customcss4"
        >
          <Button
            variant="default"
            className="btn-pd"
            icon="plus"
            iconColor="gray600"
            label={label}
            type="button"
            disabled={isUploading}
            onClick={onClick}
          />
        </Box>
        <input type="file" id="fileinput" {...inputProps} />
      </div>
      {isUploading && <Spinner />}
      <Box hideIfEmpty margin={{ top: 16, left: 8 }} className="pointer1">
        {files.length > 0
          ? `${files.length} file${files?.length > 1 ? "s" : ""}`
          : ""}
      </Box>
      {files?.length > 0 &&
        files.map((file, index) => (
          <Box
            key={uuid()}
            hideIfEmpty
            margin={{ top: 4, left: index === 0 ? 20 : 0 }}
            className="pointer1"
          >
            <FileCard
              type={file.format}
              file={file.link}
              fullName={file.full_name}
              name={file?.name}
              onRemove={() => onRemove(index)}
            />
          </Box>
        ))}
    </Box>
  );
}

Attachments.defaultProps = {
  files: [],
  isUploading: false,
  label: "Select Files",
};

export default Attachments;
