import * as React from "react";
const SvgHelp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 16 16"
    fill="currentColor"
    {...props}
  >
    <defs>
      <path
        id="Help_svg__a"
        d="M9.629 7.904c-.553.36-.718.671-.736 1.385a.142.142 0 0 1-.143.14H7.286c-.04 0-.143-.104-.143-.143v-.583c0-.611.33-1.148 1.009-1.644.053-.037.562-.37.562-.828 0-.38-.287-.646-.696-.646-.585 0-.91.293-.942.852a.143.143 0 0 1-.143.134H5.394a.143.143 0 0 1-.143-.15C5.331 4.87 6.34 3.98 8.02 3.98c1.274 0 2.645.691 2.645 2.208 0 1.017-.232 1.209-1.035 1.717ZM8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1Zm1.17 11.286a.286.286 0 0 1-.286.285H7.143a.287.287 0 0 1-.286-.285V10.57c0-.157.129-.285.286-.285h1.741c.158 0 .286.128.286.285v1.715Z"
      />
    </defs>
    <use fillRule="evenodd" xlinkHref="#Help_svg__a" />
  </svg>
);
export default SvgHelp;
