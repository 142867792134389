export const roles = [
  {
    id: 1,
    name: "Admin",
    note: "Contains all available permissions for owners and administrators.",
  },
  {
    id: 2,
    name: "Account Manager",
    note: "Contains all available permissions except for removing or adding any users.",
  },
  {
    id: 3,
    name: "Standard Users",
    note: "Contains all available permissions except for removing or adding any users and client details.",
  },
  {
    id: 4,
    name: "View Only",
    note: "Can view all the tickets assigned to them. but restricted to make any changes.",
  },
  {
    id: 5,
    name: "Client Administrator",
    note: "Contains all available permissions for owners and administrators.",
  },
  {
    id: 6,
    name: "Senior Designer",
    note: "Can view all the tickets but restricted to make any changes.",
  },
  {
    id: 7,
    name: "Designer",
    note: "Can view all the tickets assigned to them. but restricted to make any changes.",
  },
];

export const getRoleById = (roleId) => {
  const roleNote = roles.find((role) => role?.id === roleId);
  return roleNote;
};
