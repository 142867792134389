import { Select, Box } from "@atd/components";

function GroupByFilter({ value, options, onChange, field, placeholder }) {
  return (
    <Box hideIfEmpty margin={{ top: 8, left: 8 }} className="pointer1">
      <Box flex>
        <Select
          limit={20}
          size="small"
          value={value}
          isOptionGroup
          hasSearchField
          placeholder={placeholder}
          className="Box-root Flex-flex wt-auto"
          options={options}
          scrollContainerOptions={{
            style: { maxHeight: 400 },
          }}
          onChange={(newValue) => {
            onChange(field, newValue);
          }}
          renderOption={({ name, icon }) => (
            <Box flex={{ alignItems: "center" }}>
              {icon && (
                <img
                  src={icon}
                  alt="ATD"
                  className="client-avt-menu"
                  style={{ marginLeft: 0 }}
                />
              )}
              {name}
            </Box>
          )}
        />
      </Box>
    </Box>
  );
}

export default GroupByFilter;
