import { useNavigate } from "react-router-dom";

import { Box, Button, HasPermission } from "@atd/components";

import BrandLogo from "../../assets/images/create-brand.png";

function BrandsCreate() {
  const navigation = useNavigate();
  return (
    <>
      <img className="w-full" src={BrandLogo} alt="Create Brand" />
      <Box hideIfEmpty margin={{ top: 16, left: 8 }}>
        <HasPermission roles={["View Only"]} condition="not-equal">
          <Button
            variant="primary"
            label="Create New Brand"
            onClick={() => navigation("new")}
            iconColor="white"
            icon="plus"
          />
        </HasPermission>
      </Box>
    </>
  );
}

export default BrandsCreate;
