import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { pick } from "lodash";

import { fetchTemplates } from "@atd/features";

import { TemplateSchema } from "@atd/schema";
import TemplateService from "@atd/features/template/templateApi";

import CreateEmailTemplateForm from "./CreateEmailTemplateForm";
import SettingsForm from "../Communications/SettingsForm";

function CreateEmailTemplate() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { templates } = useSelector((state) => state.template);
  const dispatch = useDispatch();

  const template = useMemo(
    () =>
      Array.isArray(templates) ? templates.find((tmpl) => tmpl.id === id) : {},
    [templates, id],
  );

  const isEditing = useMemo(() => id !== "new", [id]);

  useEffect(() => {
    if (isEditing) {
      dispatch(fetchTemplates());
    }
  }, [dispatch, isEditing, id]);

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const templatePayload = {
      ...values,
      tag: values?.tag || "",
    };
    const { status } = isEditing
      ? await TemplateService.update(id, { ...templatePayload, status: 1 })
      : await TemplateService.addNew({ ...templatePayload, is_shared: 0 });

    if (status === "success") {
      setSubmitting(false);
      navigate("/settings/email-templates");
    }
  };

  const initialValues = {
    name: "",
    subject: "",
    body: "",
    tag: "",
    is_shared: 1,
    attachments: [],
  };

  const formValues = pick(template, Object.keys(initialValues));

  return (
    <SettingsForm
      title={isEditing ? "Edit Email Template" : "New Email Template"}
      initialValues={formValues || initialValues}
      onSubmit={onSubmit}
      onCancel={() => navigate("/settings/email-templates")}
      validationSchema={TemplateSchema}
      enableReinitialize
    >
      {(formikProps) => (
        <div className="Box-root" style={{ maxWidth: 630 }}>
          <CreateEmailTemplateForm {...formikProps} />
        </div>
      )}
    </SettingsForm>
  );
}

export default CreateEmailTemplate;
