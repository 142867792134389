import * as React from "react";

function SvgGood(props) {
  return (
    <svg viewBox="0 0 50 50" {...props}>
      <path
        d="M50 25c0 13.807-11.193 25-25 25S0 38.807 0 25 11.193 0 25 0s25 11.193 25 25"
        className="good_svg__base"
      />
      <path
        d="M25 35a16.91 16.91 0 0 1-8.616-2.342 2 2 0 1 0-2.03 3.446A20.91 20.91 0 0 0 25 39a20.91 20.91 0 0 0 10.647-2.896 2 2 0 0 0-2.03-3.446A16.91 16.91 0 0 1 25 35Z"
        className="good_svg__mouth"
        fill="#FFF"
      />
      <path
        d="M30.649 16.815a3.99 3.99 0 0 1 3.544-1.81 3.985 3.985 0 0 1 2.635 1.167 3.995 3.995 0 0 1 0 5.656 4 4 0 0 1-6.18-5.013Z"
        className="good_svg__right-eye"
        fill="#FFF"
      />
      <path
        d="M18.828 21.828a4.001 4.001 0 0 0-5.324-5.954 3.995 3.995 0 0 0-.332 5.954 4 4 0 0 0 5.656 0Z"
        className="good_svg__left-eye"
        fill="#FFF"
      />
    </svg>
  );
}

export default SvgGood;
