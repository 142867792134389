import axios from "axios";

// Create instance of axios
const axiosUnsplash = axios.create({
  baseURL: "https://api.unsplash.com",
  params: {
    client_id: process.env.REACT_APP_UNSPLASH_ACCESS_KEY,
  },
});

export default axiosUnsplash;
