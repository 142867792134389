import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import ClientApi from "./clientApi";
import { getUniqueObjects } from "@atd/utils";

const initialState = {
  clients: [],
  customers: [],
  meetings: [],
  meeting: {},
  hosts: [],
  client: null,
  loading: true,
  error: null,
  fetching: false,
  hasMore: false,
  hasError: false,
  counts: {},
  invoiceData: {},
};

export const fetchClients = createAsyncThunk(
  "request/fetchClients",
  async (payload, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener("abort", () => {
      source.cancel();
    });
    const response = await ClientApi.getAllClients(payload, source.token);
    return response;
  },
);

export const fetchClient = createAsyncThunk(
  "client/fetchClient",
  async ({ id, params }) => ClientApi.getClient(id, params),
);

export const updateClient = createAsyncThunk(
  "client/updateClient",
  async ({ id, data }) => ClientApi.updateClient(id, data),
);

export const deleteClient = createAsyncThunk(
  "client/deleteClient",
  async (id) => ClientApi.deleteClient(id),
);

export const getClientsCount = createAsyncThunk(
  "request/getClientsCount",
  async (payload) => ClientApi.getClientsCount(payload),
);

export const getAllMeetings = createAsyncThunk(
  "request/getAllMeetings",
  async (id) => ClientApi.getAllMeetings(id),
);
export const getClientMeetingFilters = createAsyncThunk(
  "request/getClientMeetingFilters",
  async (id) => ClientApi.getClientMeetingFilters(id),
);
export const postClientMeeting = createAsyncThunk(
  "request/postClientMeeting",
  async ({ id, payload }) => ClientApi.postClientMeeting(id, payload),
);
export const deleteClientMeeting = createAsyncThunk(
  "request/deleteClientMeeting",
  async ({ id, value }) => ClientApi.deleteClientMeeting(id, value),
);
export const getSingleClientMeeting = createAsyncThunk(
  "request/getSingleClientMeeting",
  async (payload) => ClientApi.getSingleClientMeeting(payload),
);
export const putClientMeeting = createAsyncThunk(
  "request/putClientMeeting",
  async (payload) => ClientApi.putClientMeeting(payload),
);

export const fetchCustomers = createAsyncThunk(
  "request/fetchCustomers",
  async (payload) => ClientApi.fetchCustomer(payload),
);

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    resetClientsData: (state) => {
      state.clients = [];
      state.fetching = true;
      state.hasMore = false;
    },
    resetCustomers: (state) => {
      state.fetching = true;
      state.hasMore = false;
      state.customers = [];
    },
    resetMeeting: (state) => {
      state.meeting = {};
    },
    updateInvoice: (state, { payload }) => {
      state.invoiceData = payload;
    },
  },
  extraReducers: {
    [fetchClients.pending]: (state) => {
      state.fetching = true;
      // state.hasMore = false;
    },
    [fetchClients.fulfilled]: (state, action) => {
      state.fetching = false;
      state.loading = false;
      state.hasError = false;
      state.clients = [
        ...state.clients,
        ...(action.payload.results?.data || []),
      ];
      state.hasMore = action.payload.results?.has_more;
    },
    [fetchClients.rejected]: (state, action) => {
      // state.fetching = false;
      state.loading = false;
      state.hasError = true;
      state.error = action.error;
      state.hasMore = false;
    },
    // [fetchClients.pending]: (state) => {
    //   state.loading = true;
    //   state.error = null;
    // },
    // [fetchClients.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.clients = action.payload;
    // },
    // [fetchClients.rejected]: (state, action) => {
    //   state.error = action.error.message;
    // },
    [fetchClient.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchClient.fulfilled]: (state, action) => {
      state.loading = false;
      state.client = action.payload;
    },
    [fetchClient.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [deleteClient.pending]: (state) => {
      state.error = null;
    },
    [deleteClient.fulfilled]: (state, action) => {
      state.clients = state.clients.filter(
        (client) => client.id !== action.payload,
      );
    },
    [deleteClient.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    // [updateClient.pending]: (state) => {
    //   state.error = null;
    // },
    [updateClient.fulfilled]: (state, action) => {
      state.clients = state.clients.map((client) => ({
        ...client,
        ...(client.id === action.payload.meta.arg.id ? action.payload : ""),
      }));
      state.invoiceData = action.payload;
    },
    // [updateClient.rejected]: (state, action) => {
    //   state.error = action.error.message;
    // },
    [getClientsCount.fulfilled]: (state, action) => {
      state.counts = action.payload.reduce((res, count) => {
        const results = { ...res };
        results[count.name] = count.count;
        return results;
      }, {});
    },
    [getAllMeetings.pending]: (state) => {
      state.fetching = true;
    },
    [getAllMeetings.fulfilled]: (state, action) => {
      state.fetching = false;
      state.meetings = action.payload;
    },
    [getAllMeetings.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getClientMeetingFilters.pending]: (state) => {
      state.fetching = true;
    },
    [getClientMeetingFilters.fulfilled]: (state, action) => {
      state.fetching = false;
      state.hosts = action.payload;
    },
    [getClientMeetingFilters.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [deleteClientMeeting.pending]: (state) => {
      state.fetching = true;
    },
    [deleteClientMeeting.fulfilled]: (state, action) => {
      state.fetching = false;
      state.meetings = state.meetings.filter(
        (val) => val.id !== action.meta.arg.value,
      );
    },
    [deleteClientMeeting.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getSingleClientMeeting.pending]: (state) => {
      state.fetching = true;
    },
    [getSingleClientMeeting.fulfilled]: (state, action) => {
      const {
        client_email,
        client_name,
        date,
        hosted_by,
        meeting_status,
        purpose,
        description,
      } = action.payload[0] || {};
      state.fetching = false;
      state.meeting = {
        client_email,
        client_name: client_name[0],
        date,
        hosted_by: hosted_by[0],
        meeting_status,
        purpose,
        description,
      };
    },
    [getSingleClientMeeting.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [fetchCustomers.pending]: (state) => {
      state.fetching = true;
      // state.customers = [];
      // state.hasMore = false;
    },
    [fetchCustomers.fulfilled]: (state, action) => {
      state.fetching = false;
      state.loading = false;
      state.hasError = false;
      state.customers = getUniqueObjects([
        ...state.customers,
        ...(action.payload.results?.data || []),
      ]);
      state.hasMore = action.payload.results?.has_more;
    },
    [fetchCustomers.rejected]: (state, action) => {
      // state.fetching = false;
      state.loading = false;
      state.hasError = true;
      state.error = action.error;
      state.hasMore = false;
    },
  },
});

export const { resetClientsData, resetCustomers, resetMeeting, updateInvoice } =
  clientSlice.actions;

export default clientSlice.reducer;
