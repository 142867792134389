import * as React from "react";

const SvgFeedback = (props) => (
  <svg
    width="1rem"
    height="1rem"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M103.32 17.39L40 40V75L43.74 76.335L38.105 87.605C37.4632 88.8862 37.1044 90.2905 37.0529 91.7226C37.0015 93.1546 37.2586 94.581 37.8067 95.905C38.3549 97.229 39.1814 98.4197 40.2302 99.3962C41.2789 100.373 42.5253 101.112 43.885 101.565L64.305 108.37C66.5893 109.115 69.0644 109.019 71.2841 108.099C73.5037 107.179 75.3217 105.497 76.41 103.355L82.925 90.325L103.32 97.61C104.074 97.879 104.882 97.963 105.675 97.8549C106.469 97.7467 107.225 97.4496 107.879 96.9885C108.534 96.5274 109.068 95.9158 109.437 95.2052C109.806 94.4946 109.999 93.7057 110 92.905V22.095C109.999 21.2943 109.806 20.5054 109.437 19.7948C109.068 19.0842 108.534 18.4726 107.879 18.0115C107.225 17.5504 106.469 17.2533 105.675 17.1451C104.882 17.037 104.074 17.1209 103.32 17.39ZM67.465 98.885L47.05 92.08L53.225 79.725L73.435 86.945L67.465 98.885ZM20 75H30V40H20C14.485 40 10 44.485 10 50V65C10 70.515 14.485 75 20 75Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFeedback;
