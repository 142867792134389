import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import { Formik, Form, Field } from "formik";

import {
  Button,
  Select,
  EmptyState,
  PageContent,
  Modal,
  Message,
  FormFieldInputError,
} from "@atd/components";
import { fetchTeams, createTeam } from "@atd/features";
import { withDataTablePage, withSelector } from "@atd/hoc";
import { TeamSchema } from "@atd/schema";
import { getRoleById } from "@atd/utils";

import TeamsActions from "./TeamsActions";
import Users from "./Users";
import { useLocation } from "react-router-dom";

function Teams({ showActions = true }) {
  const location = useLocation();

  // const { teams, error } = team || {};
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const { user } = useSelector((state) => state.user);
  const { roles } = useSelector((state) => state.roles);
  const { error } = useSelector((state) => state.team);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTeams());
  }, [dispatch]);

  const onSubmit = async (values, { setSubmitting }) => {
    await dispatch(createTeam(values));
    setSubmitting(false);
    setIsOpenEditModal(false);
    dispatch(fetchTeams());
  };

  const roleOptions =
    roles.length > 0
      ? roles.map((role) => ({ label: role.name, value: role.id }))
      : [];

  return (
    <>
      {isOpenEditModal && (
        <Modal
          id="adduser"
          title="Invite new users"
          isOpen={isOpenEditModal}
          onClose={setIsOpenEditModal}
        >
          <Formik
            initialValues={{ email: "", role: 1 }}
            onSubmit={onSubmit}
            validationSchema={TeamSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ isSubmitting, errors, touched, values }) => {
              const { name, note } = getRoleById(values.role) || {};

              return (
                <Form>
                  <div className="pd16">
                    <div>
                      Enter the email addresses of the users you&rsquo;d like to
                      invite for just $9/month, and choose the role they should
                      have.
                    </div>
                    <div className="grid grid-column-2 grid-align-top mgt1">
                      <div className="FormField mgt1" id="rtype">
                        <Field
                          name="email"
                          className={`iput Margin-right--8 wt-100 ${
                            errors.email &&
                            touched.email &&
                            "FormField__input--hasError"
                          }`}
                          placeholder="Email address..."
                          type="text"
                          style={{ minWidth: "100%" }}
                          autoFocus
                        />
                        <FormFieldInputError name="email" />
                      </div>
                      <div className="FormField mgt1" id="rtype">
                        <Field name="role">
                          {({
                            field: { value },
                            form: { setFieldValue, setFieldTouched },
                          }) => (
                            <>
                              <Select
                                className="wt-100"
                                appendTo="parent"
                                value={value}
                                options={roleOptions}
                                onChange={(newValue) => {
                                  setFieldValue("role", newValue);
                                }}
                                placeholder="Role"
                                inputStyle={{
                                  minWidth: "100%",
                                }}
                                inputProps={{
                                  className: `iput ${
                                    errors.role && "FormField__input--hasError"
                                  }`,
                                  // onBlur: () => {
                                  //   setFieldTouched("role", true);
                                  // },
                                }}
                              />
                              {errors.role && (
                                <div className="FormField__input--error">
                                  {errors.role}
                                </div>
                              )}
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                    <Message
                      className="Margin-top--20"
                      title={`Note: For ${name}`}
                      text={note}
                    />
                  </div>
                  <Modal.Footer>
                    <div className="Box-root Box-hideIfEmpty Margin-right--8">
                      <Button
                        variant="default"
                        type="button"
                        onClick={() => setIsOpenEditModal(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="Box-root Box-hideIfEmpty">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={
                          isSubmitting || Object.keys(errors).length > 0
                        }
                      >
                        Invite
                      </Button>
                    </div>
                  </Modal.Footer>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      )}

      {error ? (
        <EmptyState
          icon="teams"
          title="Something went wrong!"
          description={<span>{error}</span>}
        />
      ) : (
        <>
          {location?.pathname === "/settings/teams" ? (
            <Users showActions={showActions} />
          ) : (
            <PageContent>
              <PageContent.Main>
                <Users showActions={showActions} />
              </PageContent.Main>
              <PageContent.Sidebar>
                <div className="center pd-30">
                  {user && ![3, 4].includes(user?.role) && (
                    <PageContent.Action
                      actionButtonText="New User"
                      imageUrl={require("../../assets/images/team.png")}
                      onClick={() => setIsOpenEditModal(true)}
                    />
                  )}
                </div>
              </PageContent.Sidebar>
            </PageContent>
          )}
        </>
      )}
    </>
  );
}

export default compose(
  withSelector((state) => {
    const { user } = state.user;
    const { roles } = state.roles;
    return { user, roles };
  }),
  withDataTablePage(({ user, roles, team, isMain }) => {
    const { teams, error } = team || {};
    return {
      title: "Teams and Permissions",
      withMainLayout: isMain,
      action: (
        <TeamsActions
          user={user}
          disabled={error}
          roles={roles}
          teams={teams}
        />
      ),
      headerMargin: false,
    };
  }, "team"),
)(Teams);
