import { Navigate } from "react-router-dom";

import { checkCredentialCookies } from "@atd/utils";

function PrivateRoute({ children, path }) {
  return checkCredentialCookies() ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: path }} replace />
  );
}

export default PrivateRoute;
