import styled from "styled-components";

import { Icon } from "@atd/components";

export const CardWrapper = styled.div`
  display: flex;
  /* border-bottom: ${({ borderBottom }) =>
    borderBottom ?? "1px solid #e0e1e4"}; */
  padding-bottom: 20px;
  margin-top: 10px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  min-height: 120px;
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px;
  background-color: #fff;
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
  margin-right: 25px;
  justify-content: ${({ contentAlign }) => contentAlign};
`;

export const Text = styled.div`
  border-bottom: 1px solid #e0e1e4;
  padding-bottom: 20px;
`;

export const Color = styled.div`
  background-color: ${({ color }) => color};
  height: 50px;
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

export const SmallText = styled.div`
  text-transform: uppercase;
  font-size: 8px;
`;

export const FileSize = styled.div`
  font-size: 11px;
  color: #000;
  font-weight: 500;
`;

export const Close = styled(Icon)`
  position: absolute;
  right: 10px;
  top: ${({ top }) => top || 60}px;
  fill: #666;
`;

export const CardTitle = styled.div`
  margin-top: ${({ top }) => top};
`;
