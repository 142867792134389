import * as React from "react";

const SvgClockAlt = (props) => (
  <svg
    aria-hidden="true"
    className="ClockAlt_svg__SVGInline-svg ClockAlt_svg__SVGInline--cleaned-svg ClockAlt_svg__SVG-svg ClockAlt_svg__Icon-svg ClockAlt_svg__Icon--clock-svg ClockAlt_svg__Button-icon-svg ClockAlt_svg__Icon-color-svg ClockAlt_svg__Icon-color--gray600-svg"
    height={12}
    width={12}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm1-8.577V4a1 1 0 1 0-2 0v4a1 1 0 0 0 .517.876l2.581 1.49a1 1 0 0 0 1-1.732z"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgClockAlt;
