import * as Yup from "yup";

const AuthSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .when("new_password", {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref("new_password")],
        "Both passwords need to be the same",
      ),
    })
    .min(8, "Password is too short - should be 8 chars minimum."),
});

export default AuthSchema;
