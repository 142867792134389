import "./ToggleBox.css";

function ToggleBox({ children }) {
  return (
    <fieldset className="ToggleBox ToggleBox--direction--vertical">
      {children}
    </fieldset>
  );
}

export default ToggleBox;
