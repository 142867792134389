import * as React from "react";

function SvgCircleInfo(props) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.969 1.333c-3.66 0-6.636 2.991-6.636 6.667A6.674 6.674 0 0 0 8 14.667 6.674 6.674 0 0 0 14.667 8c0-3.676-3.005-6.667-6.698-6.667Zm.03 12A5.34 5.34 0 0 1 2.668 8c0-2.94 2.378-5.333 5.302-5.333 2.958 0 5.364 2.393 5.364 5.333A5.34 5.34 0 0 1 8 13.334Z"
        fill="#757988"
      />
      <path
        d="M7.333 4.667h1.334v4.666H7.333V4.667Zm0 5.333h1.334v1.333H7.333V10Z"
        fill="#757988"
      />
    </svg>
  );
}

export default SvgCircleInfo;
