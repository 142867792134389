import styled from "styled-components";

import { ratingIcons } from "./ratings";

const Wrapper = styled.section`
  background-color: #f6f9fc;
  flex: 1;
  align-items: flex-start;

  & svg {
    width: 80px;
    height: 80px;
  }

  & svg path:not([class*="svg__base"]) {
    fill: #655f52;
    width: 80px;
    height: 80px;
  }

  @media (min-width: 880px) {
    flex: 1 0 0;
    align-items: normal;
  }
`;

const Container = styled.div`
  padding-top: 60px !important;
  padding-bottom: 60px !important;
  max-width: 70%;
  margin: auto;

  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px 20px;

  @media (min-width: 880px) {
    max-width: 100%;
    padding-top: 68px;
    padding-left: 60px;
    padding-right: 60px;
  }
`;

const Heading = styled.div`
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  color: #424770;

  & > h1 {
    margin-top: 20px;
    pointer-events: all;
    will-change: opacity;
    opacity: 1;
    font-size: 1.5em;
    font-weight: 400;
  }

  @media (min-width: 670px) {
    .common-IntroText {
      font-size: 24px;
      line-height: 36px;
    }
  }
`;

const CommentBox = styled.div`
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  color: #525f7f;

  p {
    margin-top: 20px;
    pointer-events: all;
    will-change: opacity;
    opacity: 1;
  }
`;

function FeedbackReview({ rating, comment }) {
  const ratingNumber = Math.floor(rating);
  const RatingIcon = ratingIcons[ratingNumber].icon;

  return (
    <Wrapper>
      <Container>
        <div className="transition-done">
          <div
            className="icon"
            style={{ willChange: "opacity", opacity: 1, background: "none" }}
          >
            <div
              className="icon"
              style={{
                fill: ratingIcons[5].activeColor,
              }}
            >
              <RatingIcon />
            </div>
          </div>
          <Heading>
            <h1
              style={{
                pointerEvents: "all",
                willChange: "opacity",
                opacity: 1,
                fontSize: "1.5em",
                fontWeight: 400,
              }}
            >
              {ratingIcons[ratingNumber].label}! Services
            </h1>
          </Heading>
          <CommentBox>
            <p>{comment}</p>
          </CommentBox>
        </div>
      </Container>
    </Wrapper>
  );
}

export default FeedbackReview;
