import { Formik, Form } from "formik";

import { Box, Button, Heading } from "@atd/components";

import Actions from "./Actions";

function SettingsForm({ title, onCancel, children, ...formik }) {
  return (
    <Formik {...formik} onCancel={onCancel}>
      {({ isSubmitting, ...formikProps }) => (
        <Form>
          <Heading
            headText={title}
            rightContent={
              <Box flex>
                <Button
                  disabled={isSubmitting}
                  variant="link"
                  label="Cancel"
                  onClick={onCancel}
                />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="primary"
                  label="Save"
                />
              </Box>
            }
          />
          <Box margin={{ top: 32, bottom: 48 }}>
            {children({ ...formikProps, isSubmitting })}
          </Box>
          <hr />
          <Actions onCancel={onCancel} disabled={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
}

export default SettingsForm;
