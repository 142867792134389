import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setSearchQuery } from "@atd/features";
// import { Box, Icon } from "@atd/components";

function SearchInput({ onResetData }) {
  const [inputValue, setInputValue] = useState("");

  const navigate = useNavigate();
  const { query } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (query) {
      dispatch(setSearchQuery(query));
      setInputValue(query);
    } else {
      setInputValue("");
    }
  }, [dispatch, query]);

  const onKeyPress = ({ target, key }) => {
    const { value } = target;
    if (key === "Enter") {
      navigate(`/search/${value}`);
      dispatch(setSearchQuery(value));
      if (onResetData) {
        onResetData();
      }
    }
  };

  return (
    <div className="searchBox_Container">
      <div className="searchBox_wrap">
        <div className="searchBox">
          {/* <Box
            flex={{ alignItems: "center" }}
            className="atd-SuggestionInput-backing"
          >
            <Box flex className="atd-SuggestionInput-icon">
              <Icon icon="search" height={12} width={12} color="gray900" />
            </Box>
          </Box> */}
          <span className="IconSVG IconSVG--regular search_Icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 16 16"
              fill="currentColor"
            >
              <defs>
                <path
                  id="search-a"
                  d="M2.79709096,6.65696289 C2.79709096,4.52919646 4.52863816,2.796476 6.65761893,2.796476 C8.7865997,2.796476 10.517551,4.52919646 10.517551,6.65696289 C10.517551,8.78592101 8.7865997,10.5180456 6.65761893,10.5180456 C4.52863816,10.5180456 2.79709096,8.78592101 2.79709096,6.65696289 M14.9119628,13.2178243 L11.4095422,9.71484508 C11.9791771,8.83180116 12.3158337,7.78430371 12.3158337,6.65696289 C12.3158337,3.53770855 9.77631061,1 6.65761893,1 C3.5371397,1 1,3.53770855 1,6.65696289 C1,9.77681307 3.5371397,12.3145216 6.65761893,12.3145216 C7.78378005,12.3145216 8.83188449,11.980252 9.71493781,11.4100272 L13.2173585,14.9124106 C13.3341455,15.0291965 13.52601,15.0291965 13.6416054,14.9124106 L14.9119628,13.6420667 C15.0293457,13.5252809 15.0293457,13.3346101 14.9119628,13.2178243"
                />
              </defs>
              <use fillRule="evenodd" xlinkHref="#search-a" />
            </svg>
          </span>
          <textarea
            defaultValue={inputValue || ""}
            className="search_Textarea"
            placeholder="Search…"
            aria-label="Search…"
            aria-placeholder="Search…"
            autoCorrect="false"
            spellCheck="false"
            type="text"
            onChange={({ target }) => {
              setInputValue(target.value);
            }}
            onKeyPress={onKeyPress}
          />
        </div>
      </div>
    </div>
  );
}

export default SearchInput;
