import * as React from "react";

function SvgFile(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      width={20}
      height={20}
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="#888"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
        <path d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2zM9 7h1m-1 6h6m-2 4h2" />
      </g>
    </svg>
  );
}

export default SvgFile;
