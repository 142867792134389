import React, { useState, useCallback, useRef, forwardRef } from "react";
import Tippy from "@tippyjs/react/headless";
// import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/scale-subtle.css";

import sharedSmartDelay from "../../utils/sharedDelay";

import "./Tooltip.css";

const Tooltip = forwardRef(
  (
    {
      style,
      content,
      children,
      hasHeader,
      allowHTML = false,
      arrow = true,
      ...props
    },
    ref,
  ) => {
    const [isVisible, setIsVisible] = useState(props.visible || false);

    // Show with a smart delay, hide immediately
    const delayArrayRef = useRef([sharedSmartDelay.getCurrentDelay(), 0]);

    // Update Tippy's delay when we try to interact with it.
    const handleOnTrigger = useCallback(() => {
      delayArrayRef.current[0] = sharedSmartDelay.getCurrentDelay();
    }, []);

    // Tell the smart delay that the tooltip has been shown.
    const handleOnShow = useCallback(() => {
      sharedSmartDelay.show();
    }, []);

    // Tell the smart delay that the tooltip has been hidden.
    const handleOnHide = useCallback((instance) => {
      sharedSmartDelay.hide();

      const unmountInstance = () => {
        instance.unmount();

        // need to control when we remove the listener because transitionend fires multiple times
        instance.popper.firstChild.removeEventListener(
          "transitionend",
          unmountInstance,
        );
      };

      instance.popper.firstChild.addEventListener(
        "transitionend",
        unmountInstance,
      );

      setIsVisible(false);
    }, []);

    const onMount = () => {
      setIsVisible(true);
    };

    return (
      <Tippy
        ref={ref}
        animation="scale-subtle"
        delay={delayArrayRef.current}
        onMount={onMount}
        onTrigger={handleOnTrigger}
        onShow={handleOnShow}
        onHide={handleOnHide}
        render={(attrs) => (
          <div
            className="tippy-box Tooltip_tooltip"
            data-state={isVisible ? "visible" : "hidden"}
            data-animation="scale-subtle"
            style={{
              ...props.style,
              transitionDuration: isVisible ? ".25s" : "0",
            }}
            {...attrs}
          >
            {allowHTML ? (
              <div
                className="Tooltip_content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            ) : (
              <div className="Tooltip_content">{content}</div>
            )}
            {arrow && (
              <div className="Tooltip_arrow" data-popper-arrow="true" />
            )}
          </div>
        )}
        {...props}
      >
        {children}
      </Tippy>
    );
  },
);

export default Tooltip;
