import * as React from "react";

function SvgPlayColored(props) {
  return (
    <svg
      width={36}
      height={36}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 .5C8.336.5.5 8.336.5 18S8.336 35.5 18 35.5 35.5 27.664 35.5 18 27.664.5 18 .5Zm5.629 17.77-8.531 6.207a.314.314 0 0 1-.496-.254V11.816a.313.313 0 0 1 .496-.254l8.53 6.204a.31.31 0 0 1 0 .504Z"
        fill="#C880C8"
      />
    </svg>
  );
}

export default SvgPlayColored;
