import { useEffect } from "react";

import { Box, ContentState, Text } from "@atd/components";
import { useGetUpgradeQuery } from "@atd/features/user";

function UpgradeSubscriptionInfo({ subscriptionId, planId }) {
  const { data, isLoading, isFetching, refetch } = useGetUpgradeQuery({
    subscription_id: subscriptionId,
    id: planId,
  });

  const { results } = data || {};
  const upgradePlanData = results?.[0];

  useEffect(() => {
    if (planId) {
      refetch();
    }
  }, [planId, refetch]);

  if (isLoading || isFetching) {
    return (
      <Box
        margin={{ bottom: 8 }}
        flex={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <ContentState
          style={{ flex: 1 }}
          padding={{ horizontal: 0, vertical: 0 }}
        />
      </Box>
    );
  }

  return (
    <>
      {upgradePlanData?.plans?.[0]?.credit_notes?.length > 0 &&
        upgradePlanData?.plans?.[0]?.credit_notes.map((cNote, idx) => (
          <Box
            key={`credit-note-${cNote.entity_id}-${idx}`}
            margin={{ bottom: 8 }}
            flex={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Text weight="medium">
              {cNote.quantity} x {cNote?.description}
            </Text>
            <Text>
              {upgradePlanData?.currency}-{cNote?.total_amount.toLocaleString()}
            </Text>
          </Box>
        ))}
      {upgradePlanData?.plans?.[0]?.prorate_charges?.length > 0 &&
        upgradePlanData?.plans?.[0]?.prorate_charges.map((proCharge, idx) => (
          <Box
            key={`prorate-charges-${proCharge.entity_id}-${idx}`}
            margin={{ bottom: 8 }}
            flex={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Text weight="medium">
              {proCharge.quantity} x {proCharge?.description}
            </Text>
            <Text>
              {upgradePlanData?.currency}
              {proCharge?.total_amount.toLocaleString()}
            </Text>
          </Box>
        ))}
      {upgradePlanData?.plans ? (
        <Box
          margin={{ bottom: 8 }}
          flex={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Text weight="medium">Amount Being Charged Today</Text>
          <Text>
            {upgradePlanData?.currency}
            {upgradePlanData?.plans?.[0]?.payable_amount?.toLocaleString()}
          </Text>
        </Box>
      ) : null}
    </>
  );
}

export default UpgradeSubscriptionInfo;
