import { useState } from "react";
import moment from "moment";
import Datetime from "react-datetime";

import {
  Icon,
  Select,
  DatePickerWithPresets,
  AtdPopover,
  Box,
} from "@atd/components";

const getTargetValue = (name, value) => {
  return {
    name,
    value,
  };
};
function DateFilter({
  value,
  startDate,
  endDate,
  options,
  onChange,
  showDateRange = true,
  showTimeFormat = false,
}) {
  const [showCustomDateCalendar, setShowCustomDateCalendar] = useState(false);
  const setTimeFilter = (e) => {
    const target = getTargetValue(
      "time_period",
      `${moment(e._d).format("YYYY-MM-DD")}`,
    );
    onChange({ target });
  };
  return (
    <div className="Box-root Box-hideIfEmpty Margin-top--8 Margin-right--8  pointer1">
      <div className="db-InlineDateRangeFilter-group">
        <div className="Box-root Flex-flex">
          <Select
            value={value}
            options={options}
            onChange={(value) => {
              if (value === "custom") {
                setShowCustomDateCalendar(true);
              } else {
                if (showDateRange) {
                  onChange("start", undefined);
                  onChange("end", undefined);
                  onChange("start_date", undefined);
                  onChange("end_date", undefined);
                  onChange("date_string", value);
                } else {
                  onChange({ target: getTargetValue("start", undefined) });
                  onChange({ target: getTargetValue("end", undefined) });
                  onChange({ target: getTargetValue("start_date", undefined) });
                  onChange({ target: getTargetValue("end_date", undefined) });
                  onChange({ target: getTargetValue("date_string", value) });
                }
              }
            }}
            optionProps={{
              showArrow: false,
            }}
            render={({ formattedValue }) => (
              <button
                aria-label="Date"
                className="db-InlineFilterButton btn"
                type="button"
              >
                {formattedValue ?? "Date"}
              </button>
            )}
          />
        </div>
        <span className="db-InlineDateRangeFilter-separator" />
        <div className="Box-root Flex-flex" id="cale">
          <AtdPopover
            interactive
            visible={showCustomDateCalendar}
            onShow={setShowCustomDateCalendar}
            placement="bottom"
            element={
              <div className="Box-root">
                <div className="db-MenuWrapper-contextualLayer Box-root Flex-flex Flex-direction--column Flex-wrap--nowrap">
                  {showDateRange ? (
                    <DatePickerWithPresets
                      onApply={({ startDate, endDate }) => {
                        setShowCustomDateCalendar(false);
                        onChange("date_string", "custom");
                        onChange(
                          "start_date",
                          moment(startDate).format("YYYY-MM-DD"),
                        );
                        onChange(
                          "end_date",
                          moment(endDate).format("YYYY-MM-DD"),
                        );
                      }}
                      onClear={(e) => {
                        e.stopPropagation();
                        onChange("date_string", null);
                        onChange("start_date", null);
                        onChange("end_date", null);
                      }}
                    />
                  ) : (
                    <Datetime
                      input={false}
                      open={false}
                      onChange={setTimeFilter}
                      timeFormat={showTimeFormat}
                    />
                  )}
                </div>
              </div>
            }
          >
            <div className="Box-root Flex-flex dropdown wt-auto">
              <button className="db-InlineFilterButton btn" type="button">
                <Icon icon="calendar" color="gray600" />
                {startDate && endDate && (
                  <Box as="span" padding={{ left: 8 }}>
                    {moment(startDate).format("MMM DD")}-
                    {moment(endDate).format("MMM DD")}
                  </Box>
                )}
              </button>
            </div>
          </AtdPopover>
        </div>
      </div>
    </div>
  );
}

export default DateFilter;
