import React, { useState } from "react";
// import styled from "styled-components";

import StripeForm from "./StripeForm";
import PlaidPayment from "./PlaidPayment";

// const PaymentOptions = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 20px;

//   button {
//     min-width: 80px;
//     height: 35px;
//     background: #f7f8f8;
//     border: 1px solid #dbdfe6;
//     font-style: normal;
//     font-weight: 600;
//     font-size: 14px;
//     line-height: 20px;
//     letter-spacing: -0.03em;
//     color: #8a8e9d;

//     &.selected {
//       color: #428bff;
//       border-color: #428bff;
//       background: #f0f4fb;
//     }

//     &:first-child {
//       border-radius: 6px 0px 0px 6px;
//     }

//     &:last-child {
//       border-radius: 0px 6px 6px 0px;
//     }
//   }
// `;

// const PAYMENT_OPTIONS_ARRAY = [
//   { name: "Card", id: "card" },
//   { name: "ACH", id: "ach" },
// ];

const Payment = (props) => {
  const [activePaymentId] = useState("card");
  // const [activePaymentId, setActivePaymentId] = useState("card");

  return (
    <>
      {/* <PaymentOptions> */}
      {/* {PAYMENT_OPTIONS_ARRAY.map((option, idx) => (
          <button
            key={`${option.id}-${idx}}`}
            onClick={() => setActivePaymentId(option.id)}
            className={option.id === activePaymentId ? "selected" : ""}
          >
            {option.name}
          </button>
        ))} */}
      {/* </PaymentOptions> */}
      {activePaymentId === "ach" && (
        <PlaidPayment
          uId={props.uId}
          planId={props.planId}
          promoData={props.promoData}
          isLoading={props.isLoading}
          isPlanAvailable={props.isPlanAvailable}
          allPlans={props.allPlans}
          totalAmount={props.totalAmmount}
        />
      )}
      {activePaymentId === "card" && (
        <StripeForm
          uId={props.uId}
          planId={props.planId}
          promoData={props.promoData}
          isLoading={props.isLoading}
          isPlanAvailable={props.isPlanAvailable}
          allPlans={props.allPlans}
          totalAmount={props.totalAmmount}
        />
      )}
    </>
  );
};

export default Payment;
