import React, { useState } from "react";
import { Box } from "@atd/components";
import { DateTimePicker } from "@atd/components";
import moment from "moment";
const DateTimeFilter = ({ date, className, onChange }) => {
  const [toggle, setToggle] = useState(false);
  const collapseEntity = (e) => {
    setToggle(!toggle);
  };
  const setTimeFilter = (e) => {
    onChange("date", `${moment(e._d).format("YYYY-MM-DD | h:mm A")}`);
  };
  return (
    <Box flex={{ direction: "row" }} className="time_period_form">
      <input
        name="date"
        className={`time_period_input ${className}`}
        value={date}
        placeholder="Date"
      />
      <DateTimePicker
        date={date}
        onChange={setTimeFilter}
        collapse={collapseEntity}
        collapsible={toggle}
      />
    </Box>
  );
};

export default DateTimeFilter;
