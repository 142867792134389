import styled from "styled-components";

export const FeidRowStyle = styled.div`
  padding: 0 15px;
`;

export const FormControlStyle = styled.div`
  flex-direction: column;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  & label {
    padding-bottom: 5px;
    flex: 1;
  }

  & > .atd-Fieldset-fields {
    padding-left: 0;
    flex: 1;
  }

  & input {
    margin-left: 0;
  }

  & .acc-avt,
  & .bs-Fieldset-explanation {
    margin-left: 0;
  }
`;
