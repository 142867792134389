import ReactModal from "react-modal";

import ModalFooter from "./ModalFooter";

import "./Modal.css";

ReactModal.setAppElement(document.getElementById("root"));

function ModalBody({ children, backgroundColor, className, ...rest }) {
  return (
    <div
      style={{
        overflowY: "auto",
        maxHeight: "85vh",
        borderBottomLeftRadius: 4,
        backgroundColor,
      }}
      className={className}
    >
      <div className="pd16" {...rest}>
        {children}
      </div>
    </div>
  );
}

function Modal({
  title,
  children,
  isOpen,
  width,
  hasFooter = true,
  backgroundColor,
  onClose,
  className,
  ...rest
}) {
  const customStyles = {
    overlay: {
      overflowY: "auto",
    },
  };

  return (
    <ReactModal
      role="dialog"
      isOpen={isOpen}
      closeTimeoutMS={300}
      onRequestClose={() => onClose(!isOpen)}
      className={`atd-modal-container ${className}`}
      overlayClassName="atd-modal-overlay"
      style={customStyles}
      {...rest}
    >
      <div className="dialog" style={{ width }}>
        {title && (
          <div className="pop-title Box-divider--light-bottom-1">
            <span>{title}</span>

            <button
              title="Close"
              type="button"
              className="atd-modal-close"
              onClick={() => onClose(!isOpen)}
            >
              ×
            </button>
          </div>
        )}
        {children}
        {/* {hasFooter && <div style={{ height: 60 }} />} */}
      </div>
    </ReactModal>
  );
}

Modal.Footer = ModalFooter;
Modal.Body = ModalBody;

export default Modal;
