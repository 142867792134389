import React from "react";
import classNames from "classnames";

import styles from "./Menu.module.css";

const MenuList = (props) => {
  const { children, stickToBottom = false, className } = props;

  return (
    <ul
      className={classNames(className, styles.menuList, {
        [styles.menuListBottom]: stickToBottom,
      })}
    >
      {children}
    </ul>
  );
};

export default MenuList;
