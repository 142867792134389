import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import UserApi from "./userApi";

const initialState = {
  user: null,
  loading: true,
  error: null,
};

export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  try {
    const data = await UserApi.getUser();

    if (data.status === "error") {
      throw new Error(data.message);
    }

    return data.results;
  } catch (error) {
    throw Error(error);
  }
});

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (payload) => {
    try {
      const response = await UserApi.updateUser(payload);

      if (response.status === "error") {
        return response;
      }

      return response.results;
    } catch (error) {
      toast.error(error.message);
      throw Error(error);
    }
  },
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
    [fetchUser.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [updateUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
    [updateUser.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
  },
});

export default userSlice.reducer;
