import { useState, useEffect } from "react";
import styled from "styled-components";
import uuid from "react-uuid";

import { Button, Badge } from "@atd/components";

import PlanDetailsModal from "./PlanDetailsModal";
import Subscriptions from "./Subscriptions";

const Style = styled.div`
  display: flex;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 40px;

  & > * {
    margin-right: 10px;
  }
`;

function Plan({ name, isSelected, onChange }) {
  return (
    <Button variant={isSelected ? "primary" : "default"} onClick={onChange}>
      {name}
    </Button>
  );
}

function AddNewPlanActions({ currentPlan, plans, onChangePlan }) {
  return (
    <Style>
      {plans &&
        plans.map((plan) => (
          <Plan
            key={uuid()}
            name={plan.name}
            isSelected={plan.name === currentPlan.name}
            onChange={() => onChangePlan(plan)}
          />
        ))}
    </Style>
  );
}

function AddNewPlan({ prices, selectedPlan, onSelectPlan }) {
  const [showPlanDetailsModal, setShowPlanDetailsModal] = useState(false);

  useEffect(() => {
    onSelectPlan(prices[0]);
  }, [prices, onSelectPlan]);

  const billingPeriods = selectedPlan?.plans
    ? selectedPlan.plans.map((plan) => ({
        label: plan.period,
        value: plan.number_of_months.toString(),
      }))
    : [];

  return (
    <>
      <PlanDetailsModal
        title="Plan Details"
        show={showPlanDetailsModal}
        onClose={setShowPlanDetailsModal}
      />
      <div className="title3 mgt4 mgb2">
        ADD NEW PLAN{" "}
        <Badge
          type="info"
          onClick={() => setShowPlanDetailsModal(true)}
          style={{ cursor: "pointer" }}
        >
          Plan Details
        </Badge>
      </div>
      {selectedPlan && (
        <AddNewPlanActions
          plans={prices}
          currentPlan={selectedPlan}
          onChangePlan={onSelectPlan}
        />
      )}
      <Subscriptions periods={billingPeriods} />
    </>
  );
}

export default AddNewPlan;
