import { useContext, useState } from "react";

import { ExternalLink, Button } from "@atd/components";

import PlanContext from "./PlanContext";

function Coupon() {
  const { promo, onApplyPromo, onChangePromo } = useContext(PlanContext);

  const [showPromocodeBox, setShowPromocodeBox] = useState(false);

  return (
    <>
      <div className="center mgt2 bl-cont">
        <ExternalLink
          to="#"
          target="_self"
          onClick={(e) => {
            e.preventDefault();
            setShowPromocodeBox((prevPromoCodeBox) => !prevPromoCodeBox);
          }}
        >
          Did you receive promo code from us?
        </ExternalLink>
      </div>
      {showPromocodeBox && (
        <div className="center mgt2 bl-cont">
          <div className="atd-Fieldset-fields" style={{ marginBottom: 10 }}>
            <input
              value={promo}
              name="promo"
              className="iput acc-input"
              type="text"
              onChange={onChangePromo}
            />
          </div>
          <Button
            variant="link"
            disabled={!promo}
            label="Apply"
            onClick={onApplyPromo}
          />
        </div>
      )}
    </>
  );
}

export default Coupon;
