import * as React from "react";

function SvgJpeg(props) {
  return (
    <svg
      id="jpeg_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 240 234"
      style={{
        enableBackground: "new 0 0 240 234",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {".jpeg_svg__st17{fill:#d5d5d3}.jpeg_svg__st19{fill:#47546a}"}
      </style>
      <g id="jpeg_svg__QBygG5_00000170250121734886110960000013381916003934431156_">
        <path
          className="jpeg_svg__st17"
          d="M41.2 135.5V11c0-6.5 2.8-9.3 9.3-9.3h98.9c.9 0 1.8-.4 2.6.4.2 1.2.3 2.4.3 3.7v38.8c0 3.5.1 3.6 3.6 3.6h38.8c1.2 0 2.5.1 3.7.3.8.8.4 1.9.4 2.8v84.3c-1.2 1.2-2.8.9-4.2.9H45.9c-1.6-.1-3.3.3-4.7-1z"
        />
        <path
          d="M41.2 135.5c.7 0 1.5.1 2.2.1h153.2c.7 0 1.5 0 2.2-.1 1.1 0 2.1.1 3.2.1 5.4 0 8.6 3.2 8.6 8.5v61.4c0 5.6-3.2 8.8-8.8 8.8-1 0-2 0-2.9.1-1.4 1-3 .7-4.5.7h-149c-1.4 0-2.8.2-4-.7-1.3 0-2.6 0-3.9-.1-4.4-.1-7.8-3.4-7.8-7.8v-63.2c0-4.4 3.4-7.7 7.8-7.8 1.1.1 2.4 0 3.7 0z"
          style={{
            fill: "#4c8ed2",
          }}
        />
        <path
          className="jpeg_svg__st17"
          d="M41.2 214.4c.7 0 1.5-.1 2.2-.1h153.2c.7 0 1.5 0 2.2.1v7.4c0 5.1-3.2 8.3-8.3 8.3H49.6c-5.1 0-8.3-3.2-8.3-8.3-.1-2.5-.1-4.9-.1-7.4z"
        />
        <path
          className="jpeg_svg__st19"
          d="M198.4 48.4c-.4.8-1.1.5-1.7.5h-43.2c-1.5 0-1.9-.4-1.9-1.9.1-14.4 0-28.8 0-43.2 0-.6-.3-1.3.5-1.7 15.4 15.4 30.8 30.9 46.3 46.3zM119.9 84.4h53.4c.7 0 1.5-.1 2.2 0 2.1.3 3.4 1.6 3.5 3.8.1 2-1.4 3.7-3.5 4-.6.1-1.1 0-1.7 0H66.3c-.7 0-1.3.1-2-.1-2.1-.4-3.4-1.7-3.4-3.9s1.3-3.5 3.4-3.9c.7-.1 1.5-.1 2.2-.1 17.9.2 35.7.2 53.4.2zM120 108h53.4c.7 0 1.5-.1 2.2 0 2.1.4 3.4 1.6 3.5 3.8.1 2-1.4 3.7-3.6 4-.6.1-1.3 0-2 0h-107c-.8 0-1.7.1-2.4-.1-2-.5-3.1-1.8-3.1-3.9 0-2.1 1.2-3.4 3.2-3.8.8-.2 1.6-.1 2.4-.1 17.8.1 35.6.1 53.4.1z"
        />
        <path
          className="jpeg_svg__st17"
          d="M166.9 177.3c-.1 3.3-.1 6.6.3 9.8.2 2.1 1.3 3.8 3.4 4.4 2.2.6 4.4.7 6.2-1.2 1.6-1.7 2-4 1.6-6.2-.3-1.4-1.8-.7-2.8-.7-2.9-.1-4.4-1.8-3.9-4.6.3-1.8 1.2-2.9 3.3-2.9 3.4.1 6.7.1 10.1 0 1.8 0 3 .9 3.1 2.5.2 4.7.5 9.3-1.2 13.8-2.7 6.9-9.3 8.7-16.5 8.1-6.2-.5-11-3.2-12.8-9.6-.3-1.1-.6-2.2-.6-3.4 0-7.1-.6-14.3.3-21.4.9-7.1 4.2-10.7 11.4-11.9 6-1 11.8-.3 16.4 4.4 2.7 2.8 3.2 6.1 2.8 9.8-.1 1.1-.7 2-1.9 2.4-2.1.7-4.2.6-6.2-.2-1.4-.6-1.6-1.9-1.7-3.3-.2-3.2-1.9-4.8-5.2-4.9-3-.1-5.4 1.8-5.7 4.7-.5 3.5-.5 6.9-.4 10.4zM123.9 176.9V158c0-2.9 1.1-4.1 4-4.1 7.5-.1 15.1-.1 22.6 0 2.3 0 3.1 1.3 3 4.4 0 2.8-.9 4-3.1 4-4.8.1-9.7.1-14.5 0-1.8 0-2.3.6-2.2 2.3.1 2.3.1 4.6 0 6.9 0 1.3.5 1.7 1.7 1.7 2.3-.1 4.6 0 6.9 0 2.6 0 3.8 1.2 3.8 3.8 0 2.5-1.2 3.9-3.7 4-2.2.1-4.4.1-6.6 0-1.4-.1-2.1.3-2 1.9.1 2.3.1 4.6 0 6.9-.1 1.4.5 1.8 1.9 1.8 4.3-.1 8.7 0 13 0 4 0 5.8 2.4 4.7 6.3-.4 1.6-1.4 2.5-3.2 2.5-7.4-.1-14.8 0-22.1 0-3.1 0-4.1-1.2-4.1-4.2-.1-6.7-.1-13-.1-19.3zM87.9 176.7v-19.4c0-2 .9-3.3 2.8-3.4 5.7-.1 11.5-.5 17.2.2 10.6 1.2 12.7 10 11.4 17.8-1.2 7.3-6.1 11.1-13.7 11.5h-1.2c-2.1.2-4.8-.9-6.1.5-1.2 1.3-.3 3.9-.4 5.9-.1 2 0 4.1 0 6.1-.1 2.9-1.8 4.2-5.2 4.1-3.3-.1-4.6-1.2-4.6-4.2-.2-6.3-.2-12.7-.2-19.1zM81.1 173.1c-.3 4.4.4 9.8-.3 15.2-1.2 9.9-8.7 12.6-16.3 12.1-3.9-.2-7.6-1.3-10.5-4.1-3.1-2.9-4.1-6.4-3.7-10.5.2-2.8 2.9-4.1 6.7-3.4 1.8.4 3 1.1 3.2 3.4.4 4.4 2.5 6.2 6.2 5.9 2.8-.3 4.9-2.3 5-5.1.1-7.5 0-15.1.1-22.6 0-1.1-.3-1.7-1.5-1.6-.9.1-1.8 0-2.7 0-1.6.1-3.2.1-3.8-1.8-.7-1.9-.7-3.9.4-5.7.5-.7 1.2-1 2.1-1 3.8 0 7.5-.1 11.3 0 2.6.1 3.9 1.4 3.9 4-.1 4.7-.1 9.5-.1 15.2z"
        />
        <path
          d="M97.7 168.9v-1c.1-6.3-.7-5.6 3.1-5.7 6.9-.1 8.8 1.4 8.8 6.6 0 5.1-1.9 7-6.9 7s-5 0-5-5v-1.9z"
          style={{
            fill: "#4d8fd2",
          }}
        />
      </g>
      <path
        className="jpeg_svg__st17"
        d="M119.2 171.3s0 12.8-10.5 12.8H98.1l-.4 12.3-6.1-2.5v-10.2l6.5-3.3h12.5"
      />
    </svg>
  );
}

export default SvgJpeg;
