import React from "react";
import styled from "styled-components";

import Box from "../Box/Box";

const FooterStyle = styled.div`
  /* position: fixed; */
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${({ backgroundColor }) => backgroundColor ?? "#fff"};
  border-bottom-left-radius: 4px;
`;

function ModalFooter({ backgroundColor, children }) {
  return (
    <FooterStyle backgroundColor={backgroundColor}>
      <Box
        flex={{ justifyContent: "flex-end" }}
        divider={{ color: "light", side: "top", width: 1 }}
        padding={{ all: 16 }}
      >
        {children}
      </Box>
    </FooterStyle>
  );
}

export default ModalFooter;
