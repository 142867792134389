import Box from "../Box/Box";

function PanelBody({ children, className }) {
  return (
    <Box
      padding={{ horizontal: 8, vertical: 2 }}
      divider={{ color: "light", side: "bottom", width: 1 }}
      className={`ContentBlock ${className}`}
    >
      {children}
    </Box>
  );
}

export default PanelBody;
