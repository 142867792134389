import { Field } from "formik";

import { FormFieldInputError } from "@atd/components";
import { Text } from "./BrandDetails.style";

function TargetAudience({ text, disabled, isEdit }) {
  return (
    <>
      <div className="title2 mgt2">Target Audience</div>
      {isEdit ? (
        <div className="cont2 mgt1">
          <Field
            disabled={disabled}
            name="target_audience"
            className="iput"
            placeholder="Example: Business executives, tech-savvy people"
          />
          <FormFieldInputError name="target_audience" />
        </div>
      ) : (
        <Text className="cont2 mgt1">{text}</Text>
      )}
    </>
  );
}

export default TargetAudience;
