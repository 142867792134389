import { axios } from "@atd/utils";
import { toast } from "react-toastify";

class TemplateService {
  async getAllTemplates() {
    try {
      const { data } = await axios.get("/template");

      if (data.status === "error") {
        throw new Error(data.message);
      }

      return data.results;
    } catch (error) {
      throw Error(error);
    }
  }

  async getTemplate(templateId) {
    try {
      const { data } = await axios.get(`/template/${templateId}`);

      if (data.status === "error") {
        throw new Error(data.message);
      }

      return data.results;
    } catch (error) {
      throw Error(error);
    }
  }

  async addNew(payload) {
    try {
      const { data } = await axios.post("/template", payload);

      if (data.status === "error") {
        throw new Error(data.message);
      }

      if (data.status === "success") {
        toast.success(data.message);
      }

      return data;
    } catch (error) {
      toast.error(error.message);
      throw Error(error);
    }
  }

  async update(id, payload) {
    try {
      const { data } = await axios.put(`/template/${id}`, payload);

      if (data.status === "error") {
        throw new Error(data.message);
      }

      if (data.status === "success") {
        toast.success(data.message);
      }

      return data;
    } catch (error) {
      toast.error(error.message);
      throw Error(error);
    }
  }

  async deleteTemplate(id) {
    try {
      const { data } = await axios.delete(`/template/${id}`);

      if (data.status === "error") {
        throw new Error(data.message);
      }

      if (data.status === "success") {
        toast.success(data.message);
      }

      return id;
    } catch (error) {
      throw Error(error);
    }
  }

  async archive(id, isArchive) {
    try {
      const { data } = await axios({
        url: `/template/${id}`,
        method: isArchive ? "UNLOCK" : "LOCK",
      });

      if (data.status === "error") {
        throw new Error(data.message);
      }

      if (data.status === "success") {
        toast.success(data.message);
      }

      return data.results;
    } catch (error) {
      throw Error(error);
    }
  }

  async copy(id) {
    try {
      const { data } = await axios({
        url: `/template/${id}`,
        method: "COPY",
      });

      if (data.status === "error") {
        throw new Error(data.message);
      }

      if (data.status === "success") {
        toast.success(data.message);
      }

      return data;
    } catch (error) {
      throw Error(error);
    }
  }
}

export default new TemplateService();
