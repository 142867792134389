const data = [
  {
    name: "created",
    label: "Created date",
    checked: false,
    setting: "inTheLast",
    type: "date",
    options: {
      is_in_the_last: {
        input: "",
        unit: "days",
        error: null,
      },
      is_equal_to: {
        error: null,
        input: "",
        timestamp: null,
      },
      is_between: [
        {
          error: null,
          input: "",
          timestamp: null,
        },
        {
          error: null,
          input: "",
          timestamp: null,
        },
      ],
      is_before: {
        error: null,
        input: "",
        timestamp: null,
      },
      is_before_or_on: {
        error: null,
        input: "",
        timestamp: null,
      },
      is_after: {
        error: null,
        input: "",
        timestamp: null,
      },
      is_after_or_on: {
        error: null,
        input: "",
        timestamp: null,
      },
    },
    timezone: "Asia/Manila",
    disabled: false,
  },
  {
    name: "plans",
    label: "Plans",
    checked: false,
    setting: "true",
    type: "singular",
    options: [
      { value: "s_1", label: "Standard Monthly" },
      { value: "s_2", label: "Standard Quarterly" },
      { value: "s_3", label: "Standard Half Yearly" },
      { value: "s_4", label: "Standard Yearly" },
      { value: "p_1", label: "Pro Monthly" },
      { value: "p_2", label: "Pro Quarterly" },
      { value: "p_3", label: "Pro Half Yearly" },
      { value: "p_4", label: "Pro Yearly" },
      { value: "pr_1", label: "Premium Monthly" },
      { value: "pr_2", label: "Premium Quarterly" },
      { value: "pr_3", label: "Premium Half Yearly" },
      { value: "pr_4", label: "Premium Yearly" },
      { value: "ul_1", label: "Ultimate Monthly" },
      { value: "ul_2", label: "Ultimate Quarterly" },
      { value: "ul_3", label: "Ultimate Half Yearly" },
      { value: "ul_4", label: "Ultimate Yearly" },
    ],
    disabled: false,
  },
  // {
  //   name: "dicount",
  //   label: "Discount",
  //   checked: false,
  //   setting: "true",
  //   type: "singular",
  //   options: [
  //     { value: "does not have a discount", label: "does not have a discount" },
  //     { value: "has a discount", label: "has a discount" },
  //     { value: "BF", label: "BF" },
  //   ],
  //   disabled: false,
  // },
  {
    name: "designer",
    label: "Designer",
    checked: false,
    setting: "true",
    type: "singular",
    options: [
      { value: "1", label: "Rajesh" },
      { value: "2", label: "Edvin" },
      { value: "3", label: "Carlo" },
    ],
    disabled: false,
  },
  // {
  //   name: "status",
  //   label: "Status",
  //   checked: false,
  //   setting: "true",
  //   type: "singular",
  //   options: [
  //     { value: "discount", label: "Discount" },
  //     { value: "payment", label: "Payment" },
  //     { value: "failed", label: "Failed" },
  //   ],
  //   disabled: false,
  // },
  {
    name: "location",
    label: "Location",
    checked: false,
    setting: {
      error: null,
      input: "",
    },
    type: "location",
    placeholder: null,
    allowBlank: false,
    disabled: false,
  },
  {
    name: "email",
    label: "Email",
    checked: false,
    setting: {
      error: null,
      input: "",
    },
    type: "string",
    placeholder: null,
    allowBlank: false,
    disabled: false,
  },
];

export default data;
