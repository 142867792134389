import * as React from "react";

function SvgGreat(props) {
  return (
    <svg viewBox="0 0 50 50" {...props}>
      <path
        d="M50 25c0 13.807-11.193 25-25 25S0 38.807 0 25 11.193 0 25 0s25 11.193 25 25"
        className="great_svg__base"
      />
      <path
        d="M25.093 31H13.021c-.83 0-1.4.42-1.739 1-.376.644-.401 1.484.112 2.17C14.49 38.314 19.428 41 25 41c5.573 0 10.51-2.686 13.607-6.83.478-.64.488-1.415.181-2.038A2.009 2.009 0 0 0 36.98 31H25.093Z"
        className="great_svg__mouth"
        fill="#FFF"
      />
      <path
        d="M30.649 16.815a3.99 3.99 0 0 1 3.544-1.81 3.985 3.985 0 0 1 2.635 1.167 3.995 3.995 0 0 1 0 5.656 4 4 0 0 1-6.18-5.013Z"
        className="great_svg__right-eye"
        fill="#FFF"
      />
      <path
        d="M18.828 21.828a4.001 4.001 0 0 0-5.324-5.954 3.995 3.995 0 0 0-.332 5.954 4 4 0 0 0 5.656 0Z"
        className="great_svg__left-eye"
        fill="#FFF"
      />
    </svg>
  );
}

export default SvgGreat;
