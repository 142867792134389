import Box from "../Box/Box";
// eslint-disable-next-line import/no-named-as-default
import Tooltip from "../Tooltip/Tooltip";
import Select from "../Select/Select";

import {
  defaultAvatarUrl,
  getAssignToUsersOptions,
  getNotActiveUsers,
} from "../../utils";

function AssignUser({ users, value, isDisabled, onChange, ...rest }) {
  const assignToUsers = getAssignToUsersOptions(users);
  const disabledUsers = getNotActiveUsers(assignToUsers);

  return (
    <Select
      limit={20}
      isOptionGroup
      hasSearchField
      value={value}
      isDisabled={isDisabled}
      placement="bottom-start"
      disabled={disabledUsers}
      options={assignToUsers}
      onChange={onChange}
      scrollContainerOptions={{
        style: { maxHeight: 400 },
      }}
      render={({ items }) => (
        <span>
          {items?.length > 0 ? (
            items?.map((assignedUser) => (
              <Tooltip content={assignedUser?.label}>
                <img
                  data-testid="assign-user-img"
                  src={assignedUser?.icon || defaultAvatarUrl}
                  alt={assignedUser?.label}
                  className="client-avt Margin-left--4"
                />
              </Tooltip>
            ))
          ) : (
            <img
              data-testid="assign-user-img"
              src={defaultAvatarUrl}
              alt="ATD"
              className="client-avt Margin-left--4"
            />
          )}
        </span>
      )}
      renderOption={({ name, icon }) => (
        <Box flex={{ alignItems: "center" }}>
          <img
            src={icon}
            alt="ATD"
            className="client-avt-menu"
            style={{ marginLeft: 0 }}
          />
          {name}
        </Box>
      )}
      {...rest}
    />
  );
}

export default AssignUser;
