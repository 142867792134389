import { useState } from "react";

import ratings from "./ratings";
import FeedbackRatingControl from "./FeedbackRatingControl";
import Button from "../Button/Button";

function FeedbackForm({
  disabled,
  placeholder = "Additional feedback",
  onChange,
  onSubmit,
  formTitle = "How would you rate All Time Design?",
}) {
  const [ratingStatus, setRatingStatus] = useState(3);
  const [ratingDescription, setRatingDescription] = useState("");

  const activeRating = ratings.find((r) => r.id === ratingStatus);

  const submitFormHandler = (e) => {
    e.preventDefault();
    onSubmit({ rating: ratingStatus, comments: ratingDescription });
  };

  return (
    <section className="form">
      <div className="container-half">
        <h3 className="common-BodyTitle">{formTitle}</h3>
        <FeedbackRatingControl
          rating={ratingStatus}
          setRating={setRatingStatus}
        />
        <h4 className="common-BodyText">{activeRating.description}</h4>
        <div className="form-container">
          <form onSubmit={submitFormHandler} className="csat_rating">
            <input
              id="csat-initial-rating"
              type="hidden"
              defaultValue={ratingStatus}
              name="rating"
            />
            <div className="form-controls">
              <textarea
                required
                placeholder={placeholder}
                onChange={(e) => {
                  setRatingDescription(e.target.value);
                  if (onChange) {
                    onChange(e);
                  }
                }}
              />
              <Button
                type="submit"
                variant="primary"
                label="Submit Feedback"
                disabled={disabled || ratingDescription.length === 0}
                style={{ marginTop: 20 }}
              />
              {/* <button
                className="common-Button common-Button--default submit"
                disabled={disabled}
                style={{ border: 0 }}
                type="submit"
              >
                <span className="label">Submit Feedback</span>
              </button> */}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default FeedbackForm;
