import moment from "moment";
import styled from "styled-components";

import { Box } from "@atd/components";
import { getFormattedTime } from "@atd/utils";
import { useRequestTime } from "../Timer/Timer";

const AssignUserText = styled(Box)`
  padding-top: 4px;
`;

const ActivityContent = styled(Box)`
  background: var(--light-color);
  border: 1px solid #e3e8ee;
  box-sizing: border-box;
  border-radius: 2px;
  color: #666;
  padding: 10px 20px;
`;

const StatusLabelMain = styled.span`
  display: inline-block;
  padding: 2px 7px;
  flex: 0 0 auto;
  font-size: ${({ largeText }) => (largeText ? "16px" : "12px")} 
  line-height: 1.45454545;
  color: rgba(0, 0, 0, 0.75);
  border-radius: 3px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 275px;
  box-sizing: border-box;
  vertical-align: middle;
  white-space: nowrap;
  margin-top: -4px;
`;

const SplitPipelineLabel = styled.div`
  display: inline-block;
  padding: 3px 8px;
  background-color: #e3e8ee;
  border-radius: 5px;
  color: #4f566b;
`;

const AcitivityDate = styled.div``;

function Timer({ activity }) {
  const { timer_status, start_date, duration, user_name, created } =
    activity || {};

  const [hmins, secs] = useRequestTime(duration, 60);
  const [hrs, mins] = useRequestTime(hmins, 60);

  const time = getFormattedTime({ hrs, mins, secs });

  const isTimePlay = timer_status === "play" ? "Resume" : "";
  const timerStatus = timer_status === "start" ? "Started" : isTimePlay;

  return (
    <Box flex margin={{ bottom: 20 }}>
      <ActivityContent
        flex={{ alignItems: "center", justifyContent: "space-between" }}
        style={{ flex: 1 }}
      >
        <AssignUserText>
          <span style={{ marginRight: 5 }}>{user_name}</span>{" "}
          {["start", "play"].includes(timer_status) ? (
            <SplitPipelineLabel>{timerStatus}</SplitPipelineLabel>
          ) : (
            timer_status
          )}{" "}
          {["start", "play"].includes(timer_status) && (
            <span style={{ marginLeft: 5 }}>the ticket</span>
          )}
          {["pause", "complete"].includes(timer_status) && (
            <>
              <StatusLabelMain largeText>
                <span className="mglt1">
                  {moment(start_date).format("hh:mm A")}
                </span>
              </StatusLabelMain>{" "}
              →{" "}
              <StatusLabelMain largeText>
                <span className="mglt1">
                  {moment(created).format("hh:mm A")}
                </span>
              </StatusLabelMain>{" "}
              <StatusLabelMain>
                <SplitPipelineLabel>{time}</SplitPipelineLabel>
              </StatusLabelMain>
            </>
          )}
        </AssignUserText>
        <AcitivityDate>
          {moment(created).format("MMM DD, YYYY hh:mm A")}
        </AcitivityDate>
      </ActivityContent>
    </Box>
  );
}

export default Timer;
