import { Button, Modal } from "@atd/components";

function SearchFooter({ onSubmitHandler }) {
  return (
    <Modal.Footer>
      <Button
        variant="primary"
        label="Continue"
        type="button"
        style={{ marginLeft: "auto" }}
        onClick={onSubmitHandler}
      />
    </Modal.Footer>
  );
}

export default SearchFooter;
