import React, { useState, useEffect } from "react";
import uuid from "react-uuid";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  Heading,
  // GroupBy,
  DateFilter,
  SearchFilter,
  ContentState,
} from "@atd/components";
import { useGetCountriesFiltersQuery } from "@atd/features/team/teamApi";
import {
  fetchDesignerReviews,
  resetReviews,
  // resetReviews,
} from "@atd/features/team/teamSlice";
import { getFilterOptions } from "@atd/utils";
import { useLocalStorage } from "@atd/hooks";
import MoreReport from "../MoreReport";
import Border from "../../../Border";
import { Reviews } from "./Reviews";

function DesignerReviews({
  page = 0,
  path,
  dateFilterOptions,
  designerFilterOptions,
  setIsResetData,
  loaderRef,
  onResetData,
}) {
  const params = {
    country: "all",
    designer: "all",
    date_string: "this_year",
  };
  const [designerReviewsFilters, setDesignerReviewsFilters] = useLocalStorage(
    "designer-reviews-filters",
    params,
  );
  const [queryParams, setQueryParams] = useState(designerReviewsFilters);

  const countriesFilters = useGetCountriesFiltersQuery();
  const { reviews, hasMore, review_count, fetching } = useSelector(
    (state) => state.team,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const realPage = page + 1;
    const params = {
      ...queryParams,
      page: realPage,
      limit: path === "review" ? 20 : 4,
    };
    const promise = dispatch(fetchDesignerReviews(params));

    promise.then(() => {
      if (setIsResetData) {
        setIsResetData(false);
      }
    });

    return () => {
      promise.abort();
    };
  }, [dispatch, page, path, setIsResetData, queryParams]);

  let countriesfiltersOptions = getFilterOptions(
    countriesFilters?.data,
    "name",
    "name",
  );
  countriesfiltersOptions.unshift({ label: "All Countries", value: "all" });

  const onChangeFilter = (field, value) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      [field]: value,
    }));
    setDesignerReviewsFilters((prevParams) => ({
      ...prevParams,
      [field]: value,
    }));
    if (path === "review") {
      onResetData();
    } else {
      dispatch(resetReviews());
    }
  };

  return (
    <Box>
      <Border
        border={{ position: "bottom" }}
        margin={{ mb: "0px", mt: path === "review" ? "8px" : "40px" }}
      />
      <Heading
        headText="Designers Reviews"
        headerClassName="Text-fontSize--20 Text-fontWeight--bold Text-lineHeight--28 Padding-left--8"
        headCount={review_count}
        rightContent={
          <Box flex={{ direction: "row", justifyContent: "flex-end" }}>
            <SearchFilter
              field="country"
              placeholder="All Countries"
              value={queryParams?.country}
              onChange={onChangeFilter}
              options={countriesfiltersOptions}
            />
            <SearchFilter
              field="designer"
              placeholder="All Designers"
              value={queryParams?.designer}
              onChange={onChangeFilter}
              options={designerFilterOptions}
            />
            <Box className="Margin-left--8">
              <DateFilter
                value={queryParams?.date_string}
                startDate={queryParams?.start_date}
                endDate={queryParams?.end_date}
                options={dateFilterOptions}
                onChange={onChangeFilter}
              />
            </Box>
          </Box>
        }
      />
      <Border
        margin={{ mt: "10px", mb: "20px" }}
        border={{ position: "bottom" }}
      />
      <div>
        <Reviews
          key={uuid()}
          page={page}
          queryParams={queryParams}
          data={reviews}
          loaderRef={loaderRef}
          hasMore={hasMore}
          setIsResetData={setIsResetData}
          isFetching={fetching}
        />
      </div>
      {(hasMore || fetching) && (
        <Box ref={loaderRef}>
          <ContentState text="Loading..." />
        </Box>
      )}
      {path !== "review" && <MoreReport name="More Reviews" path="review" />}
    </Box>
  );
}

export default DesignerReviews;
