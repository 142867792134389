import { withDataTablePage } from "@atd/hoc";

import { Button } from "@atd/components";

import BrandsList from "./BrandsList/BrandsList";

export default withDataTablePage((ownProps) => {
  const { navigate } = ownProps;
  return {
    title: "Brands",
    hasTabs: true,
    action: (
      <Button
        variant="default"
        icon="left-arrow"
        onClick={() => navigate("/requests")}
      >
        Back to Requests
      </Button>
    ),
  };
})(BrandsList);
