import { useState } from "react";

import {
  Alert,
  Box,
  AppHeader,
  ContentState,
  ExternalLink,
  Typography,
} from "@atd/components";
import {
  useGetBillingQuery,
  useGetSubscriptionQuery,
} from "@atd/features/user";

import PaymentMethod from "./PaymentMethod";
import InvoiceHistory from "./InvoiceHistory";
import AddCardModal from "./AddCardModal";

function Payment() {
  const [isShowAddCardModal, setIsShowAddCardModal] = useState(false);

  const { data, isLoading } = useGetBillingQuery();
  const { data: subscription } = useGetSubscriptionQuery();

  const {
    card_data,
    invoice_data,
    show_invoice,
    card_update_url,
    show_card_update,
    payment_method,
  } = data || {};

  return (
    <>
      <AppHeader
        title={<Typography>Payment</Typography>}
        spread="settingsContent"
      />
      {subscription?.code === 888 && (
        <Alert
          status="error"
          title="Payment Failed"
          icon="warning"
          withIcon
          description={<span>{subscription?.message}</span>}
        />
      )}
      <Box margin={{ top: 20 }} style={{ maxWidth: 610 }}>
        {isLoading && <ContentState />}
        {!isLoading && (
          <>
            <Alert className="text">
              Check out the help article{" "}
              <ExternalLink to="https://help.alltimedesign.com/en/articles/9003679-learn-everything-you-need-to-know-about-our-billing-structure">
                here
              </ExternalLink>{" "}
              to know more about our billing periods.
            </Alert>
            <PaymentMethod
              type={payment_method}
              cardData={card_data}
              showCardUpdate={show_card_update}
              cardUpdateUrl={card_update_url}
              onShowAddCardModal={() => setIsShowAddCardModal(true)}
              isSubscriptionActive={
                subscription?.results?.is_active && subscription?.code !== 888
              }
            />
            {show_invoice && <InvoiceHistory data={invoice_data} />}
          </>
        )}
      </Box>
      <AddCardModal
        isVisible={isShowAddCardModal}
        onToggleVisibility={() =>
          setIsShowAddCardModal((currentState) => !currentState)
        }
      />
    </>
  );
}

export default Payment;
