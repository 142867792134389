import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import { Box, ContentState, EmptyState } from "@atd/components";
import axios from "@atd/app/axiosClient";
import { formatBytes } from "@atd/utils";
import FileCardIcon from "@atd/components/FileCard/FileCardIcon";
import DownloadFile from "./DownloadFile";

function DesignFiles({ id }) {
  const dispatch = useDispatch();

  const [allDetails, setAllDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!id) return;

    (async () => {
      try {
        const { data } = await axios.get(`/requests/${id}`, {
          params: {
            tab: "design_files",
          },
        });
        if (data?.results) {
          setAllDetails(data.results);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dispatch, id]);

  return (
    <Box flex={{ direction: "row" }}>
      <Box padding={{ right: 20 }} className="wt-100">
        {isLoading && <ContentState />}
        {!isLoading && allDetails?.length === 0 && (
          <EmptyState
            title="No results found"
            description={
              <span>There aren&rsquo;t any results for that query.</span>
            }
          />
        )}
        {!isLoading && allDetails?.length > 0 && (
          <table className="table mb-0">
            <thead>
              <tr>
                <th scope="col" className="border-0 tb-title pl5 wt-15">
                  VERSION TYPE
                </th>
                <th
                  aria-hidden="true"
                  scope="col"
                  className="border-0 wt-4"
                  style={{ minWidth: 45 }}
                />
                <th scope="col" className="border-0 tb-title pl5">
                  NAME
                </th>
                <th scope="col" className="border-0 tb-title pl5 wt-20">
                  SIZE
                </th>
                <th scope="col" className="border-0 tb-title pl5 wt-10">
                  LAST UPDATED
                </th>
                <th aria-hidden="true" scope="col" className="border-0 wt-4" />
              </tr>
            </thead>
            <tbody>
              {allDetails &&
                allDetails.map((detail) => (
                  <tr>
                    <td style={{ textTransform: "uppercase" }}>
                      {detail?.version}
                    </td>
                    <td>
                      <FileCardIcon icon={detail.format} />
                    </td>
                    <td>
                      <div
                        className="title1 mt-2"
                        style={{ maxWidth: 400 }}
                        title={detail?.full_name}
                      >
                        {detail?.full_name}
                      </div>
                    </td>
                    <td className="pro-click">{formatBytes(detail?.size)}</td>
                    <td>
                      <div className="cont1 mt-3">
                        {moment().format("D MMMM")}
                      </div>
                    </td>
                    <td className="dropdown">
                      <DownloadFile link={detail?.link} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </Box>
    </Box>
  );
}

export default DesignFiles;
