import { getCredentialCookies } from "@atd/utils";

import { accountApi } from "../user/accountApi";

const { token } = getCredentialCookies();

const filtersApi = accountApi.injectEndpoints({
  tagTypes: ["Filters"],
  endpoints: (build) => ({
    filters: build.query({
      // note: an optional `queryFn` may be used in place of `query`
      query: (status) => ({
        url: "/search_filters",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          status,
        },
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response) => response.results,
      providesTags: (id) => [{ type: "Filters", id }],
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useFiltersQuery } = filtersApi;
