import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";

const withNetworkDetector = (WrappedComponent) =>
  function (props) {
    const [isDisconnected, setIsDisconnected] = useState(false);

    const handleConnectionChange = useCallback(() => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        setIsDisconnected(false);
      } else {
        setIsDisconnected(true);
      }
    }, []);

    useEffect(() => {
      handleConnectionChange();
      window.addEventListener("online", handleConnectionChange);
      window.addEventListener("offline", handleConnectionChange);

      return () => {
        window.removeEventListener("online", handleConnectionChange);
        window.removeEventListener("offline", handleConnectionChange);
      };
    }, [handleConnectionChange]);

    useEffect(() => {
      if (isDisconnected) {
        toast.error("No internet connection.", {
          autoClose: !isDisconnected,
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.dismiss();
      }
    }, [isDisconnected]);

    return <WrappedComponent {...props} />;
  };

export default withNetworkDetector;
