import React, { useState } from "react";

import DatePickerContentBlock from "./DatePickerContentBlock";
import DatePickerContentHeader from "./DatePickerContentHeader";
import DatePickerFooter from "./DatePickerFooter";

function DatePickerWithPresets({ onApply, onClear }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <>
      <div className="db-DatePickerWithPresets">
        <DatePickerContentHeader
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <DatePickerContentBlock
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>
      <DatePickerFooter
        disabled={!startDate && !endDate}
        onClear={() => {
          setStartDate(null);
          setEndDate(null);
          onClear();
        }}
        onApply={(e) => {
          e.stopPropagation();
          onApply({ startDate, endDate });
        }}
      />
    </>
  );
}

export default DatePickerWithPresets;
