import React from "react";

// import { External } from "@outreach/components/Icon/icons";

import styles from "./Menu.module.css";

const MenuExternalIcon = () => {
  return <span className={styles.itemExternalIcon}>{/* <External /> */}</span>;
};

export default MenuExternalIcon;
