import axios from "@atd/app/axiosClient";
import { toast } from "react-toastify";

class UserService {
  async getUser() {
    try {
      const { data } = await axios.get("/user");

      if (data.status === "error") {
        throw new Error(data.message);
      }

      return data;
    } catch (error) {
      throw Error(error);
    }
  }

  async updateUser(payload) {
    try {
      const { data } = await axios.put("/user", payload);

      if (data.status === "error") {
        throw new Error(data.message);
      }

      if (data.status === "success") {
        toast.success(data.message);
      }

      return data;
    } catch (error) {
      toast.error(error);
      throw Error(error);
    }
  }
}

export default new UserService();
