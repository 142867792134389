import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { pick } from "lodash";
import { Formik, Form } from "formik";

import { fetchBrand, createBrand, updateBrand } from "@atd/features";
import {
  ContentState,
  PageContent,
  AppHeader,
  Box,
  Button,
} from "@atd/components";
import { Main } from "@atd/layouts";
import { BrandSchemaAdd, BrandSchemaUpdate } from "@atd/schema";

import TargetAudience from "./TargetAudience";
import Description from "./Description";
import BrandColors from "./BrandColors";
import Actions from "./Actions";
import BrandName from "./BrandName";
import MediaFile from "./MediaFile";

function BrandCreate({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [formValues, setFormValues] = useState(null);

  const { brand, loading } = useSelector((state) => state.brand);
  const dispatch = useDispatch();

  const { id } = params;
  const { state } = location || {};

  const { from } = state || {};

  useEffect(() => {
    const brandPayload = pick(
      {
        ...brand,
        target_audience: Array.isArray(brand?.target_audience)
          ? brand?.target_audience?.[0]
          : brand?.target_audience,
      },
      [
        "brand_name",
        "target_audience",
        "hex_colors",
        "description",
        "attachments",
        "logo",
        "fonts",
      ],
    );
    setFormValues(brandPayload);
  }, [brand]);

  useEffect(() => {
    if (id !== "new") {
      dispatch(fetchBrand(id));
    }
  }, [id, dispatch]);

  const isCreating = id === "new";
  const isEditView = id && id !== "new";

  const onSubmit = async (values, { setSubmitting }) => {
    if (isCreating) {
      const response = await dispatch(createBrand(values)).unwrap();
      if (response.status === "success") {
        navigate(from ? `${from}?brand=${response?.results?.id}` : "/brands");
      }
    } else {
      const { status, code } = await dispatch(
        updateBrand({
          id,
          data: {
            ...values,
            target_audience: Array.isArray(values?.target_audience)
              ? values?.target_audience?.[0]
              : values?.target_audience,
          },
        }),
      ).unwrap();
      if (code === 200 && status === "success" && isEdit) {
        navigate(-1);
      }
    }
    setSubmitting(false);
  };

  const initialValues = {
    brand_name: "",
    target_audience: "",
    description: "",
    hex_colors: [],
    logo: [],
    fonts: [],
    attachments: [],
  };

  let validationSchema;
  if (isEdit) {
    validationSchema = BrandSchemaUpdate;
  }
  if (isCreating) {
    validationSchema = BrandSchemaAdd;
  }

  return (
    <Main>
      <Formik
        initialValues={isCreating ? initialValues : formValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        enableReinitialize
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <AppHeader
              spread="appContent"
              marginBottom={false}
              title={!isCreating ? brand?.brand_name : "Brands"}
            >
              <Box>
                <Button
                  type="button"
                  variant="link"
                  label="Cancel"
                  disabled={isSubmitting}
                  onClick={() => (isEdit ? navigate(-1) : navigate("/brands"))}
                />
                {isEditView && !isEdit && (
                  <Button
                    type="submit"
                    variant="primary"
                    label="Edit"
                    disabled={isSubmitting}
                    onClick={() => navigate("edit")}
                  />
                )}
                {isEdit && (
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    label="Update"
                  />
                )}
                {!isEdit && !isEditView && (
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    label="Save"
                  />
                )}
              </Box>
            </AppHeader>
            <PageContent>
              {loading && (
                <Box padding={{ vertical: 20 }} style={{ width: "100%" }}>
                  <ContentState text="Loading" />
                </Box>
              )}
              {!loading && (
                <Box padding={{ vertical: 20 }} style={{ width: "100%" }}>
                  {(isCreating || isEdit) && (
                    <BrandName disabled={isSubmitting} />
                  )}
                  <TargetAudience
                    disabled={isSubmitting}
                    isEdit={isEdit || isCreating}
                    text={values?.target_audience}
                  />
                  <Description
                    disabled={isSubmitting}
                    isEdit={isEdit || isCreating}
                    text={values?.description || ""}
                    setFieldValue={setFieldValue}
                  />
                  <hr className="mgt3" />
                  <BrandColors
                    formattedColors={brand?.formatted_colors}
                    colors={values?.hex_colors}
                    isEdit={isEdit || isCreating}
                    setFieldValue={setFieldValue}
                  />
                  <hr />
                  <MediaFile
                    iconUrl="https://spiceblue.co/atd_ui/ticket/assets/images/logo-icon.png"
                    name="logo"
                    label="Logo"
                    value={values.logo || []}
                    isEdit={isEdit || isCreating}
                    setFieldValue={setFieldValue}
                  />
                  <hr />
                  <MediaFile
                    iconUrl="https://spiceblue.co/atd_ui/ticket/assets/images/pdf.png"
                    name="attachments"
                    label="Brand Guide"
                    value={values.attachments || []}
                    isEdit={isEdit || isCreating}
                    setFieldValue={setFieldValue}
                  />
                  <hr />
                  <MediaFile
                    iconUrl="https://spiceblue.co/atd_ui/ticket/assets/images/zip.png"
                    name="fonts"
                    label="Fonts"
                    value={values.fonts || []}
                    isEdit={isEdit || isCreating}
                    setFieldValue={setFieldValue}
                  />
                  <hr className="mgt3" />
                  <Actions
                    disabled={isSubmitting}
                    isEdit={isEdit}
                    isEditView={isEditView}
                    onCancel={() =>
                      isEdit ? navigate(-1) : navigate("/brands")
                    }
                    onEdit={() => navigate("edit")}
                  />
                </Box>
              )}
            </PageContent>
          </Form>
        )}
      </Formik>
    </Main>
  );
}

export default BrandCreate;
