import * as React from "react";

function SvgDoubleArrow(props) {
  return (
    <svg
      aria-hidden="true"
      className="DoubleArrow_svg__SVGInline-svg DoubleArrow_svg__SVGInline--cleaned-svg DoubleArrow_svg__SVG-svg DoubleArrow_svg__Icon-svg DoubleArrow_svg__Icon--arrowUpDown-svg DoubleArrow_svg__SearchableSelect-arrows-svg DoubleArrow_svg__Icon-color-svg DoubleArrow_svg__Icon-color--gray600-svg"
      height={12}
      width={12}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M11.891 9.992a1 1 0 1 1 1.416 1.415l-4.3 4.3a1 1 0 0 1-1.414 0l-4.3-4.3A1 1 0 0 1 4.71 9.992l3.59 3.591 3.591-3.591zm0-3.984L8.3 2.417 4.709 6.008a1 1 0 0 1-1.416-1.415l4.3-4.3a1 1 0 0 1 1.414 0l4.3 4.3a1 1 0 1 1-1.416 1.415z" />
    </svg>
  );
}

export default SvgDoubleArrow;
