import React from "react";

import Box from "../Box/Box";
import Text from "../Text/Text";

function FormLayout({ title, description, children }) {
  return (
    <Box background="white" className="FormLayout">
      <Box>
        <Box
          padding={{ top: 48 }}
          flex={{ justifyContent: "center" }}
          className="mgb5"
        >
          <Box className="ccss18">
            <fieldset className="FormBlock">
              <Box padding={{ vertical: 8 }} className="mgb5">
                <Box>
                  <Box padding={{ top: 32 }}>
                    <Box padding={{ bottom: 24 }} className="mgt2">
                      <Text
                        color="dark"
                        size={24}
                        medium="medium"
                        wrap="wrap"
                        display="inline"
                      >
                        <span>{title}</span>
                      </Text>
                      <div className="cont2">{description}</div>
                    </Box>
                    {children}
                  </Box>
                </Box>
              </Box>
            </fieldset>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default FormLayout;
