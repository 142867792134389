import React from "react";
import { Box, Heading, DateFilter, SearchFilter } from "@atd/components";
import Border from "@atd/pages/Reports/Border";
import styled from "styled-components";
const TicketHeading = styled(Heading)`
  & > span > .heading {
    line-height: 28px;
    font-weight: 700;
    font-size: 20px;
    padding-left: 8px;
  }
`;

const ReportBox = styled(Box)`
  width: auto !important;
  dispaly: flex;
  flex-wrap: wrap;
  /* @media only screen and (min-width: 1000px) and (max-width: 1420px) {
    max-width: 530px !important;
  } */
  /* @media screen and (min-width: 500px) {
    width: 480 !important;
  } */
`;
function TicketReportHeader({
  title,
  params,
  placeholder,
  groupByField,
  dateFilterOptions,
  groupByFilterOptions,
  onChangeFilter,
}) {
  const headTitle = title
    .split("_")
    .map((word) => (word[0] || "")?.toUpperCase() + word.substr(1))
    .join(" ");
  return (
    <ReportBox>
      <Border
        border={{ position: "bottom" }}
        margin={{ mb: "4px", mt: "0px" }}
      />
      <TicketHeading
        headText={headTitle}
        headerClassName="Text-fontSize--20 Text-fontWeight--bold Text-lineHeight--28 Padding-left--8"
        rightContent={
          <Box
            flex={{
              direction: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {groupByFilterOptions && (
              <Box className="Margin-right--8">
                <SearchFilter
                  field={groupByField}
                  placeholder={placeholder}
                  value={params[groupByField]}
                  options={groupByFilterOptions}
                  onChange={onChangeFilter}
                />
              </Box>
            )}
            <Box className="Margin-right--8">
              <DateFilter
                value={params?.date_string}
                startDate={params?.start_date}
                endDate={params?.end_date}
                options={dateFilterOptions}
                onChange={onChangeFilter}
              />
            </Box>
          </Box>
        }
      />
      <Border
        border={{ position: "bottom" }}
        margin={{ mb: "20px", mt: "12px" }}
      />
    </ReportBox>
  );
}

export default TicketReportHeader;
