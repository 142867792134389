import * as React from "react";

function SvgPencil(props) {
  return (
    <svg
      aria-hidden="true"
      className="Pencil_svg__SVGInline-svg Pencil_svg__SVGInline--cleaned-svg Pencil_svg__SVG-svg Pencil_svg__Icon-svg Pencil_svg__Icon--edit-svg Pencil_svg__Button-icon-svg Pencil_svg__Icon-color-svg Pencil_svg__Icon-color--inherit-svg"
      height={12}
      width={12}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.194 4.28 11.72.805l.087-.087a2.456 2.456 0 1 1 3.473 3.473zm-1.415 1.414-9.264 9.264L0 16l1.042-4.515 9.264-9.264z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgPencil;
