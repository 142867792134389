import { SlooviFeedback } from "sloovi-feedback";

import { getCredentialCookies, isAdmin } from "@atd/utils";
import { Button } from "@atd/components";
import styled from "styled-components";

const ButtonStyle = styled(Button)`
  svg {
    width: 17px;
    height: 17px;
  }
`;

function FeedbackButton() {
  const { email } = getCredentialCookies();

  return (
    <div className="TopNav-button">
      {!isAdmin() && (
        <SlooviFeedback
          projectId="project_c4bb9a6ae9624db3a04c00ee9ccc1f4f"
          clientId="client_7442521eaf0f4995bdbf9cdb00e94c14"
          userId={email}
        >
          <ButtonStyle
            withShadow={false}
            variant="link"
            withPadding={false}
            icon="feedback"
            label="Give us feedback"
          />
        </SlooviFeedback>
      )}
    </div>
  );
}

export default FeedbackButton;
