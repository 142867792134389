import styled from "styled-components";

export const FilterContainer = styled.ul`
  & li {
    overflow: hidden;
    margin: 0;
  }
  & [type="radio"]:checked ~ label,
  & [type="checkbox"]:checked ~ label {
    border-bottom: 0;
  }
  & li .content {
    height: 0px;
    border-top: 0;
    transition: all 0.5s ease-out;
    background-color: #f6f8fa;
    text-align: center;
    overflow: hidden;
  }
  & [type="checkbox"]:checked ~ label ~ .content {
    padding-top: 11px;
    height: 50px;
  }
  & li .content1 {
    height: 0px;
    border-top: 0;
    transition: all 0.5s ease-out;
    background-color: #f6f8fa;
    text-align: center;
  }
  & [type="checkbox"]:checked ~ label ~ .content1 {
    height: 120px;
  }
  .Checkbox-input {
    margin: 0;
    appearance: none;
    background-color: rgb(255, 255, 255);
    height: 14px;
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
      rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px,
      rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 3px 9px 0px,
      rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    min-width: 14px;
    width: 14px;
    border-radius: 4px;
    outline: none;
    transition: all 0.2s ease;
    vertical-align: middle;
  }

  .Checkbox-input:checked {
    position: relative;
    background-color: rgb(84, 105, 212);
    border-color: transparent;
  }

  .Checkbox-input:checked::after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    width: 5px;
    height: 8px;
    top: 1px;
    left: 1px;
    border: 2px solid #fff;
    border-top: none;
    border-left: none;
    transform-origin: bottom right;
    transform: translateX(0) translateY(1px) rotate(45deg);
  }

  & {
    padding: 0px;
    margin: 0px;
  }

  & li:hover {
    background-color: transparent;
  }
`;
