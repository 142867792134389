import * as React from "react";

function SvgTime(props) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.833 2.225A5.835 5.835 0 0 1 8 13.833 5.833 5.833 0 0 1 4.052 3.706l-1.18-1.18A7.5 7.5 0 1 0 7.168.546V5.57h1.666V2.225Z"
        fill="#1A1F36"
      />
      <path
        d="M4.423 5.256a.833.833 0 0 0 0 1.178L6.78 8.792A.833.833 0 0 0 7.96 7.613L5.6 5.256a.833.833 0 0 0-1.178 0Z"
        fill="#1A1F36"
      />
    </svg>
  );
}

export default SvgTime;
