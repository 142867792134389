import { useMemo, useState, useRef } from "react";
import Tippy from "@tippyjs/react";
import maxSize from "popper-max-size-modifier";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/scale-subtle.css";

function Popover({
  style,
  children,
  hasHeader,
  onHide,
  offset,
  appendTo = document.body,
  component: Component,
  placement = "bottom-start",
  trigger = "click",
  renderComponent,
  arrow = true,
  ...rest
}) {
  const tippyBox = useRef(null);

  const [instance, setInstance] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const onMount = () => {
    setIsOpen(true);
  };

  const onHidePopover = (instanceValue) => {
    const unmountInstance = () => {
      instanceValue.unmount();

      // need to control when we remove the listener because transitionend fires multiple times
      instanceValue.popper.firstChild.removeEventListener(
        "transitionend",
        unmountInstance,
      );
    };

    instanceValue.popper.firstChild.addEventListener(
      "transitionend",
      unmountInstance,
    );

    if (onHide) {
      onHide(instanceValue);
    }

    setIsOpen(false);
  };

  // Create your own apply modifier that adds the styles to the state
  const applyMaxSize = useMemo(
    () => ({
      name: "applyMaxSize",
      enabled: true,
      phase: "beforeWrite",
      requires: ["maxSize"],
      fn({ state }) {
        // The `maxSize` modifier provides this data
        const { width, height } = state.modifiersData.maxSize;

        tippyBox.current.style.maxHeight = `${height}px`;
        tippyBox.current.style.maxWidth = `${width}px`;

        state.styles.popper = {
          ...state.styles.popper,
          maxWidth: width,
          maxHeight: height,
        };
      },
    }),
    [],
  );

  const popperOptions = {
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          padding: 12,
        },
      },
      maxSize,
      applyMaxSize,
    ],
  };

  return (
    <Tippy
      animation="scale-subtle"
      theme="light-border"
      offset={offset || [0, 14]}
      trigger={trigger}
      placement={placement || "bottom"}
      interactive
      appendTo={appendTo}
      popperOptions={popperOptions}
      onMount={onMount}
      onHide={onHidePopover}
      onCreate={(popoverInstance) => setInstance(popoverInstance)}
      render={(attrs) => (
        <div
          ref={tippyBox}
          className="tippy-box"
          data-state={isOpen ? "visible" : "hidden"}
          data-animation="scale-subtle"
          style={{
            ...style,
            transitionDuration: isOpen ? ".25s" : "0",
          }}
          {...attrs}
        >
          {renderComponent
            ? renderComponent({ instance, isOpen })
            : isOpen && <Component />}
          {arrow && <div data-popper-arrow="true" />}
        </div>
      )}
      {...rest}
    >
      {children}
    </Tippy>
  );
}

export default Popover;
