/* eslint-disable no-unused-vars */
import styled from "styled-components";
import { plansData, plans_list, services } from "./data";
import checkIcon from "./assets/icon-check-circle-green.svg";
// import AccountSetup from "./AccountSetup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setActivePlanCards, setCurrentPlan } from "../../features";
import { Load, Loader } from "./components/SkeletonLoader";
import { useSearchParams } from "react-router-dom";

const SelectService = () => {
  const dispatch = useDispatch();
  const allPlans = { Standard: 0, Pro: 1, Premium: 2, Ultimate: 4 };
  const allPlan = ["Standard", "Pro", "Premium", "Ultimate", "Ultimate"];
  const [searchParams] = useSearchParams();

  const { planDetails, currentPlan } = useSelector((store) => store.auth);
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [trialStatus, setTrialStatus] = useState(false);

  //used this useEffect to keep trace of the currentPlan.
  useEffect(() => {
    setSelectedPlan(allPlans?.[planDetails[currentPlan]?.name] || 0);
  }, [allPlans, currentPlan, planDetails]);

  const changePlan = (plan_detail) => {
    const plan = `${plan_detail} ${planDetails?.[currentPlan]?.planType}`;
    if (trialStatus) {
      dispatch(setCurrentPlan(plansData[plan]));
    } else if (!trialStatus) {
      dispatch(setCurrentPlan(plans_list[plan]));
    }

    dispatch(setActivePlanCards(planDetails?.[currentPlan]?.period));
  };
  const plan = planDetails?.[currentPlan];

  useEffect(() => {
    if (searchParams.get("offers") === "off_50") {
      setTrialStatus(true);
    }
  }, [searchParams]);
  return (
    <>
      <div id="__content_account" className="co-grid-left padding-left">
        <div>
          <label
            htmlFor="__title"
            className="form-label-2 color--gray-900 align-left"
          >
            What’s included in your plan
          </label>
          <div className="flx mgt2">
            <div>
              {plan?.name ? (
                <div className="co-sidebar">
                  <div className="co-cart-title">
                    {plan?.name ? plan?.name + " " + plan?.planType : "Loading"}
                  </div>
                  <div className="sn-value color--black semi-bold fnt32">
                    {plan?.actual_price === plan?.offer_price ? (
                      ""
                    ) : (
                      <span style={{ textDecoration: "line-through" }}>
                        ${plan.actual_price}
                      </span>
                    )}{" "}
                    $
                    {trialStatus
                      ? plan.planType === "Monthly"
                        ? plan?.offer_price
                        : plan?.offer_price
                      : plan?.offer_price}
                    {trialStatus && plan.planType === "Monthly" ? (
                      <span>
                        /1<sup>st</sup>&nbsp;month
                      </span>
                    ) : (
                      <span>/m</span>
                    )}
                  </div>
                </div>
              ) : (
                <Loader className="co-sidebar">
                  <Load className="para__load"></Load>
                  <Load className="heading__load"></Load>
                </Loader>
              )}
            </div>
            <div>
              <div className="mgt2 lstcont">
                <img
                  loading="lazy"
                  src={checkIcon}
                  alt=""
                  className="icon-check-circle-green vbot"
                />
                140+ graphic design services
              </div>
              <div className="mgt2 lstcont">
                <img
                  loading="lazy"
                  src={checkIcon}
                  alt=""
                  className="icon-check-circle-green vbot"
                />
                Custom illustrations
              </div>

              <div className="mgt2 lstcont">
                <img
                  loading="lazy"
                  src={checkIcon}
                  alt=""
                  className="icon-check-circle-green vbot"
                />
                {(plan?.name === "Standard" || !plan) && "2 day turnaround"}
                {plan?.name === "Pro" && "24 hours turnaround"}
                {plan?.name === "Premium" && "Sameday Turnaround"}
                {plan?.name === "Ultimate" && "Source Files"}
              </div>
            </div>
            <div>
              <div className="mgt2 lstcont">
                <img
                  loading="lazy"
                  src={checkIcon}
                  alt=""
                  className="icon-check-circle-green vbot"
                />
                Unlimited Brands
              </div>
              <div className="mgt2 lstcont">
                <img
                  loading="lazy"
                  src={checkIcon}
                  alt=""
                  className="icon-check-circle-green vbot"
                />
                Unlimited Designs
              </div>
              <div
                className="mgt2 lstcont"
                style={{ display: plan?.name === "Ultimate" && "none" }}
              >
                <img
                  loading="lazy"
                  src={checkIcon}
                  alt=""
                  className="icon-check-circle-green vbot"
                />
                Source Files
              </div>
            </div>
          </div>
          <label
            htmlFor="__title"
            className="form-label-2 color--gray-900 align-left mgt5"
          >
            Select your add-ons
          </label>
          {services.service.map(
            ({ heading, desc, logo, plan, plan_detail, id }, ind) => {
              return (
                <PlanDetailsCol
                  className="checkbox mgt2"
                  key={ind}
                  onClick={(e) => {
                    e.preventDefault();
                    if (plan_detail === allPlan[selectedPlan]) {
                      // making standard plan selected by default
                      if (selectedPlan <= 0) {
                        setSelectedPlan(0);
                        changePlan(allPlan[0]);
                        return;
                        // as we have 2 "Unlimited plan" in allPlan we need this else if
                      } else if (selectedPlan === 4) {
                        setSelectedPlan(2);
                        changePlan(allPlan[2]);
                        return;
                      }
                      //if the plan current plan ad selected plan are same without the above cases
                      setSelectedPlan(ind - 1);
                      changePlan(allPlan[ind - 1]);
                      return;
                    }
                    // default action if current plan ad selected plan are not equal.
                    setSelectedPlan(ind);
                    changePlan(allPlan[ind]);
                  }}
                >
                  <label className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={ind <= selectedPlan ? true : false}
                    />
                    <span className="checkbox-tile grn">
                      <div>
                        <img
                          src={logo}
                          width={130}
                          style={{ borderRadius: "4px" }}
                          alt="plan logo"
                        />
                      </div>
                      <div className="ckitems">
                        <span className="ctitle">
                          {/* Custom Illustrations&nbsp;&nbsp; */}
                          {heading}&nbsp;&nbsp;
                          {plan && <div className="pln">{plan}</div>}
                        </span>
                        <span className="cdesc">{desc}</span>
                      </div>
                      <div className="rincl">
                        <div
                          style={{
                            display: ind <= selectedPlan ? "" : "none",
                          }}
                          className="incl"
                        >
                          INCLUDED
                        </div>
                      </div>
                    </span>
                  </label>
                </PlanDetailsCol>
              );
            },
          )}
          <hr className="hrule" />
          <label
            htmlFor="__title"
            className="form-label-2 color--gray-900 align-left mgt3"
          >
            Want a design manager?
          </label>
          {services.adds.map(
            ({ heading, desc, logo, plan, plan_detail }, ind) => {
              return (
                <PlanDetailsCol
                  className="checkbox mgt2"
                  key={ind}
                  onClick={(e) => {
                    setSelectedPlan((prevPlan) => {
                      if (prevPlan <= 1) {
                        changePlan(plan_detail);
                        return 1;
                      }
                      return prevPlan;
                    });
                  }}
                >
                  <label className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={ind <= selectedPlan ? true : false}
                    />
                    <span className="checkbox-tile grn">
                      <div>
                        <img
                          src={logo}
                          width={130}
                          style={{ borderRadius: "4px" }}
                          alt="logo"
                        />
                      </div>
                      <div className="ckitems">
                        <span className="ctitle">
                          {/* Custom Illustrations&nbsp;&nbsp; */}
                          {heading}&nbsp;&nbsp;
                          {plan && <div className="pln">{plan}</div>}
                        </span>
                        <span className="cdesc">{desc}</span>
                      </div>
                      <div className="rincl">
                        <div
                          className="incl"
                          style={{
                            display: ind <= selectedPlan ? "" : "none",
                          }}
                        >
                          INCLUDED
                        </div>
                      </div>
                    </span>
                  </label>
                </PlanDetailsCol>
              );
            },
          )}
        </div>
      </div>
    </>
  );
};

export default SelectService;

const PlanDetailsCol = styled.div`
  .checkbox-tile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    height: 116px !important;
  }
`;
