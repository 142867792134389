import { memo, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useGetSubscriptionQuery } from "../../features/user";
import { axios, getCurrentTimeZone, isAdmin } from "../../utils";

import Sidebar from "../Sidebar";

// const INTERCOM_APP_ID = "d8nohwun";

function Root({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  // const { name, email, created } = getCredentialCookies() || {};

  const { data } = useGetSubscriptionQuery();

  useEffect(() => {
    const fetchUserStatus = () => {
      axios.get(`user_status?local_time_zone=${getCurrentTimeZone()}`);
    };
    fetchUserStatus();
    const interval = setInterval(() => {
      fetchUserStatus();
    }, 50000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const { code } = data || {};

    if (
      (code === 999 || code === 404) &&
      location.pathname !== "/settings/plans" &&
      !isAdmin()
    ) {
      navigate("/settings/plans");
    }

    if (
      code === 888 &&
      location.pathname !== "/settings/payment" &&
      !isAdmin()
    ) {
      navigate("/settings/payment");
    }
  });

  // useEffect(() => {
  //   if (!isAdmin() && checkCredentialCookies()) {
  //     window.intercomSettings = {
  //       api_base: "https://api-iam.intercom.io",
  //       app_id: INTERCOM_APP_ID,
  //       name,
  //       email,
  //       created_at: created,
  //     };
  //   }

  //   return () => {
  //     window.Intercom("shutdown");
  //   };
  // }, [name, email, created]);

  return (
    <div id="main">
      <div className="container">
        <div className="content-area-wrap">
          <Sidebar />
          {children || <Outlet />}
        </div>
      </div>
    </div>
  );
}

export default memo(Root);
