import * as React from "react";

const SvgArrowRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    width="1em"
    height="1em"
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path
      fill="currentColor"
      d="M340.864 192.128 652.672 512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12 0 0 0 41.6 0L714.24 534.336a32 32 0 0 0 0-44.672L382.528 149.376a29.12 29.12 0 0 0-41.728 0 30.592 30.592 0 0 0 0 42.752z"
    />
  </svg>
);

export default SvgArrowRight;
