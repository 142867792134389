/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

import { FormFieldInputError } from "@atd/components";

export const ErrorMessage = styled(FormFieldInputError)`
  text-align: left;
  margin-top: 0;
  margin-bottom: 15px;
  margin-top: -10px;
`;
