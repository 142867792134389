import * as React from "react";

function SvgCompleteColored(props) {
  return (
    <svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20a20 20 0 1 1 40 0 20 20 0 0 1-40 0Zm18.859 8.56 11.514-14.395-2.08-1.664-9.818 12.27-6.955-5.795-1.707 2.048 9.046 7.539v-.003Z"
        fill="#228403"
      />
    </svg>
  );
}

export default SvgCompleteColored;
