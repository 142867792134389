import React from "react";
import { NavLink } from "react-router-dom";
// import styled from "styled-components";

// const SettingsMenuTitleStyle = styled.div`
//   margin-bottom: 5px;
//   font-weight: bold;
// `;

// function SettingsMenuTitle({ children }) {
//   return (
//     <SettingsMenuTitleStyle className="title2 Padding-left--20">
//       {children}
//     </SettingsMenuTitleStyle>
//   );
// }

function SettingsMenuItem({ link, children }) {
  return (
    <li>
      <NavLink
        to={link}
        style={({ isActive }) => ({ color: isActive && "#ea552b" })}
      >
        {children}
      </NavLink>
    </li>
  );
}

const SettingsMenu = ({ isAdmin, items }) => {
  const menus = items.map((item) => {
    if (item?.restricted) {
      return null;
    }
    const settingsItemMenu = item.menu.filter((menu) => {
      if (menu?.restricted) {
        return null;
      }
      // eslint-disable-next-line no-prototype-builtins
      if (!menu.hasOwnProperty("userType")) {
        return menu;
      }
      if (isAdmin()) {
        return menu.userType === "admin";
      }
      return menu.userType === "client";
    });

    return (
      <div className="settings-group">
        {item.title && <h2>{item.title}</h2>}
        <ul>
          {settingsItemMenu.map((itemMenu) => (
            <SettingsMenuItem key={item.title} link={itemMenu.link}>
              {itemMenu.name}
            </SettingsMenuItem>
          ))}
        </ul>
      </div>
    );
  });

  return menus;
};

export default SettingsMenu;
