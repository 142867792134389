import React from "react";

function PanelFooter({ right, children, ...rest }) {
  return (
    <div
      className="ContentFooter Box-root Box-background--white Padding-horizontal--20 Padding-vertical--16 Flex-flex Flex-alignItems--stretch Flex-direction--row Flex-justifyContent--flexEnd"
      {...rest}
    >
      <div className="Box-root Flex-flex" style={{ float: right }}>
        {children}
      </div>
    </div>
  );
}

export default PanelFooter;
