import React from "react";

import { Editor } from "@atd/components";

function NotesEditor(props) {
  return (
    <div id="editor">
      <Editor {...props} hasCustomBottomToolbar />
    </div>
  );
}

export default NotesEditor;
