import * as React from "react";

function SvgLock(props) {
  return (
    <svg
      width={10}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 .167a2.92 2.92 0 0 0-2.917 2.916v1.75H1.5A1.167 1.167 0 0 0 .333 6v4.667A1.167 1.167 0 0 0 1.5 11.833h7a1.167 1.167 0 0 0 1.167-1.166V6A1.167 1.167 0 0 0 8.5 4.833h-.583v-1.75A2.92 2.92 0 0 0 5 .167ZM3.25 3.083c0-.965.785-1.75 1.75-1.75s1.75.785 1.75 1.75v1.75h-3.5v-1.75Zm2.333 6.255v1.329H4.417V9.338a1.163 1.163 0 0 1 .848-2.141 1.167 1.167 0 0 1 .745 1.717 1.16 1.16 0 0 1-.427.424Z"
        fill="#A3ACB9"
      />
    </svg>
  );
}

export default SvgLock;
