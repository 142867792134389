import React, { useMemo } from "react";
import { Box, DataTable } from "@atd/components";
import styled from "styled-components";
const ReportDataTable = styled(DataTable)`
  margin-top: 20px;
  tr {
    border-bottom: 1px solid #e3e8ee;
  }
  tr:hover {
    background-color: rgb(247, 250, 252);
  }
  th:nth-child(2) {
    text-align: end;
  }
  td:nth-child(2) {
    text-align: end;
  }
  th {
    font-size: 14px;
    text-transform: uppercase;
    line-height: 20px;
    font-weight: 500;
  }
  @media screen and (max-width: 1000px) and (max-width: 1420px) {
    max-width: 300px !important;
  }
  @media screen and (min-width: 500px) {
    width: 480 !important;
  }
`;
function TicketReportDataTable({ isLoading, columns, data }) {
  const tableData = useMemo(() => {
    const table = data?.length
      ? data?.map((item) => ({
          location: item?.entity,
          count: item?.tickets,
        }))
      : [];
    return table;
  }, [data]);
  return (
    <Box
      flex={{
        direction: "column",
        justifyContent: "flex-start",
        wrap: "wrap",
      }}
      style={{ height: "300px", overflow: "hidden" }}
      className="Margin-horizontal--20"
    >
      <ReportDataTable
        tabs={[""]}
        withTabs={false}
        isLoading={isLoading}
        columns={columns}
        data={tableData}
      />
    </Box>
  );
}

export default TicketReportDataTable;
