/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch } from "react-redux";
import moment from "moment";

import { Box, Button, EmptyState, ExternalLink } from "@atd/components";
import { updateClient } from "@atd/features";

import { FieldSet, Title } from "./CompanyDetails";

function InvoiceDetails({ invoiceData, company, client }) {
  const [formValues, setFormValues] = useState();

  const { show_invoice, show_card_update } = client || {};

  const dispatch = useDispatch();

  useEffect(() => {
    setFormValues({
      show_invoice,
      show_card_update,
    });
  }, [show_invoice, show_card_update]);

  const onUpdateCompanyDetails = async (values, { setSubmitting }) => {
    await dispatch(
      updateClient({
        id: company,
        data: {
          update_type: "card_and_invoice",
          show_invoice: !!values.show_invoice,
          show_card_update: !!values.show_card_update,
        },
      }),
    );
    setSubmitting(false);
  };

  const initialValues = {
    show_invoice: false,
    show_card_update: false,
  };

  return (
    <>
      <Title>Invoice Actions</Title>
      <Box className="ContentBlock" id="clint">
        <Formik
          initialValues={formValues || initialValues}
          onSubmit={onUpdateCompanyDetails}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <div id="MerchantInfoForm">
                <Box margin={{ bottom: 2 }} className="atd-Fieldset-wrapper">
                  <Box
                    background="offset"
                    margin={{ top: 12 }}
                    padding={{ horizontal: 9, vertical: 2 }}
                    divider={{
                      color: "light",
                      side: "bottom",
                      width: 1,
                    }}
                    className="ContentBlock"
                  >
                    <fieldset className="atd-Fieldset">
                      <div className="atd-Fieldset-rows">
                        <Field
                          label="Enable Invoice Download options"
                          name="show_invoice"
                          component={FieldSet}
                        />
                        <Field
                          label="Enable Card Update options"
                          name="show_card_update"
                          component={FieldSet}
                        />
                      </div>
                    </fieldset>
                  </Box>

                  <table className="table mb-0 wt-100 mgt1">
                    <thead>
                      <tr>
                        <th scope="col" className="border-0 tb-title pl5">
                          INVOICE DETAILS
                        </th>
                        <th scope="col" className="border-0 tb-title pl5">
                          AMOUNT
                        </th>
                        <th scope="col" className="border-0 tb-title wt-9" />
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceData?.length === 0 && (
                        <EmptyState
                          icon="client"
                          title="No invoice data"
                          description={
                            <span>
                              There aren&lsquo;t any results for that query.
                            </span>
                          }
                        />
                      )}
                      {invoiceData?.length > 0 &&
                        invoiceData?.map((invoice) => (
                          <tr>
                            <td className="title2">
                              Invoice for{" "}
                              {moment(invoice?.date).format("MMMM DD, YYYY")}
                            </td>
                            <td>
                              {invoice?.currency}
                              {invoice?.amount}
                            </td>
                            <td>
                              <Box
                                hideIfEmpty
                                margin={{ top: 8, left: 8 }}
                                className="customcss9"
                              >
                                <ExternalLink
                                  to={invoice?.invoice_url}
                                  target="_blank"
                                  className="wt-but"
                                >
                                  View
                                </ExternalLink>
                              </Box>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <Box
                    flex={{
                      alignItems: "stretch",
                      direction: "row",
                      justifyContent: "flex-end",
                    }}
                    padding={{
                      horizontal: 20,
                      vertical: 16,
                    }}
                    className="ContentFooter"
                  >
                    <Box flex={{ display: "flex" }} className="fltrgt">
                      <Box hideIfEmpty className="pointer1">
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={isSubmitting}
                        >
                          Update
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}

export default InvoiceDetails;
