import React from "react";
import FeedbackTable from "./FeedbackTable";
import { Box, ContentState } from "@atd/components";
function FeedbackList({
  page,
  hasMore,
  fetching,
  loaderRef,
  hasError,
  hasPermission,
  setIsResetData,
  onResetData,
  queryParams,
  data,
  tabs,
  onClickTabItem,
}) {
  // const [queryParams, setQueryParams] = useState({
  //   status: "all",
  //   page: 1,
  // });

  // const onClickTabItem = (index) => {
  //   setIsResetData(true);
  //   onResetData();
  //   setQueryParams({
  //     ...queryParams,
  //     status: tabList[index].id,
  //   });
  // };
  const tableData = (dataTable) => {
    const table = dataTable
      ? dataTable.map((item) => {
          const keys = Object.keys(item);
          const mapItem = keys.reduce(
            (acc, val) => ({
              ...acc,
              [val]: item[val],
            }),
            {},
          );
          return mapItem;
        })
      : [];
    return table;
  };
  return (
    <>
      <FeedbackTable
        error={null}
        hasMore={hasMore}
        fetching={fetching}
        queryParams={queryParams}
        page={page}
        data={tableData(data)}
        tabList={tabs}
        hasError={false}
        hasPermission={true}
        onClickTabItem={onClickTabItem}
      />
      {(hasMore || fetching) && !hasError && (
        <Box ref={loaderRef}>
          <ContentState text="Loading..." />
        </Box>
      )}
    </>
  );
}

export default FeedbackList;
