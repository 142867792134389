import { Dropdown, MoreButton } from "@atd/components";

function DownloadFile({ link }) {
  return (
    <Dropdown as={MoreButton} onChange={() => window.open(link)}>
      <Dropdown.Options>
        <Dropdown.Option value="download">Download</Dropdown.Option>
      </Dropdown.Options>
    </Dropdown>
  );
}

export default DownloadFile;
