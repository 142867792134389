import { Icon, Spinner } from "@atd/components";

import { Card } from "./BrandDetails.style";

function BrandInfoAdder({ title, onClick, isLoading, inputProps }) {
  return (
    <Card contentAlign="center" onClick={onClick}>
      {isLoading && <Spinner size="large" />}
      {!isLoading && (
        <>
          <Icon icon="plus" />
          <div className="title2 mgt1">{title}</div>
          <input
            type="file"
            id="fileinput"
            style={{ display: "none" }}
            {...inputProps}
          />
        </>
      )}
    </Card>
  );
}

export default BrandInfoAdder;
