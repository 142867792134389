import { cloneElement, useState } from "react";
import uuid from "react-uuid";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";

import Table from "../Table/Table";
import ContentState from "../ContentState/ContentState";
import EmptyState from "../EmptyState/EmptyState";
import Badge from "../Badge/Badge";

const DataTableTabs = styled(Tabs)`
  ${(props) =>
    props.withinHeader && "margin-top: -55px; position: relative; z-index: 1;"}

  .tabs {
    border: 0;
  }
`;

function DataTable({
  data,
  columns,
  isLoading,
  tabs = [""],
  tabCounts = -1,
  withTableHead = true,
  withTabs = true,
  withTabCounts = false,
  withEmptyState = false,
  onClick,
  onClickTabItem,
  className,
  tabClassName,
  withinHeader,
  ...rest
}) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleClickTabItem = (index) => {
    setActiveTabIndex(index);
    onClickTabItem(index);
  };

  const rows = data?.map((dataRow, index) => {
    return {
      index,
      id: dataRow.id,
      value: dataRow,
      cells: columns.map((col) => {
        return {
          id: dataRow[col.accessor]?.id || dataRow?.id,
          column: col,
          row: dataRow,
          onClick: col.onClick || "undefined",
          render: (type) => {
            if (type === "Cell") {
              const Cell = col?.Cell;

              if (Cell) {
                return cloneElement(<Cell />, { value: dataRow[col.accessor] });
              }

              return dataRow[col.accessor];
            } else if (
              type === "className" &&
              typeof col?.className === "function"
            ) {
              return col?.className(dataRow[col.accessor]);
            } else if (
              type === "className" &&
              typeof col?.className === "string"
            ) {
              return col?.className;
            }

            return null;
          },
        };
      }),
    };
  });

  return (
    <DataTableTabs
      className={tabClassName}
      selectedTabClassName="active"
      selectedIndex={activeTabIndex}
      onSelect={handleClickTabItem}
      withinHeader={withinHeader}
    >
      {withTabs ? (
        <TabList className="tabs Padding-all--0 Margin-bottom--0 Requests--tabs">
          {tabs.map((item, index) => (
            <Tab key={item.id} className="tab-item capitalize">
              {item.value}
              {withTabCounts && tabCounts[tabs?.[index]?.id] && (
                <Badge style={{ marginLeft: 5 }}>
                  {tabCounts[tabs[index].id]}
                </Badge>
              )}
            </Tab>
          ))}
        </TabList>
      ) : null}

      {tabs.map((item) => (
        <TabPanel key={item.id}>
          <Table className={`table mb-0 wt-100 mgt1 ${className}`} {...rest}>
            {withTableHead ? (
              <Table.Head>
                <Table.Row>
                  {columns?.map((col) => (
                    <Table.Cell
                      key={uuid()}
                      component="th"
                      scope="col"
                      className={`border-0 tb-title pl5${
                        col?.className ? ` ${col?.className}` : ""
                      }`}
                    >
                      {col.Header}
                    </Table.Cell>
                  ))}
                </Table.Row>
              </Table.Head>
            ) : null}
            <Table.Body>
              {isLoading && (
                <Table.Row>
                  <Table.Cell
                    component="td"
                    colSpan={columns?.length}
                    style={{ border: 0 }}
                  >
                    <ContentState />
                  </Table.Cell>
                </Table.Row>
              )}
              {!isLoading && rows?.length === 0 && withEmptyState && (
                <Table.Row>
                  <Table.Cell
                    component="td"
                    colSpan={columns?.length}
                    style={{ border: 0 }}
                  >
                    <EmptyState
                      title="No results found"
                      description={
                        <span>
                          There aren&rsquo;t any results for that query.
                        </span>
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              )}
              {!isLoading && rows?.length > 0 && (
                <>
                  {rows.map((row) => (
                    <Table.Row key={row.id}>
                      {row.cells.map((cell) => (
                        <Table.Cell
                          key={uuid()}
                          component="td"
                          className={cell?.render("className")}
                          onClick={() => cell.onClick?.(cell.id)}
                        >
                          {cell.render("Cell")}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </>
              )}
            </Table.Body>
          </Table>
        </TabPanel>
      ))}
    </DataTableTabs>
  );
}

export default DataTable;
