import { Select, Box } from "@atd/components";

function SearchFilter({
  value,
  options,
  onChange,
  field,
  placeholder,
  size = "small",
  margin = { top: 8, left: 8 },
  inputStyle,
  ...rest
}) {
  return (
    <Box id="filter-status" hideIfEmpty margin={margin} className="pointer1">
      <Box>
        <Select
          limit={20}
          size={size}
          inputStyle={inputStyle}
          value={value}
          hasSearchField
          placeholder={placeholder}
          className="Box-root Flex-flex wt-auto"
          options={options}
          onChange={(companyId) => {
            onChange(field, companyId);
          }}
          {...rest}
        />
      </Box>
    </Box>
  );
}

export default SearchFilter;
