import { useState } from "react";
import styled from "styled-components";

import { Badge, Box, Modal } from "@atd/components";

import ConfirmPlanSelectionModal from "../../Settings/Plans/ConfirmPlanSelectionModal";

const MarketingVideoBox = styled.div`
  background: white;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  border-radius: 3px;
  margin-top: 20px;
  padding: 20px;

  img {
    cursor: pointer;
  }
`;

function MarketingVideo({ plan }) {
  // const [selectedPlan, setSelectedPlan] = useState(null);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [showUpdatePlanModal, setShowUpdatePlanModal] = useState(false);

  // const planStatus = ["Premium", "Ultimate"].includes(plan)
  //   ? "info"
  //   : "#30B284";
  // const planColor = plan === "Pro" ? "#5469d4" : planNonProColor;

  return (
    <>
      <ConfirmPlanSelectionModal
        // plan={selectedPlan}
        // isForUltimatePlan
        isOpen={showUpdatePlanModal}
        onClose={() => setShowUpdatePlanModal(false)}
      />
      <Modal
        isOpen={isVideoVisible}
        hasFooter={false}
        width={1000}
        onClose={setIsVideoVisible}
        className="youtube_url"
      >
        <iframe
          width="100%"
          height="1000"
          src="https://www.youtube.com/embed/RlLuzMDvt3w?autoplay=1&modestbranding=1&controls=0&showinfo=0&autohide=1&rel=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          style={{ display: "inherit", maxHeight: "85vh", overflow: "hidden" }}
        ></iframe>
      </Modal>
      <MarketingVideoBox>
        <Badge type="info">Ultimate Plan</Badge>
        <div className="title2 mgt2">Scale your brand</div>
        <div className="cont2 mgt1">
          Level up your marketing game with motion graphics and website UI
          designs.
        </div>
        <div className="mgt2">
          <img
            alt="ATD Marketing Video"
            src="https://spiceblue.co/atd_ui/atdapp/client/assets/images/video.png"
            onClick={() => setIsVideoVisible(true)}
          />
        </div>
        <Box flex={{ justifyContent: "center" }} margin={{ top: 12 }}>
          {/* <Button
            variant="primary"
            label="Upgrade to Ultimate"
            onClick={() => setShowUpdatePlanModal(true)}
          /> */}
        </Box>
      </MarketingVideoBox>
    </>
  );
}

export default MarketingVideo;
