import { useState } from "react";
import uuid from "react-uuid";

import {
  CardWrapper,
  Card,
  CardTitle,
  Color,
  SmallText,
  Close,
} from "./BrandDetails.style";
import ColorPicker from "../ColorPicker";

function BrandColors({ colors, formattedColors, isEdit, setFieldValue }) {
  const [showColorPicker, setShowColorPicker] = useState(false);

  const onRemoveColor = (index) => {
    const updatedAttachments = [...colors];
    if (index > -1) {
      updatedAttachments.splice(index, 1);
    }
    setFieldValue("hex_colors", updatedAttachments);
  };

  if (!isEdit && (colors?.length === 0 || !colors)) {
    return null;
  }

  return (
    <div>
      <div className="title2 mgt2">Colors</div>
      <CardWrapper>
        {colors?.map((color, i) => {
          const activeColor = formattedColors
            ? formattedColors.find((c) => c.colors === color)
            : null;

          return (
            <Card key={uuid()}>
              {isEdit && (
                <Close icon="cancel" onClick={() => onRemoveColor(i)} />
              )}
              <Color color={color} />
              <CardTitle top="15px" className="title2">
                {color}
              </CardTitle>
              {activeColor?.type && <SmallText>{activeColor.type}</SmallText>}
            </Card>
          );
        })}
        {isEdit && (
          <ColorPicker
            showColorPicker={showColorPicker}
            onShowColorPicker={setShowColorPicker}
            onChange={(color) => {
              setFieldValue("hex_colors", [...colors, color.hex]);
            }}
          />
        )}
      </CardWrapper>
    </div>
  );
}

export default BrandColors;
