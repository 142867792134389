import { useState, useCallback } from "react";
import { toast } from "react-toastify";

import { Modal, Button } from "@atd/components";
import { useUpgradePlanMutation, useGetBillingQuery } from "@atd/features/user";
import { getErrorMessage } from "@atd/utils";
import { useNavigate } from "react-router-dom";
import ConfirmPlanItem from "./ConfirmPlanItem";

function ConfirmPlanSelectionModal({
  plan,
  isOpen,
  isForUltimatePlan = false,
  onClose,
}) {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [updatePlanData, setUpdatePlanData] = useState(null);
  const navigate = useNavigate();
  const { refetch: refetchBillingQuery } = useGetBillingQuery();
  const [upgradePlan] = useUpgradePlanMutation();

  const onSetUpdatePlan = useCallback((value) => setUpdatePlanData(value), []);

  const onUpgradePlan = useCallback(async () => {
    const { subscription_id } = plan || {};
    try {
      setIsUpgrading(true);
      const { data } = await upgradePlan({
        subscription_id: updatePlanData?.subscription_id || subscription_id,
        id: selectedPlan?.id,
      });
      if (data?.code === 202) {
        await refetchBillingQuery();
        onClose();
        toast.success(data?.message);
        navigate("/settings/plan");
        return;
      } else if (data?.code === 200) {
        onClose();
        toast.success(data?.message);
        return;
      }
      onClose();
      const errorMessage = getErrorMessage(data);
      toast.error(errorMessage);
    } finally {
      setIsUpgrading(false);
    }
  }, [
    plan,
    onClose,
    refetchBillingQuery,
    selectedPlan,
    upgradePlan,
    updatePlanData,
    navigate,
  ]);

  return (
    <Modal title="Confirm Plan Selection" isOpen={isOpen} onClose={onClose}>
      <Modal.Body>
        <ConfirmPlanItem
          plan={plan}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          setUpdatePlanData={onSetUpdatePlan}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="Box-root Box-hideIfEmpty Margin-right--8">
          <Button variant="default" type="button" onClick={onClose}>
            Cancel
          </Button>
        </div>
        <div className="Box-root Box-hideIfEmpty">
          <Button
            type="button"
            variant="primary"
            disabled={isUpgrading || !selectedPlan}
            onClick={onUpgradePlan}
          >
            Confirm Plan
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmPlanSelectionModal;
