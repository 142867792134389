import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import uuid from "react-uuid";

import { getCredentialCookies } from "../../utils/tokenUtilities";

const { token } = getCredentialCookies();

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: [
    "Temp",
    "Register",
    "Billing",
    "Price",
    "Subscription",
    "Upgrade",
    "Announcement",
  ],
  endpoints: (build) => ({
    getPlans: build.query({
      query: () => ({
        url: "plans",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response) => {
        const currentData = { ...response.results };
        currentData.plans = currentData.plans.map((plan) => ({
          id: uuid(),
          name: plan?.name,
          data: plan.data,
          standard: plan.data?.[0],
          pro: plan.data?.[1],
          offer: plan?.offer,
          quantity: 1,
          period: plan.period,
        }));
        return currentData;
      },
    }),
    getUpgrade: build.query({
      query: ({ subscription_id, id }) => ({
        url: "upgrade",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          subscription_id,
          id,
        },
      }),
      // transformResponse: (response) => response.results,
      providesTags: ["Upgrade"],
    }),
    getBilling: build.query({
      query: (params) => ({
        url: "/billing",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      }),
      transformResponse: (response) => response.results,
      providesTags: ["Billing"],
    }),
    getPrice: build.query({
      query: (params) => ({
        url: "/price",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      }),
      transformResponse: (response) => response.results,
      providesTags: ["Price"],
    }),
    getSubscription: build.query({
      query: () => ({
        url: "subscription",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      providesTags: ["Subscription"],
    }),
    upgradePlan: build.mutation({
      query: ({ subscription_id, id }) => ({
        url: "upgrade",
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          subscription_id,
          id,
        },
      }),
      invalidatesTags: ["Billing"],
    }),
    addAccount: build.mutation({
      query: (body) => ({
        url: "temp_register",
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body,
      }),
      invalidatesTags: ["Temp"],
    }),
    registerAccount: build.mutation({
      query: ({ body, params = { payment_method: "card" } }) => ({
        url: "/register",
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body,
        params,
      }),
      invalidatesTags: ["Register"],
    }),
    addCard: build.mutation({
      query: (body) => ({
        url: "/card_payment_source",
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body,
      }),
      invalidatesTags: ["Billing"],
    }),
  }),
});

export const {
  useGetPlansQuery,
  useAddAccountMutation,
  useRegisterAccountMutation,
  useUpgradePlanMutation,
  useAddCardMutation,
  useGetPromoQuery,
  useGetBillingQuery,
  useGetUpgradeQuery,
  useGetPriceQuery,
  useGetSubscriptionQuery,
} = accountApi;
