import styled, { css } from "styled-components";

const PressablePart = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  & > div {
    border-radius: 4px;
  }

  ${(props) =>
    props.keylines &&
    css`
      z-index: 2;
    `}
  ${(props) =>
    props.shadows &&
    css`
      z-index: 1;
    `}
  ${(props) =>
    props.selectedKeyline &&
    css`
      z-index: 3;
      box-shadow: 0 0 0 2px blue;
    `}
  ${(props) =>
    props.focusShadow &&
    css`
      box-shadow: 0 0 0 0 rgb(0 0 0 / 0%);
      transition: opacity 0.25s, box-shadow 0.25s;
    `}
`;

export default PressablePart;
