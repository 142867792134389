import { ContentState, EmptyState } from "@atd/components";
import SearchItem from "./SearchItem";
import styles from "./RoyalImages.module.css";

function SearchBody({
  isLoading,
  searchImages,
  selectedImg,
  selectImgHandler,
}) {
  return (
    <div className="mgt2">
      <div className={styles.unsplashImgs}>
        {isLoading && <ContentState />}
        {!isLoading &&
          searchImages?.length > 0 &&
          searchImages?.map((img) => (
            <SearchItem
              key={img.id}
              img={img}
              className={
                // eslint-disable-next-line no-nested-ternary
                selectedImg
                  ? img?.urls?.regular === selectedImg
                    ? styles.active
                    : undefined
                  : undefined
              }
              onClick={() => selectImgHandler(img?.urls?.regular)}
            />
          ))}
        {!isLoading && searchImages.length === 0 && (
          <EmptyState
            style={{ minHeight: 0 }}
            title="No results found"
            description={
              <span>There aren&rsquo;t any results for that query.</span>
            }
          />
        )}
      </div>
    </div>
  );
}

export default SearchBody;
