import styled from "styled-components";

import { Button, Spinner } from "@atd/components";
import {
  PlayColored,
  PauseColored,
  CompleteColored,
} from "@atd/components/Icon/icons";

const TimerLoading = styled.span`
  border-radius: 100px;
  background-color: #9d9d9d;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
`;

const ButtonStyle = styled(Button)`
  padding: 0;
`;

function TimeButton({ isLoading, onClick, icon }) {
  return (
    <ButtonStyle
      variant="link"
      withPadding={false}
      onClick={() => {
        if (!isLoading && onClick) {
          onClick();
        }
      }}
    >
      {isLoading ? (
        <TimerLoading>
          <Spinner size="larget" white />
        </TimerLoading>
      ) : (
        <>
          {icon === "play" && <PlayColored />}
          {icon === "pause" && (
            <PauseColored
              style={{ transform: "scale(0.9) translate(-2px, -2px)" }}
            />
          )}
          {icon === "complete" && (
            <CompleteColored
              style={{ transform: "scale(0.9) translate(-2px, -2px)" }}
            />
          )}
        </>
      )}
    </ButtonStyle>
  );
}

export default TimeButton;
