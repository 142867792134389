import { useState } from "react";

import { Badge } from "@atd/components";

import PlanDetailsModal from "./PlanDetailsModal";

function PlanDetails() {
  const [showPlanDetailsModal, setShowPlanDetailsModal] = useState(false);

  return (
    <>
      <PlanDetailsModal
        title="Plan Details"
        show={showPlanDetailsModal}
        onClose={setShowPlanDetailsModal}
      />
      <Badge
        type="info"
        onClick={() => setShowPlanDetailsModal(true)}
        style={{ cursor: "pointer" }}
      >
        Plan Details
      </Badge>
    </>
  );
}

export default PlanDetails;
