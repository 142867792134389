import { createSlice } from "@reduxjs/toolkit";
import { axios, getCurrentTimeZone } from "@atd/utils";
import { getCredentialCookies } from "@atd/utils/tokenUtilities";

const { token } = getCredentialCookies();
export const fetchActivities =
  (
    { group_by = "all", select, date_string, start_date, end_date },
    page = "1",
    reference_data,
  ) =>
  async (dispatch) => {
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        group_by,
        select,
        date_string,
        start_date,
        end_date,
        reference_data,
        time_zone: getCurrentTimeZone(),
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/activities?page=${page}`,
      config,
    );

    dispatch(setFetching(true));
    dispatch(setActivities(response.data.results));
    dispatch(setFetching(false));
  };

export const activitySlice = createSlice({
  name: "activity",
  initialState: {
    activities: [],
    reference_data: [],
    loading: true,
    error: null,
    fetching: false,
    hasMore: false,
    hasError: false,
  },
  reducers: {
    setActivities: (state, { payload }) => {
      state.activities = [...state.activities, ...(payload.data || [])];
      state.hasMore = payload.has_more;
      state.reference_data = JSON.stringify(payload.reference_data);
    },
    setFetching: (state, { payload }) => {
      state.fetching = payload;
      state.loading = payload;
    },
    resetActivities: (state, { payload }) => {
      state.activities = [];
      state.hasMore = false;
      state.loaing = true;
      state.fetching = true;
    },
  },
});

export const { setActivities, setFetching, resetActivities } =
  activitySlice.actions;
export default activitySlice.reducer;
