import { Field } from "formik";

function VideoUrl({ onChange }) {
  return (
    <>
      <div className="Box-root Flex-flex Flex-alignItems--center Flex-direction--row mgt4 wt200">
        <div className="Box-root Margin-right--8">
          <span
            title="Gross volume"
            className="Text-color--default Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--block customcss7"
          >
            <span>Video Brief</span>
          </span>
        </div>
        <div className="Box-root Box-hideIfEmpty mwt58">
          <div
            aria-label="0.0%"
            title="0%"
            className="Badge Badge--variant--status Box-root Box-background--gray100 Flex-inlineFlex Flex-alignItems--center Flex-direction--row"
          >
            <span className="Badge-text Text-color--gray600 Text-fontSize--12 Text-fontWeight--medium Text-lineHeight--16 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--inline">
              Optional
            </span>
          </div>
        </div>
      </div>
      <div className="cont4">
        Do you find writing down your design brief time-consuming? Record it
        using the loom and post the link below.
      </div>
      <div className="FormField mgt1">
        <Field
          name="video_url"
          className="iput"
          placeholder="Enter the video URL here"
          type="url"
          onChange={onChange}
        />
      </div>
    </>
  );
}

export default VideoUrl;
