import styled from "styled-components";

const Container = styled.div`
  border-radius: 5px;
  padding: 8px 12px;
  background-color: ${(props) =>
    props.status === "warning" ? "#fcedb9" : "var(--light-color)"};
`;

const Title = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
  color: var(--primary-color);
`;

const Content = styled.div`
  color: ${(props) => (props.status === "warning" ? "#a82c00" : "#697386")};
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  line-height: 20px;
  font-weight: 400;
  font-size: 14px;
`;

function Message({ title, text, status, ...rest }) {
  return (
    <Container status={status} {...rest}>
      {title && <Title>{title}</Title>}
      <Content status={status}>{text}</Content>
    </Container>
  );
}

export default Message;
