import React from "react";

import Header from "./PanelHeader";
import Body from "./PanelBody";
import Footer from "./PanelFooter";

import "./Card.css";

function Panel({ children }) {
  return (
    <div className="Box-root Padding-bottom--20">
      <div className="Card-root Card--radius--all Card--shadow--keyline Section Box-root Box-background--white">
        {children}
      </div>
    </div>
  );
}

Panel.Header = Header;
Panel.Body = Body;
Panel.Footer = Footer;

export default Panel;
