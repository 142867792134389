import {
  useCallback,
  useEffect,
  // useMemo,
  createContext,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { debounce, pick } from "lodash";

import {
  Box,
  Button,
  DashboardForm,
  FullscreenHeader,
  FullscreenContent,
  FormLayout,
  ContentState,
  EmptyState,
  FullscreenView,
  ScrollToFieldError,
} from "@atd/components";
import { useAttachment } from "@atd/hooks";
import { fetchBrands, getRequest, getRequestTypesAsync } from "@atd/features";
import { RequestSchema } from "@atd/schema";
import RequestService from "@atd/features/request/requestApi";

import FormBody from "./FormBody/FormBody";

export const CreateRequestContext = createContext({
  onChangeField: () => {},
});

const initialValues = {
  dimentions: [
    {
      category_id: "",
      category_item_id: "",
      dimension_string: "",
      title_id: "",
      height: 0,
      length: 0,
      units: "px",
      icon: "",
    },
  ],
  request_name: "",
  request_type: "",
  request_icon: "",
  file_dimentions: "",
  // category_item_id: "",
  // file_dimentions: [
  //   {
  //     category_id: "",
  //     category_item_id: "",
  //     title_id: "",
  //     height: null,
  //     length: null,
  //     units: "px",
  //     icon: "",
  //   },
  // ],
  brand_id: "",
  file_type: [],
  project_details: "",
  royalty_free_images: [],
  attachments: [],
  inspiration_url: "",
  video_url: "",
};

function CreateRequest() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [formValues, setFormValues] = useState(null);

  const { brands, loading: brandsLoading } = useSelector(
    (state) => state.brand,
  );
  const {
    request,
    error,
    isRequestLoading: loading,
  } = useSelector((state) => state.requests);
  const dispatch = useDispatch();

  const { attachments: currentAttachments } = request || {};

  const pathArray = location?.pathname.split("/");
  // eslint-disable-next-line no-unused-vars
  const lastSlug = pathArray?.[pathArray.length - 1];

  const { id } = params || {};
  const isEditing = true;
  // const isEditing = useMemo(() => lastSlug !== "new", [lastSlug]);

  const newBrandId = searchParams.get("brand");

  const { attachments, isUploading, onSetAttachments, onRemoveAttachment } =
    useAttachment(isEditing ? currentAttachments : null, { request_id: id });
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    // accept: ".pdf",
    onDrop: (files) => {
      onSetAttachments(files);
    },
  });

  useEffect(() => {
    if (request) {
      const modifiedFileDimention = request.file_dimentions.reduce(
        (acc, fileDimention) => {
          acc.push(`${fileDimention.dimension_string}`);
          return acc;
        },
        [],
      );
      const requestValues = pick(
        {
          ...request,
          dimentions: request.file_dimentions,
          brand_id: newBrandId ?? request?.brand_id,
          file_dimentions: modifiedFileDimention.join(", "),
          file_type: request.file_type,
          project_details: request.project_details,
        },
        Object.keys(initialValues),
      );
      setFormValues(requestValues);
    }
  }, [request, newBrandId]);

  useEffect(() => {
    if (isEditing) {
      dispatch(getRequest(id));
    }
  }, [dispatch, isEditing, id]);

  useEffect(() => {
    dispatch(getRequestTypesAsync());
    dispatch(fetchBrands());
  }, [dispatch]);

  const onSubmit = async (values) => {
    const fieldValues = { ...values };
    let requestPayload;
    if (
      values.file_dimentions.length === 0 &&
      typeof values.file_dimentions === "string"
    ) {
      Object.assign(fieldValues, { file_dimentions: [] });
    }
    if (values.file_dimentions && typeof values.file_dimentions === "string") {
      const dimensions = [
        {
          category_id: values.dimentions[0].category_id,
          category_item_id: values.dimentions[0].category_item_id,
          icon: values.dimentions[0].icon,
          title_id: "custom",
          dimension_string: values.dimentions[0].dimension_string,
          height: 0,
          length: 0,
          units: "px",
        },
      ];
      Object.assign(fieldValues, { file_dimentions: dimensions });
      requestPayload = {
        ...fieldValues,
        attachments,
        dimentions: undefined,
      };
    } else {
      requestPayload = {
        ...fieldValues,
        attachments,
        file_dimentions: values.dimentions,
        dimentions: undefined,
      };
    }
    const { status } = await RequestService.submitRequest(id, requestPayload);
    if (status === "success") {
      navigate("/requests");
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSaveRequestField = useCallback(
    debounce(async (nextValue) => {
      await RequestService.updateRequest({ data: nextValue, id });
    }, 1000),
    [id],
  );

  if (error?.message === "Access Denied" && !loading) {
    return (
      <EmptyState
        icon="cancel"
        title="Access Denied"
        description={
          <>
            <span>Sorry, You don&rsquo;t have access to this request</span>
            <Box padding={{ top: 16 }}>
              <Button
                variant="primary"
                label="Go Back"
                onClick={() => navigate("/requests")}
              />
            </Box>
          </>
        }
      />
    );
  }

  return (
    <CreateRequestContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        onChangeField: onSaveRequestField,
      }}
    >
      <FullscreenView>
        <DashboardForm
          enableReinitialize
          initialValues={formValues || initialValues}
          validationSchema={RequestSchema}
          validateOnBlur={false}
          onSubmit={onSubmit}
          form={{ ...getRootProps() }}
        >
          {({ isSubmitting }) => (
            <>
              <ScrollToFieldError />
              <FullscreenHeader
                // title={isEditing ? "Edit Request" : "New Request"}
                title={lastSlug !== "new" ? "Edit Request" : "New Request"}
                controls={
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    label="Make Request"
                  />
                }
                onClose={() => navigate("/requests")}
              />
              <FullscreenContent>
                {loading && (
                  <Box padding={{ top: 64 }}>
                    <ContentState />
                  </Box>
                )}
                {!loading && (
                  <FormLayout
                    title="Making a request is easy"
                    description="Send your request our way and our designers are waiting to serve you with your design requests."
                  >
                    <FormBody
                      requestId={id}
                      brandsLoading={brandsLoading}
                      isEditing={isEditing}
                      brands={brands || []}
                      attachments={attachments || []}
                      onClickAttachment={open}
                      onRemoveAttachment={onRemoveAttachment}
                      isUploading={isUploading}
                      inputProps={{ ...getInputProps() }}
                    />
                  </FormLayout>
                )}
              </FullscreenContent>
            </>
          )}
        </DashboardForm>
      </FullscreenView>
    </CreateRequestContext.Provider>
  );
}

export default CreateRequest;
