import styled from "styled-components";

import Button from "../Button/Button";

const Header = styled.div`
  justify-content: space-between;
  align-items: center;
  padding: 6px !important;
  margin: 0 !important;
  background-color: #f6f8fa;
`;

function FilterHeader({ onClear, onDone }) {
  return (
    <Header className="Flex-flex">
      <div>
        <Button variant="default" label="Clear" onClick={onClear} />
      </div>
      <div class="title2">Filters</div>
      <div>
        <Button variant="primary" label="Done" onClick={onDone} />
      </div>
    </Header>
  );
}

export default FilterHeader;
