import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import { useLocation } from "react-router-dom";

import {
  Message,
  Modal,
  Select,
  Button,
  Box,
  FormFieldInputError,
} from "@atd/components";
import { createTeam, fetchTeams } from "@atd/features";
import { TeamSchema } from "@atd/schema";
import { getRoleById } from "@atd/utils";

function TeamsActions({ user, disabled, roles }) {
  const location = useLocation();

  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [roleFilter, setRoleFilter] = useState("");

  const dispatch = useDispatch();

  const onSubmit = async (values, { setSubmitting }) => {
    await dispatch(createTeam(values));
    setSubmitting(false);
    setIsOpenEditModal(false);
  };

  const onChangeFilter = useCallback(
    (value) => {
      setRoleFilter(value);
      if (value) {
        dispatch(fetchTeams({ filter_type: value }));
      } else {
        dispatch(fetchTeams());
      }
    },
    [dispatch],
  );

  const roleOptions =
    roles.length > 0
      ? roles.map((role) => ({ label: role.name, value: role.id }))
      : [];

  return (
    <>
      <Modal
        id="adduser"
        title="Invite new users"
        isOpen={isOpenEditModal}
        onClose={setIsOpenEditModal}
      >
        <Formik
          initialValues={{ email: "", role: 1 }}
          onSubmit={onSubmit}
          validationSchema={TeamSchema}
          validateOnBlur={false}
        >
          {({ isSubmitting, errors, touched, values }) => {
            const { name, note } = getRoleById(values.role) || {};

            return (
              <Form>
                <div className="pd16">
                  <div>
                    Enter the email addresses of the users you&rsquo;d like to
                    invite for just $9/month, and choose the role they should
                    have.
                  </div>
                  <div className="grid grid-column-2 grid-align-top mgt1">
                    <div className="FormField mgt1" id="rtype">
                      <Field
                        name="email"
                        className={`iput Margin-right--8 wt-100 ${
                          errors.email &&
                          touched.email &&
                          "FormField__input--hasError"
                        }`}
                        placeholder="Email address..."
                        type="text"
                        style={{ minWidth: "100%" }}
                        autoFocus
                      />
                      <FormFieldInputError name="email" />
                    </div>
                    <div className="FormField mgt1" id="rtype">
                      <Field name="role">
                        {({
                          field: { value },
                          form: { setFieldValue, setFieldTouched },
                        }) => (
                          <>
                            <Select
                              className="wt-100"
                              value={value}
                              options={roleOptions}
                              onChange={(newValue) => {
                                setFieldValue("role", newValue);
                              }}
                              placeholder="Role"
                              inputStyle={{
                                minWidth: "100%",
                              }}
                              inputProps={{
                                className: `iput ${
                                  errors.role && "FormField__input--hasError"
                                }`,
                                onBlur: () => {
                                  setFieldTouched("role", true);
                                },
                              }}
                            />
                            {errors.role && (
                              <div className="FormField__input--error">
                                {errors.role}
                              </div>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                  <Message
                    className="Margin-top--20"
                    title={`Note: For ${name}`}
                    text={note}
                  />
                </div>
                <Modal.Footer>
                  <div className="Box-root Box-hideIfEmpty Margin-right--8">
                    <Button
                      variant="default"
                      type="button"
                      onClick={() => setIsOpenEditModal(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="Box-root Box-hideIfEmpty">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting || Object.keys(errors).length > 0}
                    >
                      Invite
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      <div className="Box-root">
        <div
          id="tm"
          className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexStart Flex-wrap--nowrap"
          style={{ marginLeft: "-8px", marginTop: "-8px" }}
        >
          <Box hideIfEmpty margin={{ top: 8, left: 8 }}>
            <Select
              value={roleFilter}
              options={[{ label: "All Roles", value: "" }, ...roleOptions]}
              onChange={onChangeFilter}
              render={({ formattedValue }) => (
                <Button
                  disabled={disabled}
                  variant="default"
                  type="button"
                  icon="filter"
                  label={formattedValue ?? "Filters"}
                />
              )}
            />
          </Box>
          {location.pathname === "/settings/teams" && (
            <Box hideIfEmpty margin={{ top: 8, left: 8 }}>
              {user && ![3, 4].includes(user?.role) && (
                <Button
                  disabled={disabled}
                  variant="primary"
                  icon="plus"
                  iconColor="white"
                  label="New User"
                  onClick={() => setIsOpenEditModal(true)}
                />
              )}
            </Box>
          )}
        </div>
      </div>
    </>
  );
}

export default TeamsActions;
