import { Field } from "formik";

import { Editor, FormFieldInputError } from "@atd/components";

function ProjectDetails({ onSetFieldValue }) {
  return (
    <>
      <div className="title2 mgt4">
        Design Brief
        <span style={{ color: "red" }}>*</span>
      </div>
      <div className="cont4">
        Tell us what your design expectations are. The more details, the better!
      </div>
      <div className="FormField mgt1">
        <Field name="project_details">
          {({ field: { value } }) => (
            <Editor
              model={value}
              config={{
                placeholderText:
                  "Your perfectly crafted design brief goes here!",
              }}
              onModelChange={(newValue) => {
                onSetFieldValue("project_details", newValue);
              }}
            />
          )}
        </Field>
        <FormFieldInputError name="project_details" />
      </div>
    </>
  );
}

export default ProjectDetails;
