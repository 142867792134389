import "./FullscreenView.css";

function FullscreenView({ children }) {
  return (
    <div className="FullscreenView FullscreenView--isLayer Box-root Box-background--white Flex-flex Flex-alignItems--center Flex-direction--column Flex-justifyContent--center">
      {children}
    </div>
  );
}

export default FullscreenView;
