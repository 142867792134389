import { getCredentialCookies } from "@atd/utils";

import { accountApi } from "./accountApi";

const { token } = getCredentialCookies();

const plansApi = accountApi.injectEndpoints({
  endpoints: (build) => ({
    addPrice: build.mutation({
      query: (body) => ({
        url: "price",
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body,
      }),
      invalidatesTags: ["Billing"],
    }),
  }),
  overrideExisting: false,
});

// eslint-disable-next-line import/prefer-default-export
export const { useAddPriceMutation } = plansApi;
