import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ContentState, EmptyState } from "@atd/components";
import { fetchRoles, fetchTeams } from "@atd/features";

import TeamsList from "./TeamsList";

function Users({ params, showActions }) {
  const { teams, loading } = useSelector((state) => state.team);
  const { roles } = useSelector((state) => state.roles);
  const dispatch = useDispatch();

  useEffect(() => {
    if (params) {
      dispatch(fetchTeams(params));
    } else {
      dispatch(fetchTeams());
    }
    dispatch(fetchRoles());
  }, [dispatch, params]);

  return (
    <>
      {loading && <ContentState text="Loading" />}
      {!loading && teams?.length === 0 && (
        <EmptyState
          icon="teams"
          title="No results found"
          description={
            <span>There aren&rsquo;t any results for that query.</span>
          }
        />
      )}
      {!loading && teams?.length > 0 && (
        <TeamsList roles={roles} items={teams} showActions={showActions} />
      )}
    </>
  );
}

Users.defaultProps = {
  showActions: true,
};

export default Users;
