import { Box, AppHeader, Typography } from "@atd/components";

import ProfileForm from "./ProfileForm/ProfileForm";

function Profile() {
  return (
    <>
      <AppHeader
        title={<Typography>Account Settings</Typography>}
        spread="settingsContent"
      />
      <Box margin={{ top: 20 }}>
        <ProfileForm />
      </Box>
    </>
  );
}

export default Profile;
