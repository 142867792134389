/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useFormikContext } from "formik";

import { Panel, Button } from "@atd/components";

import ChangeProfileForm from "./ChangeProfileForm";

function ChangeProfile({ isLoading, user }) {
  const { isSubmitting } = useFormikContext();

  return (
    <Panel>
      <Panel.Header title="Change Profile" />
      <Panel.Body>
        <ChangeProfileForm isLoading={isLoading} user={user} />
      </Panel.Body>
      <Panel.Footer right>
        <div className="Box-root Box-hideIfEmpty Margin-right--8">
          <Button type="button" disabled={isSubmitting} variant="default">
            Cancel
          </Button>
        </div>
        <div className="Box-root Box-hideIfEmpty">
          <Button type="submit" disabled={isSubmitting} variant="primary">
            Save Profile
          </Button>
        </div>
      </Panel.Footer>
    </Panel>
  );
}

export default ChangeProfile;
