import FeedbackForm from "./FeedbackForm";
import FeedbackSuccess from "./FeedbackSuccess";

import FeedbackReview from "./FeedbackReview";
import FeedbackReviewLegacy from "./FeedbackReviewLegacy";

import "./Feedback.css";

function Feedback({ disabled, isSuccess = false, onChange, onSubmit }) {
  return (
    <div className="globalContent">
      <main className="columns">
        {!isSuccess && (
          <FeedbackForm
            onSubmit={onSubmit}
            onChange={onChange}
            disabled={disabled}
          />
        )}
        {isSuccess && <FeedbackSuccess />}
      </main>
    </div>
  );
}

Feedback.Review = FeedbackReview;
Feedback.ReviewLegacy = FeedbackReviewLegacy;

export default Feedback;
