import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "@atd/app/axiosClient";

const initialState = {
  roles: [],
  role: null,
  loading: true,
  error: null,
};

export const fetchRoles = createAsyncThunk("role/fetchRoles", async () => {
  try {
    const { data } = await axios.get("/roles");

    if (data.status === "error") {
      throw new Error(data.message);
    }

    return data.results;
  } catch (error) {
    throw Error(error);
  }
});

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchRoles.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.roles = action.payload;
    },
    [fetchRoles.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export default roleSlice.reducer;
