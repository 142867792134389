import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { ContentState } from "@atd/components";
import { axios } from "@atd/utils";

function Ticket() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const tickId = searchParams.get("id");

  useEffect(() => {
    (async () => {
      const { data } = await axios.post("/request/s_no", {
        s_no: tickId,
      });
      navigate(`/requests/thread/${data.results.request_id}`);
    })();
  }, [navigate, tickId]);

  return <ContentState text="Processing..." />;
}

export default Ticket;
