import classNames from "classnames";
import uuid from "react-uuid";

import Box from "../Box/Box";
import Text from "../Text/Text";
import Icon from "../Icon/Icon";

function FeedbackReviewLegacy({ comment, rating1, rating2, rating3 }) {
  return (
    <Box className="FeedbackReviewLegacy__container">
      <Box flex={{ alignItems: "center", direction: "column" }}>
        <Box className="FeedbackReviewLegacy__icon">
          <Icon icon="review" />
        </Box>
        <ReviewsHeading />
        <ReviewsContent comment={comment} />
        <Reviews rating_1={rating1} rating_2={rating2} rating_3={rating3} />
      </Box>
    </Box>
  );
}

function ReviewsHeading() {
  return (
    <Box className="FeedbackReviewLegacy__heading">
      Thank you for the feedback
    </Box>
  );
}

function ReviewsContent({ comment }) {
  return <Box className="FeedbackReviewLegacy__content">{comment}</Box>;
}

export function ReviewStar({ index, rating, ...rest }) {
  return (
    <span
      className={classNames("FeedbackReviewLegacy__reviews--star", {
        "FeedbackReviewLegacy__reviews--star-fill": index <= rating,
      })}
      {...rest}
    >
      ★
    </span>
  );
}

function ReviewItem({ label, rating }) {
  return (
    <Box
      className="FeedbackReviewLegacy__reviews--item"
      flex={{ alignItems: "center", justifyContent: "space-between" }}
    >
      <Text
        color="default"
        size={20}
        typeface="base"
        wrap="wrap"
        display="block"
        weight="medium"
      >
        {label}
      </Text>
      <Box>
        {Array(5)
          .fill()
          .map((_, index) => (
            <ReviewStar index={index + 1} rating={rating} key={uuid()} />
          ))}
      </Box>
    </Box>
  );
}

function Reviews({ rating_1, rating_2, rating_3 }) {
  return (
    <Box className="FeedbackReviewLegacy__reviews">
      <Box>
        <ReviewItem label="Communication" rating={rating_1} />
        <ReviewItem label="Service as described" rating={rating_2} />
        <ReviewItem label="Overall Experience" rating={rating_3} />
      </Box>
    </Box>
  );
}

export default FeedbackReviewLegacy;
