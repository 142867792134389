import React from "react";

import EmptyState from "../EmptyState/EmptyState";
import { Main, Sidebar } from "../../layouts";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    // You can also log the error to an error reporting service
    this.setState({ error: error.message, hasError: true });
  }

  render() {
    const { children } = this.props;
    const { error, hasError } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className="atd-World-root">
          <div className="atd-World-wrapper Box-root Flex-flex Flex-direction--column">
            <div className="atd-World-scrollWrapper">
              <Sidebar />
              <Main>
                <EmptyState
                  icon="client"
                  title="Something went wrong."
                  description={
                    <span>
                      Error:
                      {error}
                    </span>
                  }
                />
              </Main>
            </div>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
