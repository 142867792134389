import { useState } from "react";

function SearchInput({ searchImageHandler }) {
  const [searchInp, setSearchInp] = useState("");

  const searchInputHandler = (e) => {
    setSearchInp(e.target.value);
  };

  const onKeyDownHandler = (e) => {
    const code = e.charCode || e.which;
    if (code === 13) {
      searchImageHandler(searchInp);
      setSearchInp("");
    }
  };

  return (
    <div className="FormField mgt1">
      <div className="btn">
        <input
          name="type"
          className="iput wt-100"
          placeholder="eg. Digital"
          type="text"
          value={searchInp}
          onChange={searchInputHandler}
          onKeyDown={onKeyDownHandler}
        />
        <span className="iconn">
          <svg
            aria-hidden="true"
            className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--search-svg Icon-color-svg Icon-color--gray500-svg"
            height={12}
            width={12}
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6 11.2c.037.028.073.059.107.093l3 3a1 1 0 1 1-1.414 1.414l-3-3a1.009 1.009 0 0 1-.093-.107 7 7 0 1 1 1.4-1.4zM7 12A5 5 0 1 0 7 2a5 5 0 0 0 0 10z"
              fillRule="evenodd"
            />
          </svg>
        </span>
      </div>
    </div>
  );
}

export default SearchInput;
