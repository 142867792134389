import React, { useEffect } from "react";
import ActivityGroupContent from "./ActivityGroupContent";
import { Box, ContentState } from "@atd/components";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivities } from "@atd/features";

function ActivityGroup({ params, page, loaderRef, setIsResetData, users }) {
  const { activities, reference_data, hasMore, loading, fetching } =
    useSelector((state) => state.activity);
  const dispatch = useDispatch();
  useEffect(() => {
    const realPage = page + 1;
    const promise = dispatch(
      realPage === 1
        ? fetchActivities(params, realPage)
        : fetchActivities(params, realPage, reference_data),
    );
    promise.then(() => {
      setIsResetData(false);
    });
  }, [page, params, setIsResetData, dispatch]);
  return (
    <Box flex={{ direction: "column" }}>
      <ActivityGroupContent
        loading={loading}
        fetching={fetching}
        activities={activities}
        params={params}
        users={users}
      />
      {(hasMore || fetching) && (
        <Box ref={loaderRef}>
          <ContentState text="Loading..." />
        </Box>
      )}
    </Box>
  );
}

export default ActivityGroup;
