import * as React from "react";

function SvgArrowExport(props) {
  return (
    <svg
      aria-hidden="true"
      className="ArrowExport_svg__SVGInline-svg ArrowExport_svg__SVGInline--cleaned-svg ArrowExport_svg__SVG-svg ArrowExport_svg__Icon-svg ArrowExport_svg__Icon--arrowExport-svg ArrowExport_svg__Button-icon-svg ArrowExport_svg__Icon-color-svg ArrowExport_svg__Icon-color--gray600-svg"
      height={12}
      width={12}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 10.006a1 1 0 1 1-2 0v-5.6L2.393 15.009A.992.992 0 1 1 .99 13.605L11.595 3.002h-5.6a1 1 0 0 1 0-2.001h8.02a1 1 0 0 1 .284.045.99.99 0 0 1 .701.951z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgArrowExport;
