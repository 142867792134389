import React, { useState, useMemo, useCallback } from "react";
import {
  Box,
  Heading,
  DateFilter,
  Charts,
  DataTable,
  SearchFilter,
  Table,
} from "@atd/components";
import MoreReport from "./MoreReport";
import Border from "../../Border";
import CollapsibleReport from "../AnalyticalReport/CollapsibleReport";
import { useGetDailyReportsQuery } from "@atd/features/team/teamApi";
import styled from "styled-components";
import { useLocalStorage } from "@atd/hooks";

const DailyReportTable = styled(DataTable)`
  th {
    line-height: 20px;
    font-weight: 500;
    font-size: 14px;
    color: #3c4257;
    text-align: center;
  }
  th:first-child {
    text-align: start;
  }
  span {
    font-weight: 400;
  }
  td:first-child {
    text-align: start;
  }
  td {
    text-align: center;
  }
`;
const DailyReport = ({
  path,
  queryParam,
  dateFilterOptions,
  designerFilterOptions,
}) => {
  const [dailyReportFilters, setDailyReportFilters] = useLocalStorage(
    "daily-report-filters",
    {
      designer: queryParam ? queryParam : undefined,
      date_string: "today",
    },
  );
  const params = useMemo(() => {
    return {
      designer: queryParam ? queryParam : undefined,
      date_string: dailyReportFilters["date_string"],
    };
  }, [dailyReportFilters, queryParam]);
  const [queryParams, setQueryParams] = useState(params);

  const { data, isLoading } = useGetDailyReportsQuery(queryParams);
  const { chart, table, designer } = data || {};
  const onChangeFilter = (field, value) => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      [field]: value,
    }));
    setDailyReportFilters((prevParams) => ({
      ...prevParams,
      [field]: value,
    }));
  };
  const columns = useMemo(
    () => [
      {
        Header: "DESIGNERS",
        accessor: "name",
        Cell: ({ value }) => (
          <span className="title2 blu-txt cli">{value}</span>
        ),
        onClick: (id) => {
          window.location.href = `/reports/analytics/${path}/${id}`;
        },
      },
      {
        Header: "New",
        accessor: "new",
        Cell: ({ value }) => <span className="">{value || 0}</span>,
      },
      {
        Header: "IN PROGRESS",
        accessor: "in_progress",
        Cell: ({ value }) => <span className="">{value || 0}</span>,
      },
      {
        Header: "REVISION",
        accessor: "revision",
        Cell: ({ value }) => <span className="">{value || 0}</span>,
      },
      {
        Header: "DELIVERED",
        accessor: "delivered",
        Cell: ({ value }) => <span className="">{value || 0}</span>,
      },
    ],
    [path],
  );
  const chartData = useMemo(() => {
    return chart ? chart : queryParam ? [] : [{ name: "", data: [] }];
  }, [chart, queryParam]);
  const collapsible = table?.map((val) => false);
  const [collapse, setCollapse] = useState(collapsible || [false]);
  const toggle = useCallback(
    (index) => {
      let collapseFilter = collapse;
      collapseFilter[index] = !collapseFilter[index];
      setCollapse([]);
      setCollapse((arr) => [...arr, ...collapseFilter]);
    },
    [collapse],
  );
  return (
    <Box>
      <Border
        margin={{ mt: path ? "8px" : "20px", mb: "0px" }}
        border={{ position: "bottom" }}
      />
      <Heading
        headText="Daily Reports"
        headerClassName="Text-fontSize--20 Text-fontWeight--bold Text-lineHeight--28 Padding-left--8"
        rightContent={
          <Box
            flex={{
              direction: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {queryParam && designerFilterOptions ? (
              <>
                <SearchFilter
                  field="designer"
                  placeholder="Designer"
                  value={queryParams?.designer}
                  options={designerFilterOptions}
                  onChange={onChangeFilter}
                />
              </>
            ) : null}
            <Box className="Margin-left--8">
              <DateFilter
                value={queryParams?.date_string}
                startDate={queryParams?.start_date}
                endDate={queryParams?.end_date}
                options={dateFilterOptions}
                onChange={onChangeFilter}
              />
            </Box>
          </Box>
        }
      />
      <Border
        margin={{ mt: "10px", mb: "10px" }}
        border={{ position: "bottom" }}
      />
      <Charts
        id={queryParam ? designer : ""}
        type={`${queryParam ? "bar" : "stacked"}`}
        width="100%"
        height="380"
        chartData={chartData || []}
        isLoading={isLoading}
      />
      {path === "dailyreport" ? (
        <>
          {!queryParam ? (
            <DailyReportTable
              tabs={[""]}
              withTabs={false}
              isLoading={isLoading}
              columns={columns}
              data={table}
            />
          ) : (
            <>
              <Table className="wt-100">
                <Table.Head>
                  <Box className="Margin-left--24 Padding-left--8">
                    <Heading
                      className="status_heading"
                      headerClassName="Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20"
                      headText={"STATUS"}
                    />
                  </Box>
                </Table.Head>
                <Table.Body>
                  {table?.length
                    ? table.map((value, i) => (
                        <CollapsibleReport
                          key={i}
                          data={value}
                          showDefault
                          // headers={headers}
                          toggle={() => toggle(i)}
                          collapse={collapse[i]}
                          firstKey="ticket"
                        />
                      ))
                    : null}
                </Table.Body>
              </Table>
            </>
          )}
        </>
      ) : (
        <>
          <MoreReport name="More analytics" path="dailyreport" />
          <Border
            margin={{ mt: "10px", mb: "10px" }}
            border={{ position: "bottom" }}
          />
        </>
      )}
    </Box>
  );
};

export default DailyReport;
