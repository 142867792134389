import { useState, memo } from "react";
import { toast } from "react-toastify";
import uuid from "react-uuid";
import styled from "styled-components";

import { Modal, Button, Icon } from "@atd/components";

import RoyalImageSearchForm from "./RoyalImageSearchForm";

const ImageHolder = styled.div`
  position: relative;
  /* background: #ebebeb; */

  & img {
    width: 40px;
    height: 59px;
    object-fit: cover;
  }

  &:hover .remove {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
`;

const RemoveImage = styled.span`
  background: #df1b41;
  width: 18px;
  height: 18px;
  border-radius: 100px;
  position: absolute;
  right: -4px;
  top: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.4);
  transition: all 300ms ease-out;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  z-index: 1;

  & svg {
    with: 10px;
    height: 10px;
  }
`;

function RoyalImageItem({ images, onRemoveImage }) {
  return (
    <div className="imgs_wrapper">
      {Array.isArray(images) &&
        images.map((img, index) => (
          <ImageHolder key={uuid()}>
            <RemoveImage
              className="remove"
              onClick={() => onRemoveImage(index)}
            >
              <Icon icon="cancel" color="white" />
            </RemoveImage>
            <img src={img} alt={`request-royalty-${uuid()}`} />
          </ImageHolder>
        ))}
    </div>
  );
}

function RoyalImages({ values, onSetFieldValue }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const royalImages = values?.royalty_free_images || [];

  const onRemoveImage = (index) => {
    const updatedImages = [...royalImages];
    if (index > -1) {
      updatedImages.splice(index, 1); // 2nd parameter means remove one item only
    }
    onSetFieldValue("royalty_free_images", updatedImages);
  };

  return (
    <>
      <Modal
        title="Select your royalty images"
        isOpen={isModalOpen}
        onClose={setIsModalOpen}
      >
        <RoyalImageSearchForm
          onCloseModal={() => setIsModalOpen(false)}
          royalImagesHandler={(image) => {
            if (royalImages?.length < 4) {
              onSetFieldValue("royalty_free_images", [...royalImages, image]);
            } else {
              toast.warning("You can only select upto max 4 images!");
            }
          }}
        />
      </Modal>
      <div className="Box-root Flex-flex Flex-alignItems--center Flex-direction--row mgt4 wt200">
        <div className="Box-root Margin-right--8">
          <span
            title="Gross volume"
            className="Text-color--default Text-fontSize--14 Text-fontWeight--medium Text-lineHeight--20 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--block customcss7"
          >
            <span>Do you need royalty free images?</span>
          </span>
        </div>
        <div className="Box-root Box-hideIfEmpty mwt58">
          <div
            aria-label="0.0%"
            title="0%"
            className="Badge Badge--variant--status Box-root Box-background--gray100 Flex-inlineFlex Flex-alignItems--center Flex-direction--row"
          >
            <span className="Badge-text Text-color--gray600 Text-fontSize--12 Text-fontWeight--medium Text-lineHeight--16 Text-numericSpacing--proportional Text-typeface--base Text-wrap--noWrap Text-display--inline">
              Optional
            </span>
          </div>
        </div>
      </div>
      <div className="FormField mgt1">
        <div className="royalityImages__wrapper">
          <div>
            <Button
              icon="plus"
              variant="default"
              className="btn-pd"
              label="Select Images"
              type="button"
              onClick={() => setIsModalOpen(true)}
            />
          </div>
          {royalImages?.length > 0 && (
            <p className="royalityImages__status">
              {royalImages?.length} file
              {royalImages?.length > 1 ? "s" : ""}
            </p>
          )}
          <RoyalImageItem images={royalImages} onRemoveImage={onRemoveImage} />
        </div>
      </div>
    </>
  );
}

export default memo(RoyalImages);
